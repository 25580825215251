/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dragula from "react-dragula";
import uniqid from 'uniqid';
import { Modal } from 'react-bootstrap';
import RearrangeImages from './rearrangeImages';
import Button from '../components/buttons/default';
import { handleImageTypeReorder } from '../helpers';

const _api = require('../api')

const ArrangeImagesList = (props) => {
    const dispatch = useDispatch()
    const dragulaRef = useRef();
    const reArrangeRef = useRef();
    const entity = useSelector(state => state[props.id]);
    const [selectedImages, setSelectedImages] = useState([])
    const [view, setView] = useState('list');
    const [dragula, setDragula] = useState(uniqid());

    let currentImages = props.section === 'site' ? (entity ? entity.images?.filter(x => (x.type === 'image' || x.type === 'dzi') && x.date_id === null && x.type !== 'then_now') : []) : (entity ? entity.images?.filter(x => (x.type === 'image' || x.type === 'dzi')) : [])
    let imageRecognition = entity ? entity.images?.filter(x => (x.type === 'image-recognition') && x.type !== 'then_now') : []
    let currentDocuments = props.section === 'site' ? (entity ? entity.images?.filter(x => x.type === 'document' && x.date_id === null && x.type !== 'then_now') : []) : (entity ? entity.images?.filter(x => x.type === 'document') : [])
    let dragulaContainerImage = 'image'
    let dragulaContainerDocument = 'document'
    let dragulaContainerImageRecognition = 'image-recognition'
    useEffect(() => {
        let containers = [
            document.getElementById(dragulaContainerImage),
            document.getElementById(dragulaContainerDocument),  
            document.getElementById(dragulaContainerImageRecognition)
        ];
        if (props.entityType === "site") {
            entity.dates.forEach((date) => {
                containers.push(document.getElementById(`${dragulaContainerImage}-${date.id}`))
                containers.push(document.getElementById(`${dragulaContainerDocument}-${date.id}`))
            });
        }
        dragulaRef.current = Dragula(containers, {});
        dragulaRef.current.on('drop', () => { handleArrangeImages() })
    }, [dragula])

    const handleArrangeImages = () => {
        let imageList = [...entity.images]
        let dates = entity?.dates ? [...entity?.dates] : []
        const images = handleImageTypeReorder(imageList, dates, dragulaContainerImage)
        const documents = handleImageTypeReorder(imageList, dates, dragulaContainerDocument)
        const imageRecognition = handleImageTypeReorder(imageList, dates, dragulaContainerImageRecognition)
        reArrangeRef.current = [...images, ...documents, ...imageRecognition]
        setDragula(uniqid())
        dispatch({ type: 'entity', payload: { ...entity, images: reArrangeRef.current } });
    }
    const handleSelectedImages = (item) => {
        let images = [...selectedImages]
        if ((images.findIndex(x => x.id === item.id) === -1)) {
            images.push(item)
        }
        else {
            images = images.filter(x => x.id !== item.id)
        }
        setSelectedImages(images)
    }
    const handleSave = async () => {
        if (reArrangeRef.current?.length > 0) {
            //update Sort
            reArrangeRef.current.forEach((image, index) => image.sort = index + 1);

            entity.images = await _api[props.entityType].updateImage(props.id, reArrangeRef.current)
            dispatch({ type: 'entity', payload: { ...entity, images: reArrangeRef.current } });
        }
        props.onHide();
    }

    return (
        <Modal show={props.show} size='xl' onHide={() => props.onHide()}>
            <Modal.Body className='modal-bulk'>
                <div>
                    <div className='d-flex justify-content-between'>
                        <div className='cnow-form-title'>Drag and Drop the images to the required sections.</div>
                        <div>
                            <div className='d-flex gap-2'>
                                {view === 'list' && <Button size='sm' icon='grid' onClick={() => setView('grid')} />}
                                {view === 'grid' && <Button size='sm' icon='list' onClick={() => setView('list')} />}
                                <Button size='sm' label='Save' color='danger' onClick={() => handleSave()} />
                                <Button size='sm' icon='close' target='_self' onClick={() => props.onHide()} />
                            </div>
                        </div>
                    </div>
                    <hr className='cnow-hr' />

                    <RearrangeImages dragInstance={dragula} images={currentImages} handleSelectedImages={handleSelectedImages} selectedImages={selectedImages} title={props.section === 'site' ? 'Current Images' : 'Other Photos'} dragId={dragulaContainerImage} type={'Images'} />
                    {
                        (props.entityType === 'site' && entity.dates?.length > 0) && entity.dates.map((date, index) => {
                            let date_images = entity.images?.length > 0 && entity.images.filter(x => x.date_id === date.id && (x.type === 'image' || x.type === 'dzi') && x.type !== 'then_now')
                            return (<>
                                <RearrangeImages dragInstance={dragula} images={date_images} handleSelectedImages={handleSelectedImages} selectedImages={selectedImages} title={`${date?.year || ''} - ${date.event_type_name || ''} Images`} dragId={`${dragulaContainerImage}-${date.id}`} type={'date Images'} />
                            </>)
                        })
                    }
                    <RearrangeImages dragInstance={dragula} images={currentDocuments} handleSelectedImages={handleSelectedImages} selectedImages={selectedImages} title={props.section === 'site' ? 'Current documents' : 'Other Drawings'} dragId={dragulaContainerDocument} type={'documents'} />

                    {
                        (props.entityType === 'site' && entity.dates?.length > 0) && entity.dates.map((date, index) => {
                            let date_documents = entity.images?.length > 0 && entity.images.filter(x => x.date_id === date.id && x.type === 'document' && x.type !== 'then_now')
                            return (<>
                                <RearrangeImages dragInstance={dragula} images={date_documents} handleSelectedImages={handleSelectedImages} selectedImages={selectedImages} title={`${date.year || ''} - ${date.event_type_name || ''} Documents`} dragId={`${dragulaContainerDocument}-${date.id}`} type={'date documents'} />
                            </>)
                        })
                    }
                    <RearrangeImages dragInstance={dragula} images={imageRecognition} handleSelectedImages={handleSelectedImages} selectedImages={selectedImages} title='Image Recognition' dragId={dragulaContainerImageRecognition} type={'image-recognition'} />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ArrangeImagesList