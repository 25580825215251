/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid';
import Button from '../../components/buttons/default';
import LinkButton from '../../components/buttons/link';
import SelectPerson from '../../components/selects/person';
const _api = require('../../api')

const RecipePeople = (props) => {
    const dispatch = useDispatch();
    const recipe = useSelector(state => state[props.id]);
    const addpeople = (id) => {
        if (!recipe.people) recipe.people = [];
        let people_item = {
            'id': `temp.${uniqid()}`,
            'recipe_id': props.id,
            'profession_name': '',
            'role_name': props.title,
            'role_id': props.role_id,
            'sort': recipe.people?.length + 1,
            'type_id': recipe.type_id
        }
        recipe.people.push(people_item)
        recipe.people.forEach((item, index) => item.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...recipe, people: recipe.people } });
        props.refresh()
    }

    const removepeople = async (id) => {
        let index = recipe.people.findIndex((elem) => elem.id === id)
        if (recipe.people[index]) {
            if (!recipe.people[index].id.startsWith('temp.')) await _api.person.deleteRecipe(recipe.people[index].id)
            recipe.people.splice(index, 1)
            recipe.people.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...recipe, people: recipe.people } });
        }
    }

    const handlepeopleChange = (id, value) => {
        let index = recipe.people.findIndex((elem) => elem.id === id)
        recipe.people[index].person_id = value.id;
        recipe.people[index].person_name = value.name;
        recipe.people[index].profession_name = value.role_name;
        recipe.people[index].profession_id = value.role_id;
        dispatch({ type: 'entity', payload: { ...recipe, people: recipe.people } })
    };

    return (

        <>

            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>{props.title}</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a Person' onClick={() => addpeople()} />
                </div>
            </div>
            <div id={props.dragulaContainer} key={props.dragula} className='dragula-container'>
                {
                    recipe?.people?.length > 0 && recipe.people.filter((elem) => elem?.role_id === props?.role_id).map((item, index) => {
                        return (
                            <div className='row g-1 mt-1 dragula-item' key={index} id={item.id}>
                                {
                                    recipe?.people?.length > 1 &&
                                    <div className='col-auto' >
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Order</label>}
                                        <input type='text' id={`${item.person_name}, ${item.person_id}`} className='nwprime form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete="off" />
                                    </div>
                                }
                                <div className='col-auto d-flex flex-grow-1'>
                                    <div className='w-100 ms-2'>
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Person *</label>}
                                        <SelectPerson key={item.id} value={item} className="prime" onChange={(value) => handlepeopleChange(item.id, value)}  type='recipe_participant' />
                                    </div>
                                    <div className='w-100 ms-2'>
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Profession *</label>}
                                        <input value={item?.profession_name} disabled className='w-100 cnow-input-disabled-height' />
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label'></label>}
                                    <div className='btn-list'>
                                        <LinkButton icon='edit' to={`/people/${item.person_id}/editor`} />
                                        <Button icon='delete' onClick={() => removepeople(item.id)} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>


    )
}

export default RecipePeople;