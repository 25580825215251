/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/buttons/default';
import Spinner from '../../components/spinner';
import 'react-image-crop/dist/ReactCrop.css';
import { handleApiError } from '../../helpers';

const _api = require('../../api')

const ImageResize = (props) => {
    const id = props.match.params.id
    const type = props.match.params.type;
    const image = useSelector(state => state[id]);
    const [crop, setCrop] = useState({});
    const [aspectRatio, setAspect] = useState();

    const [scale, setScale] = useState({ scaleX: '', scaleY: '' });
    const [region, setRegion] = useState({ left: 0, top: 0, width: 0, height: 0 });
    const [spinner, showSpinner] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory()

    useEffect(() => {
        if (type === 'thumb') setAspect(1.25)
        if (type === 'banner') setAspect(3.2)
        if (type === 'headshot') setAspect(1 / 2)
    }, [type]);

    useEffect(() => {
        if (typeof (image) === 'undefined') {
            _api.image.getById(id).then(image => {
                dispatch({ type: 'entity', payload: image })
            }).catch(error => console.log("eror: ", error))
        }
    }, [id])

    useEffect(() => {
        if (image?.type === 'document' && image?.url.toLowerCase().endsWith('original.pdf')) {
            image.url = image.url.replace('.pdf', '.png');
        }
      }, [image]);

    const handleLoad = ({ target: img }) => {
        const { offsetHeight, offsetWidth, naturalHeight, naturalWidth } = img;
        setScale({ scaleX: naturalHeight / offsetHeight, scaleY: naturalWidth / offsetWidth })
    };

    const handleSave = () => {
        if(region?.width){
        showSpinner(true)
        _api.image.process({
            'id': image.id,
            'type': type,
            'region': region
        }).then(response => {
            toast.success('Your image has been cropped')
            showSpinner(false)
        }).catch(handleApiError);}
    }

    const cropImage = async (crop) => {
        setRegion({
            left: parseInt(crop.x * scale.scaleX),
            top: parseInt(crop.y * scale.scaleY),
            width: parseInt(crop.width * scale.scaleY),
            height: parseInt(crop.height * scale.scaleX)
        });
    };

    return (
        <div>
            <div id='top' className='fixed-top border-bottom p-3 bg-white'>
                <div className='row'>
                    <div className='col'>
                        <strong>
                            {type === 'web' && 'Image Editor'}
                            {type === 'thumb' && 'Edit Thumbnail'}
                            {type === 'banner' && 'Edit Banner'}
                        </strong>
                    </div>
                    <div className='col-auto'>
                        <div className='btn-list'>
                            <Spinner display={spinner}>
                                <Button size='sm' label='Save' color='danger' onClick={handleSave} />
                                <Button size='sm' icon='close' onClick={() => history.goBack()} />
                            </Spinner>
                        </div>
                    </div>
                </div>
            </div>

            <div id='image_tagger' className='cnow-image-resize-container text-center'>
                <ReactCrop
                    crop={crop}
                    onChange={crop => setCrop(crop)}
                    onComplete={crop => cropImage(crop)}
                    aspect={aspectRatio}
                    ruleOfThirds
                >
                    <img alt={image?.alt_text || image?.caption} src={image?.url} onLoad={handleLoad} />
                </ReactCrop>
            </div>
        </div>
    )
}
export default ImageResize;   
