import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid'
import PageTitle from '../../components/pageTitle';
import AwardEditor from '../../components/editors/awardEditor';
import DesignationEditor from '../../components/editors/designationEditor';

const SiteAwardDesignation = (props) => {
  const site = useSelector(state => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (
    <>
      {
        site && <>
          <PageTitle title={site.name} />
          <AwardEditor id={props.id} type='award_site' key={`award-editor-${key}`} showDate={true} refresh={() => setKey(uniqid())} entity={'site'} />
          <DesignationEditor id={props.id} type='site' key={`designation-editor-${key}`} refresh={() => setKey(uniqid())} />
        </>
      }
    </>
  );
};

export default SiteAwardDesignation;