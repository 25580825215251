import React from 'react';
import ImageViewer from './viewers/image';

const TourInlineImageList = (props) => {
    return (
        props.items?.length > 0 && <div className='d-flex inlineimagelist'>
            {
                props.items?.length > 0 && props?.items?.map((item, index) => {
                    const selected = props.image ? props.image.id === item.image_id : props.bannerImageId === item.image_id;
                    return (
                        item?.label ?
                            <div className='pe-2'>
                                <div className='d-flex fw-bold align-items-center text-truncate ps-2'><div className={`tour-stop-site-number-red`}>{item.label}</div><div className='ps-2'>{item?.site_name}</div></div>
                                <div className='d-flex'>
                                    {item.image.map((elem) => {
                                        return <span onClick={() => props.onSelect(elem.image_id)}>
                                            <ImageViewer key={elem?.image_id} type='thumb' cssClass={`thumb thumb-auto m-2 col-sm-6 col-lg-4 ${props?.image?.id === elem?.image_id ? 'highlight-box' : ''}`} entityId={elem?.image_id} entityType='image' />
                                        </span>
                                    }
                                    )}
                                </div>
                            </div>
                            :
                            <div key={item.id} onClick={() => props.onSelect(item.image_id)} >
                                {item.index ? <div className={Number.isInteger(item.index) ? `tour-stop-number-red d-flex ms-1` : `tour-stop-site-number-red ms-1`}>{item.index}</div> : <div>&nbsp;</div>}
                                <ImageViewer key={item.image_id} type='thumb' cssClass={`thumb thumb-auto m-2 col-sm-6 col-lg-4 ${selected ? 'highlight-box' : ''}`} entityId={item.image_id} entityType='image' />
                            </div>
                    )
                })
            }
        </div>
    )
}

export default TourInlineImageList