import React, { useState, useEffect, useRef } from "react";
import uniqid from 'uniqid'
import Dragula from 'react-dragula'
import { useDispatch, useSelector } from 'react-redux';
import SelectGlobalList from './selects/globalList';
import Button from './buttons/default';
import { handleReorder, newTempId } from '../helpers';
const _api = require('../api')
const Category = (props) => {

    const dispatch = useDispatch();
    const entity = useSelector((state) => state[props.id]);

    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-category'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.categories, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, categories: items } })
            props.refresh && props.refresh()
        })
        // eslint-disable-next-line
    }, [dragula])
    // Dragula End

    //#region Category
    const addCategory = (category_id) => {
        if (!entity.categories) entity.categories = [];
        entity.categories.push({
            'id': newTempId(),
            [`${props.type}_id`]: entity.id,
            'category_name': '',
            'category_id': category_id,
            'sort': entity.categories?.length + 1
        });
        entity.categories.forEach((item, index) => item.sort = index + 1)
        setDragula(uniqid())
        dispatch({ type: 'entity', payload: { ...entity, categories: entity.categories } });
    }

    const removeCategory = async (index) => {
        if (entity.categories[index]) {
            if (!entity.categories[index].id.startsWith('temp.'))
                await _api[props.type].deleteCategory(entity.categories[index].id)

            entity.categories.splice(index, 1)
            entity.categories.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...entity, categories: entity.categories } });
        }
    }

    const handleCategoryChange = (id, data) => {
        let index = entity.categories.findIndex(function (item) { return item.id === id; });
        if (index >= 0) {
            entity.categories[index].category_id = data.id;
            entity.categories[index].category_name = data.name
        }
        dispatch({ type: 'entity', payload: { ...entity, categories: entity.categories } });
    };
    //#endregion

    return (
        <div id='category'>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <div className='form-group'>
                        <div className='cnow-form-title'>Category *</div>
                    </div>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a Category' onClick={() => addCategory(null)} disabled={(props?.disableButton && entity?.categories?.length === 3) ? true : false} />
                </div>
            </div>

            <div id={dragulaContainer} key={dragula}>
                {
                    entity?.categories?.length > 0 && entity.categories.map((category, index) => {
                        return (
                            <div className='row g-1 mb-3 dragula-item' id={category.id} key={category.id}>
                                {
                                    entity.categories.length > 1 &&
                                    <div className='col-auto'>
                                        {index === 0 && <label className='cnow-form-label'>Order</label>}
                                        <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} data-id={category.id} autoComplete="off" />
                                    </div>
                                }
                                <div className='col'>
                                    <div className='w-100'>
                                        {index === 0 && <label className='cnow-form-label'>Category *</label>}
                                        <SelectGlobalList type={props?.globalListType} value={{ id: category.category_id, name: category.category_name }} onChange={(data) => handleCategoryChange(category.id, data)} />
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label'></label>}
                                    <div className='text-end'>
                                        <Button icon='delete' onClick={() => removeCategory(index)} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default Category