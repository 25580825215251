import React, { useState, useEffect, useRef } from "react";
import uniqid from 'uniqid'
import Dragula from 'react-dragula'
import { useDispatch, useSelector } from 'react-redux';
import SelectGlobalList from './selects/globalList';
import Button from './buttons/default';
import { handleReorder, newTempId } from '../helpers';
const _api = require('../api')

const EventType = (props) => {

    const dispatch = useDispatch();
    const entity = useSelector((state) => state[props.id]);

    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-event-type'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.types, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, types: items } })
            props.refresh && props.refresh()
        })
        // eslint-disable-next-line
    }, [dragula])
    // Dragula End

    //#region Event Type
    const addEventType = (type_id) => {
        if (!entity.types) entity.types = [];
        entity.types.push({
            'id': newTempId(),
            [`${props.type}_id`]: entity.id,
            'type_name': '',
            'type_id': type_id,
            'sort': entity.types?.length + 1
        });
        entity.types.forEach((item, index) => item.sort = index + 1)
        setDragula(uniqid())
        dispatch({ type: 'entity', payload: { ...entity, types: entity.types } });
    }

    const removeEventType = async (index) => {
        if (entity.types[index]) {
            if (!entity.types[index].id.startsWith('temp.'))
                await _api[props.type].deleteType(entity.types[index].id)

            entity.types.splice(index, 1)
            entity.types.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...entity, types: entity.types } });
        }
    }

    const handleEventTypeChange = (id, data) => {
        let index = entity.types.findIndex(function (item) { return item.id === id; });
        if (index >= 0) {
            entity.types[index].type_id = data.id;
            entity.types[index].type_name = data.name
        }
        dispatch({ type: 'entity', payload: { ...entity, types: entity.types } });
    };
    //#endregion

    return (
        <div id='types'>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <div className='form-group'>
                        <div className='cnow-form-title'>Type of Event *</div>
                    </div>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a type' onClick={() => addEventType(null)} />
                </div>
            </div>

            <div id={dragulaContainer} key={dragula}>
                {
                    entity?.types?.length > 0 && entity.types.map((event_type, index) => {
                        return (
                            <div className='row g-1 mb-3 dragula-item' id={event_type.id} key={event_type.id}>
                                {
                                    entity.types.length > 1 &&
                                    <div className='col-auto'>
                                        {index === 0 && <label className='cnow-form-label'>Order</label>}
                                        <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={event_type?.sort || index + 1} data-id={event_type.id} autoComplete="off" />
                                    </div>
                                }
                                <div className='col'>
                                    <div className='w-100'>
                                        {index === 0 && <label className='cnow-form-label'>Type of Event*</label>}
                                        <SelectGlobalList type={props?.globalListType} value={{ id: event_type.type_id, name: event_type.type_name }} onChange={(data) => handleEventTypeChange(event_type.id, data)} />
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label'></label>}
                                    <div className='text-end'>
                                        <Button icon='delete' onClick={() => removeEventType(index)} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default EventType