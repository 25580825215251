import React, { useState, useEffect, useRef } from 'react'
import CollapsableSection from '../../components/collapsableSection';
import FeaturedCard from '../../components/featured/featuredCard';
import TextEditor from '../../components/editors/textEditor';
import ImagePreview from '../../components/featured/editInfo/imagePreview';
import Spinner from '../../components/spinner';
import { useHistory } from 'react-router-dom';
import { uniqBy } from "lodash";
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { customSelectStyle, personName, handleDragDropReorder } from '../../helpers';
import uniqid from 'uniqid'
import Dragula from 'react-dragula'
import FeaturedTitle from './title';
const options = require('../../data/featured_entity_of_day.json')
const SiteOfTheDay = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [editedIndex, setEditedIndex] = useState(-1);
    const [siteOfDay, setSiteOfDay] = useState([])
    const [entityOfDay, setEntityOfDay] = useState('site')
    const [display, setDisplay] = useState(false)
    const searchSiteofDay = useSelector((state) => state.search);
    const featured = useSelector((state) => state.featured);
    const site_of_the_day = featured.site_of_the_day || []
    let selectedDate = siteOfDay[editedIndex]?.dates?.map((elem) => { return { value: elem.year, label: elem.year } })
    selectedDate = uniqBy(selectedDate, 'label')
    const start_date = featured && featured.start_date && JSON.parse(featured.start_date)
    const end_date = featured && featured.end_date && JSON.parse(featured.end_date)
    // eslint-disable-next-line

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = (props?.page !== 'home') ? 'dragula-container-site-of-day' : ''
    //forcefully updating dragulla to get refreshed updated data for multiple ids
    useEffect(() => { setDisplay(true); setTimeout(() => { setDisplay(false); setDragula(uniqid()) }, 1000) }, [start_date, end_date])
    useEffect(() => {
        handleDragDrop()
        // eslint-disable-next-line
    }, [dragula])
    const handleDragDrop = () => {
        const siteOfDay = site_of_the_day?.[0]?.data?.site_of_the_day || []
        if (siteOfDay?.length > 0) {
            let containers = []
            containers.push(document.getElementById(dragulaContainer))
            dragulaRef.current = Dragula(containers, {})
            dragulaRef.current.on('drop', () => {
                setEditedIndex(-1)
                const items = handleDragDropReorder(siteOfDay, dragulaContainer)
                site_of_the_day[0].edited = true
                site_of_the_day[0].data.site_of_the_day = items
                dispatch({ type: 'featured', payload: { ...featured, site_of_the_day: site_of_the_day } })
                setDragula(uniqid())
            })
        }

    }
    // Dragula End

    // eslint-disable-next-line 
    useEffect(() => { formatSiteOfDay() }, [site_of_the_day?.length, site_of_the_day, dragula])

    const formatSiteOfDay = () => {
        let data = []
        if (site_of_the_day)
            for (let i in site_of_the_day) {
                if (site_of_the_day[i]?.data?.site_of_the_day) {
                    for (let j in site_of_the_day[i].data.site_of_the_day) {
                        site_of_the_day[i].data.site_of_the_day[j].section_index = i
                        site_of_the_day[i].data.site_of_the_day[j].entity_index = j
                        if (!site_of_the_day[i].data.site_of_the_day[j].description) addDateDescription(i, j)
                        data.push(site_of_the_day[i].data.site_of_the_day[j])
                    }
                }
            }
        setSiteOfDay(data);
    }

    const addDateDescription = (i, j) => {
        site_of_the_day[i].data.site_of_the_day[j].description = site_of_the_day[i].data.site_of_the_day[j]?.dates?.filter((e) => e.description !== '')[0]?.description
        dispatch({ type: 'featured', payload: { ...featured, site_of_the_day: site_of_the_day } })
    }

    const handleInputChange = (value, type, id) => {
        let id_index = siteOfDay[editedIndex].section_index
        let id_site_index = siteOfDay[editedIndex].entity_index
        site_of_the_day[id_index].edited = true
        if (type === 'name') {
            site_of_the_day[id_index].data.site_of_the_day[id_site_index].name = value
        }
        if (type === 'date') {
            let dates = site_of_the_day[id_index].data.site_of_the_day[id_site_index].dates
            let index = dates.findIndex((elem) => elem.year === value.value)
            let selectedDate = dates.splice(index, 1)
            dates.unshift(selectedDate[0])
            site_of_the_day[id_index].data.site_of_the_day[id_site_index].dates = dates
        }
        if (type === 'description' && value) {
            site_of_the_day[id_index].data.site_of_the_day[id_site_index].description = value
        }
        if (type === 'first_name') {
            site_of_the_day[id_index].data.site_of_the_day[id_site_index].first_name = value
        }
        if (type === 'last_name') {
            site_of_the_day[id_index].data.site_of_the_day[id_site_index].last_name = value
        }
        dispatch({ type: 'featured', payload: { ...featured, site_of_the_day: site_of_the_day } })
    }

    const handleDeleteSite = () => {
        setDisplay(true)
        let id_index = siteOfDay[editedIndex].section_index
        let id_site_index = siteOfDay[editedIndex].entity_index
        site_of_the_day[id_index].edited = true
        site_of_the_day[id_index].data.site_of_the_day.splice(id_site_index, 1)
        dispatch({ type: 'featured', payload: { ...featured, site_of_the_day: site_of_the_day } })
        setDisplay(false)
        //to refresh Call function Again
        formatSiteOfDay()
        setEditedIndex(editedIndex - 1)
    }

    const handleRedirect = () => {
        dispatch({ type: 'search', payload: { ...searchSiteofDay, site_of_the_day: siteOfDay, selectedResult: [], title: `HOMEPAGE - ${entityOfDay} of the day`, filterLive: true } })
        history.push(`/featured/searchList/site_of_the_day/${entityOfDay}`)
    }

    return (
        <div>

            <FeaturedTitle title='Featured Sites on the Landing page' page={props?.page} />
            <hr className={`cnow-hr ${props?.page ? 'd-none' : ''}`} />

            <div>
                <CollapsableSection collapsable={true} title="FEATURED" count='1 item'
                    button={{
                        close: { show: true, onHandle: () => { console.log('closedd') } }
                    }}
                    disable={props?.page === 'home' ? true : false}
                >
                    <CollapsableSection textColor="cnow-text-primary" collapsable={true} title="OF THE DAY"
                        count={siteOfDay.length > 1 ? `${siteOfDay?.length} items` : `${siteOfDay?.length} item`}
                        button={{
                            set: { show: true, onHandle: () => { } },
                            search: { show: true, onHandle: () => { handleRedirect() } },
                            edit: { show: true, onHandle: () => { setEditedIndex(0) } },
                            add: { show: true, onHandle: () => { } },
                        }}
                        page={props?.page}
                        select={{
                            entity: { show: true, options: options, onHandle: (data) => { setEntityOfDay(data.value) }, defaultValue: options[0] },
                        }}>
                        <Spinner display={display} >
                            <div id={dragulaContainer} key={dragula} className='row'>
                                {siteOfDay.map((item, index) => (
                                    <div id={item.id} key={index} className={`mt-2 col-auto dragula-item`} style={{ border: `${index === editedIndex ? '3px solid #a82829' : 'none'}` }} onClick={() => { if (props?.page !== 'home') setEditedIndex(index); }}>
                                        <FeaturedCard data={item} image="true" title="true" description="true" />
                                    </div>))
                                }
                            </div>
                        </Spinner>
                    </CollapsableSection>
                    {(editedIndex !== -1) && siteOfDay?.length > 0 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            edit: { show: true, onHandle: () => { history.push(`/${siteOfDay[editedIndex].entity_type !== 'person' ? siteOfDay[editedIndex].entity_type : 'people'}/${siteOfDay[editedIndex].id}/editor`) } },
                            reset: { show: true, onHandle: () => { } },
                            save: { show: true, onHandle: () => { setEditedIndex(-1) } },
                            delete: { show: (siteOfDay.length > 1), onHandle: () => { handleDeleteSite() } }
                        }}
                    >
                        <div className='mt-2 mx-5'>
                            <div className='row'>
                                <div className='col-2'>
                                    <label className='cnow-form-label fw-bold'>Date</label>
                                    <Select key={`Select-${editedIndex}`} options={selectedDate} styles={customSelectStyle} placeholder={"Select"} onChange={(e) => { handleInputChange(e, 'date') }} defaultValue={selectedDate[0]} />
                                </div>
                                <div className='col-10'>
                                    {siteOfDay[editedIndex]?.entity_type !== 'person' && <> <label className='cnow-form-label fw-bold text-capitalize'>{`${siteOfDay[editedIndex]?.entity_type !== 'event_series' ? siteOfDay[editedIndex]?.entity_type : 'Event Series'} Title`}</label>
                                        <input key={`input-${editedIndex}`} name='name' type='text' className='form-control' value={siteOfDay[editedIndex]?.name} onChange={(e) => handleInputChange(e.target.value, 'name')} autoComplete="off" />
                                    </>}
                                    {siteOfDay[editedIndex]?.entity_type === 'person' && siteOfDay[editedIndex].type === 'person' && <div className='row'>
                                        <div className='col-auto'><label className='cnow-form-label fw-bold text-capitalize'>{`${siteOfDay[editedIndex]?.entity_type} First Name`}</label>
                                            <input key={`input-${editedIndex}`} name='name' type='text' className='form-control' value={siteOfDay[editedIndex]?.first_name} onChange={(e) => handleInputChange(e.target.value, 'first_name')} autoComplete="off" /> </div>
                                        <div className='col-auto'> <label className='cnow-form-label fw-bold text-capitalize'>{`${siteOfDay[editedIndex]?.entity_type} Last Name`}</label>
                                            <input key={`input-${editedIndex}`} name='name' type='text' className='form-control' value={siteOfDay[editedIndex]?.last_name} onChange={(e) => handleInputChange(e.target.value, 'last_name')} autoComplete="off" /></div>
                                    </div>}

                                    {siteOfDay[editedIndex]?.entity_type === 'person' && siteOfDay[editedIndex].type !== 'person' && <> <label className='cnow-form-label fw-bold text-capitalize'>{`${siteOfDay[editedIndex]?.entity_type} Name`}</label>
                                        <input key={`input-${editedIndex}`} name='name' type='text' className='form-control' value={siteOfDay[editedIndex]?.name} onChange={(e) => handleInputChange(e.target.value, 'name')} autoComplete="off" />
                                    </>
                                    }
                                </div>
                            </div>
                            <div>
                                <TextEditor key={`TextEditor-${editedIndex}`} data={siteOfDay[editedIndex]?.description} onChange={(data) => { handleInputChange(data, 'description', siteOfDay[editedIndex].id) }} limit="50" button={{ reset: { show: true, onHandle: () => { } } }}>
                                    <label className='cnow-form-label mt-2 fw-bold text-capitalize'>{`${siteOfDay[editedIndex]?.entity_type !== 'event_series' ? siteOfDay[editedIndex]?.entity_type : 'Event Series'} of the Day Description`}</label>
                                </TextEditor>
                            </div>
                        </div>
                        <ImagePreview data={siteOfDay[editedIndex]} key={`ImagePreview-${editedIndex}`} section_index={siteOfDay[editedIndex].section_index} entity_index={siteOfDay[editedIndex].entity_index} section={'site_of_the_day'} imageEditorTitle={`${siteOfDay[editedIndex]?.entity_type !== 'event_series' ? siteOfDay[editedIndex]?.entity_type : 'Event Series'} of the Day - ${siteOfDay[editedIndex]?.name || personName(siteOfDay[editedIndex])}`} typeWeb={'web@3x'} />
                    </CollapsableSection>}
                </CollapsableSection>
            </div>
        </div>
    )
}

export default SiteOfTheDay