/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import Button from '../../components/buttons/default';
import EntityImages from '../../components/editors/entityImages';
import PageTitle from '../../components/pageTitle';
import ImageViewer from '../../components/viewers/image';

import { customSelectStyle, dateString, getOffsetImageIndex } from '../../helpers';
import BulkImageEditor from './bulkImageEditor';

import bulkOptions from '../../data/bulk_options.json'
import ArrangeImagesList from '../../components/arrangeImagesList';

const _api = require('../../api')

const SiteImages = (props) => {
    const dispatch = useDispatch();
    const site = useSelector(state => state[props.id]);
    const [isSelectAll, setSelectAll] = useState(false);

    const [selectedImages, handleSelectItem] = useState([])

    const [bulkEditorType, setBulkEditorType] = useState()
    const [showBulkEditor, setShowBulkEditor] = useState(false)


    const location = useLocation()
    const history = useHistory()

    let currentImages = site ? site.images?.filter(x => x.type === 'image' && x.date_id === null) : []
    let images = site ? site.images?.filter(x => x.type === 'image') : []
    let currentDocuments = site ? site.images?.filter(x => x.type === 'document' && x.date_id === null) : []
    let imageRecognition = site ? site.images?.filter(x => x.type === 'image-recognition' ) : []

    const handleOnHide = async () => {
        setBulkEditorType();
        //to revert back user changes
        site.images = await _api.site.getImage(props.id)
        dispatch({ type: 'entity', payload: { ...site, image: site.images } });
        setShowBulkEditor(false);
    }

    const editItems = (id) => {
        let imagesList = site.images.filter(image => image.type !== 'then_now')
        dispatch({ type: 'images', payload: { entityImages: imagesList, source: location.pathname, id: site.id, entityType: 'site' } })
        history.push(`/image/${id}/banner`)
    }

    return (
        <>
            {
                site && <>
                    <PageTitle title={site?.name} />
                    <div id='banner'>
                        <hr className='cnow-hr' />
                        <div className='row'>
                            <div className='col-auto'>
                                <div className='form-group'>
                                    <div className='cnow-form-title'>Set Banner Image *</div>
                                    <div onClick={() => editItems(site.image_id)} className='cursor-pointer' key={site.image_id}>
                                        <ImageViewer key={site.image_id} url={site?.image?.url} type='banner' cssClass='cnow-image-editor-banner' />
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='d-flex gap-1 justify-content-end'>
                                    <Button icon='edit' label='Set Banner Image' onClick={() => editItems(site.image_id)} disabled={site.images?.length > 0 ? false : true} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className='cnow-hr' />

                    <div id='bulk-edit' className='row g-1 align-items-start mb-2 justify-content-between'>
                        <div className='col-3'>
                            <div className='cnow-form-title'>Images</div>
                        </div>
                        <div className='col'>
                            <div className='row g-1 align-items-end'>
                                <div className='col-auto'>
                                </div>
                                <div className='col'>
                                    <label className='small fw-bold fst-normal'>Associated images and drawings by</label>
                                    <Select className='' styles={customSelectStyle} />
                                </div>
                                <div className='col-4'>
                                    <label className='small fw-bold fst-normal'>Bulk Editing</label>
                                    <Select styles={customSelectStyle} options={bulkOptions} onChange={(item) => { setBulkEditorType(item); setShowBulkEditor(true) }} />

                                    {
                                        (bulkEditorType?.value === 'image-type' || bulkEditorType?.value === 'image-credit' || bulkEditorType?.value === 'image-date' || bulkEditorType?.value === 'another-section' || bulkEditorType?.value === 'delete' || bulkEditorType?.value === 'image-recognition') &&
                                        <BulkImageEditor
                                            id={site?.id}
                                            show={showBulkEditor}
                                            type={bulkEditorType}
                                            items={selectedImages}
                                            onHide={() => handleOnHide()}
                                            entityType={'site'}
                                        />
                                    }

                                    {
                                        bulkEditorType?.value === 'rearrange' &&
                                        <ArrangeImagesList id={site?.id}
                                            show={showBulkEditor}
                                            type={bulkEditorType}
                                            items={selectedImages}
                                            section='site'
                                            entityType='site'
                                            onHide={() => handleOnHide()}
                                        />
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                    <hr className='cnow-hr' />
                    <div id='entity-image-sort'>
                        {
                            <EntityImages title={`Current Images`} items={currentImages}
                                onSelect={(items) => handleSelectItem(items)}
                                onSelectAll={(value) => setSelectAll(value)}
                                isSelectAll={isSelectAll}
                                showCheckbox={true}
                                imageType='image'
                                entityType='site'
                                entityId={site.id}
                                dateId={null}
                                buttonLabel={'Add an Image'}
                                offsetIndex={0}
                            />
                        }

                        {
                            site.dates?.map((date, index) => {
                                const dateImages = site.images?.filter(x => x.type === 'image' && x.date_id === date.id)
                                return (
                                    <React.Fragment key={index}>
                                        {
                                            <EntityImages title={`${dateString(date)} - ${date.event_type_name} Images`} items={dateImages}
                                                onSelect={(items) => handleSelectItem(items)}
                                                showCheckbox={true}
                                                imageType='image'
                                                entityType='site'
                                                entityId={site.id}
                                                dateId={date.id}
                                                buttonLabel={'Add an Image'}
                                                offsetIndex={getOffsetImageIndex(site.images, site.dates, date.id, 'image')}
                                            />
                                        }
                                    </React.Fragment>
                                )
                            })
                        }

                        {
                            <EntityImages title={`Document and Drawings *`} items={currentDocuments}
                                onSelect={(items) => handleSelectItem(items)}
                                showCheckbox={true}
                                imageType='document'
                                entityType='site'
                                entityId={site.id}
                                dateId={null}
                                buttonLabel={'Add a Document'}
                                offsetIndex={images?.length}
                            />
                        }

                        {
                            site.dates?.map((date, index) => {
                                const dateDocuments = site.images?.filter(x => x.type === 'document' && x.date_id === date.id)
                                return (
                                    <React.Fragment key={index}>
                                        {
                                            <EntityImages title={`${dateString(date)} - ${date.event_type_name} Document and Drawings`} items={dateDocuments}
                                                onSelect={(items) => handleSelectItem(items)}
                                                showCheckbox={true}
                                                imageType='document'
                                                entityType='site'
                                                entityId={site.id}
                                                dateId={date.id}
                                                buttonLabel={'Add a Document'}
                                                offsetIndex={images?.length + getOffsetImageIndex(site.images, site.dates, date.id, 'document')}
                                            />
                                        }
                                    </React.Fragment>

                                )
                            })
                        }
                        {
                            <EntityImages title={`Image Recognition *`} items={imageRecognition}
                                onSelect={(items) => handleSelectItem(items)}
                                showCheckbox={true}
                                imageType='image-recognition'
                                entityType='site'
                                entityId={site.id}
                                buttonLabel={'Add a Document'}
                                offsetIndex={site.images ? site.images.filter(image => (image.type === 'image' || image.type === 'document'))?.length :"0"}
                            />
                        }

                    </div>
                </>
            }
        </>
    );
};

export default SiteImages;