import axios from 'axios';
import { toast } from 'react-toastify';

function ApiCall(method, url, params) {
    let errorMessage = 'Oops! There was an error trying to process your request. Please try again or contact admin.';
    if (method === 'get') {
        return new Promise(async (resolve, reject) => {
            axios.get(url).then((res) => resolve(res.data)).catch((error) => {
                toast.error(error.response.data?.message || errorMessage);
                reject(null)
            })
        });
    } else if (method === 'post') {
        return new Promise(async (resolve, reject) => {
            axios.post(url, params).then((res) => resolve(res.data)).catch((error) => {
                toast.error(error.response.data?.message || errorMessage);
                reject(null)
            })
        });
    } else if (method === 'put') {
        return new Promise(async (resolve, reject) => {
            axios.put(url, params).then((res) => resolve(res.data)).catch((error) => {
                toast.error(error.response.data?.message || errorMessage);
                reject(null)
            })
        });
    } else if (method === 'delete') {
        return new Promise(async (resolve, reject) => {
            axios.delete(url).then((res) => resolve(res.data)).catch((error) => {
                toast.error(error.response.data?.message || errorMessage);
                reject(null)
            })
        });
    }
}

export {
    ApiCall
}
