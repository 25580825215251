/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../components/buttons/default';
import ImageViewer from '../../components/viewers/image';
import EntityImages from '../../components/editors/entityImages';
import PageTitle from '../../components/pageTitle';
import { useHistory } from 'react-router-dom';
import RelatedEventImage from './RelatedEventImage';


const EventSeriesImage = (props) => {

    const dispatch = useDispatch()
    const event = useSelector(state => state[props.id])

    const location = useLocation()
    const history = useHistory()

    let currentImages = event ? event.images?.filter(x => x.type === 'image' && x.date_id === null) : []
    let currentDocuments = event ? event.images?.filter(x => x.type === 'document' && x.date_id === null) : []

    const editItems = (id) => {
        let imagesList = event.images.filter(image => image.type !== 'then_now')
        dispatch({ type: 'images', payload: { entityImages: imagesList, source: location.pathname, id: event.id, entityType: 'event' } })
        history.push(`/image/${id}/banner`)
    }

    return (
        <>
            {
                event && <>
                    <PageTitle title={event?.name} />

                    <div id='banner'>
                        <hr className='cnow-hr' />
                        <div className='row'>
                            <div className='col-auto'>
                                <div className='form-group'>
                                    <div className='cnow-form-title'>Set Banner Image *</div>
                                    <div onClick={() => editItems(event.image_id)} className='cursor-pointer'>
                                        <ImageViewer
                                            entityId={event?.image_id}
                                            entityType={'image'}
                                            type='banner'
                                            cssClass='cnow-image-editor-banner'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='d-flex gap-1 justify-content-end'>
                                    <Button icon='edit' label='Set Banner Image' onClick={() => editItems(event.image_id)} disabled={event.images?.length > 0 ? false : true} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className='cnow-hr' />
                    <div id='entity-image-sort'>

                        {
                            <EntityImages title={`Images *`} items={currentImages}
                                imageType='image'
                                entityType='event_series'
                                entityId={event.id}
                                buttonLabel={'Add a Image'}
                                offsetIndex={0}
                            />
                        }

                        {
                            <EntityImages title={`Drawing and Documents`} items={currentDocuments}
                                imageType='document'
                                entityType='event_series'
                                entityId={event.id}
                                buttonLabel={'Add a Document'}
                                offsetIndex={0}
                            />
                        }

                        <label className='cnow-form-title pt-3'>{`Related Events`}</label>
                            <RelatedEventImage id={props?.id} type='site' items={event?.related_event} title={'Site Title'} />


                    </div>

                </>
            }
        </>
    )
}

export default EventSeriesImage 