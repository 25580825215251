import React from "react";
import ImageViewer from "../viewers/image";
import Icon from '../viewers/icon'

const EventRecipe = ({ data }) => {
    return (
        <div>
            <div className='container ps-0' >
                <div className='cnow-form-title'>{`Recipe- ${data?.recipe_name}`}</div>
                <div className='row mt-2'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                        <div className='position-relative event-image-opacity'>
                            <ImageViewer entityId={data?.recipe_id} entityType={"recipe"} type='thumb@2x' cssClass='podcast-thumb' />
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-6 mapViews-overlays-scrollBar' style={{ overflow: 'auto', maxHeight: '140px' }}>
                        <div className="d-flex flex-column h-100">
                            <div className="">
                                <span className='fw-bold d-block'>About</span>
                                <div><span className='small' dangerouslySetInnerHTML={{ __html: data?.description }} /></div>
                                {data?.sections?.length > 0 && data?.sections?.map(item => {
                                    return (
                                    <div>
                                        <span className='fw-bold d-block'>{item.title}</span>
                                        <div><span className='small' dangerouslySetInnerHTML={{ __html: item.description }} /></div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {data?.images.map((item, index) => { return (<ImageViewer url={item?.image_url} type='thumb' cssClass='thumb p-1' />) })}
            </div>
            <div className="row mt-3">
                {data?.podcasts?.length > 0 && data?.podcasts?.map(item => {
                    return (
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 mt-2 '>
                            <div className="row m-0 p-0" style={{ backgroundColor: "#ededed" }}>
                                <div className="col-6  m-0 p-0">
                                    <div className='position-relative event-image-opacity'>
                                        <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute' >
                                            <div className="overlay-test"></div>
                                            <button type="button" className="play-button z-3" style={{ zIndex: "99" }} ><Icon name={"play-round-black"} size={25} /></button>
                                        </div> <ImageViewer url={item?.podcast_image_url} type='thumb@2x' cssClass={"thumb"} /> </div>
                                </div>
                                <div className='col-6  m-0 p-0 cnow-preview-title'> <span className='fw-bold d-flex justify-content-center'>{item?.name}</span> </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default EventRecipe