/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Featured from './index'
import FeaturedMenu from './menu';
import FeaturedPreview from './preview';
import Spinner from '../../components/spinner';
import SiteOfTheDay from './siteOfTheDay';
import FeaturedForCollection from './featuredForCollection';
import AboutTheMuseum from './aboutTheMuseum';
import OnThisDayMonthYear from './onThisDayMonthYear';
import InThisPhoto from './inThisPhoto';
import WhatHappenedHere from './whatHappenedHere';
import People from './people';
import Podcast from './podcast';
import CNowMaps from './cNowMaps';
import Tours from './tours';
import UpComingEvents from './upComingEvents';
import StaticImages from './staticImages';
import CulturenowCollectionSite from './culturenowCollectionSite';
// import { toast } from 'react-toastify';
const _api = require('../../api');

const FeaturedEditor = (props) => {
	const id = props.match.params.id || 'NEW';
	const section = props.match.params.section || '';
	const dispatch = useDispatch()
	const history = useHistory();
	const menuSize = useSelector((state) => state.menu);
	const previewSize = useSelector((state) => state.preview);
	const featured = useSelector((state) => state.featured);
	const [spinner, showSpinner] = useState(false);
	const start_date = featured && featured.start_date && JSON.parse(featured.start_date)
	const end_date = featured && featured.end_date && JSON.parse(featured.end_date)
	// useEffect(() => {
	// 	if (start_date && end_date && end_date !== "1970-01-01" && !featured.source && (start_date === end_date)) getData();
	// 	if (start_date && end_date && end_date !== "1970-01-01" && !featured.source && (start_date !== end_date) && section) { dispatch({ type: 'featured', payload: { ...featured, [section]: [] } }); }
	// }, [id, featured?.end_date, section]);

	const handleMultipleIds = (response) => {
		let data = []
		response.forEach((elem) => {
			data.push(elem.data[section])
			elem.data[section] = []
		})
		response[0].edited = true;
		response[0].data[section] = data.flat()
		return response
	}

	const getData = async () => {
		showSpinner(true);
		let params = {
			"section": section,
			"start_date": start_date.slice(0, 10),
			"end_date": end_date.slice(0, 10),
		}
		if (section === 'site_of_the_day') {
			let response = await _api.featured.getFeatured(params)
			if (response.length > 1) featured.site_of_the_day = handleMultipleIds(response)
			else featured.site_of_the_day = response;
		}
		if (section && section !== 'site_of_the_day') {
			let response = await _api.featured.getFeatured(params)
			featured[section] = response
		}
		dispatch({ type: 'featured', payload: { ...featured } })
		showSpinner(false);
	}

	const updateAndRedirect = async (path) => {
		showSpinner(true);
		if (!path) {
			path = section;
		}
		if (section === 'site_of_the_day') {
			if (featured.site_of_the_day) {
				featured.site_of_the_day.forEach((elem) => {
					if (elem.id.startsWith('temp.') || elem.edited) {
						_api.featured.updateFeatured([elem])
					}
					if (!elem.id.startsWith('temp.') && elem.data.site_of_the_day.length === 0) {
						_api.featured.deleteFeatured(elem.id)
					}
				})
			}
		}
		if (section && section !== 'site_of_the_day') {
			if (featured[section] && featured[section]?.length > 0) {
				featured[section].forEach((elem) => {
					if ((elem.id.startsWith('temp.') || elem.edited) && (elem.data[section]?.length > 0 || (!elem.data[section] && elem.data?.description))) {
						_api.featured.updateFeatured([elem])
					}
					if (!elem.id.startsWith('temp.') && elem.data[section]?.length === 0) {
						_api.featured.deleteFeatured(elem.id)
					}
				})
			}
		}
		history.push(`/featured/editor/${path}`)
		dispatch({ type: 'featured', payload: { ...featured, source: '' } })
		dispatch({ type: 'search', payload: {} })
		showSpinner(false);
		// toast.success('Data Saved');
	}

	const onSave = () => {
		showSpinner(true);
		setTimeout(() => {
			if ((start_date === end_date)) getData()
			if ((start_date !== end_date)) dispatch({ type: 'featured', payload: { ...featured, [section]: [] } })
			showSpinner(false);
		}, 1000)
	}
	return (
		<div className='cnow-editor-container cnow-feature-menu'>
			<div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
				<FeaturedMenu id={id} section={section} onClick={(path) => updateAndRedirect(path)} display={spinner} onSave={(path) => { if (path === section) onSave() }} />
			</div>
			<div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
				<Spinner display={spinner}>
					{!section && <Featured id={id} onUpdate={() => updateAndRedirect()} />}
					{section === 'site_of_the_day' && <SiteOfTheDay />}
					{section === 'featured_for_collection' && <FeaturedForCollection />}
					{section === 'about_the_museum_without_walls' && <AboutTheMuseum />}
					{section === 'on_this_day' && <OnThisDayMonthYear />}
					{section === 'in_this_photo' && <InThisPhoto />}
					{section === 'what_happened_here' && <WhatHappenedHere />}
					{section === 'public_art' && <CulturenowCollectionSite section={section} />}
					{section === 'architecture' && <CulturenowCollectionSite section={section} />}
					{section === 'history' && <CulturenowCollectionSite section={section} />}
					{section === 'people' && <People />}
					{section === 'podcast' && <Podcast />}
					{section === 'cnow_maps' && <CNowMaps />}
					{section === 'tours' && <Tours />}
					{section === 'upcoming_events' && <UpComingEvents />}
					{section === 'what_is_around' && <CulturenowCollectionSite section={section} />}
					{section === 'places_over_time' && <CulturenowCollectionSite section={section} />}
					{section === 'image_search_for_app' && <StaticImages section={section} />}
					{section === 'curate_your_own_collection' && <StaticImages section={section} />}
					{section === 'contribute_content' && <StaticImages section={section} />}
					{section === 'join_the_community' && <StaticImages section={section} />}
					{section === 'donate' && <StaticImages section={section} />}
				</Spinner>
			</div>
			<div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
				<FeaturedPreview id={id} onUpdate={() => updateAndRedirect()} section={section} display={spinner} onSave={() => onSave()} />
			</div>
		</div>
	);
};

export default FeaturedEditor;