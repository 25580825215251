/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';
import Spinner from '../../components/spinner';
import EventTitle from './title';
import EventSeriesMenu from './menu';
import EventPreview from './preview';
import EventLocation from './location';
import EventSeriesPeople from './people';
import FeaturedEvent from './event';
import EventSeriesAbout from './about';
import EventSeriesTour from './tour';
import EventMeta from './meta';
import EventSeriesImage from './image';
import EventSeriesPodcast from './podcast';
import RelatedMapEditor from '../../components/editors/relatedMapEditor';
import EventSeriesSpecialFeature from './specialFeature';
import EventSeriesCuratorManagement from './curator';
import Legal from '../../components/legal/editor';
const _api = require('../../api');

const EventSeriesEditor = (props) => {
    const id = props.match.params.id || 'NEW';
    const section = props.match.params.section || 'title';
    const sectionId = props.match.params.sectionId || '';
    const history = useHistory();
    const dispatch = useDispatch();
    const event = useSelector(state => state[id]);
    const menuSize = useSelector((state) => state.menu);
    const previewSize = useSelector((state) => state.preview);
    const [spinner, showSpinner] = useState(false);

    useEffect(() => {
        if (id === 'NEW') {
            dispatch({
                type: 'entity', payload: {
                    'id': 'NEW',
                    'name': '',
                    'description': '',
                    'meta': {},
                    'image_id': null,
                    'deleted': false,
                    'live': false,
                    'types': [],
                    'categories': [],
                    'themes': []
                }
            })
        } else {
            getEventSeriesData()
        }
    }, [id, sectionId, section]);

    const getEventSeriesData = async () => {
        showSpinner(true);
        let myEvent = await _api.event_series.getById(id)

        if (section === 'title' || !section) {
            myEvent.themes = await _api.event_series.getTheme(id)
            myEvent.categories = await _api.event_series.getCategory(id)
            myEvent.types = await _api.event_series.getType(id)
        }

        if (section === 'location') {
            myEvent.locations = await _api.event_series.getLocation(id)
        }

        if (section === 'people') {
            myEvent.hosts = await _api.event_series.getHost(id)
            myEvent.related_people = await _api.event_series.getRelatedPerson(id)
        }

        if (section === 'about') {
            myEvent.tags = await _api.event_series.getTag(myEvent.id)
            myEvent.stories = await _api.event_series.getStory(myEvent.id)
            myEvent.related_sites = await _api.event_series.getRelatedSite(id) || []
        }
        if (section === 'images') {
            // myEvent.participants = await _api.event.getParticipant(id)
            myEvent.images = await _api.event_series.getImage(id) || []
            myEvent.related_event = await _api.event_series.EventImage(id)
        }

        if (section === 'tours') {

            myEvent.tour = await _api.event_series.gettour(myEvent.id)
        }

        if (section === 'attach_map') {
            myEvent.maps = await _api.event_series.getRelatedMap(id)
        }

        if (section === 'featured_events') {
            myEvent.featured = await _api.event_series.getevent(id)

        }

        if (section === 'podcast') {
            myEvent.podcasts = await _api.event_series.getPodcast(id)
            myEvent.playlists = await _api.event_series.getPlaylist(id)
            myEvent.related_event = await _api.event_series.EventImage(id)
        }

        if (section === 'special_features') {
            myEvent.special_features = await _api.event_series.getEventSeriesRecipe(id)
        }

        if (section === 'legal') {
            myEvent.images = await _api.event_series.getImage(id) || []
            myEvent.podcasts = await _api.event_series.getPodcast(id)
        }

        if (section === 'curator') {
            myEvent.images = await _api.event_series.getImage(id)
            myEvent.podcasts = await _api.event_series.getPodcast(id)
            myEvent.curator = await _api.event_series.getCurator(id)
        }


        dispatch({ type: 'entity', payload: myEvent })
        showSpinner(false);
    }

    const updateAndRedirect = async (path) => {
        if (!path) {
            path = section;
            if (sectionId) path = path.concat('/', sectionId);
        }

        showSpinner(true);
        if (event.id === 'NEW') event.id = null;
        let myEvent = await _api.event_series.update(event)

        if (section === 'title' || !section) {
            myEvent.themes = await _api.event_series.updateTheme(myEvent.id, event.themes)
            myEvent.categories = await _api.event_series.updateCategory(myEvent.id, event.categories)
            myEvent.types = await _api.event_series.updateType(myEvent.id, event.types)
        }

        if (section === 'location') {
            myEvent.locations = await _api.event_series.updateLocation(myEvent.id, event.locations)
        }

        if (section === 'people') {
            myEvent.hosts = await _api.event_series.updateHost(myEvent.id, event.hosts)

            if (event.related_people?.length > 0) {
                let related_people = []
                for (const item of event.related_people) {
                    if (item.person_id) {
                        let response = await _api.person.updateEventSeries(item.person_id, [item])
                        related_people.push(response[0])
                    }
                }
                myEvent.related_people = related_people
            }
        }

        if (section === 'about') {
            myEvent.tags = await _api.event_series.updateTag(myEvent.id, event.tags)
            myEvent.stories = await _api.event_series.updateStory(myEvent.id, event.stories)
            if (event.related_sites?.length > 0) {
                let related_site = []
                for (const item of event.related_sites) {
                    if (item.site_id) {
                        let response = await _api.site.updateEventSeries(item.site_id, [item])
                        related_site.push(response[0])
                    }
                }
                myEvent.related_sites = related_site
            }
        }


        if (section === 'featured_events') {
            myEvent.featured = await _api.event_series.update_event(myEvent.id, event.featured)

        }

        if (section === 'tours') {
            myEvent.tour = await _api.event_series.update_tour(myEvent.id, event.tour)
        }

        if (section === 'attach_map') {
            if (event.maps?.length > 0) {
                let related_map = []
                for (const item of event.maps) {
                    if (item.map_id) {
                        let response = await _api.map.updateEventSeries(item.map_id, [item])
                        related_map.push(response[0])
                    }
                }
                myEvent.maps = related_map
            }
        }
        if (section === 'images') {
            myEvent.images = await _api.event_series.updateImage(myEvent.id, event.images) || []
        }

        if (section === 'podcast') {
            myEvent.podcasts = await _api.event_series.updatePodcast(myEvent.id, event.podcasts)
            if (event.playlists?.length > 0) {
                let related_playlist = []
                for (const item of event.playlists) {
                    if (item.playlist_id) {
                        let response = await _api.playlist.updateEventSeries(item.playlist_id, [item])
                        related_playlist.push(response[0])
                    }
                }
                myEvent.playlists = related_playlist
            }

        }
        if (section === 'special_features') {
            myEvent.special_features = await _api.event_series.updateEventSeriesRecipe(myEvent.id, event.special_features)
        }

        if (section === 'curator') {
            myEvent.curator = await _api.event_series.updateCurator(myEvent.id, event.curator)
        }

        dispatch({ type: 'entity', payload: myEvent })
        history.push(`/event_series/${myEvent.id}/editor/${path}`)
        showSpinner(false);
    }

    return (
        <div className='cnow-editor-container'>
            <div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
                <EventSeriesMenu id={id} section={section} sectionId={sectionId} onClick={(path) => updateAndRedirect(path)} display={spinner} />
            </div>
            <div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
                <Spinner display={spinner}>
                    {section === 'title' && <EventTitle id={id} />}
                    {section === 'location' && <EventLocation id={id} locationId={sectionId} onUpdate={(path) => updateAndRedirect(path)} />}
                    {section === 'people' && <EventSeriesPeople id={id} />}
                    {section === 'about' && <EventSeriesAbout id={id} />}
                    {section === 'attach_map' && <RelatedMapEditor id={id} type={'event_series'} />}
                    {section === 'meta' && <EventMeta id={id} />}
                    {section === 'podcast' && <EventSeriesPodcast id={id} />}
                    {section === 'featured_events' && <FeaturedEvent id={id} />}
                    {section === 'tours' && <EventSeriesTour id={id} />}
                    {section === 'special_features' && <EventSeriesSpecialFeature id={id} />}
                    {section === 'legal' && <Legal id={id} />}
                    {section === 'curator' && <EventSeriesCuratorManagement id={id} />}
                    {section === 'images' && <EventSeriesImage id={id} onUpdate={() => updateAndRedirect()} />}
                </Spinner>
            </div>
            <div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
                <EventPreview id={id} onUpdate={() => updateAndRedirect()} spinner={spinner} section={section} locationId={sectionId} display={spinner} />
            </div>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ['places', 'visualization', 'drawing', 'geometry'],
})(EventSeriesEditor);