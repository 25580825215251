import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import FeaturedImage from "../../preview/featuredImage";

const OnthisDay = ({ featured }) => {
    const on_this_day = featured.on_this_day
    const [onThisDay, setOnThisDay] = useState([])
    // eslint-disable-next-line
    useEffect(() => { formatOnThisDay() }, [on_this_day?.length, on_this_day])

    const formatOnThisDay = () => {
        let data = []
        if (on_this_day)
            for (let i in on_this_day) {
                if (on_this_day[i]?.data?.on_this_day) {
                    for (let j in on_this_day[i].data.on_this_day) {
                        on_this_day[i].data.on_this_day[j].section_index = i
                        on_this_day[i].data.on_this_day[j].entity_index = j
                        on_this_day[i].data.on_this_day[j].id = on_this_day[i].data.on_this_day[j].objectID
                        data.push(on_this_day[i].data.on_this_day[j])
                    }
                }
            }
        setOnThisDay(data)
    }

    return (
        <>
            {on_this_day && <Carousel>
                {onThisDay?.length > 0 && onThisDay.map((elem) => {
                    return (
                        <Carousel.Item interval={2000}>
                            <FeaturedImage
                                url={elem?.image_url}
                                title="ON THIS DAY"
                                subTitle={`${elem?.entity_name} - ${elem?.year} - ${elem?.sub_entity} : ${elem?.name}`}
                                description={elem.description}
                                type={'featured@3x'}
                            />
                        </Carousel.Item>
                    )
                })}
            </Carousel>}

        </>
    )
}

export default OnthisDay