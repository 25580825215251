import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { customSelectStyle, newTempId } from '../../helpers';

const _api = require('../../api')

const EntityTagEditor = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);
    const type = props.type
    if (entity) entity.my_tags = [];
    if (entity?.tags?.length) entity.tags.forEach(data => entity.my_tags.push({ 'value': data.tag_id, 'label': data.tag_name, 'id': data?.id }))

    const searchTags = async (keyword) => {
        let response = await _api.global_list.getAll('tags', keyword);
        let items = [];
        response.forEach(data => items.push({ 'value': data.id, 'label': data.name, id: newTempId() }));
        if (items.length === 0) items.push({ 'value': 'NEW', 'label': keyword, 'data': keyword, id: newTempId() })
        return items;
    }

    const handleChange = async (items, e) => {
        if (e.action === 'select-option') {
            entity.tags = [];

            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                if (item.value === 'NEW') {
                    const response = await _api.global_list.create('tags', { name: item.label })
                    item = { 'value': response.id, 'label': response.name, 'id': newTempId() }
                }
                entity.tags.push({ 'tag_id': item.value, 'tag_name': item.label, 'id': item?.id })
            }
            dispatch({ type: 'entity', payload: { ...entity, tags: entity.tags } });
        }
        if (e.action === 'remove-value') {
            entity.tags = [];
            for (let i = 0; i < items.length; i++) {
                let item = items[i];
                item = { 'tag_id': item.value, 'tag_name': item.label, 'id': item?.id }
                entity.tags.push(item)
            }
            dispatch({ type: 'entity', payload: { ...entity, tags: entity.tags } });
            if (!e.removedValue?.id?.startsWith('temp.')) _api[type].deleteTag(e.removedValue.id);
        }
    }

    return (
        <div>
            <hr className='cnow-hr' />

            <label className='cnow-form-title'>Hash Tags</label>
            {/* disabled formatCreateLabel */}
            <AsyncCreatableSelect isMulti cacheOptions defaultOptions styles={customSelectStyle}
                loadOptions={searchTags} value={entity?.my_tags}
                onChange={(items, e) => handleChange(items, e)} isClearable={false} formatCreateLabel={() => undefined}
            />
        </div>
    )
}

export default EntityTagEditor;