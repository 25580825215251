import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import Button from '../buttons/default';


const TextEditor = (props) => {

  const toolbar = ['italic'];
  const placeholder = props.placeholder || '';
  const wordLimit = props.limit || 250;
  const [wordCount, setWordCount] = useState(props?.data?.split(' ').length || 0);
  const button = props.button || null;


  const handleChange = (e, editor) => {
    const data = editor.getData();
    setWordCount(data.split(' ').filter(word => word).length);
    props.onChange(data);
  }

  return (

    <>
      <div className='d-flex justify-content-between'>
        <div className=''>
          {props.children}
        </div>
        <div className=''>
          <label className='cnow-form-word-count'>
            {wordCount > 0 ? `${wordCount} of` : `Limit`} {wordLimit} words
          </label>
         {button?.reset?.show && <Button size='sm' color='light' icon='Symbols_Repeat' className={`ms-1`} onClick={button?.reset?.onHandle} />}
        </div>

      </div>
      <div className='border bg-white'>
        <CKEditor config={{ toolbar: toolbar, autoParagraph: false, placeholder: placeholder }}
          data={props.data}
          onChange={handleChange}
          editor={InlineEditor}
        />
      </div>
    </>
  );
};

export default TextEditor;