
import { ApiCall } from './utils'

//person
const getById = (id) => ApiCall('get', `/person/${id}`)

const search = (params) => ApiCall('post', `/person/search`, params)

const update = (params) => ApiCall('post', `/person`, params)

const deleteById = (id) => ApiCall('delete', `/person/${id}`)

const updateLive = (id, live) => ApiCall('put', `/person/${id}/live/${live}`)

//Affiliation
const getAffiliation = (id) => ApiCall('get', `/person/${id}/affiliation`)

const updateAffiliation = (id, params) => ApiCall('post', `/person/${id}/affiliation`, params)

const deleteAffiliation = (id) => ApiCall('delete', `/person/affiliation/${id}`)

//Award
const getAward = (id) => ApiCall('get', `/person/${id}/award`)

const updateAward = (id, params) => ApiCall('post', `/person/${id}/award`, params)

const deleteAward = (id) => ApiCall('delete', `/person/award/${id}`)

//date
const getDate = (id) => ApiCall('get', `/person/${id}/date`)

const updateDate = (id, params) => ApiCall('post', `/person/${id}/date`, params)

const deleteDate = (id) => ApiCall('delete', `/person/date/${id}`)

//designation
const getDesignation = (id) => ApiCall('get', `/person/${id}/designation`)

const updateDesignation = (id, params) => ApiCall('post', `/person/${id}/designation`, params)

const deleteDesignation = (id) => ApiCall('delete', `/person/designation/${id}`)

//highlights
const getHighlight = (id) => ApiCall('get', `/person/${id}/highlight`)

const updateHighlight = (id, params) => ApiCall('post', `/person/${id}/highlight`, params)

const deleteHighlight = (id) => ApiCall('delete', `/person/highlight/${id}`)

//image
const getImage = (id) => ApiCall('get', `/person/${id}/image`)

const updateImage = (id, params) => ApiCall('post', `/person/${id}/image`, params)

const deleteImage = (id) => ApiCall('delete', `/person/image/${id}`)

const getAdditionalPhotos = (id) => ApiCall('get', `/person/${id}/tagged_images`)

//location
const getLocation = (id) => ApiCall('get', `/person/${id}/location`)

const updateLocation = (id, params) => ApiCall('post', `/person/${id}/location`, params)

const deleteLocation = (id) => ApiCall('delete', `/person/location/${id}`)

//Organization Type
const getOrganizationType = (id) => ApiCall('get', `/person/${id}/organization_type`)

const updateOrganizationType = (id, params) => ApiCall('post', `/person/${id}/organization_type`, params)

const deleteOrganizationType = (id) => ApiCall('delete', `/person/organization_type/${id}`)

//podcast

const getPodcast = (id) => ApiCall('get', `/person/${id}/podcast`)

const updatePodcast = (id, params) => ApiCall('post', `/person/${id}/podcast`, params)

const deletePodcast = (id) => ApiCall('delete', `/person/podcast/${id}`)

//profession
const getProfession = (id) => ApiCall('get', `/person/${id}/profession`)

const updateProfession = (id, params) => ApiCall('post', `/person/${id}/profession`, params)

const deleteProfession = (id) => ApiCall('delete', `/person/profession/${id}`)

//quote
const getQuote = (id) => ApiCall('get', `/person/${id}/quote`)

const updateQuote = (id, params) => ApiCall('post', `/person/${id}/quote`, params)

const deleteQuote = (id) => ApiCall('delete', `/person/quote/${id}`)

//Related Person
const getRelatedPerson = (id) => ApiCall('get', `/person/${id}/related_person`)

const updateRelatedPerson = (id, params) => ApiCall('post', `/person/${id}/related_person`, params)

const deleteRelatedPerson = (id) => ApiCall('delete', `/person/related_person/${id}`)

//story
const getStory = (id) => ApiCall('get', `/person/${id}/story`)

const updateStory = (id, params) => ApiCall('post', `/person/${id}/story`, params)

const deleteStory = (id) => ApiCall('delete', `/person/story/${id}`)

//map
const getMap = (id) => ApiCall('get', `/person/${id}/map`)

const updateMap = (id, params) => ApiCall('post', `/person/${id}/map`, params)

const deleteMap = (id) => ApiCall('delete', `/person/map/${id}`)

//tag
const getTag = (id) => ApiCall('get', `/person/${id}/tag`)

const updateTag = (id, params) => ApiCall('post', `/person/${id}/tag`, params)

const deleteTag = (id) => ApiCall('delete', `/person/tag/${id}`)

//related entities
const getSite = (id) => ApiCall('get', `/person/${id}/site`)

const getSiteImageAlbums = (id) => ApiCall('get', `/person/${id}/site_image_album`)

const getPersonImageAlbums = (id) => ApiCall('get', `/person/${id}/person_image_album`)

const getCreditedPodcast = (id) => ApiCall('get', `/person/${id}/credited_podcast`)

//tours
const getTour = (id) => ApiCall('get', `/person/${id}/tour`)

const updateTour = (id, params) => ApiCall('post', `/person/${id}/tour`, params)

const deleteTour = (id) => ApiCall('delete', `/person/tour/${id}`)

//tour roles
const getTourRoles = (id) => ApiCall('get', `/person/${id}/tour_roles`)

//related site
const getRelatedSite = (id) => ApiCall('get', `/person/${id}/related_site`)

//get publication
const getPublication = (id) => ApiCall('get', `/person/${id}/publication`)

//playlist
const getPlaylist = (id) => ApiCall('get', `/person/${id}/playlist`)

const getMorePlaylist = (id) => ApiCall('get', `/person/${id}/more_playlist`)

const updatePlaylist = (id, params) => ApiCall('post', `/person/${id}/playlist`, params)

const deletePlaylist = (id) => ApiCall('delete', `/person/playlist/${id}`)

const getOnThisDay = (id) => ApiCall('get', `/person/on_this_day/${id}`)

const updateOnThisDay = (params) => ApiCall('post', `/person/on_this_day`, params)

const updateListOnThisDay = (people_id, id, value) => ApiCall('put', `/person/${people_id}/on_this_day/${id}/live/${value}`)

const deleteOnThisDay = (id) => ApiCall('delete', `/person/on_this_day/${id}`)

//publication_type 
const getPublicationType = (id) => ApiCall('get', `/person/${id}/publication_type `)

const updatePublicationType = (id, params) => ApiCall('post', `/person/${id}/publication_type `, params)

const deletePublicationType = (id) => ApiCall('delete', `/person/publication_type/${id}`)

//map credits
const getMapCredits = (id) => ApiCall('get', `/person/${id}/map_credits`)

//site's podcast list
const getPersonSitePodcasts = (id) => ApiCall('get', `/person/${id}/related_item_podcasts`)

//person Event
const getEvent = (id) => ApiCall('get', `/person/${id}/event `)

const updateEvent = (id, params) => ApiCall('post', `/person/${id}/event `, params)

const deleteEvent = (id) => ApiCall('delete', `/person/event/${id}`)

// person eventseries
const getEventSeries = (id) => ApiCall('get', `/person/${id}/event_series `)

const updateEventSeries = (id, params) => ApiCall('post', `/person/${id}/event_series `, params)

const deleteEventSeries = (id) => ApiCall('delete', `/person/event_series/${id}`)

//Automated Playlist 

const automatedPlaylist = (id) => ApiCall('post', `/person/${id}/playlist/sync`, '')

//get related items
const getRelatedItems = (id) => ApiCall('get', `/person/${id}/related_item`)

// Special Feature

const getRecipePeople = (id) => ApiCall('get', `/person/${id}/recipe`)

const updateRecipePeople = (id, params) => ApiCall('post', `/person/${id}/recipe`, params)

const deleteRecipe = (id) => ApiCall('delete', `/person/recipe/${id}`)

//curator

const getCurator = (id) => ApiCall('get', `/person/${id}/curator`)

const updateCurator = (id, params) => ApiCall('post', `/person/${id}/curator`, params)

export default {
    getById,
    search,
    update,
    deleteById,
    updateLive,

    getDate,
    updateDate,
    deleteDate,

    getLocation,
    updateLocation,
    deleteLocation,

    getStory,
    updateStory,
    deleteStory,

    getAward,
    updateAward,
    deleteAward,

    getDesignation,
    updateDesignation,
    deleteDesignation,

    getTag,
    updateTag,
    deleteTag,

    getSite,
    getSiteImageAlbums,
    getPersonImageAlbums,
    getCreditedPodcast,

    getRelatedSite,

    getRelatedPerson,
    updateRelatedPerson,
    deleteRelatedPerson,

    getHighlight,
    updateHighlight,
    deleteHighlight,

    getAffiliation,
    updateAffiliation,
    deleteAffiliation,

    getImage,
    updateImage,
    deleteImage,
    getAdditionalPhotos,

    getPodcast,
    updatePodcast,
    deletePodcast,

    getPlaylist,
    updatePlaylist,
    deletePlaylist,

    getProfession,
    updateProfession,
    deleteProfession,

    getQuote,
    updateQuote,
    deleteQuote,

    getOrganizationType,
    updateOrganizationType,
    deleteOrganizationType,

    getMap,
    updateMap,
    deleteMap,

    getTour,
    updateTour,
    deleteTour,
    getTourRoles,

    getOnThisDay,
    updateOnThisDay,
    deleteOnThisDay,

    getPublication,

    getPublicationType,
    updatePublicationType,
    deletePublicationType,
    getMapCredits,
    getPersonSitePodcasts,
    getRelatedItems,
    getMorePlaylist,

    getEvent,
    updateEvent,
    deleteEvent,

    getEventSeries,
    updateEventSeries,
    deleteEventSeries,

    automatedPlaylist,

    getRecipePeople,
    deleteRecipe,
    updateRecipePeople,
    updateListOnThisDay,

    getCurator,
    updateCurator
}