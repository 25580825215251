import React, { useState } from 'react';
import { cleanHtml } from '../../helpers';
const MIN_LENGTH = 3

const EventReservationTicket = ({ data }) => {
  const [length, setLength] = useState(MIN_LENGTH);
  const [more, showMore] = useState(false);
  return (
    <>
      {
        data && data.length > 0 && <>
          <hr />
          <div className='d-flex justify-content-between'>
            <div className='fw-bold fst-italic'>Tickets</div>

            {data.length > MIN_LENGTH &&
              <div className='fst-italic'>
                {more && <div className='small d-flex align-items-center' style={{ cursor: 'pointer', color: '#000000' }} onClick={() => { setLength(3); showMore(false) }}>
                  {`${data.length} total, Show less`} <i className='fe fe-chevron-up ms-1 fa-2x text-dark' style={{ fontWeight: '900' }} /></div>}
                {!more && <div className='small d-flex align-items-center' style={{ cursor: 'pointer', color: '#000000' }} onClick={() => { setLength(data.length); showMore(true) }}>
                  {`${data.length} total, Show more`}<i className='fe fe-chevron-down ms-1 fa-2x text-dark' style={{ fontWeight: '900' }} /></div>}
              </div>
            }
          </div>
          {
            data.slice(0, length).map((item, index) => {
              return <div key={index}>

                <div>


                  Reservation required. Book <a href={item.url}><span dangerouslySetInnerHTML={{ __html: cleanHtml(item.name) }} /></a>
                  <div><span dangerouslySetInnerHTML={{ __html: cleanHtml(item.description) }} /></div>



                </div>
              </div>


            })
          }
        </>
      }
    </>
  )
}

export default EventReservationTicket;