import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import LegalHistory from "../../components/curator/legalHistory";
import PageTitle from "../../components/pageTitle";
import EntityOfDayCheckBox from "../../components/curator/entityOfDayCheckbox";
import ToursMapInput from "../../components/curator/toursMapInput";
import CuratorTextField from "../../components/curator/textField";
import { newTempId } from "../../helpers";

const CuratorManagement = ({ id }) => {
    const tour = useSelector(state => state[id]);
    return (
        <>
            <PageTitle title={tour.name} />
            <TextFied id={id} key={id} />
            <ToursMapInput id={id} />
            <LegalHistory id={id} type={'tour'} />
            <EntityOfDayCheckBox type={'tour'} id={id} entity_day={'Tour'} show_entity_of_day={'not_show_tour_of_the_day'} key={tour?.curator?.not_show_tour_of_the_day} />
        </>
    )
}

export default CuratorManagement

const TextFied = ({ id }) => {
    const tour = useSelector(state => state[id]);
    const dispatch = useDispatch()
    const tour_curator = tour.curator
    const handleOnChange = (data, type) => {
        if (!tour?.curator) tour.curator = {}
        if (!tour?.curator?.id) tour.curator.id = newTempId()
        if (!tour.curator?.[type]) tour.curator[type] = ''
        tour.curator[type] = data
        dispatch({ type: 'entity', payload: { ...tour, curator: { ...tour.curator, [type]: tour.curator[type] } } })
    }

    return (
        <>
            <CuratorTextField subTitle={'Description'} key={`${id}-remarks`} label={'REMARKS'} disableMainTextBox handleSubInputChange={(e) => { handleOnChange(e, 'remarks') }} subInput={tour_curator?.remarks || ''} hrTrue />
        </>
    )
}