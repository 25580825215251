import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import LegalHistory from "../../components/curator/legalHistory";
import PageTitle from "../../components/pageTitle";
import EntityOfDayCheckBox from "../../components/curator/entityOfDayCheckbox";
import CuratorTextField from "../../components/curator/textField";
import PersonVerification from "../../components/curator/personVerification";
import { newTempId, customSelectStyle, personName } from "../../helpers";
import Select from 'react-select';
import Button from '../../components/buttons/default';
const currency = require('../../data/event_currency.json')

const CuratorManagement = ({ id }) => {
    const people = useSelector(state => state[id]);
    return (
        <>
            <PageTitle title={personName(people)} />
            <PersonVerification id={id} />
            <TextFied id={id} />
            {people?.type === 'publication' && <PurchaseLink id={id} />}
            <LegalHistory id={id} type={'people'} />
            <EntityOfDayCheckBox type={'people'} id={id} entity_day={'People'} key={people?.curator?.not_show_person_of_the_day} show_entity_of_day={'not_show_person_of_the_day'} />
        </>
    )
}

export default CuratorManagement

const TextFied = ({ id }) => {
    const people = useSelector(state => state[id]);
    const dispatch = useDispatch()
    const people_curator = people.curator
    const handleOnChange = (data, type) => {
        if (!people?.curator) people.curator = {}
        if (!people?.curator.id) people.curator.id = newTempId()
        if (!people.curator?.[type]) people.curator[type] = ''
        people.curator[type] = data
        dispatch({ type: 'entity', payload: { ...people, curator: { ...people.curator, [type]: people.curator[type] } } })
    }

    return (
        <>
            <CuratorTextField label={'BIO'} title={'Bio Attribution:'} disableSubTextBox={true} handleMainInputChange={(e) => { handleOnChange(e, 'bio_attribution') }} mainInput={people_curator?.bio_attribution || ''} hrTrue />
        </>
    )
}

const PurchaseLink = ({ id }) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[id]);

    const removeTickets = () => {
        entity.curator.currency = '';
        entity.curator.price = '';
        entity.curator.purchase_link_name = '';

        dispatch({ type: 'entity', payload: { ...entity, curator: entity.curator } })
    }

    const handleInputChange = (data) => {
        if (!entity?.curator) entity.curator = {}
        if (!entity?.curator?.id) entity.curator.id = newTempId()
        entity.curator.purchase_link_name = data
        dispatch({ type: 'entity', payload: { ...entity, curator: { ...entity.curator, purchase_link_name: entity.curator.purchase_link_name } } })
    }


    const handleChange = (e) => {
        if (!entity?.curator) entity.curator = {}
        if (!entity?.curator?.id) entity.curator.id = newTempId()
        if (!entity?.curator?.currency) entity.curator.currency = ''
        entity.curator.currency = e.value
        dispatch({ type: 'entity', payload: { ...entity, curator: { ...entity.curator, currency: entity.curator.currency } } })
    }

    const handlePriceChange = (data) => {
        if (!entity?.curator) entity.curator = {}
        if (!entity?.curator.id) entity.curator.id = newTempId()
        if (!entity.curator.price) entity.curator.price = 0
        entity.curator.price = data
        dispatch({ type: 'entity', payload: { ...entity, curator: { ...entity.curator, price: entity.curator.price } } })
    }

    return (
        <>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>{'PURCHASE LINK'}</label>
                </div>
            </div>
            <div>
                <div className='row g-1 mt-1 dragula-item' >
                    <div className='col'>
                        <label className='fw-bold'><small>{'Purchase Link Name'}</small></label>
                        <input name='category' type='text' className='form-control' placeholder='Purchase Link name' value={entity?.curator?.purchase_link_name} onChange={({ target: { value } }) => handleInputChange(value)} autoComplete='off' />
                    </div>
                    <div className='col-auto'>
                        <label className='fw-bold'><small>{'Currency'}</small></label>
                        <Select cacheOptions defaultOptions
                            styles={customSelectStyle}
                            options={currency}
                            value={{
                                value: entity?.curator?.currency,
                                label: entity?.curator?.currency
                            }}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className='col-auto'>
                        <label className='fw-bold'><small>{'Price'}</small></label>
                        <input name='price' type='number' className='form-control' placeholder='xxx' value={entity?.curator?.price || ''} onChange={({ target: { value } }) => handlePriceChange(value)} autoComplete='off' />
                    </div>
                    <div className='col-auto'>
                        <label className='cnow-form-label'></label>
                        <div className='btn-list'>
                            <Button icon='delete' onClick={() => removeTickets()} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}