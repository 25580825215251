/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import uniqid from 'uniqid'
import Dragula from 'react-dragula'

import TextEditor from './textEditor'
import SelectGlobalList from '../selects/globalList'
import SelectDate from '../selects/date'
import Button from '../buttons/default'
import { handleReorder, newTempId } from '../../helpers'

const _api = require('../../api')

const DateEditor = (props) => {

    const dispatch = useDispatch()
    const entity = useSelector(state => state[props.id])

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (entity.dates && entity.dates.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.dates, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, dates: items } })
        })
    }, [dragula])
    // Dragula End


    const addDate = () => {
        let dates = entity.dates || []
        let date = {
            'id': newTempId(),
            'day': null,
            'month': null,
            'year': null,
            'ca': false,
            'bce': false,
            'remarks': '',
            'description': '',
            'event_type_id': null,
            'event_type_name': null,
            'sort': dates.length + 1
        }

        date[`${props.type}_id`] = entity.id
        dates.push(date)
        setDragula(uniqid())
        dates.forEach((item, index) => item.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...entity, dates: dates } })
    }

    const removeDate = async (index) => {
        if (entity.dates[index]) {
            if (!entity.dates[index].id.startsWith('temp.')) {
                await _api[(props.type=== "organization" || props.type === "publication" ) ? 'person': props?.type].deleteDate(entity.dates[index].id).then(_ => {
                    entity.dates.splice(index, 1)
                    entity.dates.forEach((item, index) => item.sort = index + 1)
                    dispatch({ type: 'entity', payload: { ...entity, dates: entity.dates } });
                })
            } else {
                entity.dates.splice(index, 1)
                entity.dates.forEach((item, index) => item.sort = index + 1)
                dispatch({ type: 'entity', payload: { ...entity, dates: entity.dates } });
            }
        }
    }

    const handleDateEditorChange = (index, data) => {
        entity.dates[index].remarks = data
        dispatch({ type: 'entity', payload: { ...entity, dates: entity.dates } })
    }

    const handleDateChange = (index, data) => {
        entity.dates[index].day = data.day
        entity.dates[index].month = data.month
        entity.dates[index].year = data.year
        entity.dates[index].ca = data.ca
        entity.dates[index].bce = data.bce

        if (data.bce === true || data.ca === true) {
            data.day = null
            data.month = null
        }

        dispatch({ type: 'entity', payload: { ...entity, dates: entity.dates } })
    }

    const handleEventTypeChange = (index, value) => {
        entity.dates[index].event_type_id = value.id
        entity.dates[index].event_type_name = value.name
        dispatch({ type: 'entity', payload: { ...entity, dates: entity.dates } })
    }

    return (
        <div>
            <hr className='cnow-hr' />

            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Date</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a Date' onClick={() => addDate()} />
                </div>
            </div>

            <div id={dragulaContainer} key={dragula} >
                {
                    entity?.dates?.map((date, index) => {
                        const event_type = {
                            'id': date.event_type_id,
                            'name': date.event_type_name
                        }

                        return (
                            <div id={date.id} key={index} className='dragula-item'>
                                <div className='card-bodys'>
                                    <div className='row g-1'>
                                        <div className={`${entity?.dates?.length > 1 ? 'col-auto' : 'd-none'}`}>
                                            <label className='cnow-form-label fw-bold'>Order</label>
                                            <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete='off' />
                                        </div>

                                        <div className={entity?.dates?.length > 1 ? 'col' : 'col'}>
                                            <div className='row g-1'>
                                                <div name='event_type' className='col-12 col-md-auto d-flex flex-grow-1'>
                                                    <div className='w-100'>
                                                        <label className='cnow-form-label fw-bold'>What happened</label>
                                                        <SelectGlobalList key={date.id} type={`${props.type}_date`} value={event_type} onChange={(value) => handleEventTypeChange(index, value)} />
                                                    </div>
                                                </div>

                                                <div name='date' className='col-auto'>
                                                    <SelectDate key={date.id} label={true} type={true} value={date} onChange={(data) => handleDateChange(index, data)} />
                                                </div>

                                                <div name='button' className='col-auto'>
                                                    <label className='cnow-form-label'></label>
                                                    <div className='text-end'>
                                                        <Button icon='delete' onClick={() => removeDate(index)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <TextEditor key={date.id} name='remarks' data={date.remarks} onChange={(data) => handleDateEditorChange(index, data)}>
                                                <label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
                                            </TextEditor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DateEditor