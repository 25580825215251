/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid'
import Dragula from "react-dragula";
import SelectPerson from './selects/person';
import Button from './buttons/default';
import PreviewButton from './buttons/preview';
import LinkButton from './buttons/link';
import { handleReorder, newTempId, personName } from '../helpers';
const _api = require('../api')

const EventPeopleEditor = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);
    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = `dragula-container-${props.section}-people`

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity[props.section], null, dragulaContainer)
            dispatch({ type: 'entity', payload: { ...entity, [props.section]: items } })
            setDragula(uniqid())
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addPerson = () => {
        if (!entity[props.section]) entity[props.section] = [];
        entity[props.section].push({
            'id': newTempId(),
            [`${props?.type}_id`]: props.id,
            'person_id': null,
            'person_name': '',
            'sort': entity[props.section]?.length + 1,
        })
        entity[props.section].forEach((item, index) => item.sort = index + 1)
        setDragula(uniqid())
        dispatch({ type: 'entity', payload: { ...entity, [props.section]: entity[props.section] } });

    }

    const removePerson = async (index) => {
        if (entity[props.section][index]) {
            if (!entity[props.section][index].id.startsWith('temp.')) {
                await _api[props?.type][props.deleteLabel](entity[props.section][index].id)
            }
            entity[props.section].splice(index, 1)
            entity[props.section].forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...entity, [props.section]: entity[props.section] } });
        }
    }

    const handlePersonChange = (index, value) => {
        entity[props.section][index].person_id = value.id;
        entity[props.section][index].person_name = value.name;
        dispatch({ type: 'entity', payload: { ...entity, [props.section]: entity[props.section] } });
    }

    return (
        <div>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>{props.title}</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a Person' onClick={() => addPerson()} />
                </div>
            </div>
            <div id={dragulaContainer} key={dragula} >
                {
                    entity?.[props.section]?.map((item, index) => {
                        return (
                            <div key={index} id={item.id} className='mb-3 dragula-item'>
                                <div className='row g-1'>
                                    <div className={entity?.[props.section]?.length > 1 ? 'col-auto' : 'd-none'}>
                                       {index === 0 &&  <label className='cnow-form-label fw-bold'>Order</label>}
                                        <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete="off" />
                                    </div>
                                    <div className='col'>
                                        <div className='row g-1'>
                                            <div className='col-auto d-flex flex-grow-1'>
                                                <div className='w-100'>
                                                    {index === 0 && <label className='cnow-form-label fw-bold'>Person or Organization*</label>}
                                                    {
                                                        !item.id.startsWith('temp.') ?
                                                            <input value={personName(item)} disabled className='w-100 cnow-input-disabled-height' /> :
                                                            <SelectPerson key={item.id} value={{ id: item?.person_id, name: personName(item) }} className="prime1" onChange={(value) => handlePersonChange(index, value)} />
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-auto'>
                                                { index === 0 &&  <div>&nbsp;</div>}
                                                <div className='btn-list'>
                                                    <PreviewButton link={`people/${item?.person_id}`} />
                                                    <LinkButton icon='edit' to={`/people/${item?.person_id}/editor`} />
                                                    <Button icon='delete' onClick={() => removePerson(index)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default EventPeopleEditor;