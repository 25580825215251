import CKEditorInline from "@ckeditor/ckeditor5-build-inline";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import ImageView from "../../components/viewers/image";
import { useDispatch, useSelector } from "react-redux";
import SelectDate from "../../components/selects/date";
import Button from "../../components/buttons/default";
import BannerPodcast from '../image/bannerPodcast'
import moment from 'moment';
import ImageUploader from '../image/upload';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
const _api = require('../../api')

const PodcastFormTwo = (props) => {
  const dispatch = useDispatch();
  const podcast = useSelector((state) => state[props.id]);
  const [changeBanner, setChangeBanner] = useState(false)
  const [imageUploader, showImageUploader] = useState(false)
  const loc = podcast?.type === 'video' && podcast?.url ? new URL(podcast?.url) : null;

  const location = useLocation()
  const history = useHistory()

  let iframe = "";
  let host = "";
  let src = ""
  if (loc) {
    host = loc.host;
    if (host === "www.youtube.com") {
      const params = loc.searchParams;
      const v_code = params.get('v');
      src = `https://www.youtube.com/embed/${v_code}`
    } else if (host === "youtu.be") {
      src = `https://www.youtube.com/embed${loc.pathname}`
    } else {
      src = `https://player.vimeo.com/video${loc.pathname}`
    }
    iframe = `<iframe width="100%" height="100%" src=${src}
  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
  allowfullscreen></iframe>`;
  }

  const handleTakenDateChange = (data) => {
    podcast.date_uploaded = data;
    dispatch({
      type: "entity",
      payload: { ...podcast, date_uploaded: podcast.date_uploaded },
    });
  };
  const handleCaptionChange = (e, editor) => {
    const data = editor.getData();
    podcast.caption = data;
    dispatch({ type: "entity", payload: { ...podcast, caption: podcast.caption } });
  };

  const handleSave = async (image) => {
    podcast.image = image;
    podcast.image_id = image[0]?.id ? image[0]?.id : image?.id
    let podcastResponse = await _api.podcast.update(podcast)
    dispatch({ type: "entity", payload: { ...podcast, ...podcastResponse } });
  }

  const editItems = (id) => {
    let imagesList = []
    dispatch({ type: 'images', payload: { entityImages: imagesList, source: location.pathname } })
    history.push(`/image/${id}/editor`)
  }
  return (
    <>
      {podcast && (
        <div className='cnow-image-editor-container'>
          <div id='date_taken'>
            <div className='cnow-form-title'>Date Recorded/Uploaded</div>
            <SelectDate
              type={false}
              value={podcast?.date_uploaded}
              onChange={handleTakenDateChange}
            />
            <hr className='cnow-hr' />
          </div>
          <div className='cnow-image-select'>

            {podcast?.related_items?.length > 0 ?
              <>
                <div className="d-flex justify-content-between">
                  <div className='cnow-form-title'>Image *</div>
                  <div className="btn-list">
                    {podcast?.image?.id && <Button size='sm' icon='edit' onClick={() => editItems(podcast?.image?.id)} />}
                    <Button size='sm' icon='replace' onClick={() => setChangeBanner(true)} />
                  </div>
                </div>
                <BannerPodcast onHide={() => setChangeBanner(false)} show={changeBanner} id={props?.id}
                  handleSave={(image) => handleSave(image)} type='podcast' entityId={podcast.related_items[0].id} entityType={podcast.related_items[0].source} key={props?.id} />
              </> :
              <>
                <div className="d-flex justify-content-between">
                  <div className='cnow-form-title'>Image *</div>
                  <Button size='sm' icon='plus' label='Set a Banner Image' onClick={() => showImageUploader(true)} />
                </div>
              </>
            }
            {
              imageUploader &&
              <ImageUploader globalSearch={true} imageType='image'
                show={imageUploader}
                onHide={() => showImageUploader(false)}
                onSave={(image) => handleSave(image)}
              />
            }

            <div onClick={() => setChangeBanner(true)} >
              {
                (podcast?.image?.type === 'document' && podcast?.image?.url?.endsWith('.pdf')) ?
                  <ImageView url={podcast.image.url} entityType='image' type='headshot' cssClass='cnow-image-editor-thumbnail' />
                  : <ImageView entityId={podcast?.image_id} entityType='image' type='headshot' cssClass='cnow-image-editor-thumbnail' />
              }
            </div>
            <hr className='cnow-hr' />
          </div>
          <div className='cnow-audio-captions'>
            <div className="d-flex justify-content-between">
              <div className='cnow-form-title'>{podcast?.type === 'audio' ? "Audio" : "Video"} Captions</div>
              <Button size='sm' icon='edit' onClick={() => { }} />
            </div>
            {podcast?.type === 'audio' ?
              <AudioPlayer
                preload='meta'
                style={{ backgroundColor: "transparent", boxShadow: "none" }}
                autoPlay={false}
                autoPlayAfterSrcChange={false}
                src={podcast?.url + '?' + moment().unix()}
                showSkipControls={false}
                showJumpControls={true}
                progressJumpSteps={{
                  backward: 10000,
                  forward: 10000,
                }}
                volume={0.6}
                customAdditionalControls={["<div></div>"]}
              /> : <div style={{ height: '320px', width: '100%' }} dangerouslySetInnerHTML={{ __html: iframe }} />}
            <div className='cnow-edit-captions'>
              <div className='fw-bold'>Edit captions</div>
              <div
                className='border bg-white'
                style={{ height: 300, overflow: "auto" }}
              >
                <CKEditor
                  // onReady={(editor) => {
                  //   editor.ui.view.panel.element.setAttribute("style", "display:none");
                  // }}
                  config={{ autoParagraph: false }}
                  data={podcast?.caption}
                  onChange={handleCaptionChange}
                  editor={CKEditorInline}
                />
              </div>
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default PodcastFormTwo;
