import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import LegalHistory from "../../components/curator/legalHistory";
import PageTitle from "../../components/pageTitle";
import PlaylistType from "../../components/curator/playlistType";
import EntityOfDayCheckBox from "../../components/curator/entityOfDayCheckbox";
import CuratorTextField from "../../components/curator/textField";
import { newTempId } from "../../helpers";

const CuratorManagement = ({ id }) => {
    const playlist = useSelector(state => state[id]);
    return (
        <>
            <PageTitle title={playlist.name} />
            <TextFied id={id} key={id} />
            <PlaylistType id={id} />
            <LegalHistory id={id} type={'playlist'} />
            <EntityOfDayCheckBox type={'playlist'} id={id} entity_day={'Playlist'} key={playlist?.curator?.not_show_playlist_of_the_day} show_entity_of_day={'not_show_playlist_of_the_day'} />

        </>
    )
}

export default CuratorManagement

const TextFied = ({ id }) => {
    const playlist = useSelector(state => state[id]);
    const dispatch = useDispatch()
    const playlist_curator = playlist.curator
    const handleOnChange = (data, type) => {
        if (!playlist?.curator) playlist.curator = {}
        if (!playlist?.curator?.id) playlist.curator.id = newTempId()
        if (!playlist?.curator?.[type]) playlist.curator[type] = ''
        playlist.curator[type] = data
        dispatch({ type: 'entity', payload: { ...playlist, curator: { ...playlist.curator, [type]: playlist.curator[type] } } })
    }

    return (
        <>
            <CuratorTextField label={'NOTES'} disableMainTextBox subTitle={'Internal Notes (available only to users with admin access):'} handleSubInputChange={(e) => { handleOnChange(e, 'internal_notes') }} subInput={playlist_curator?.internal_notes || ''} hrTrue />
        </>
    )
}