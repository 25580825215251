import React from 'react';
import { useSelector } from 'react-redux';
import ImageViewer from '../../components/viewers/image';
import LinkButton from '../../components/buttons/link';
import PreviewButton from '../../components/buttons/preview';

const RecipeRelatedEditor = (props) => {
    const entity = useSelector(state => state[props.id]);
    return (
        <>
            <hr className='cnow-hr' />
            <div className='cnow-form-title'>{`Related ${props?.type === "event_series" ? 'event series' : props?.type}`}</div>

            {entity?.[props?.type]?.length > 0 && entity?.[props?.type].map((item, index) => {
                return (
                    <div className='row g-1 mt-1' key={index}>
                        <div className='col-auto'>
                            {index >= 0 && <label className='cnow-form-label'>Order</label>}
                            <input type='text' className='form-control form-control-sort' disabled name='sort' value={index + 1} autoComplete="off" />
                        </div>
                        {props.type !== "playlist" && <div className='col-auto'>
                            <div>&nbsp;</div>
                            <ImageViewer key={item?.image_id} entityType={props?.type} entityId={item[`${props?.type}_id`]} type='thumb' cssClass='thumb-small' />
                        </div>}
                        {props.type === "playlist" && <div className='col-auto'>
                            <div>&nbsp;</div>
                            <div className='position-relative'>
                                <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
                                    {item?.playlist_image_url && <img src="/img/icons/playlist.svg" style={{ width: '100%', height: '100%' }} alt="playlist" />}
                                </div>
                                <ImageViewer url={item?.playlist_image_url} type='thumb' cssClass='thumb' key={item.id} />
                            </div>
                        </div>}


                        <div className='col'>
                            {index >= 0 && <label className='cnow-form-label'>{`${props?.type === "event_series" ? 'event series' : props?.type} name`}</label>}
                            <input type='text' className='form-control' disabled value={item[`${props?.type}_name`]} autoComplete="off"></input>
                        </div>

                        <div className='col-auto'>
                            {index >= 0 && <label className='cnow-form-label'></label>}
                            <div className='btn-list'>
                                <PreviewButton link={`${props?.type}/${item[`${props?.type}_id`]}`} />
                                <LinkButton icon='edit' to={`/${props?.type}/${item[`${props?.type}_id`]}/editor`} />
                            </div>
                        </div>
                    </div>
                )
            })}
        </>

    )
}

export default RecipeRelatedEditor