/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import swal from 'sweetalert';
import Button from '../../../components/buttons/default';
import PreviewButton from '../../../components/buttons/preview';
import Pagination from '../../../components/pagination';
import ResultCount from '../../../components/resultCount';
import ScrollToTop from '../../../components/scrollToTop';
import Spinner from '../../../components/spinner';
import CreditList from '../../../components/table/creditList';
import LocationList from '../../../components/table/locationList';
import RelatedEntityList from '../../../components/table/relatedEntityList';
import TagList from '../../../components/table/tagList';
import TableHeader from '../../../components/tableHeader';
import Icon from '../../../components/viewers/icon';
import ImageViewer from '../../../components/viewers/image';
import LongTextView from '../../../components/viewers/longText';
import PodcastUpload from '../../podcast/upload';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { differenceBy } from 'lodash';
import { dateString, TABLE_ROWS, newTempId } from '../../../helpers';

const columns = require('../../../data/columns_podcast.json')
const _api = require('../../../api')

const FeaturedPodcast = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()
  // const [searchResult, setSearchResult] = useState([]);
  const [uploader, showUploader] = useState(false);
  const [keywordValue, setKeywordValue] = useState('');
  const [keyword, setKeyword] = useState('');

  const take = 50;
  const [page, setPage] = useState(1);;
  const [sort, setSort] = useState(columns[0]);

  const [spinner, showSpinner] = useState(false);

  const [paginationHits, setPaginationHits] = useState(0)
  let section = props.section
  const searchPodcast = useSelector((state) => state.search);
  let searchResult = searchPodcast.searchResult || []
  let selectedResult = searchPodcast.selectedResult?.filter((elem) => elem.entity_type === 'podcast') || []
  let sectionResult = searchPodcast[section]?.filter((elem) => elem.entity_type === 'podcast') || []
  let selectedEntity = [...sectionResult, ...selectedResult]
  const featured = useSelector((state) => state.featured);

  const [resultFilters, setResultFilters] = useState({
    live: searchPodcast.filterLive,
    limit: searchPodcast.selectedLimit,
  })
  const [label, setLabel] = useState([])
  let start_date = featured.start_date && JSON.parse(featured.start_date)
  let end_date = featured.end_date && JSON.parse(featured.end_date)
  let start = start_date ? moment(start_date).format("MMMM Do YYYY") : ''
  let end = end_date ? moment(end_date).format("MMMM Do YYYY") : ''


  useEffect(() => {
    search()
  }, [page, sort, keyword]);

  const search = async () => {
    showSpinner(true);

    const params = {
      keyword: keyword,
      hitsPerPage: take,
      page: page - 1,
      facetFilters: [
        [`live:${resultFilters?.live}`],
        ["deleted:false"]
      ]
    }
    handleSetKeywords(keyword)
    _api.podcast.search(params).then(results => {
      // setSearchResult(results)
      setPaginationHits(results.nbHits);
      const filteredArray = differenceBy(results.hits, selectedEntity, 'id')
      dispatch({ type: 'search', payload: { ...searchPodcast, searchResult: filteredArray } })
      showSpinner(false)
      // dispatch({ type: 'podcasts', payload: [] })
    })
  }

  const handleSetKeywords = (keyword) => {
    let data = [];
    if (resultFilters?.live) data.push(`Live - Podcast`)
    if (keyword) data.push(keyword)
    setLabel(data)
  }

  const handleDeleteLabel = (index) => {
    let data = label[index];
    if (data.includes('Live')) resultFilters.live = ''
    if (data === keyword) setKeywordValue(''); setKeyword('')
    setResultFilters(resultFilters)
    search()
  }


  const deletePodcast = (podcast) => {
    swal({
      title: `Delete Podcast`,
      text: `Are you sure that you want to delete ${podcast.index_name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then((status) => { _api.podcast.deleteById(podcast?.id).then(() => { search() }) });
  };

  const updateLive = (id, live) => {
    showSpinner(true)
    let selectedSearchResult = searchResult.hits.filter((item) => item.id === id)
    let selectedResult = selectedSearchResult[0]
    if (live === true) {
      _api.podcast.updateLive(id, live).then((_) => search())
      swal({
        title: 'Podcast is Live!',
        icon: 'success',
        text: `${selectedResult?.name} is now Live!`,
        buttons: 'Ok',
      })
    } else {
      _api.podcast.updateLive(id, live).then((_) => search())
      swal({
        title: 'Not Live!',
        icon: 'info',
        text: `${selectedResult?.name} is Not Live.`,
        buttons: 'Ok',
      })
    }
    showSpinner(false)
  }

  const handleEdit = (route) => {
    history.push({ pathname: route })
    dispatch({ type: 'podcasts', payload: { entityPodcasts: [], source: location.pathname } })
  }

  const handleOpenUploader = () => {
    dispatch({ type: 'podcasts', payload: { entityPodcasts: [], source: location.pathname } })
    showUploader(true)
  }

  const selectedPodcast = (index) => {
    showSpinner(true)
    searchResult[index].entity_type = 'podcast'
    selectedResult.push(searchResult[index])
    searchResult.splice(index, 1)
    dispatch({
      type: 'search', payload: {
        ...searchPodcast,
        selectedResult: selectedResult,
        searchResult: searchResult
      }
    })
    showSpinner(false)
  }

  const removeSelectedPodcast = (index) => {
    if (selectedEntity[index].section_index !== undefined && selectedEntity[index].entity_index !== undefined) {
      let section_index = selectedEntity[index].section_index
      let entity_index = selectedEntity[index].entity_index
      featured[section][section_index].edited = true
      featured[section][section_index].data[section].splice(entity_index, 1)
      featured[section][section_index].data[section].forEach((elem, index) => elem.entity_index = index)
      let sectionIndex = sectionResult.findIndex(elem => elem.section_index === section_index && elem.entity_index === entity_index)
      sectionResult.splice(sectionIndex, 1)
      dispatch({ type: 'featured', payload: { ...featured, [`${section}`]: featured[`${section}`] } })
      dispatch({ type: 'search', payload: { ...searchPodcast, [`${section}`]: sectionResult } })
    }
    else {
      let searchResultIndex = selectedResult.findIndex(elem => elem.id === selectedEntity[index].id)
      searchResult.push(selectedResult[searchResultIndex])
      selectedResult.splice(searchResultIndex, 1)
      dispatch({
        type: 'search', payload: { ...searchPodcast, selectedResult: selectedResult, searchResult: searchResult }
      })
    }

  }

  const selectedPodcastDispatch = () => {
    if (selectedResult.length > 0) {
      let index = featured[`${section}`].length === 0 ? -1 : 0
      let data = {
        "start_date": start_date.slice(0, 10),
        "end_date": end_date.slice(0, 10),
        "section": `${section}`,
      }
      if (index === -1) {
        data.id = newTempId()
        data.data = {
          [`${section}`]: selectedResult
        }
        featured[`${section}`].push(data)
      }
      else {
        featured[`${section}`][index].edited = true
        featured[`${section}`][index].data[`${section}`] = [...featured[`${section}`][index].data[`${section}`], ...selectedResult]
      }
    }
    dispatch({ type: 'featured', payload: { ...featured, source: location.pathname } })
    dispatch({ type: 'search', payload: {} })
    history.goBack()
  }


  return (
    <>
      <div className='container-fluid mt-3'>
        <div className='row g-1'>
          <div className='col'>
            <div className='input-group'>
              <span className='input-group-text border-end-0'>
                <Icon name='search' />
              </span>
              <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                onChange={(event) => { setKeywordValue(event.target.value) }}
                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue) } }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className='col-auto'>
            <div className='d-flex gap-1 btn-list'>
              <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue) }} />
              <Button label='Add a Podcast' color='danger' icon='plus-white' onClick={() => handleOpenUploader()} />
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid mt-3 p-0'>
        <div className='card'>
          <div className='card-header bg-light p-2'>
            <div className='d-flex justify-content-between'>
              <div>
                <h4 className='mb-0 cursor-pointer text-capitalize' onClick={() => { selectedPodcastDispatch() }}>{`${searchPodcast.title} - ${start_date === end_date ? start : `${start} - ${end}`}`}</h4>
                <h6 className='mb-0'>PODCASTS</h6>
                <div className='small'>
                  <ResultCount page={page} take={take} count={paginationHits} />

                  {
                    label && label.map((elem, index) => {
                      return (
                        <span className='badge badge-search'>
                          {elem} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { handleDeleteLabel(index) }} />
                        </span>
                      )
                    })
                  }
                </div>
              </div>
              <div className='cols align-self-end'>
                <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
              </div>
              <div className='align-self-end'>
                <div className='dropdown'>
                  <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                    Sort by: {sort.name}
                  </button>
                  <ul className='dropdown-menu'>
                    {columns.filter(x => x.sort === true)
                      .map((column, index) =>
                        <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Spinner display={spinner}>
            <div className='table-responsive cnow-table'>
              <table className='table table-striped table-hover table-bordered'>
                <thead>
                  <tr className='bg-light'>
                    {columns.filter(x => x.display === true).map((column, index) =>
                      <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr className='bg-dark'>
                    <th className='text-white' scope="col " colspan="100%">
                      <div className='d-flex ' style={{ width: "94vw" }}>
                        <span className=''>
                          {`${searchPodcast.title} PODCAST SELECTED`}
                        </span>
                        <span className='ms-auto text-lowercase'>{selectedEntity.length > 1 ? `${selectedEntity.length} results` : `${selectedEntity.length} result`}</span>
                      </div>
                    </th>
                  </tr>
                  {selectedEntity?.map((item, index) => {
                    return (
                      <tr key={`${item.id}-${index}`}>
                        <td>
                          <div className='row g-1'>
                            <div className='col-auto'>
                              <div className='form-check mt-1'>
                                <input className='form-check-input align-middle' type='checkbox' name='select_site' value={item.id} checked={true} onClick={() => { removeSelectedPodcast(index) }} />
                              </div>
                            </div>
                            <div className='col-auto'>
                              <div className='btn-list'>
                                <Button size='sm' icon='edit' onClick={() => { handleEdit(`/podcast/${item.id}/editor`) }} />
                                <Button size='sm' icon='delete' onClick={() => deletePodcast(item)} />
                                <PreviewButton size='sm' link={`podcast/${item.id}`} />
                                <Button size='live'
                                  icon={item.live === true ? 'live' : 'not-live'}
                                  color={item.live === true ? '' : 'danger'}
                                  onClick={() => updateLive(item.id, item.live === true ? false : true)} />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <ImageViewer entityType={'podcast'} entityId={item?.id} type='thumb@2x' cssClass='thumb' />
                        </td>
                        <td className='fw-bold'><LongTextView lines={TABLE_ROWS + 1}>{item.name}</LongTextView></td>
                        <td style={{ textAlign: 'center' }}>
                          <Icon name={`podcast_${item.type}`} size={16} />
                        </td>
                        <td>{item.duration}</td>
                        <CreditList data={item.credits} />
                        <LocationList data={item?.related_item} />
                        <td>{item.id}</td>
                        <td>{dateString(item.date_uploaded)}</td>
                        <TagList data={item.tags} />
                        <td>
                          <LongTextView lines={TABLE_ROWS + 1}>{item.description}</LongTextView>
                        </td>
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'playlist')} type='playlist' />
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'site')} type='site' />
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'person')} type='people' />
                        <td></td>
                        <td></td>
                        <td></td>
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'map')} type='map' />
                        <td>{item?.edited_by}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tbody>
                  <tr className='bg-dark'>
                    <th className='text-white' scope="col " colspan="100%">
                      <div className='d-flex ' style={{ width: "94vw" }}>
                        <span className=''>
                          {`${searchPodcast.title} PODCAST AVAILABLE`}
                        </span>
                        <span className='ms-auto text-lowercase'>{searchResult.length > 1 ? `${searchResult.length} results` : `${searchResult.length} result`}</span>
                      </div>
                    </th>
                  </tr>
                  {searchResult?.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <div className='row g-1'>
                            <div className='col-auto'>
                              <div className='form-check mt-1'>
                                <input className='form-check-input align-middle' type='checkbox' name='select_site' value={item.id} onClick={() => { selectedPodcast(index) }} disabled={resultFilters.limit && (selectedEntity.length >= resultFilters.limit) ? true : false} />
                              </div>
                            </div>
                            <div className='col-auto'>
                              <div className='btn-list'>
                                <Button size='sm' icon='edit' onClick={() => { handleEdit(`/podcast/${item.id}/editor`) }} />
                                <Button size='sm' icon='delete' onClick={() => deletePodcast(item)} />
                                <PreviewButton size='sm' link={`podcast/${item.id}`} />
                                <Button size='live'
                                  icon={item.live === true ? 'live' : 'not-live'}
                                  color={item.live === true ? '' : 'danger'}
                                  onClick={() => updateLive(item.id, item.live === true ? false : true)} />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <ImageViewer entityType={'podcast'} entityId={item?.id} type='thumb@2x' cssClass='thumb' />
                        </td>
                        <td className='fw-bold'><LongTextView lines={TABLE_ROWS + 1}>{item.name}</LongTextView></td>
                        <td style={{ textAlign: 'center' }}>
                          <Icon name={`podcast_${item.type}`} size={16} />
                        </td>
                        <td>{item.duration}</td>
                        <CreditList data={item.credits} />
                        <LocationList data={item?.related_item} />
                        <td>{item.id}</td>
                        <td>{dateString(item.date_uploaded)}</td>
                        <TagList data={item.tags} />
                        <td>
                          <LongTextView lines={TABLE_ROWS + 1}>{item.description}</LongTextView>
                        </td>
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'playlist')} type='playlist' />
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'site')} type='site' />
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'person')} type='people' />
                        <td></td>
                        <td></td>
                        <td></td>
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'map')} type='map' />
                        <td>{item?.edited_by}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className='d-flex justify-content-between m-3'>
              <div className='cols'>
                <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
              </div>
            </div>
          </Spinner>
        </div>
      </div>
      {uploader && <PodcastUpload
        show={uploader}
        onHide={() => {
          // dispatch({ type: 'podcasts', payload: [] })
          showUploader(false)
        }
        } />}
      <ScrollToTop />
    </>
  );
};

export default FeaturedPodcast;