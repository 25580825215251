import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';
import Name from '../../components/preview/name';
import Description from '../../components/preview/description';
// import EventList from '../../components/preview/eventList';
import TourList from '../../components/preview/tourList';
import EventHost from '../../components/preview/eventHost';
import MapsList from '../../components/preview/mapsList';
import MetaDescription from '../../components/preview/metaDescription';
import TagList from '../../components/preview/tagList';
import { locationName } from '../../helpers';
import LocationList from '../../components/preview/locationList';
import EventMap from '../../components/preview/eventMap';
import EventImages from '../../components/preview/eventImages';
import StoryList from '../../components/preview/storyList';
import RelatedPeopleList from '../../components/preview/relatedPeopleList';
import RelatedSiteList from '../../components/preview/relatedSiteList';
import PodcastList from '../../components/preview/podcastList';
import Playlist from '../../components/preview/playlist';
import swal from 'sweetalert'
import ImageViewer from '../../components/viewers/image';
import About from '../../components/preview/about';
import EventCard from '../../components/preview/eventCard';
import RecipeCard from '../../components/preview/recipeCard';
const _api = require('../../api');

const EventPreview = (props) => {
    const dispatch = useDispatch();
    const event = useSelector(state => state[props.id]);
    const previewSize = useSelector((state) => state.preview);

    const togglePreviewSize = () => dispatch({ type: 'preview', payload: previewSize === 'sm' ? 'lg' : 'sm' })

    const handleLive = (eventId) => {
        if (event.id === 'NEW') {
            swal({
                title: 'Event Series cannot go Live.',
                icon: 'error',
                text: `${event?.id === 'NEW' && 'Fill the mandatory fields * to make the Event go live.'}`,
                buttons: 'Go back',
                dangerMode: true,
            })
        }
        else if (event?.name) {
            swal({
                title: 'Event Series is Live!',
                icon: 'success',
                text: `${event.name} is now Live.`,
                buttons: 'Ok',
            })
            _api.event.updateLive(eventId, true).then(() =>
                dispatch({ type: 'entity', payload: { ...event, live: true } })
            )
        } else {
            swal({
                title: 'Event Series cannot go Live.',
                icon: 'error',
                text: `${'Please fill the mandatory fields:'}
					${event?.name ? '' : 'Title is missing.'}`,
                buttons: 'Go back',
                dangerMode: true,
            })
            _api.event.updateLive(eventId, false).then(() =>
                dispatch({ type: 'entity', payload: { ...event, live: false } })
            )
        }

    }

    const handleNotLive = (eventId) => {
        _api.event.updateLive(eventId, false).then(() =>
            dispatch({ type: 'entity', payload: { ...event, live: false } })
        )
        swal({
            title: 'Not Live!',
            icon: 'info',
            text: `${event.name} is now Not Live.`,
            buttons: 'Ok',
        })

    }

    return (
        <>
            <>
                {
                    previewSize === 'sm' && <div className='btn-grid'>
                        <Button size='sm' color='danger' icon='save-white' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
                        <PreviewButton size='sm' color='light' link={`event_series/${event?.id}`} />

                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

                        <Button size='sm' color='light' icon='help' />
                    </div>
                }

                {
                    previewSize === 'lg' && <div className='btn-list action-bar'>
                        <Button size='sm' color='light' label='Instructions' icon='help' />
                        {event?.live ? (<Button size='sm' color='light' label='Live' onClick={() => { handleNotLive(event.id) }} />)
                            : (<Button size='sm' color='danger' label='Not Live' onClick={() => { handleLive(event.id) }} />)}
                        <Button size='sm' color='danger' label='Save' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
                        <PreviewButton size='sm' color='light' link={`event_series/${event?.id}`} />
                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}
                    </div>
                }
            </>

            {previewSize === 'lg' && <div className='preview'>
                {
                    props.section !== 'location' && props.section !== 'attach_map' && props.section !== 'meta' && props.section !== 'images' && props.section !== 'special_features' && props.section !== 'featured_events' && <>
                        <div className='cnow-bg-red text-white mt-2 fs-5'>Preview</div>
                        <ImageViewer entityId={event?.image_id} entityType={"image"} type="banner@3x" cssClass="banner" />
                        {event?.description?.length > 0 && <About data={event?.description} storiesq={event?.story} />}
                        {event?.related_event?.length > 0 &&
                            <>
                                <div className='cnow-form-title'>Events</div>
                                <EventCard data={event?.related_event} />
                            </>}
                        {event?.special_features?.length > 0 &&
                            <>
                                <div className='cnow-form-title'>Recipe</div>
                                <RecipeCard data={event?.special_features} />
                            </>}
                        <div className='cnow-bg-red text-white mt-2 fs-5'>Preview</div>
                        <Name data={event?.name} />
                        <div> {locationName(event?.locations?.[0])}</div>
                        <div className='d-flex'>
                            {event?.type?.length > 0 && event?.types.map((type, index) => {
                                return (<div className='small text-muted d-flex'>{type?.type_name}{!(index === event.types.length - 1) ? "," : ""}</div>)
                            })}
                        </div>
                        <Description data={event?.description} />
                        <EventHost data={event?.host} title={"Hosted by"} />
                        <StoryList data={event?.stories} />
                        <RelatedPeopleList data={event?.related_person} />
                        <RelatedSiteList data={event?.related_site} />
                        <TourList data={event?.tour} />
                        <PodcastList data={event?.podcast} />
                        <Playlist data={event?.playlists} />

                        {/* 
                        <EventList data={event?.related_events} />
                        <EventMap id={props?.id} type={'event'} />
                        */
                            <TagList tags={event?.tag} themes={event?.themes} categories={event?.categories} entity={'event'} />
                        }
                    </>
                }

                {event && props.section === 'location' && !props.locationId && <EventMap id={props?.id} type={'event_series'} height='70vh' column='flex-column-reverse' />}
                {event && props.section === 'location' && props?.locationId && <LocationList id={event.id} height='70vh' locationId={props?.locationId} type={'event_series'} column='flex-column-reverse' />}
                {event && props.section === 'attach_map' &&
                    <>
                        <MapsList id={event?.id} type='event' />
                        <EventMap id={props?.id} height='70vh' type={'event_series'} />
                    </>
                }
                {event && props.section === 'images' &&
                    <EventImages id={event?.id} type={"event_series"} />}
                {event && props.section === 'featured_events' &&
                    <> {event?.related_event?.length > 0 && <>
                        <div className='cnow-form-title'>Events</div>
                        <EventCard data={event?.related_event} />   </>}</>}
                {event && props.section === 'special_features' &&
                    <> {event?.special_features?.length > 0 && <>
                        <div className='cnow-form-title'>Recipe</div>
                        <RecipeCard data={event?.special_features} /> </>}</>}
                {event && props.section === 'meta' && <MetaDescription data={event?.meta} id={event?.id} type='event' />}
            </div>
            }
        </>
    );
};

export default EventPreview;