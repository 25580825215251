import { toast } from 'react-toastify';
import moment from "moment";
import axios from 'axios';
import uniqid from 'uniqid';
import { uniqBy, has } from 'lodash';
const _api = require('../api');
const months = require('../data/months.json');
export const TABLE_ROWS = 2;

export const customSelectStyle = {
  control: (base, state) => ({
    ...base, borderRadius: '0px',
    borderColor: '#707070',
    color: '#000000',
    textTransform: 'none',
    fontWeight: 'normal'
  }),
  option: (styles) => {
    return {
      ...styles,
      color: '#000000',
      textTransform: 'none',
      fontWeight: 'normal'
    }
  },
  valueContainer: (provided, state) => ({
    ...provided,
    width: '100px'
  }),

  indicatorSeparator: () => { },
}

export const newTempId = () => {
  return `temp.${uniqid()}`
}

export const getImageUrl = (url, type) => {
  if (url) {
    if (url.toLowerCase().endsWith('.pdf'))
      return url.replace('original.pdf', `${type}.png`)
    else
      return url.replace('original.', `${type}.`)
  } else {
    return ''
  }
}

//dragula Functions Start
export const handleReorder = (items, dates, dragulaContainer) => {
  let orderedItems = []

  let container = document.getElementById(dragulaContainer)
  if (container) {
    const elements = container.getElementsByClassName('dragula-item')
    // Without Date Items
    for (let i in elements) {
      const id = elements[i].id
      const index = items.findIndex(x => x.id === id)
      if (index >= 0) {
        items[index].date_id = null
        orderedItems.push(items[index])
      }
    }
  }

  // Date Items
  if (dates && dates.length > 0) {
    for (let j in dates) {
      const date = dates[j]
      container = document.getElementById(`${dragulaContainer}-${date.id}`)
      if (container) {
        const elements = container.getElementsByClassName('dragula-item')

        for (let i in elements) {
          const id = elements[i].id
          const index = items.findIndex(x => x.id === id)
          if (index >= 0) {
            items[index].date_id = date.id
            orderedItems.push(items[index])
          }
        }
      }
    }
  }

  // Sort Items
  orderedItems.forEach((item, index) => item.sort = index + 1)

  return orderedItems
}

export const handleDragDropReorder = (items, dragulaContainer) => {
  let orderedItems = []

  let container = document.getElementById(dragulaContainer)
  if (container) {
    const elements = container.getElementsByClassName('dragula-item')
    for (let i in elements) {
      const id = elements[i].id
      const index = items.findIndex(x => x.id === id)
      if (index >= 0) {
        orderedItems.push(items[index])
      }
    }
  }
  // Sort Items
  orderedItems.forEach((item, index) => item.sort = index + 1)

  return orderedItems
}

export const handleImageTypeReorder = (items, dates, dragulaContainer) => {
  let orderedItems = []
  let container = document.getElementById(dragulaContainer)
  if (container) {
    const elements = container.getElementsByClassName('dragula-item')
    // Without Date Items
    for (let i in elements) {
      const id = elements[i].id
      const index = items.findIndex(x => x.id === id)
      if (index >= 0) {
        items[index].date_id = null
        items[index].type = dragulaContainer
        orderedItems.push(items[index])
      }
    }
  }
  // Date Items
  if (dates && dates.length > 0) {
    for (let j in dates) {
      const date = dates[j]
      container = document.getElementById(`${dragulaContainer}-${date.id}`)
      if (container) {
        const elements = container.getElementsByClassName('dragula-item')

        for (let i in elements) {
          const id = elements[i].id
          const index = items.findIndex(x => x.id === id)
          if (index >= 0) {
            items[index].date_id = date.id
            orderedItems.push(items[index])
            items[index].type = dragulaContainer
          }
        }
      }
    }
  }
  // Sort Items
  orderedItems.forEach((item, index) => item.sort = index + 1)

  return orderedItems
}

export const ReorderSites = (stops, dragulaContainer) => {
  let orderedItems = []
  let sitesData = stops.map((elem) => elem.sites);
  sitesData = sitesData?.flat()
  if (stops && stops.length > 0) {
    for (let j in stops) {
      const stop = stops[j]
      let container = document.getElementById(`${dragulaContainer}-${stop.id}`)
      if (container) {
        const elements = container.getElementsByClassName('dragula-site-item');
        if (elements?.length === 0) {
          stop.sites = []
          orderedItems.push(stop)
        }
        else {
          stop.sites = []
          for (let i in elements) {
            const id = elements[i]?.id
            const index = sitesData.findIndex(x => x?.id === id)
            if (index >= 0) {
              stop.sites.push(sitesData[index])
            }
          }
          orderedItems.push(stop)
        }

      }
    }
    orderedItems.forEach((item, index) => {
      item.sort = index + 1;
      item.sites.forEach((elem, idx) => { if (elem) elem.sort = idx + 1 })
    })
    return orderedItems
  }

}

export const handleReorderEventPodcast = (items, types, dragulaContainer) => {
  let orderedItems = []

  // Date Items
  if (types && types.length > 0) {
    for (let j in types) {
      const podcastType = types[j]
      let container = document.getElementById(`${dragulaContainer}-${podcastType}`)
      if (container) {
        const elements = container.getElementsByClassName('dragula-item')

        for (let i in elements) {
          const id = elements[i].id
          const index = items.findIndex(x => x.id === id)
          if (index >= 0) {
            items[index].type = podcastType
            orderedItems.push(items[index])
          }
        }
      }
    }
  }

  // Sort Items
  orderedItems.forEach((item, index) => item.sort = index + 1)

  return orderedItems
}

export const ReorderPersonRecipe = (items, recipeRole, dragulaContainer) => {
  let orderedItems = []
  // recipeRole Items
  if (recipeRole && recipeRole.length > 0) {
    for (let j in recipeRole) {
      const role = recipeRole[j]
      let container = document.getElementById(`${dragulaContainer}-${role.id}`)
      if (container) {
        const elements = container.getElementsByClassName('dragula-item')

        for (let i in elements) {
          const id = elements[i].id
          const index = items.findIndex(x => x.id === id)
          if (index >= 0) {
            items[index].role_id = role.id
            orderedItems.push(items[index])
          }
        }
      }
    }
  }

  // Sort Items
  orderedItems.forEach((item, index) => item.sort = index + 1)
  return orderedItems
}

export const ReorderSpecialFeature = (items, recipeType, dragulaContainer) => {
  let orderedItems = []
  // recipeType Items
  if (recipeType && recipeType.length > 0) {
    for (let j in recipeType) {
      const type = recipeType[j]
      let container = document.getElementById(`${dragulaContainer}-${type.id}`)
      if (container) {
        const elements = container.getElementsByClassName('dragula-item')
        for (let i in elements) {
          const id = elements[i].id
          const index = items.findIndex(x => x.id === id)
          if (index >= 0) {
            items[index].type_id = type.id
            orderedItems.push(items[index])
          }
        }
      }
    }
  }

  // Sort Items
  orderedItems.forEach((item, index) => item.sort = index + 1)
  return orderedItems
}
//dragula Functions End

export const getNewItemIndex = (items, dates, date_id) => {
  let index = 0

  if (date_id) {
    index = items.filter(x => x.date_id === null).length
    const dateIndex = dates.findIndex(x => x.id === date_id)
    for (let i = 0; i <= dateIndex; i++) {
      index += items.filter(x => x.date_id === dates[i].id).length
    }
  } else {
    index = items.filter(x => x.date_id === null).length
  }

  return index
}

export const getOffsetIndex = (items, dates, date_id) => {
  let offset = items?.filter(x => x.date_id === null).length
  if (date_id) {
    let uniqueDateId = uniqBy(dates, 'id')
    for (let i = 0; i <= uniqueDateId.length; i++) {
      if (uniqueDateId[i].id !== date_id) offset += items?.filter(x => x.date_id === uniqueDateId[i].id).length
      else return offset
    }
  }
  return offset
}

export const getImageAlbum = (imageAlbum, classifier) => {
  let uniqueCreditedItems = uniqBy(imageAlbum, classifier)
  uniqueCreditedItems.forEach(item => {
    if (item.person_id) {
      let person_images = [];
      imageAlbum.filter(x => x.person_id === item.person_id).forEach(listItem => person_images.push({
        image_id: listItem.image_id,
        image_credit: listItem.image_credit,
        image_caption: listItem.image_caption
      }))
      item.person_images = person_images
      item.entity_type = 'people'
    }
    if (item.site_id) {
      let site_images = [];
      imageAlbum.filter(x => x.site_id === item.site_id).forEach(listItem => site_images.push({
        image_id: listItem.image_id,
        image_credit: listItem.image_credit,
        image_caption: listItem.image_caption
      }))
      item.site_images = site_images;
      item.entity_type = 'site'
    }
  })
  return uniqueCreditedItems
}

export const handleApiError = (error) => {
  toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
}

export const dateTitle = (date) => {
  let text = `${date.event_type_name} - ${dateString(date)}`
  return text
}

export const dateName = (date) => {
  let text = ''

  if (date) {
    let eventType = date.event_type_id ? date.event_type_name : ''
    let datePart = dateString(date);

    if (eventType && datePart) text = eventType.concat(', ', datePart);
    else if (eventType) text = eventType;
    else if (datePart) text = datePart;
  }

  return text
}

export const TOOLBAR_OPTIONS = {
  sort: true,
  map: true,
  share: true,
  print: true,
  edit: true,
  save: true,
}


export const dateString = (date) => {
  // let month = parseInt(date?.month) >= 0 ? moment().month(parseInt(date?.month)).format('MMM') : '';
  let month = parseInt(date?.month || date?.story_month) >= 0 ? months.find((item) => item.value === parseInt(date?.month || date?.story_month))?.label : '';
  let text = '';
  text = date?.ca ? text.concat('ca ') : text;
  text = month ? text.concat(month) : text;
  text = (date?.day || date?.story_day) ? text.concat(' ', (date?.day || date?.story_day)) : text;
  text = ((month || (date?.day || date?.story_day)) && (date?.year || date?.story_year)) ? text.concat(', ') : text;
  text = (date?.year || date?.story_year) ? text.concat(date?.year || date?.story_year) : text;
  text = date?.bce ? text.concat(' BCE') : text;
  return text;
}

export const time_convert = (seconds) => {
  seconds = Number(seconds);
  let d = Math.floor(seconds / (3600 * 24));
  let h = Math.floor(seconds % (3600 * 24) / 3600);
  let m = Math.floor(seconds % 3600 / 60);
  // let s = Math.floor(seconds % 60)
  let dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
  let hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  let mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  // let sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return dDisplay + hDisplay + mDisplay;
}

export const imageTypeName = (type) => {
  if (type) {
    if (type === 'image') return 'Image'
    else if (type === 'document') return 'Document'
    else if (type === 'then_now') return 'Then & Now'
    else if (type === 'dzi') return 'High Resolution Image'
    else return ''
  } else return ''
}

export const getCredits = (credits) => {
  return credits?.length > 0 && credits.map(x => personName(x)).join(', ')
}

export const personName = (person) => {
  let text = '';

  if (person && (person.person_name || person.person_first_name)) {
    if (person.person_name && person.person_name.length > 0) text = person.person_name
    else if (person.person_first_name && person.person_last_name) text = text.concat(person.person_first_name, ' ', person.person_last_name)
    else if (person.person_first_name) text = person.person_first_name
    else if (person.person_last_name) text = person.person_last_name
  }

  if (person && (person.name || person.first_name)) {
    if (person.name && person.name.length > 0) text = person.name
    else if (person.first_name && person.last_name) text = text.concat(person.first_name, ' ', person.last_name)
    else if (person.first_name) text = person.first_name
    else if (person.last_name) text = person.last_name
  }

  return text;
}

export const relatedPersonName = (person) => {
  let text = '';

  if (person) {
    if (person.related_person_name && person.related_person_name.length > 0) text = person.related_person_name
    else if (person.related_person_first_name && person.related_person_last_name) text = text.concat(person.related_person_first_name, ' ', person.related_person_last_name)
    else if (person.related_person_first_name) text = person.related_person_first_name
    else if (person.related_person_last_name) text = person.related_person_last_name
  }

  return text;
}

export const locationName = (location) => {
  let text = '';

  if (location) {
    if (location.custom) text = location.alias
    else if (location.city && location.state && location.country) text = text.concat(location.city, ', ', location.state, ', ', location.country)
    else if (location.city && location.state) text = text.concat(location.city, ', ', location.state)
    else if (location.state && location.country) text = text.concat(location.state, ', ', location.country)
    else if (location.city) text = location.city
    else if (location.state) text = location.state
    else if (location.country) text = location.country
  }

  return text;
}

export const fileFormat = (path) => {
  let parts = path?.split('.');
  let text;
  if (parts) {
    text = parts[parts?.length - 1];
  }
  return text;
}

export const cleanHtml = (html) => {
  return html?.replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
}

export const cleanHtmlMeta = (meta) => {
  return meta.replace(/<\/?[^>]+(>|$)|(&nbsp;)*|/g, "");
}

export const numberToTimeConvert = (num) => {
  var sec_num = parseInt(num, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
}

export const validURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

export const getMiniImage = (image) => {
  return {
    'id': image.id,
    'caption': image.caption || '',
    'url': image.url || '',
    'related_items': image.related_items || []
  }
}

export const getDefaultImage = (entity) => {
  if (entity.image && entity.image.id) return entity.image
  if (entity.images && entity.images.length > 0) return entity.images[0]
  else if (entity.documents && entity.documents.length > 0) return entity.documents[0]
}

export const getPodcastsDuration = (podcasts) => {
  let duration = 0
  if (podcasts && podcasts.length > 0) {
    podcasts.forEach((item) => {
      if (item.podcast_duration) {
        var splitDuration = item.podcast_duration.split(':');
        if (splitDuration.length === 0 || item.podcast_duration === "") {
          return '';
        }
        var tempHour = Number(splitDuration[0]);
        var tempMin = Number(splitDuration[1]);
        var tempSec = Number(splitDuration[2]);

        var timeInHour = tempHour * 60;
        tempMin = tempMin + timeInHour;
        var timeInSecond = tempMin * 60;
        timeInSecond = timeInSecond + tempSec;
        var min = (timeInSecond / 60).toFixed();
        duration = duration + Number(min)
      }
    })
  }
  return duration
}

export const getImageList = (list) => {
  const promises = [];
  if (list && list.length > 0) {
    for (const item of list) {
      if (item?.id) promises.push(asyncGetCall(`image/${item?.id}`))
    }
  }
  return Promise.all(promises);
}

function asyncGetCall(url) {
  return new Promise(async resolve => {
    axios.get(url).then((res) => resolve(res.data)).catch(() => resolve(false))
  });
}

export const updatePersonRelatedSite = async (person) => {
  for (let site of person.related_sites) {
    if (site.site_id) {
      let site_related_person = await _api.site.getRelatedPerson(site.site_id)
      if (!site_related_person) site_related_person = []
      if (site_related_person.findIndex(x => x.related_person_id === person.id) < 0) {
        site_related_person.push({
          description: site.description,
          id: null,
          related_person_first_name: person.first_name,
          related_person_id: person.id,
          related_person_last_name: person.last_name,
          related_person_name: person.name,
          related_person_type: person.type,
          site_id: site.site_id,
          sort: site_related_person.length + 1
        })
        await _api.site.updateRelatedPerson(site.site_id, site_related_person)
      }
    }
  }
}

export const missingInfo = (data) => {
  let info = []
  let dateDescription = data?.dates?.filter((e) => e.description !== '')
  if (!data?.name) info.push('Name')
  if (!getCategoryName(data?.categories)) info.push('Categories')
  if (!(data?.locations && data?.locations[0]?.address)) info.push('Locations')
  if (!data?.description && !(dateDescription?.length > 0)) info.push('Description')
  if (!data?.image?.url) info.push('Image')
  return info
}

export const getCategoryName = (entity) => {
  let category = '';
  let categories = entity
  if (categories && categories?.length > 0) {
    if (categories[0]?.category_has_children && categories[0]?.subcategory_has_children) category = categories[0]?.program_name;
    if (categories[0]?.category_has_children && !categories[0]?.subcategory_has_children) category = categories[0]?.subcategory_name;
    if (!categories[0]?.category_has_children) category = categories[0]?.category_name;
    return category
  }
  let index = categories?.length > 0 && categories.findIndex((category) => { return dateString(category) !== '' })
  if (index !== -1 && categories?.length > 0) {
    if (categories[0]?.category_has_children && categories[0]?.subcategory_has_children) category = categories[0]?.program_name;
    if (categories[0]?.category_has_children && !categories[0]?.subcategory_has_children) category = categories[0]?.subcategory_name;
    if (!categories[0]?.category_has_children) category = categories[0]?.category_name;
    return category
  }
  return category
}

export const getAwardsName = (award) => {
  let item = ''
  if (award.award_parent_name) item = award.award_parent_name
  if (award.award_child_name) item = award.award_child_name
  if (award.award_parent_name && award.award_child_name) item = award.award_parent_name + ' - ' + award.award_child_name
  return item
}

export const getImageCategoryName = (image) => {
  let item = ''
  if (image?.image_parent_name) item = image.image_parent_name
  if (image?.image_child_name) item = image.image_child_name
  if (image?.image_parent_name && image?.image_child_name) item = image.image_parent_name + ' - ' + image.image_child_name
  return item
}


export const getRole = (item) => {
  let role_name = item.role_name;
  if (item.type === 'person')
    role_name = item.role_name;
  else if (item.type === 'publication')
    role_name = item.publication_type;
  else if (item.type === 'organization')
    role_name = item.organization_type;
  return role_name;
}

export const getOffsetImageIndex = (items, dates, date_id, type) => {
  let offset = items?.filter(x => x.date_id === null && x.type === type).length
  if (date_id) {
    let uniqueDateId = uniqBy(dates, 'id')
    for (let i = 0; i <= uniqueDateId.length; i++) {
      if (uniqueDateId[i].id !== date_id) offset += items?.filter(x => x.date_id === uniqueDateId[i].id && x.type === type).length
      else return offset
    }
  }
  return offset
}

const checkLive = (element) => {
  const ent = [
    "images",
    "related_sites",
    "related_people",
    "playlists",
    "maps",
    "podcasts",
    "people",
    "related_map",
    "affiliations",
    "related_item",
    "related_playlist",
    "tours"
  ];
  ent.forEach((val) => {
    if (has(element, val) && element[val]?.length > 0) {
      if (val === "images" || val === "related_people" || val === "people" || val === "affiliations") {
        element[val] = element[val]?.filter((item) => item.deleted === false);
      } else if (val === 'related_item') {
        element[val] = element[val]?.filter((item) => (item.source === "person" || item.source === "organization" || item.source === "publication") ? item.deleted === false : (item.live === true && item.deleted === false));
      } else {
        element[val] = element[val]?.filter((item) => item.live === true && item.deleted === false);
      }
    }
  });
  return element;
};

export const filterLiveAndDeleted = (data) => {
  if (data?.length > 0) {
    return (data.filter(item => has(item, 'live') ? (item.live === true && item.deleted === false) : item.deleted === false)?.map((item) => checkLive(item)));
  } else {
    return checkLive(data);
  }
};


export const changeUTC = (values) => {
  if (typeof values !== 'number' || isNaN(values)) {
    return ""; // Return empty string for invalid input
  }
  const year = moment(new Date(values * 1000)).format('MMMM Do YYYY, h:mm:ss a')
  return year;

}

