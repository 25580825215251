/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dragula from "react-dragula";
import uniqid from 'uniqid';
import axios from 'axios'
import TextEditor from './textEditor';
import SelectDate from '../selects/date';
import SelectGlobalList from '../selects/globalList';
import Button from '../buttons/default';
import { dateString, handleReorder, getNewItemIndex } from '../../helpers';

const DesignationEditor = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-playlist'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (entity.dates && entity.dates.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.designations, entity.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, designations: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addDesignation = (date_id) => {
        if (!entity.designations) entity.designations = [];
        let index = getNewItemIndex(entity.designations, entity.dates, date_id)

        entity.designations.splice(index, 0, {
            'id': `temp.${uniqid()}`,
            'date_id': date_id,
            'designation_id': null,
            'designation_name': '',
            'day': null,
            'month': null,
            'year': null,
            'ca': false,
            'bce': false,
            'description': '',
            'attribution': '',
            'sort': index
        });

        entity.designations.forEach((item, index) => item.sort = index + 1)

        setDragula(uniqid())
        dispatch({ type: 'entity', payload: { ...entity, designations: entity.designations } });
    }

    const removeDesignation = async (index) => {
        if (entity.designations[index]) {
            if (!entity.designations[index].id.startsWith('temp.'))
                await axios.delete(`/site/designation/${entity.designations[index].id}`)

            entity.designations.splice(index, 1)
            entity.designations.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...entity, designations: entity.designations } });
        }
    }

    const handleInputChange = ({ target: { name, value, checked, dataset } }) => {
        if (name === 'attribution') entity.designations[dataset.id].attribution = value;
        dispatch({ type: 'entity', payload: { ...entity, designations: entity.designations } });
    }

    const handleEditorChange = (index, data) => {
        entity.designations[index].description = data;
        dispatch({ type: 'entity', payload: { ...entity, designations: entity.designations } });
    }

    const handleDesignationChange = (index, data) => {
        entity.designations[index].designation_id = data.id;
        entity.designations[index].designation_name = data.name;
        dispatch({ type: 'entity', payload: { ...entity, designations: entity.designations } });
    }

    const handleDateChange = (index, data) => {
        entity.designations[index].day = data.day;
        entity.designations[index].month = data.month;
        entity.designations[index].year = data.year;
        dispatch({ type: 'entity', payload: { ...entity, designations: entity.designations } });
    }

    const handleItemChange = (index, value) => {
        entity.designations[index].designation_id = value.id;
        entity.designations[index].designation_name = value.name;
        dispatch({ type: 'entity', payload: { ...entity, designations: entity.designations } });
    };

    return (
        <div>
            <hr className='cnow-hr' />

            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Designations</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a Designation' onClick={() => addDesignation(null)} />
                </div>
            </div>

            <div id={dragulaContainer} key={dragula} className='dragula-container'>
                {
                    entity?.designations?.filter(x => x.date_id === null).map((designation, index) => {
                        const selectedDesignation = { id: designation.designation_id, name: designation.designation_name }
                        const selectedDate = { day: designation.day, month: designation.month, year: designation.year }
                        return (
                            <div key={index} className='my-3 dragula-item' id={designation.id}>
                                <div className='card-bodys'>
                                    <div className='row g-1'>
                                        {
                                            entity?.designations?.length > 1 &&
                                            <div className='col-auto'>
                                                <label className='cnow-form-label fw-bold'>Order</label>
                                                <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete="off" />
                                            </div>
                                        }
                                        <div className={entity?.designations?.length > 1 ? 'col' : 'col'}>
                                            <div className='row g-1'>
                                                <div className='col-12 col-md-auto d-flex flex-grow-1'>
                                                    <div className='w-100'>
                                                        <label className='cnow-form-label fw-bold'>Designation *</label>
                                                        <SelectGlobalList key={designation.designation_id} type='designation'
                                                            value={selectedDesignation} onChange={(data) => handleDesignationChange(index, data)} />
                                                    </div>
                                                </div>

                                                <div className='col-auto'>
                                                    <SelectDate key={designation.designation_id} label={true}
                                                        value={selectedDate} onChange={(data) => handleDateChange(index, data)} />
                                                </div>

                                                <div className='col-auto'>
                                                    <label className='cnow-form-label'></label>
                                                    <div className='text-end'>
                                                        <Button icon='delete' onClick={() => removeDesignation(index)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <TextEditor key={designation.designation_id} data={designation.description} onChange={(data) => handleEditorChange(index, data)}>
                                            <label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
                                            </TextEditor>

                                            <label className='cnow-form-label mt-2 fw-bold'>Remarks Attribution</label>
                                            <textarea className='form-control' rows='1' name='attribution' maxLength={250} onChange={handleInputChange} value={designation.attribution} data-id={index} autoComplete='false' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>

            {
                entity?.dates?.length > 0 && <>
                    <hr className='cnow-hr' />
                    <div className='cnow-form-title'>Designations, By Dates</div>
                    {
                        entity?.dates?.map((date, index) => {
                            return (
                                <div key={index} className='mb-2'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label className='cnow-form-subtitle'>{date.event_type_name} - {dateString(date)}</label>
                                        </div>
                                        <div className='col-9 text-end'>
                                            <Button size='sm' icon='plus' label='Add a Designation' onClick={() => addDesignation(date.id)} />
                                        </div>
                                    </div>
                                    <div id={`${dragulaContainer}-${date.id}`} key={`${dragula}${date.id}`} className='dragula-container'>
                                        {
                                            entity?.designations?.filter(x => x.date_id === date.id).map((designation, index) => {
                                                const designationIndex = entity.designations.findIndex(x => x.id === designation.id)
                                                const selectedDesignation = { id: designation.designation_id, name: designation.designation_name }
                                                const selectedDate = { day: designation.day, month: designation.month, year: designation.year }

                                                return (
                                                    <div key={index} className={`${index > 0 ? 'mt-4' : ''} dragula-item`} id={designation.id}>

                                                        <div className='row g-1'>
                                                            {
                                                                entity?.designations?.length > 1 &&
                                                                <div className='col-auto'>
                                                                    <label className='cnow-form-label fw-bold'>Order</label>
                                                                    <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={designation.sort} autoComplete="off" />
                                                                </div>
                                                            }
                                                            <div className='col'>
                                                                <div className='row g-1'>
                                                                    <div className='col-12 col-md-auto d-flex flex-grow-1'>
                                                                        <div className='w-100'>
                                                                            <label className='cnow-form-label fw-bold'>Designation *</label>
                                                                            <SelectGlobalList key={designation.id} type='designation' value={selectedDesignation} onChange={(data) => handleItemChange(designationIndex, data)} />
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-auto'>
                                                                        <SelectDate key={designation.id} label={true} value={selectedDate} onChange={(data) => handleDateChange(designationIndex, data)} />
                                                                    </div>

                                                                    <div className='col-auto'>
                                                                        <label className='cnow-form-label'></label>
                                                                        <div className='text-end'>
                                                                            <Button icon='delete' onClick={() => removeDesignation(designationIndex)} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <TextEditor key={designation.id} data={designation.description} onChange={(data) => handleEditorChange(designationIndex, data)}>
                                                                    <label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
                                                                </TextEditor>

                                                                <label className='cnow-form-label mt-2 fw-bold'>Remarks Attribution</label>
                                                                <textarea className='form-control' rows='1' name='attribution' maxLength={250} onChange={handleInputChange} value={designation.attribution} data-id={designationIndex} autoComplete="off" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
            }
        </div >
    )
}

export default DesignationEditor;