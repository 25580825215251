import React from 'react';

import AsyncSelect from 'react-select/async';
import { customSelectStyle, personName } from '../../helpers';
import NoOptionsMessage from './NoOptionsMessage';

const _api = require('../../api')

const SelectPeople = (props) => {
	let name = personName(props.value)
	let selected = props.value?.id ? {
		'value': props.value.id, 'label': name, 'email': props.value.email, 'role_name': props.value.role_name,
		'role_id': props.value.role_id, 'is_participant': props.value.is_participant
	} : {};

	const items = async (keyword) => {
		if (keyword) {
			let items = [];
			const params = {
				keyword: keyword,
				hitsPerPage: 100,
				facetFilters: [
					"deleted: false"
				],
				page: 0,
			}
			if (props?.entityType) { params.facetFilters[1] = `type  : ${props?.entityType}` }
			let response = await _api.person.search(params);
			if (response.hits?.length) {
				response.hits.forEach(data => {
					let item = {
						'value': data.id,
						'label': personName(data),
						'email': data.primary_contact?.email,
						'images': data?.images,
						'type': data.type,
						'is_participant': data.is_participant
					}

					if (data.type === 'person') {
						item.role_name = data.professions?.length > 0 ? data.professions[0]?.profession_name : ''
						item.role_id = data.professions?.length > 0 ? data.professions[0]?.profession_id : null
						item.designations_name = data.designations?.length > 0 ? data.designations[0]?.designation_name : null

					} else if (data.type === 'organization') {
						item.role_name = data.organization_types?.length > 0 ? data.organization_types[0]?.organization_type_name : ''
						item.role_id = data.organization_types?.length > 0 ? data.organization_types[0]?.organization_type_id : null
					}
					else if (data.type === 'publication') {
						item.role_name = data.publication_types?.length > 0 ? data.publication_types[0]?.publication_type_name : ''
						item.role_id = data.publication_types?.length > 0 ? data.publication_types[0]?.publication_type_id : null
					}
					items.push(item)
				});
			}
			else {
				items.push({ 'value': 'participant', 'label': '+ Add a Participants' })
			}
			return items;
		}
	}

	const handleChange = (e) => {
		if (e.value !== 'participant') {
			let value = { 'id': e.value, 'name': e.label, 'email': e.email, 'role_name': e.role_name, 'role_id': e.role_id, 'type': 'person', 'designations_name': e.designations_name, 'images': e.images, 'is_participant': e.is_participant };
			props.onChange(value);
		}
		else {
			if (e.value === 'participant') window.open('/participant/create')
		}
	}

	return (
		<AsyncSelect cacheOptions defaultOptions
			styles={customSelectStyle}
			components={{ NoOptionsMessage }}
			loadOptions={items}
			value={selected}
			onChange={handleChange}
		/>
	);
};

export default SelectPeople;