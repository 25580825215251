import { ApiCall } from './utils'

//tour
const getById = (id) => ApiCall('get', `/tour/${id}`)

const update = (params) => ApiCall('post', `/tour`, params)

const updateLive = (id, live) => ApiCall('put', `/tour/${id}/live/${live}`)

const deleteById = (id) => ApiCall('delete', `/tour/${id}`)

const search = (params) => ApiCall('post', `/tour/search`, params)

//category
const getCategory = (id) => ApiCall('get', `/tour/${id}/category`)

const updateCategory = (id, params) => ApiCall('post', `/tour/${id}/category`, params)

const deleteCategory = (id) => ApiCall('delete', `/tour/category/${id}`)

//created_by
const getCreatedBy = (id) => ApiCall('get', `/tour/${id}/created_by`)

const updateCreatedBy = (id, params) => ApiCall('post', `/tour/${id}/created_by`, params)

const deleteCreatedBy = (id) => ApiCall('delete', `/tour/created_by/${id}`)

//partner_org
const getPartnerOrg = (id) => ApiCall('get', `/tour/${id}/partner_org`)

const updatePartnerOrg = (id, params) => ApiCall('post', `/tour/${id}/partner_org`, params)

const deletePartnerOrg = (id) => ApiCall('delete', `/tour/partner_org/${id}`)

//tour_leader
const getTourLeader = (id) => ApiCall('get', `/tour/${id}/tour_leader`)

const updateTourLeader = (id, params) => ApiCall('post', `/tour/${id}/tour_leader`, params)

const deleteTourLeader = (id) => ApiCall('delete', `/tour/tour_leader/${id}`)

//tag
const getTag = (id) => ApiCall('get', `/tour/${id}/tag`)

const updateTag = (id, params) => ApiCall('post', `/tour/${id}/tag`, params)

const deleteTag = (id) => ApiCall('delete', `/tour/tag/${id}`)

//Releted Site
const getRelatedSite = (id) => ApiCall('get', `/tour/${id}/related_site`)

//Related Person
const getRelatedPerson = (id) => ApiCall('get', `/tour/${id}/related_person`)

//Related Person
const getRelatedMap = (id) => ApiCall('get', `/tour/${id}/related_map`)

//Related Playlist
const getRelatedPlaylist = (id) => ApiCall('get', `/tour/${id}/related_playlist`)

//image
const getImage = (id) => ApiCall('get', `/tour/${id}/image`)

const updateImage = (id, params) => ApiCall('post', `/tour/${id}/image`, params)

const deleteImage = (id) => ApiCall('delete', `/tour/image/${id}`)

//theme
const getTheme = (id) => ApiCall('get', `/tour/${id}/theme`)

const updateTheme = (id, params) => ApiCall('post', `/tour/${id}/theme`, params)

const deleteTheme = (id) => ApiCall('delete', `/tour/theme/${id}`)

//tours
const getTour = (id) => ApiCall('get', `/tour/${id}/related_tour`)

const updateTour = (id, params) => ApiCall('post', `/tour/${id}/related_tour`, params)

const deleteTour = (id) => ApiCall('delete', `/tour/related_tour/${id}`)

//tour stops
const getTourStops = (id) => ApiCall('get', `/tour/${id}/stop`)

const updateTourStops = (id, params) => ApiCall('post', `/tour/${id}/stop`, params)

const deleteAllStops = (id) => ApiCall('delete', `/tour/tour_stops/${id}`)

const deleteStop = (id) => ApiCall('delete', `/tour/stop/${id}`)

const deleteSite = (id) => ApiCall('delete', `/tour/stop/site/${id}`)

//podcast
const getPodcast = (id) => ApiCall('get', `/tour/${id}/podcast`)

const updatePodcast = (id, params) => ApiCall('post', `/tour/${id}/podcast`, params)

const deletePodcast = (id) => ApiCall('delete', `/tour/podcast/${id}`)

// onThisDay
const getOnThisDay = (id) => ApiCall('get', `/tour/on_this_day/${id}`)
const updateOnThisDay = (params) => ApiCall('post', `/tour/on_this_day`, params)
const deleteOnThisDay = (id) => ApiCall('delete', `/tour/on_this_day/${id}`)
const updateListOnThisDay = (tour_id, id, value) => ApiCall('put', `/tour/${tour_id}/on_this_day/${id}/live/${value}`)
//stories
const getStory = (id) => ApiCall('get', `/tour/${id}/story`)

const updateStory = (id, params) => ApiCall('post', `/tour/${id}/story`, params)

const deleteStory = (id) => ApiCall('delete', `/tour/story/${id}`)

//Automated Playlist 

const automatedPlaylist = (id) => ApiCall('post', `/tour/${id}/playlist/sync`, '')

//Tour Event
const getEvent = (id) => ApiCall('get', `/tour/${id}/event `)

const updateEvent = (id, params) => ApiCall('post', `/tour/${id}/event `, params)

const deleteEvent = (id) => ApiCall('delete', `/tour/event/${id}`)

//curator

const getCurator = (id) => ApiCall('get', `/tour/${id}/curator`)

const updateCurator = (id, params) => ApiCall('post', `/tour/${id}/curator`, params)


export default {
    getById,
    update,
    updateLive,
    search,
    deleteById,

    getCategory,
    updateCategory,
    deleteCategory,

    getCreatedBy,
    updateCreatedBy,
    deleteCreatedBy,

    getPartnerOrg,
    updatePartnerOrg,
    deletePartnerOrg,

    getTourLeader,
    updateTourLeader,
    deleteTourLeader,

    getTag,
    updateTag,
    deleteTag,

    getImage,
    updateImage,
    deleteImage,

    getTheme,
    updateTheme,
    deleteTheme,

    getPodcast,
    updatePodcast,
    deletePodcast,

    getTourStops,
    updateTourStops,
    deleteAllStops,
    deleteStop,
    deleteSite,

    getOnThisDay,
    deleteOnThisDay,
    updateOnThisDay,

    getTour,
    updateTour,
    deleteTour,

    getRelatedSite,
    getRelatedPerson,
    getRelatedMap,
    getRelatedPlaylist,

    getStory,
    updateStory,
    deleteStory,

    automatedPlaylist,
    getEvent,
    updateEvent,
    deleteEvent,

    updateListOnThisDay,
    getCurator,
    updateCurator
}