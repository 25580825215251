/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '../../../components/pagination';
import ResultCount from '../../../components/resultCount';
import ScrollToTop from '../../../components/scrollToTop';
import Spinner from '../../../components/spinner';
import Icon from '../../../components/viewers/icon';
import TableHeader from '../../../components/tableHeader';
import LinkButton from '../../../components/buttons/link';
import Button from '../../../components/buttons/default';
// import CategoryList from '../../../components/table/categoryList';
// import ThemeList from '../../../components/table/themeList';
import TagList from '../../../components/table/tagList';
import ImageViewer from '../../../components/viewers/image';
import LongTextView from '../../../components/viewers/longText';
import PreviewButton from '../../../components/buttons/preview';
import { TABLE_ROWS, getCategoryName, newTempId } from '../../../helpers';
import swal from 'sweetalert';
import TourPeopleList from '../../../components/table/tourPeopleList';
import PodcastList from '../../../components/table/podcastList';
import moment from 'moment';
import { differenceBy } from 'lodash';
import Playlist from '../../../components/table/playlist';
import RelatedPeopleList from '../../../components/table/relatedPeopleList';
import RelatedSiteList from '../../../components/table/relatedSiteList';

const _api = require('../../../api');
const columns = require('../../../data/columns_tour.json');

const FeaturedTour = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch()
    // const [searchResult, setSearchResult] = useState([]);
    const [keywordValue, setKeywordValue] = useState('');
    const [keyword, setKeyword] = useState('');
    const take = 50;
    const [page, setPage] = useState(1);;
    const [sort, setSort] = useState(columns[0]);

    const [spinner, showSpinner] = useState(false);

    const [paginationHits, setPaginationHits] = useState(0)
    let section = props.section
    const searchTour = useSelector((state) => state.search);
    let searchResult = searchTour.searchResult || []
    let selectedResult = searchTour.selectedResult?.filter((elem) => elem.entity_type === 'tour') || []
    let sectionResult = searchTour[section]?.filter((elem) => elem.entity_type === 'tour') || []
    let selectedEntity = [...sectionResult, ...selectedResult]
    const featured = useSelector((state) => state.featured);

    const [resultFilters, setResultFilters] = useState({
        live: searchTour.filterLive,
        limit: searchTour.selectedLimit,
    })
    const [label, setLabel] = useState([])
    let start_date = featured.start_date && JSON.parse(featured.start_date)
    let end_date = featured.end_date && JSON.parse(featured.end_date)
    let start = start_date ? moment(start_date).format("MMMM Do YYYY") : ''
    let end = end_date ? moment(end_date).format("MMMM Do YYYY") : ''

    useEffect(() => {
        search()
    }, [page, sort, keyword]);

    const search = async () => {
        showSpinner(false)
        const params = {
            keyword: keyword,
            hitsPerPage: take,
            page: page - 1,
            facetFilters: [
                [`live:${resultFilters?.live}`],
                ["deleted:false"]
            ]
        }

        showSpinner(true)
        handleSetKeywords(keyword)
        await _api.tour.search(params).then(results => {
            // setSearchResult(results)
            setPaginationHits(results.nbHits);
            const filteredArray = differenceBy(results.hits, selectedEntity, 'id')
            dispatch({ type: 'search', payload: { ...searchTour, searchResult: filteredArray } })
            showSpinner(false)
        })
    }

    const handleSetKeywords = (keyword) => {
        let data = [];
        if (resultFilters?.live) data.push(`Live - Tours`)
        if (keyword) data.push(keyword)
        setLabel(data)
    }

    const handleDeleteLabel = (index) => {
        let data = label[index];
        if (data.includes('Live')) resultFilters.live = ''
        if (data === keyword) setKeywordValue(''); setKeyword('')
        setResultFilters(resultFilters)
        search()
    }

    const handleDelete = (tour) => {
        swal({
            title: `Delete Tour`,
            text: `Are you sure that you want to delete ${tour?.name}?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => { _api.tour.deleteById(tour.id).then(() => { search() }) });
    }

    const updateLive = (id, live) => {
        showSpinner(true)
        let selectedSearchResult = searchResult.hits.filter((item) => item.id === id)
        let selectedResult = selectedSearchResult[0]
        let dateDescription = selectedResult.dates?.filter((e) => e.description !== '')
        let category = getCategoryName(selectedResult?.categories)
        if (live === true) {
            if (
                selectedResult?.name &&
                category &&
                selectedResult?.stops && selectedResult?.stops?.[0]?.location &&
                (selectedResult?.description || dateDescription?.length > 0) &&
                selectedResult?.image_id

            ) {
                _api.tour.updateLive(id, live).then(async (_) => {
                    await search()
                    swal({
                        title: 'Tour is Live!',
                        icon: 'success',
                        text: `${selectedResult?.name} is now Live!`,
                        buttons: 'Ok',
                    })
                })
            } else {
                swal({
                    title: `${selectedResult.name} cannot go Live!`,
                    icon: 'error',
                    text: `Please fill the mandatory fields:
              ${selectedResult?.name ? '' : 'Title is missing.'}
              ${category && category?.length ? '' : 'Category is missing.'}
              ${selectedResult?.stops && selectedResult?.stops?.[0]?.location ? '' : 'Tour stops is missing.'} 
              ${(selectedResult?.description || dateDescription?.length > 0) ? '' : 'Description is missing.'} 
              ${selectedResult?.image_id ? '' : 'Image is missing.'} `, buttons: 'Go back', dangerMode: true,
                })
            }
        } else {
            _api.tour.updateLive(id, live).then(async (_) => {
                await search()
                swal({
                    title: 'Not Live!',
                    icon: 'info',
                    text: `${selectedResult?.name} is Not Live.`,
                    buttons: 'Ok',
                })
            })
        }
        showSpinner(false)
    }

    const selectedTour = (index) => {
        showSpinner(true)
        searchResult[index].entity_type = 'tour'
        selectedResult.push(searchResult[index])
        searchResult.splice(index, 1)
        dispatch({
            type: 'search', payload: {
                ...searchTour,
                selectedResult: selectedResult,
                searchResult: searchResult
            }
        })
        showSpinner(false)
    }

    const removeSelectedTour = (index) => {
        if (selectedEntity[index].section_index !== undefined && selectedEntity[index].entity_index !== undefined) {
            let section_index = selectedEntity[index].section_index
            let entity_index = selectedEntity[index].entity_index
            featured[section][section_index].edited = true
            featured[section][section_index].data[section].splice(entity_index, 1)
            featured[section][section_index].data[section].forEach((elem, index) => elem.entity_index = index)
            let sectionIndex = sectionResult.findIndex(elem => elem.section_index === section_index && elem.entity_index === entity_index)
            sectionResult.splice(sectionIndex, 1)
            dispatch({ type: 'featured', payload: { ...featured, [`${section}`]: featured[`${section}`] } })
            dispatch({ type: 'search', payload: { ...searchTour, [`${section}`]: sectionResult } })
        }
        else {
            let searchResultIndex = selectedResult.findIndex(elem => elem.id === selectedEntity[index].id)
            searchResult.push(selectedResult[searchResultIndex])
            selectedResult.splice(searchResultIndex, 1)
            dispatch({
                type: 'search', payload: { ...searchTour, selectedResult: selectedResult, searchResult: searchResult }
            })
        }

    }

    const selectedTourDispatch = () => {
        if (selectedResult.length > 0) {
            let index = featured[`${section}`].length === 0 ? -1 : 0
            let data = {
                "start_date": start_date.slice(0, 10),
                "end_date": end_date.slice(0, 10),
                "section": `${section}`,
            }
            if (index === -1) {
                data.id = newTempId()
                data.data = {
                    [`${section}`]: selectedResult
                }
                featured[`${section}`].push(data)
            }
            else {
                featured[`${section}`][index].edited = true
                featured[`${section}`][index].data[`${section}`] = [...featured[`${section}`][index].data[`${section}`], ...selectedResult]
            }
        }
        dispatch({ type: 'featured', payload: { ...featured, source: location.pathname } })
        dispatch({ type: 'search', payload: {} })
        history.goBack()
    }

    return (
        <>
            <div className='mt-3 container-fluid'>
                <div className='row g-1'>
                    <div className='col'>
                        <div className='input-group'>
                            <span className='input-group-text border-end-0'>
                                <Icon name='search' />
                            </span>
                            <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                                onChange={(event) => { setKeywordValue(event.target.value) }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue); } }}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue); }} />
                    </div>
                    <div className='col-auto'>
                        <Button label='Add a Tour' color='danger' icon='plus-white' onClick={() => history.push('/tour/create')} />
                    </div>
                </div>
            </div>

            <div className='mt-3 p-0 container-fluid'>
                <div className='card'>
                    <div className='card-header bg-light p-2'>
                        <div className='d-flex justify-content-between gap-2 align-items-end'>
                            <div>
                                <h4 className='mb-0 cursor-pointer text-capitalize' onClick={() => { selectedTourDispatch() }}>{`${searchTour.title} - ${start_date === end_date ? start : `${start} - ${end}`}`}</h4>
                                <h6 className='mb-0'>TOURS</h6>
                                <div className='small'>
                                    <ResultCount page={page} take={take} count={paginationHits} />
                                    {
                                        label && label.map((elem, index) => {
                                            return (
                                                <span className='badge badge-search'>
                                                    {elem} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { handleDeleteLabel(index) }} />
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />

                            <div className='dropdown'>
                                <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                                    Sort by: {sort.name}
                                </button>
                                <ul className='dropdown-menu'>
                                    {
                                        columns.filter(x => x.sort === true).map((column, index) =>
                                            <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>

                    <Spinner display={spinner}>
                        <div className='table-responsive cnow-table'>
                            <table className='table table-striped table-hover table-bordered'>
                                <thead>
                                    <tr className='bg-light'>
                                        {
                                            columns.filter(x => x.display === true).map((column, index) =>
                                                <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='bg-dark'>
                                        <th className='text-white' scope="col " colspan="100%">
                                            <div className='d-flex ' style={{ width: "94vw" }}>
                                                <span className=''>
                                                    {`${searchTour.title}  TOUR SELECTED`}
                                                </span>
                                                <span className='ms-auto text-lowercase'>{selectedEntity.length > 1 ? `${selectedEntity.length} results` : `${selectedEntity.length} result`}</span>
                                            </div>
                                        </th>
                                    </tr>
                                    {
                                        selectedEntity?.map((item, index) => (
                                            <tr key={`${item.id}-${index}`}>
                                                <td>
                                                    <div className='row g-1'>
                                                        <div className='col-auto'>
                                                            <div className='form-check mt-1'>
                                                                <input className='form-check-input align-middle' type='checkbox' checked={true} onClick={() => { removeSelectedTour(index) }} name='select_tour' value={item.id} />
                                                            </div>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <div className='btn-list'>
                                                                <LinkButton size='sm' icon='edit' to={`/tour/${item.id}/editor`} />
                                                                <Button size='sm' icon='delete' onClick={() => { handleDelete(item) }} />
                                                                <PreviewButton size='sm' link={`tour/${item.id}`} />
                                                                <Button size='live'
                                                                    icon={item.live === true ? 'live' : 'not-live'}
                                                                    color={item.live === true ? '' : 'danger'}
                                                                    onClick={() => { updateLive(item.id, item.live === true ? false : true) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ImageViewer url={item.image?.url} type='thumb' cssClass='thumb' />
                                                </td>
                                                <td className='fw-bold'>{item.name}</td>
                                                <td></td>
                                                <td> <TourPeopleList data={item.created_by} title="created by" />

                                                </td>
                                                {/* <LocationList data={item.locations} /> */}
                                                {/* <PeopleList data={item.people} /> */}
                                                <td></td>

                                                {/* <CategoryList data={item?.categories} /> */}
                                                {/* <ThemeList data={item?.themes} /> */}
                                                <td>{item?.duration}</td>
                                                {/* <DateList data={item.dates} />
                                                <MissingRequiredInfoList data={item} /> */}
                                                <td>{item?.type}</td>
                                                <td>{item?.id}</td>
                                                <TagList data={item?.tags} />
                                                <td>{item?.stops &&
                                                    <>
                                                        <div className='text-muted'>{item.stops?.length} Stops</div>
                                                        {item.stops?.slice(0, 3).map((items, index) => {
                                                            return <div key={index}>{items?.stop_name}</div>
                                                        })}
                                                    </>}</td>
                                                <td>
                                                    <LongTextView lines={TABLE_ROWS + 1}>{item?.description}</LongTextView>
                                                </td>
                                                <PodcastList data={item?.podcasts} />



                                                <Playlist data={item?.playlists} />
                                                <td></td>
                                                <td></td>
                                                <RelatedSiteList data={item?.related_sites} />
                                                <RelatedPeopleList data={item?.related_people} />
                                                <td></td>
                                                <td></td>

                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tbody>
                                    <tr className='bg-dark'>
                                        <th className='text-white' scope="col " colspan="100%">
                                            <div className='d-flex ' style={{ width: "94vw" }}>
                                                <span className=''>
                                                    {`${searchTour.title}  TOUR AVAILABLE`}
                                                </span>
                                                <span className='ms-auto text-lowercase'>{searchResult.length > 1 ? `${searchResult.length} results` : `${searchResult.length} result`}</span>
                                            </div>
                                        </th>
                                    </tr>
                                    {
                                        searchResult?.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>
                                                    <div className='row g-1'>
                                                        <div className='col-auto'>
                                                            <div className='form-check mt-1'>
                                                                <input className='form-check-input align-middle' type='checkbox' name='select_tour' value={item.id} onClick={() => { selectedTour(index) }} disabled={resultFilters.limit && (selectedEntity.length >= resultFilters.limit) ? true : false} />
                                                            </div>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <div className='btn-list'>
                                                                <LinkButton size='sm' icon='edit' to={`/tour/${item.id}/editor`} />
                                                                <Button size='sm' icon='delete' onClick={() => { handleDelete(item) }} />
                                                                <PreviewButton size='sm' link={`tour/${item.id}`} />
                                                                <Button size='live'
                                                                    icon={item.live === true ? 'live' : 'not-live'}
                                                                    color={item.live === true ? '' : 'danger'}
                                                                    onClick={() => { updateLive(item.id, item.live === true ? false : true) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ImageViewer url={item.image?.url} type='thumb' cssClass='thumb' />
                                                </td>
                                                <td className='fw-bold'>{item.name}</td>
                                                <td></td>
                                                <td> <TourPeopleList data={item.created_by} title="created by" />

                                                </td>
                                                {/* <LocationList data={item.locations} /> */}
                                                {/* <PeopleList data={item.people} /> */}
                                                <td></td>

                                                {/* <CategoryList data={item?.categories} /> */}
                                                {/* <ThemeList data={item?.themes} /> */}
                                                <td>{item?.duration}</td>
                                                {/* <DateList data={item.dates} />
                                                <MissingRequiredInfoList data={item} /> */}
                                                <td>{item?.type}</td>
                                                <td>{item?.id}</td>
                                                <TagList data={item?.tags} />
                                                <td>{item?.stops &&
                                                    <>
                                                        <div className='text-muted'>{item.stops?.length} Stops</div>
                                                        {item.stops?.slice(0, 3).map((items, index) => {
                                                            return <div key={index}>{items?.stop_name}</div>
                                                        })}
                                                    </>}</td>
                                                <td>
                                                    <LongTextView lines={TABLE_ROWS + 1}>{item?.description}</LongTextView>
                                                </td>
                                                <PodcastList data={item?.podcasts} />



                                                <Playlist data={item?.playlists} />
                                                <td></td>
                                                <td></td>
                                                <RelatedSiteList data={item?.related_sites} />
                                                <RelatedPeopleList data={item?.related_people} />
                                                <td></td>
                                                <td></td>

                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className='d-flex justify-content-between m-3'>
                            <div className='cols'>
                                <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
                            </div>
                        </div>
                    </Spinner>
                </div>
            </div>

            <ScrollToTop />
        </>
    );
};

export default FeaturedTour;