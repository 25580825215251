import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleApiWrapper } from 'google-maps-react';

import MapOverlayMenu from './menu'
import Spinner from '../../components/spinner';
import Overlay from './overlay';
import OverlayLocation from './location';
import OverlayPreview from './preview';
import Legal from '../../components/legal/editor';

const _api = require('../../api');

const MapOverlayEditor = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const menuSize = useSelector((state) => state.menu);
    const previewSize = useSelector((state) => state.preview);

    const id = props.match.params.id || 'NEW';
    const section = props.match.params.section || 'overlay';
    const sectionId = props.match.params.sectionId || '';

    const overlay = useSelector(state => state[id])

    const [spinner, showSpinner] = useState(false);

    useEffect(() => {
        if (id === 'NEW') {
            dispatch({
                type: 'entity', payload: {
                    "id": 'NEW',
                    "year": null,
                    "title": "",
                    "description": "",
                    "image_id": null
                }
            })
        } else {
            getMapOverlayData();
        }
        // eslint-disable-next-line
    }, [id, section, sectionId]);

    const getMapOverlayData = async () => {
        showSpinner(true);
        let myOverlay = await _api.map_overlay.getById(id);
        myOverlay.overlay_links = await _api.map_overlay.getOverlayLinks(id);
        myOverlay.related_maps = await _api.map_overlay.getRelatedMaps(id);

        if (section === 'location') {
            myOverlay.locations = await _api.map_overlay.getLocation(id)
        }
        if (section === 'legal') {
            if (myOverlay.image_id) {
                const image = await _api.image.getById(myOverlay.image_id)
                myOverlay.images = [image]
                myOverlay.images.forEach((elem) => {
                    elem.image_id = elem.id;
                    elem.image_type = elem.type;
                    elem.image_url = elem.url;
                    elem.image_caption = elem.caption;
                })
            }
        }
        dispatch({ type: 'entity', payload: myOverlay })
        showSpinner(false);
    }

    const updateAndRedirect = async (path) => {
        if (!path) {
            path = section;
        }
        showSpinner(true);
        if (overlay.id === 'NEW') overlay.id = null;
        let myOverlay = await _api.map_overlay.update(overlay)
        overlay?.overlay_links?.length > 0 && overlay.overlay_links.forEach(link => { if (link.map_overlay_id.startsWith('NEW')) link.map_overlay_id = myOverlay.id });
        myOverlay.overlay_links = await _api.map_overlay.updateOverlayLinks(myOverlay.id, overlay.overlay_links);

        if (section === 'location') {
            myOverlay.locations = await _api.map_overlay.updateLocation(myOverlay.id, myOverlay.locations)
        }

        dispatch({ type: 'entity', payload: myOverlay })
        history.push(`/map_overlay/${myOverlay.id}/editor/${path}`)
        showSpinner(false);
    }

    return (
        <>
            <div className='cnow-editor-container'>
                <div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
                    <MapOverlayMenu id={id} section={section} onClick={(path) => updateAndRedirect(path)} sectionId={sectionId} display={spinner} />
                </div >
                <div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
                    <Spinner display={spinner}>
                        {section === 'overlay' && <Overlay id={id} />}
                        {section === 'location' && <OverlayLocation id={id} locationId={sectionId} onUpdate={(path) => updateAndRedirect(path)} />}
                        {section === 'legal' && <Legal id={id} />}
                    </Spinner>
                </div>
                <div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
                    <OverlayPreview id={id} onUpdate={() => updateAndRedirect()} section={section} locationId={sectionId} display={spinner} />
                </div>
            </div >
        </>)
}
export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ['places', 'visualization', 'drawing', 'geometry'],
})(MapOverlayEditor);     