/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import uniqid from 'uniqid'
import Dragula from 'react-dragula'
import TextEditor from './textEditor'
import SelectDate from '../selects/date'
import Button from '../buttons/default'
import SelectSite from '../selects/site'
import SelectPerson from '../selects/person'
import SelectGlobalList from '../selects/globalList'
import PreviewButton from '../../components/buttons/preview'
import LinkButton from '../../components/buttons/link'
import { handleReorder, newTempId, relatedPersonName } from '../../helpers'

const _api = require('../../api')

const HighlightEditor = (props) => {
  const dispatch = useDispatch()
  const entity = useSelector((state) => state[props.id])

  // Dragula Start
  const [dragula, setDragula] = useState(uniqid())
  const dragulaRef = useRef()
  const dragulaContainer = 'dragula-container-highlight'

  useEffect(() => {
    let containers = []
    containers.push(document.getElementById(dragulaContainer))
    if (entity.dates && entity.dates.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
    dragulaRef.current = Dragula(containers, {})
    dragulaRef.current.on('drop', () => {
      const items = handleReorder(entity.highlights, entity.dates, dragulaContainer)
      setDragula(uniqid())
      dispatch({ type: 'entity', payload: { ...entity, highlights: items } })
      props.refresh && props.refresh()
    })
    dragulaRef.current.on('dragend', () => { setDragula(uniqid()) })
  }, [dragula])
  // Dragula End

  const addHighlight = () => {
    if (!entity.highlights) entity.highlights = []

    entity.highlights.push({
      'id': newTempId(),
      'highlight': '',
      'description': '',
      'attribution': '',
      'day': null,
      'month': null,
      'year': null,
      'related_person_id': null,
      'related_site_id': null
    })

    dispatch({ type: 'entity', payload: { ...entity, highlights: entity.highlights } })
  }

  const handleEntityInputChange = ({ target: { name, value, checked, dataset } }) => {
    const index = dataset.id

    entity.highlights[index].related_site_id = null
    entity.highlights[index].related_site_name = ''
    entity.highlights[index].related_person_id = null
    entity.highlights[index].related_person_last_name = ''
    entity.highlights[index].related_person_first_name = ''

    if (name === 'year') entity.highlights[index].date.year = value
    if (name === 'description') entity.highlights[index].description = value
    if (name === `link_${index}_site` && checked) entity.highlights[index].related_site_id = newTempId()
    if (name === `link_${index}_person` && checked) entity.highlights[index].related_person_id = newTempId()

    dispatch({ type: 'entity', payload: { ...entity, highlights: entity.highlights } })
  }

  const handleEntityChange = (type, index, value) => {
    if (type === 'site') {
      entity.highlights[index].related_site_id = value.id
      entity.highlights[index].related_site_name = value.name
    }
    if (type === 'person') {
      entity.highlights[index].related_person_id = value.id
      entity.highlights[index].related_person_name = value?.name
    }
    dispatch({ type: 'entity', payload: { ...entity, highlights: entity.highlights } })
  }

  const removeHighlight = async (index) => {
    if (entity.highlights[index]) {
      if (!entity.highlights[index].id.startsWith('temp.'))
        await _api.person.deleteHighlight(entity.highlights[index].id)

      entity.highlights.splice(index, 1)
      entity.highlights.forEach((item, index) => item.sort = index + 1)
      dispatch({ type: 'entity', payload: { ...entity, highlights: entity.highlights } })
    }
  }

  const removeHighlightLink = async (index) => {
    entity.highlights[index].related_site_id = null
    entity.highlights[index].related_site_name = ''
    entity.highlights[index].related_person_id = null
    entity.highlights[index].related_person_last_name = ''
    entity.highlights[index].related_person_first_name = ''
    entity.highlights[index].related_person_name = ''
    dispatch({ type: 'entity', payload: { ...entity, highlights: entity.highlights } })
  }

  const handleInputChange = ({ target: { name, value, checked, dataset } }) => {
    const index = dataset.id
    if (name === 'highlight') entity.highlights[index].highlight = value
    if (name === 'attribution') entity.highlights[index].attribution = value
    dispatch({ type: 'entity', payload: { ...entity, highlights: entity.highlights } })
  }

  const handleEditorChange = (index, data) => {
    entity.highlights[index].description = data
    dispatch({ type: 'entity', payload: { ...entity, highlights: entity.highlights } })
  }

  const handleDateChange = (index, data) => {
    entity.highlights[index].day = data.day
    entity.highlights[index].month = data.month
    entity.highlights[index].year = data.year
    dispatch({ type: 'entity', payload: { ...entity, highlights: entity.highlights } })
  }

  return (
    <div>
      <hr className='cnow-hr' />

      <div className='row'>
        <div className='col'>
          <label className='cnow-form-title'>{entity.type === 'person' ? 'Career Highlights' : 'Highlights'}</label>
        </div>
        <div className='col-auto'>
          <Button size='sm' icon='plus' label='Add a Highlight' onClick={() => addHighlight()} />
        </div>
      </div>

      <div id={dragulaContainer} key={dragula}>
        {
          entity.highlights?.map((highlight, index) => {
            const selectedDate = { day: highlight.day, month: highlight.month, year: highlight.year }
            const selectedPerson = { id: highlight.related_person_id, name: relatedPersonName(highlight) }
            const selectedSite = { id: highlight.related_site_id, name: highlight.related_site_name }

            return (
              <div key={`${highlight.id}`} id={highlight.id} className='my-3 dragula-item'>
                <div className='card-bodys'>
                  <div className='row g-1'>
                    <div className={`${entity.highlights?.length > 1 ? 'col-auto' : 'd-none'}`}>
                      <label className='cnow-form-label fw-bold'>Order</label>
                      <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete='off' />
                    </div>
                    <div className='col'>
                      <div className='row g-1'>
                        <div className='col-12 col-md-auto d-flex flex-grow-1'>
                          <div className='w-100'>
                            <label className='cnow-form-label fw-bold'>{entity.type === 'person' ? 'Career Highlights' : 'Highlights'} </label>
                            <input name='highlight' type='text' className='form-control' value={highlight.highlight}
                              onChange={(e) => handleInputChange(e)} data-id={index} autoComplete='off' />
                          </div>
                        </div>
                        <div className='col-auto'>
                          <SelectDate key={`date_${index}`} label={true} value={selectedDate} onChange={(data) => handleDateChange(index, data)} />
                        </div>
                        <div className='col-auto'>
                          <label className='cnow-form-label'></label>
                          <div className='text-end'>
                            <Button icon='delete' onClick={() => removeHighlight(index)} />
                          </div>
                        </div>
                      </div>

                      <TextEditor key={`text_${index}`} data={highlight.description} onChange={(data) => handleEditorChange(index, data)}>
                        <label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
                      </TextEditor>

                      <label className='cnow-form-label mt-2 fw-bold'>Remarks Attribution</label>
                      <textarea name='attribution' className='form-control' rows='1' maxLength={250} onChange={(e) => handleInputChange(e)} value={highlight.attribution} data-id={index} />
                      <div key={`remarks_${index}`} className='row mt-2'>
                        <div className='col-auto'>
                          <label className='cnow-form-label fw-bold'>Related Link</label>
                        </div>
                        <div className='col-auto'>
                          <div className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' value='na' data-id={index}
                              name={`link_${index}`} id={`link_${index}_na`}
                              checked={highlight.related_person_id === null && highlight.related_site_id === null}
                              onChange={handleEntityInputChange}
                            />
                            <label className='form-check-label small'>Not Applicable</label>
                          </div>
                        </div>
                        <div className='col-auto'>
                          <div className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' value='site' data-id={index}
                              name={`link_${index}_site`} id={`link_${index}_site`}
                              checked={highlight.related_site_id} onChange={handleEntityInputChange}
                            />
                            <label className='form-check-label small'>Site</label>
                          </div>
                        </div>
                        <div className='col-auto'>
                          <div className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' value='person' data-id={index}
                              name={`link_${index}_person`} id={`link_${index}_person`}
                              checked={highlight.related_person_id} onChange={handleEntityInputChange}
                            />
                            <label className='form-check-label small'>Person/Org</label>
                          </div>
                        </div>
                      </div>
                      {
                        highlight.related_site_id && (
                          <div className='row g-1 '>
                            <div className='col'>
                              <div className='w-100'>
                                <label className='cnow-form-label'>Related Site</label>
                                {!highlight?.id.startsWith('temp.') ?
                                  <input value={selectedSite?.name} disabled className='w-100 cnow-input-disabled-height' />
                                  :
                                  <SelectSite key={`site-${index}`} value={selectedSite} onChange={(value) => handleEntityChange('site', index, value)} />
                                }
                              </div>
                            </div>
                            <div className='col-auto'>
                              <div>&nbsp;</div>
                              <div className='btn-list'>
                                <PreviewButton link={`site/${highlight.related_site_id}`} />
                                <LinkButton icon='edit' to={`/site/${highlight.related_site_id}/editor`} />
                                <Button icon='delete' onClick={() => removeHighlightLink(index)} />
                              </div>
                            </div>
                          </div>
                        )
                      }

                      {
                        highlight.related_person_id && (
                          <div className='row g-1'>
                            <div className='col'>
                              <div className='w-100'>
                                <label className='cnow-form-label'>Related Person / Organization</label>
                                {!highlight?.id.startsWith('temp.') ?
                                  <input value={selectedPerson?.name} disabled className='w-100 cnow-input-disabled-height' />
                                  :
                                  <SelectPerson value={selectedPerson} onChange={(value) => handleEntityChange('person', index, value)} />
                                }
                              </div>
                            </div>
                            <div className='col-auto'>
                              <div>&nbsp;</div>
                              <div className='btn-list'>
                                <PreviewButton link={`/people/${highlight?.related_person_id}`} />
                                <LinkButton icon='edit' to={`/people/${highlight?.related_person_id}/editor`} />
                                <Button icon='delete' onClick={() => removeHighlightLink(index)} />
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>

      {
        entity.type !== 'person' && (
          <>
            <hr className='cnow-hr' />
            <div className='row'>
              <div className='col'>
                <label className='cnow-form-title'>Awarding Organization - Award List</label>
              </div>
              <div className='col-auto'>
                <Button size='sm' icon='plus' label='Add an Award' />
              </div>
            </div>

            <div>
              <div className='card-bodys'>
                <div className='row g-1'>
                  <div className='col-auto'>
                    <label className='cnow-form-label'>Order</label>
                    <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={1} autoComplete='off' />
                  </div>
                  <div className='col'>
                    <label className='cnow-form-label'>Award</label>
                    <SelectGlobalList type='award_person' />
                  </div>
                  <div className='col-auto'>
                    <div>&nbsp;</div>
                    <Button size='sm' icon='edit' />
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  )
}

export default HighlightEditor
