/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dragula from 'react-dragula';
import uniqid from 'uniqid';
import Select from 'react-select';
import { handleReorder, customSelectStyle } from '../../helpers';
import Button from '../../components/buttons/default';
import SelectPerson from '../../components/selects/person';

const credit = require('../../data/event_credit.json')
const _api = require('../../api')

const Education = (props) => {
    const dispatch = useDispatch();
    const event = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-continuing-education'

    const reactSelectStyle = {
        ...customSelectStyle,
        valueContainer: (provided, state) => ({
            ...provided,
            width: '50px'
        }),
    }

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(event.continuing_education, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...event, continuing_education: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addContinuingEducation = () => {
        if (!event.continuing_education) event.continuing_education = [];
        let continuing_education_item = {
            'id': `temp.${uniqid()}`,
            'person_id': null,
            'person_name': null,
            'credit': null,
            'name': '',
            'sub_category': '',
            'sort': event.continuing_education?.length + 1,
        }
        event.continuing_education.push(continuing_education_item)
        setDragula(uniqid())
        event.continuing_education.forEach((item, index) => item.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...event, continuing_education: event.continuing_education } });
    }

    const removeContinuingEducation = async (index) => {
        if (event.continuing_education[index]) {
            if (!event.continuing_education[index].id.startsWith('temp.')) await _api.event.deleteContinuingEducation(event.continuing_education[index].id)
            event.continuing_education.splice(index, 1)
            event.continuing_education.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...event, continuing_education: event.continuing_education } });
        }
    }

    const handleInputChange = (name, data, index) => {
        if (name === 'credit') event.continuing_education[index].credit = data?.value
        if (name === 'credit_name') event.continuing_education[index].name = data
        if (name === 'sub_category') event.continuing_education[index].sub_category = data
        dispatch({ type: 'entity', payload: { ...event, continuing_education: event.continuing_education } });
    }

    const handleOrganizationChange = (value, index) => {
        event.continuing_education[index].person_id = value.id
        event.continuing_education[index].person_name = value.name
        dispatch({ type: 'entity', payload: { ...event, continuing_education: event.continuing_education } });
    }

    return (
        <>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Continuing Education</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add Credit' onClick={() => addContinuingEducation()} />
                </div>
            </div>
            <div id={dragulaContainer} key={dragula} className='dragula-container'>
                {
                    event?.continuing_education?.length > 0 && event.continuing_education.map((item, index) => {
                        return (
                            <div className='row g-1 mt-1 dragula-item' key={index} id={item.id}>
                                {
                                    event?.continuing_education?.length > 1 &&
                                    <div className='col-auto' >
                                        {index === 0 && <label className='fw-bold'><small>Order</small></label>}
                                        <input type='text' id={`${item.id}`} className='nwprime form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete='off' />
                                    </div>
                                }
                                <div className='col'>
                                    {index === 0 && <label className='fw-bold'><small>{'Organization'}</small></label>}
                                    <SelectPerson key={item.id} value={{ name: item?.person_name, id: item?.person_id }} className="prime" onChange={(value) => handleOrganizationChange(value, index)} entityType='organization' type='continuing_education' />
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='fw-bold'><small>{'Credit'}</small></label>}
                                    <Select cacheOptions defaultOptions
                                        styles={reactSelectStyle}
                                        options={credit}
                                        value={{
                                            value: item?.credit,
                                            label: item?.credit
                                        }}
                                        onChange={(e) => handleInputChange('credit', e, index)}
                                    />
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='fw-bold'><small>{'Credit Name'}</small></label>}
                                    <input name='price' type='credit_name' className='form-control' value={item?.name || ''} onChange={({ target: { value } }) => handleInputChange('credit_name', value, index)} autoComplete='off' />
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='fw-bold'><small>{'Credit Sub Category'}</small></label>}
                                    <input name='price' type='sub_category' className='form-control' value={item?.sub_category || ''} onChange={({ target: { value } }) => handleInputChange('sub_category', value, index)} autoComplete='off' />
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label'></label>}
                                    <div className='btn-list'>
                                        <Button icon='delete' onClick={() => removeContinuingEducation(index)} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Education;