import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import uniqid from 'uniqid';
import moment from 'moment-timezone';
import Dragula from "react-dragula";
import { customSelectStyle, handleDragDropReorder } from '../../helpers';
import { toast } from 'react-toastify';
import Button from '../../components/buttons/default';
import PageTitle from '../../components/pageTitle';
import DateEvent from '../../components/eventDate';
import TextEditor from '../../components/editors/textEditor'
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

import frequencyOptions from '../../data/event_frequency.json'
const _api = require('../../api')

const EventDateTime = (props) => {

    const event = useSelector(state => state[props.id]);
    const dispatch = useDispatch()
    const [showTimezone, setShowTimezone] = useState(false)
    const [selectedTimeZone, setSelectedTimeZone] = useState(event?.dates?.[0]?.timezone || moment.tz.guess());

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-date-time'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleDragDropReorder(event?.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...event, dates: items } });

        })/* eslint-disable react-hooks/exhaustive-deps */
    }, [dragula])
    // Dragula End

    const addDateAndTime = () => {
        if (!event.dates) event.dates = [];
        let dateTime_items = {
            'id': `temp.${uniqid()}`,
            'start_date': '',
            'end_date': '',
            'recurring': false,
            'frequency': null,
            'timezone': selectedTimeZone,
            'remarks': '',
            'sort': event.dates?.length + 1,
        }
        dateTime_items[`${props.type}_id`] = event.id
        event.dates.push(dateTime_items)
        event.dates.forEach((item, index) => item.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...event, dates: event.dates } });
        setDragula(uniqid())
    }

    const handleDateAndTime = (type, index, value) => {
        if (type === 'startDate') {
            let dateTime = moment(new Date(value)).format('YYYY-MM-DD HH:mm')
            let data = moment.tz(dateTime, selectedTimeZone).utc().unix()
            event.dates[index].start_date = data
            if (event.dates[index].end_date && !event.dates[index].show_end_date) {
                let date = moment.unix(data).tz(selectedTimeZone).format('YYYY-MM-DD')
                let time = moment.unix(event.dates[index].end_date).tz(selectedTimeZone).format('HH:mm')
                let endDateTime = moment(date + ' ' + time).format('YYYY-MM-DD HH:mm')
                let endData = moment.tz(endDateTime, selectedTimeZone).utc().unix()
                event.dates[index].end_date = endData
            }
            dispatch({ type: 'entity', payload: { ...event, dates: event.dates } });
        }

        if (type === 'startTime') {
            if (event.dates[index].start_date) {
                let date = moment.unix(event.dates[index].start_date).tz(selectedTimeZone).format('YYYY-MM-DD')
                let time = moment(new Date(value)).format('HH:mm')
                let dateTime = moment(date + ' ' + time).format('YYYY-MM-DD HH:mm')
                let data = moment.tz(dateTime, selectedTimeZone).utc().unix()
                event.dates[index].start_date = data
                dispatch({ type: 'entity', payload: { ...event, dates: event.dates } });
            }
            else {
                return toast.error('Start date and time not set!')
            }
        }

        if (type === 'endDate' && event.dates[index].show_end_date) {
            let dateTime = moment(new Date(value)).format('YYYY-MM-DD HH:mm')
            let data = moment.tz(dateTime, selectedTimeZone).utc().unix()
            event.dates[index].end_date = data
            dispatch({ type: 'entity', payload: { ...event, dates: event.dates } });
        }


        if (type === 'endTime' && !event.dates[index].show_end_date) {
            if (event.dates[index].start_date) {
                let date = moment.unix(event.dates[index].start_date).tz(selectedTimeZone).format('YYYY-MM-DD')
                let time = moment(new Date(value)).format('HH:mm')
                let dateTime = moment(date + ' ' + time).format('YYYY-MM-DD HH:mm')
                let data = moment.tz(dateTime, selectedTimeZone).utc().unix()
                event.dates[index].end_date = data
                dispatch({ type: 'entity', payload: { ...event, dates: event.dates } });
            }
            else {
                return toast.error('Start date and time not set!')
            }
        }
        if (type === 'endTime' && event.dates[index].show_end_date) {
            if (event.dates[index].end_date) {
                let date = moment.unix(event.dates[index].end_date).tz(selectedTimeZone).format('YYYY-MM-DD')
                let time = moment(new Date(value)).format('HH:mm')
                let dateTime = moment(date + ' ' + time).format('YYYY-MM-DD HH:mm')
                let data = moment.tz(dateTime, selectedTimeZone).utc().unix()
                event.dates[index].end_date = data
                dispatch({ type: 'entity', payload: { ...event, dates: event.dates } });
            }
            else {
                return toast.error('End date and time not set!')
            }
        }
        setDragula(uniqid())
    }

    const removeDateAndTime = async (index) => {
        if (event.dates[index]) {
            if (!event.dates[index].id.startsWith('temp.')) await _api[props.type].deleteEventDate(event.dates[index].id)
            event.dates.splice(index, 1)
            event.dates.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...event, dates: event.dates } });
        }
    }

    const handleChangeTimeZone = (e) => {
        let timeZone = e.target.value
        setSelectedTimeZone(timeZone);
        if (event.dates.length > 0) {
            for (let i in event.dates) {
                event.dates[i].timezone = timeZone
                dispatch({ type: 'entity', payload: { ...event, dates: event.dates } });
            }
        }
    };

    const handleRemarks = (data, index) => {
        event.dates[index].remarks = data
        dispatch({ type: 'entity', payload: { ...event, dates: event.dates } });
    }

    const handleShowEndDate = (index, value) => {
        let showEndDate = value
        event.dates[index].show_end_date = showEndDate
        dispatch({ type: 'entity', payload: { ...event, dates: event.dates } });

    }
    const handleRecurring = (value) => {
        const selectedDays = event.dates[0].recurring_days || [];
        const index = selectedDays.indexOf(value);
        if (index > -1) {
            selectedDays.splice(index, 1);
        } else {
            selectedDays.push(value);
        }
        event.dates[0].recurring_days = selectedDays;
        if (selectedDays.length > 0) {
            event.dates[0].recurring = true;
        } else {
            event.dates[0].recurring = false;
        }
        if (selectedDays.includes('true')) {
            const trueIndex = selectedDays.indexOf('true');
            selectedDays.splice(trueIndex, 1);
        }
        dispatch({ type: 'entity', payload: { ...event, dates: event.dates } });
    }

    const handleFrequencyChange = (index, value) => {
        event.dates[0].frequency = value.value
        dispatch({ type: 'entity', payload: { ...event, dates: event.dates } })
    }
    return (
        <>
            <PageTitle title={event?.name} />
            <hr />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>DATE AND TIME</label>
                </div>
                <div className='col-auto'>
                    <div className='btn-list'>
                        <Button size='sm' color='light' icon='chevrons-left' label={`Timezone ${selectedTimeZone}`} onClick={() => setShowTimezone(true)} />
                        <Button size='sm' disabled={event?.dates?.length > 1 ? true : false} color={event?.dates?.[0]?.recurring ? 'danger' : 'light'} icon='chevrons-right' label={'Recurring'} onClick={() => handleRecurring(!event?.dates?.[0]?.recurring)} />
                        <Button size='sm' disabled={event.dates?.[0]?.recurring ? true : false} color='light' icon='plus' label={'Add a Date'} onClick={() => addDateAndTime()} />
                    </div>
                </div>
            </div>
            <div id={dragulaContainer} key={dragula}>

                {
                    event.dates?.length > 0 && event.dates.map((item, index) => {
                        return (
                            <div key={`${item.id}`} id={item?.id} className='mt-3 dragula-item'>
                                <div className='d-flex justify-content-between align-items-end'>
                                    <div className='d-flex align-items-end gap-1'>
                                        {
                                            event?.dates?.length > 1 &&
                                            <div className='col-auto' >
                                                {index === 0 && <label className='cnow-form-label fw-bold'>Order</label>}
                                                <input type='text' id={`${item.id}`} className='nwprime form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete="off" />
                                            </div>
                                        }
                                        <div className='d-flex justify-content-start align-items-center gap-2'>
                                            <div>
                                                <small className='cnow-form-title'>STARTS</small>
                                                <div className=''>
                                                    <small className='fw-bold'>Day, Month-Date-Year</small>
                                                    <DateEvent value={item.start_date ? item.start_date : ''} onChange={(value) => { handleDateAndTime('startDate', index, value) }} timeZone={selectedTimeZone} />
                                                </div>
                                            </div>
                                            <div className='flex-grow-0' style={{ width: '120px' }}>
                                                <div>&nbsp;</div>
                                                <small className='fw-bold'>Time</small>
                                                <DateEvent time={true} value={item.start_date ? item.start_date : ''} onChange={(value) => { handleDateAndTime('startTime', index, value) }} timeZone={selectedTimeZone} />
                                            </div>
                                            <div>
                                                <div>&nbsp;</div>
                                                <div>&nbsp;</div>
                                                -
                                            </div>
                                            <div>

                                                {
                                                    item.show_end_date &&
                                                    <div>
                                                        <small className='cnow-form-title'>ENDS</small>
                                                        <div className=''>
                                                            <small className='fw-bold'>Day, Month-Date-Year</small>
                                                            <DateEvent value={item.end_date ? new Date(item.end_date) : ''} minDate={item.start_date} onChange={(value) => { handleDateAndTime('endDate', index, value) }} timeZone={selectedTimeZone} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    !item.show_end_date ?
                                                        <small className='cnow-form-title'>ENDS</small> :
                                                        <div>&nbsp;</div>
                                                }
                                                <div className='flex-grow-0' style={{ width: '120px' }}>
                                                    <small className='fw-bold'>Time</small>
                                                    <DateEvent time={true} value={item.end_date ? new Date(item.end_date) : ''} onChange={(value) => { handleDateAndTime('endTime', index, value) }} timeZone={selectedTimeZone} />
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div>&nbsp;</div>
                                                <div>&nbsp;</div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={item.show_end_date} onChange={(e) => handleShowEndDate(index, e.target.checked)} />
                                                    <label className="small form-check-label false">Change End Date</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-auto d-flex justify-content-end'>
                                        <Button size='sm' icon='delete' onClick={() => removeDateAndTime(index)} />
                                    </div>
                                </div>
                                {
                                    !event.dates[0]?.recurring &&
                                    <TextEditor key={'award.id'} data={item.remarks} onChange={(data) => handleRemarks(data, index)} >
                                        <label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
                                    </TextEditor>
                                }
                                {
                                    event.dates[0]?.recurring &&
                                    <>
                                        <div>
                                            <small className='cnow-form-title'>REPEAT</small>
                                            <div className='w-25'>
                                                <small className='fw-bold'>Frequency</small>
                                                <Select styles={customSelectStyle} options={frequencyOptions} value={{ "value": event.dates[0]?.frequency, "label": event.dates[0]?.frequency?.toUpperCase() }} onChange={(value) => handleFrequencyChange(index, value)} />
                                            </div>
                                        </div>
                                        {
                                            event.dates[0].frequency?.toLowerCase() === 'weekly' &&
                                            <div className='d-flex justify-content-between align-items-center mt-2'>
                                                {
                                                    frequencyOptions[1].days.map((day) => {
                                                        return (
                                                            <div className='d-flex align-items-center gap-2'>
                                                                <span>
                                                                    <input id={day.value} className='form-check-input'
                                                                        checked={event.dates[0].recurring_days && event.dates[0].recurring_days.includes(day.value)}
                                                                        onChange={() => handleRecurring(day.value)} type='checkbox' />
                                                                </span>
                                                                <label htmlFor={day.value} className='small form-check-label false'>{day.label}</label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        <TextEditor key={'award.id'} data={item.remarks} onChange={(data) => handleRemarks(data, index)} >
                                            <label className='cnow-form-label mt-2 fw-bold'>Remarks</label>
                                        </TextEditor>
                                    </>
                                }

                            </div>
                        )
                    })
                }
            </div>

            <Modal show={showTimezone} centered onHide={() => setShowTimezone(false)}>
                <div className='p-3'>
                    <div className='d-flex justify-content-between mb-3'>
                        <h4 className='cnow-form-title sm'>Time Zone</h4>
                        <Button className='btn btn-sm btn-light' icon='close' onClick={() => setShowTimezone(false)} />
                    </div>
                    <select className='cnow-date-input' value={selectedTimeZone} onChange={handleChangeTimeZone} styles={customSelectStyle} >
                        {moment.tz.names().map((timezone) => (
                            <option key={timezone} value={timezone}>
                                {timezone}
                            </option>
                        ))}
                    </select>
                </div>
            </Modal>

        </>
    )
}

export default EventDateTime