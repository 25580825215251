import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../buttons/default';
import ImageViewer from '../viewers/image';
import { getCredits } from '../../helpers';

const _api = require('../../api')

const EntityPlaylistImages = (props) => {
    const entity = useSelector(state => state[props.entityId]);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [expand, setExpand] = useState(true)
    const [entityImages, setEntityImages] = useState([])

    useEffect(() => {
        getImages()
        // eslint-disable-next-line
    }, [entity.podcasts?.length])

    const getImages = async () => {
        let newEntity = []
        if (entity.podcasts) {
            for (const podcast of entity.podcasts) {
                if (podcast.podcast_image_id) {
                    let res = await _api.image.getById(podcast.podcast_image_id)
                    res.podcast_id = podcast.podcast_id
                    if (res) newEntity.push(res)
                } else {
                    newEntity.push({
                        id: null,
                        caption: null,
                        url: null,
                        credits: null,
                        podcast_id: podcast.podcast_id
                    })
                }
            }
        }
        setEntityImages(newEntity)
    }

    const handleEdit = (route) => {
        history.push({ pathname: route })
        dispatch({ type: 'podcasts', payload: { entityPodcasts: entity.podcasts, source: location.pathname } })
      }

    return (
        <div className='mt-3'>
            <div className='cnow-form-title'></div>
            <table className='table'>
                <thead className='bg-light'>
                    <tr className='small'>
                        <td style={{ width: '70px' }}>Order</td>
                        <td style={{ width: '40px' }}></td>
                        <td style={{ width: '90px' }}>Media</td>
                        <td>Photo Title, Credits</td>
                        <td className='text-end text-capitalize' style={{ width: '150px' }}>
                            {entityImages && entityImages.length > 0 ? entityImages.length : 0} {props.imageType}
                            {expand === true && <i className='fe fe-chevron-down ms-1' style={{ 'cursor': 'pointer' }} onClick={() => setExpand(false)} />}
                            {expand === false && <i className='fe fe-chevron-up ms-1' style={{ 'cursor': 'pointer' }} onClick={() => setExpand(true)} />}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        expand === true && entityImages.map((item, index) => {
                            return (
                                <tr key={index} className='text-left sort-images' id={item.id}>
                                    <td>
                                        <input type='text' className='form-control form-control-sort' disabled name='sort' value={index + 1} autoComplete='off' />
                                    </td>
                                    <td></td>
                                    <td>
                                        <ImageViewer url={item?.url} type='thumb' cssClass='thumb' />
                                    </td>
                                    <td>
                                        <div>{item?.caption || ''}</div>
                                        <div className='small'>{item.credits ? `© ${getCredits(item.credits)}` : ''}</div>
                                    </td>
                                    <td>
                                        <div className='d-flex gap-1 justify-content-end'>
                                            <Button size='sm' icon={(item?.url && item.credits?.length > 0 && item.caption) ? 'edit' : 'editWhite'}
                                                color={(item?.url && item.credits?.length > 0 && item.caption) ? '' : 'danger'}
                                                onClick={() => handleEdit(`/podcast/${item.podcast_id}/editor`)} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default EntityPlaylistImages