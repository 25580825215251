/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
const _api = require('../../api')

const SiteTitle = (props) => {
  const dispatch = useDispatch()
  const site = useSelector(state => state[props.id])

  const [showAlias, toggleAlias] = useState(site?.alias?.length > 0)
  const [themes, setThemes] = useState([])

  useEffect(() => {
    _api.global_list.getAll('site_theme').then(themes => setThemes(themes))
  }, [])


  useEffect(() => {
    if (!showAlias) dispatch({ type: 'entity', payload: { ...site, alias: '' } })
  }, [showAlias])

  const handleInputChange = ({ target: { name, value } }) => {
    site[name] = value
    dispatch({ type: 'entity', payload: { ...site, [name]: value } })
  }

  const handleThemeChange = ({ target: { name, value, checked } }) => {
    const theme = themes.filter(x => x.id === value)[0]
    if (!site.themes) site.themes = []

    if (checked === true) {
      site.themes.push({
        'site_id': props.id,
        'theme_id': theme.id,
        'theme_name': theme.name,
      })
    } else {
      site.themes = site.themes.filter(function (item) { return item.theme_id !== value })
    }

    dispatch({ type: 'entity', payload: { ...site, themes: site.themes } })
  }

  return (
    <>
      {
        site && <>

          <div className='form-group'>
            <div className='cnow-form-title'>Title <span className='text-cn-warning'>*</span> </div>
            <input name='name' type='text' className='form-control' placeholder='e.g. TWA Flight Center' value={site.name || ''} onChange={handleInputChange} autoComplete='off' />
          </div>

          <hr className='cnow-hr' />

          <div className='form-group'>
            <div className='d-flex justify-content-between'>
              <span className='cnow-form-title'>Nickname / Also Known As </span>
              <div className='form-check'>
                <input className='form-check-input' type='checkbox' value={site.alias || ''} checked={showAlias} onChange={() => toggleAlias(!showAlias)} />
                <label className={`small form-check-label ${showAlias && 'fw-bold'}`}>If applicable</label>
              </div>
            </div>
            {showAlias && <input type='text' className='form-control' name='alias' placeholder='e.g. Flight Center' value={site.alias || ''} onChange={handleInputChange} autoComplete='off' />}
          </div>

          <hr className='cnow-hr' />

          {
            themes && themes.length > 0 &&
            <div className='form-group'>
              <span className='cnow-form-title'>Theme</span>
              {
                themes.map((theme) => {
                  const isChecked = site.themes ? site.themes.findIndex(x => x.theme_id === theme.id) >= 0 : false
                  return (
                    <div className='form-check mt-2' key={theme.id}>
                      <input className='form-check-input' type='checkbox' name='theme' value={theme.id} checked={isChecked} onChange={handleThemeChange} />
                      <label className='form-check-label'>{theme.name}</label>
                    </div>
                  )
                })
              }
            </div>
          }
        </>
      }
    </>
  )
}

export default SiteTitle