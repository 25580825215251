import React from 'react';
import AsyncSelect from 'react-select/async';
import { customSelectStyle } from '../../helpers';
import NoOptionsMessage from './NoOptionsMessage';

const _api = require('../../api')

const SelectImage = (props) => {
	let selected = props.value?.id ? { 'value': props.value.id, 'label': props.value.caption, 'url': props.value.url } : {};

	const items = async (keyword) => {
		let items = [];
		if (keyword) {
			const params = {
				keyword: keyword,
				hitsPerPage: 100,
				facetFilters: ["deleted: false"],
				page: 0
			}

			let response = await _api.image.search(params);
			response.hits.forEach(data => items.push({
				'value': data.id,
				'label': data.caption,
				'url': data.url
			}));
		}
		return items;
	}

	const handleChange = (e) => {
		let value = { 'id': e.value, 'caption': e.label, 'url': e.url };
		props.onChange(value);
	}

	return (
		<AsyncSelect cacheOptions defaultOptions
			styles={customSelectStyle}
			loadOptions={items}
			value={selected}
			onChange={handleChange}
			components={{ NoOptionsMessage }}
		/>
	);
};

export default SelectImage;