import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import FeaturedImage from "../../preview/featuredImage";
import { personName } from "../../../helpers";

const WhatHappenedHere = ({ featured }) => {
    const what_happened_here = featured.what_happened_here
    const [whatHappenedHere, setWhatHappenedHere] = useState([])
    // eslint-disable-next-line
    useEffect(() => { formatWhatHappenedHere() }, [what_happened_here?.length, what_happened_here])

    const formatWhatHappenedHere = () => {
        let data = []
        if (what_happened_here)
            for (let i in what_happened_here) {
                if (what_happened_here[i]?.data?.what_happened_here) {
                    for (let j in what_happened_here[i].data.what_happened_here) {
                        what_happened_here[i].data.what_happened_here[j].section_index = i
                        what_happened_here[i].data.what_happened_here[j].entity_index = j
                        data.push(what_happened_here[i].data.what_happened_here[j])
                    }
                }
            }
        setWhatHappenedHere(data)
    }
    return (
        <>
            {what_happened_here && <Carousel>
                {whatHappenedHere?.length > 0 && whatHappenedHere.map((elem) => {
                    return (
                        <Carousel.Item interval={2000}>
                            <FeaturedImage
                                url={elem?.image?.url}
                                title="WHAT HAPPENED HERE"
                                subTitle={`${elem?.dates[0]?.year || ''}${elem?.dates[0]?.year && ' - '}${personName(elem)}`}
                                description={elem?.description}
                                type={'featured@3x'}
                            />
                        </Carousel.Item>
                    )
                })}
            </Carousel>}

        </>
    )
}

export default WhatHappenedHere