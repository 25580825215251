/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const ImageViewer = ({ url, entityType, entityId, type, altText, cssClass, onLoad }) => {
    let imageType = type || 'web';
    let [imageUrl, setImageUrl] = useState(`/img/${type}.png`);

    useEffect(() => {
        if (url) {
            url = url?.toLowerCase().endsWith('.pdf')? url.replace('.pdf', '.png') : url;
            setImageUrl(url?.replace('original.', `${imageType}.`).replace('original.', `${imageType}.`) + '?' + moment().unix());
        }
        
        if (entityType && entityId) {
            setImageUrl(`${process.env.REACT_APP_API_URL}public/image/${entityType}/${entityId}/${imageType}?${moment().unix()}`);
        }
    }, [url, entityType, entityId]);

    const handleError = () => {
        setImageUrl(`/img/${entityType === 'podcast' ? 'thumb_podcast' : imageType}.png`);
    }

    return (
        <img
            src={imageUrl}
            className={cssClass}
            alt={altText || ''}
            onError={handleError}
            onLoad={onLoad}
        />
    );
}

export default ImageViewer;