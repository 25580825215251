import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';

import Name from '../../components/preview/name';
//  import PeopleList from '../../components/preview/peopleList';
import Description from '../../components/preview/description';
import RelatedPeopleList from '../../components/preview/relatedPeopleList';
import RelatedSiteList from '../../components/preview/relatedSiteList';
import TagList from '../../components/preview/tagList';
import MetaDescription from '../../components/preview/metaDescription';
import TourMap from '../../components/preview/tourMap';
import Playlist from '../../components/preview/playlist';
import StoryList from '../../components/preview/storyList';
import PodcastList from '../../components/preview/podcastList';
import TourHighlights from '../../components/preview/touringHighlights';
import TourList from '../../components/preview/tourList';
import TourImages from '../../components/preview/tourImages';
import { personName, getCategoryName,locationName } from '../../helpers';
import swal from 'sweetalert'
const _api = require('../../api');

const TourPreview = (props) => {
    const dispatch = useDispatch();
    const tour = useSelector(state => state[props.id]);
    const previewSize = useSelector((state) => state.preview);

    const togglePreviewSize = () => dispatch({ type: 'preview', payload: previewSize === 'sm' ? 'lg' : 'sm' })

    const handleLive = (tourId) => {
        let dateDescription = tour.dates?.filter((e) => e.description !== '')
        let category = getCategoryName(tour?.categories)
        if (tour.id === 'NEW') {
            swal({
                title: 'Site cannot go Live.',
                icon: 'error',
                text: `${tour?.id === 'NEW' && 'Fill the mandatory fields * to make the site go live.'}`,
                buttons: 'Go back',
                dangerMode: true,
            })
        }
        else if (
            tour?.name &&
            category &&
            tour?.stops?.[0] &&
            tour?.stops?.[0]?.location &&
            (tour?.description || dateDescription?.length > 0) &&
            tour?.image_id
        ) {
            swal({
                title: 'Tour is Live!',
                icon: 'success',
                text: `${tour.name} is now Live.`,
                buttons: 'Ok',
            })
            _api.tour.updateLive(tourId, true).then(() =>
                dispatch({ type: 'entity', payload: { ...tour, live: true } })
            )
        } else {
            swal({
                title: 'Tour cannot go Live.',
                icon: 'error',
                text: `${'Please fill the mandatory fields:'}
					${tour?.name ? '' : 'Title is missing.'}
					${category ? '' : 'Category is missing.'}
					${tour?.stops?.[0] && tour?.stops?.[0]?.location ? '' : 'Tour stops  is missing.'} 
					${(tour?.description || dateDescription?.length > 0) ? '' : 'Description is missing.'} 
					${tour?.image_id ? '' : 'Image is missing.'}`,
                buttons: 'Go back',
                dangerMode: true,
            })
            _api.tour.updateLive(tourId, false).then(() =>
                dispatch({ type: 'entity', payload: { ...tour, live: false } })
            )
        }
    }

    const handleNotLive = (tourId) => {
        _api.tour.updateLive(tourId, false).then(() =>
            dispatch({ type: 'entity', payload: { ...tour, live: false } })
        )
        swal({
            title: 'Not Live!',
            icon: 'info',
            text: `${tour.name} is now Not Live.`,
            buttons: 'Ok',
        })
    }



    return (
        <>
            <>
                {
                    previewSize === 'sm' && <div className='btn-grid'>
                        <Button size='sm' color='danger' icon='save-white' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
                        <PreviewButton size='sm' color='light' link={`tour/${tour?.id}`} />

                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

                        <Button size='sm' color='light' icon='help' />
                    </div>
                }

                {
                    previewSize === 'lg' && <div className='btn-list action-bar'>
                        <Button size='sm' color='light' label='Instructions' icon='help' />
                        {tour?.live ? (<Button size='sm' color='light' label='Live' onClick={() => { handleNotLive(tour.id) }} />)
                            : (<Button size='sm' color='danger' label='Not Live' onClick={() => { handleLive(tour.id) }} />)}
                        <Button size='sm' color='danger' label='Save' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
                        <PreviewButton size='sm' color='light' link={`tour/${tour?.id}`} />
                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}
                    </div>
                }
            </>

            <div className='preview'>
                {
                    previewSize === 'lg' && <>
                        <div className='cnow-bg-red text-white mt-2 fs-5'>Preview</div>
                        {
                            tour && props.section !== 'meta' && props.section !== 'tour_stops' && props.section !== 'location' && props.section !== 'images' && props.section !== 'on_this_day' && props.section !== 'attach_map' && props.section !== 'path_duration' &&
                            <>
                                <Name data={tour?.name} />
                                {tour?.type !== "VIRTUAL" && <div> {locationName(tour.stops?.[0]?.location)}</div>}
                                {tour?.type !== "VIRTUAL" &&
                                    <div className='text-muted'>Self Guided Tour</div>}
                                {/* <PeopleList data={tour.people?.length > 0 ? tour.people.filter((item) => item.person_type !== 'publication') : []} /> */}
                                <Description data={tour?.description} />
                                <TourHighlights data={tour?.stops} highlight={tour?.highlight_description} />
                                {(tour?.duration?.length > 0 && tour?.type !== "VIRTUAL") && <><hr></hr>
                                    <div className='fw-bold fst-italic'>Duration</div>
                                    <div>{tour.duration?.length > 0 ? tour.duration : ''} </div></>}
                                <PodcastList data={tour?.podcasts} />
                                <StoryList data={tour?.stories} />
                                <RelatedPeopleList data={tour?.related_people} />
                                <TourList data={tour?.related_tours} />
                                <RelatedSiteList data={tour?.related_sites} />
                                <Playlist data={tour?.playlists} />

                                <TourMap id={tour?.id} height='70vh' />
                                {tour?.created_by && <>
                                    <hr />
                                    <div className='fw-bold fst-italic'>Created by</div>
                                    {tour.created_by.map((item, index) => {
                                        return <div key={index}>
                                            <a {...(item.live && { href: `${process.env.REACT_APP_WEB_URL}/people/${item.related_person_id || item.person_id}` })} target='_blank' className='text-decoration-none text-dark'>{personName(item)}</a>
                                        </div>
                                    })}
                                </>
                                }
                                {tour?.partner_org && <>
                                    <hr className={tour.created_by < 0 ? "d-block" : "d-none"} />
                                    <div className='fw-bold fst-italic'>Par tner organization</div>
                                    {tour.partner_org.map((item, index) => {
                                        return <div key={index}>
                                            <a {...(item.live && { href: `${process.env.REACT_APP_WEB_URL}/people/${item.related_person_id || item.person_id}` })} target='_blank' className='text-decoration-none text-dark'>{personName(item)}</a>
                                        </div>
                                    })}
                                </>
                                }
                                {tour?.tour_leader && <>
                                    <hr className={tour.created_by < 0 || tour.partner_org < 0 ? "d-block" : "d-none"} />
                                    <div className='fw-bold fst-italic'>Tour leader</div>
                                    {tour.tour_leader.map((item, index) => {
                                        return <div key={index}>
                                            <a {...(item.live && { href: `${process.env.REACT_APP_WEB_URL}/people/${item.related_person_id || item.person_id}` })} target='_blank' className='text-decoration-none text-dark'>{personName(item)}</a>
                                        </div>
                                    })}
                                </>
                                }


                                <RelatedPeopleList data={tour?.related_people?.length > 0 ? tour?.related_people.filter((item) => item?.related_person_type === 'publication') : []} title={'Related Publication'} />
                                <TagList tags={tour?.tags} themes={tour?.themes} categories={tour?.categories} entity={'tour'} />
                            </>
                        }
                        {(props.section === 'tour_stops' || props.section === 'path_duration') &&
                            <>
                                {(tour?.duration?.length > 0 && tour?.type !== "VIRTUAL") && <><hr></hr>
                                    <div className='fw-bold fst-italic'>Duration</div>
                                    <div>{tour?.duration?.length > 0 ? tour?.duration : ''} </div></>}
                                <TourMap id={tour?.id} height='70vh' showPath={props.section === 'path_duration'} />
                            </>
                        }

                        {(props.section === 'images') &&
                            <>
                                <TourImages id={tour.id} type='tour' />
                            </>
                        }
                        {tour && props.section === 'meta' && <MetaDescription data={tour.meta} id={tour.id} type='tour' />}
                    </>
                }
            </div>
        </>
    );
};

export default TourPreview;