/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import uniqid from 'uniqid'
import Dragula from "react-dragula";

import TextEditor from './textEditor';
import SelectSite from '../selects/site';
import SelectPerson from '../selects/person';
import SelectTour from '../selects/tour'
import SelectDate from '../selects/date';
import SelectMap from '../selects/map';
import SelectEvent from '../selects/event';
import Button from '../buttons/default';
import PreviewButton from '../buttons/preview';
import LinkButton from '../buttons/link';
import { handleReorder, newTempId } from '../../helpers';

const _api = require('../../api');

const StoryEditor = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);
    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-story'
    const [key, setKey] = useState(uniqid())

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        if (entity?.dates && entity.dates.length > 0) entity.dates.forEach((date) => containers.push(document.getElementById(`${dragulaContainer}-${date.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.stories, entity.dates, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, stories: items } })
            props.refresh && props.refresh()
        })
        dragulaRef.current.on('dragend', () => { setDragula(uniqid()) })

    }, [dragula])
    // Dragula End

    //done
    const addStory = () => {
        if (!entity.stories) entity.stories = [];
        let story = {
            'id': newTempId(),
            'day': null,
            'month': null,
            'year': null,
            'ca': false,
            'bce': false,
            //person
            'related_person_id': null,
            'related_person_first_name': '',
            'related_person_last_name': '',
            'related_person_name': '',
            'is_participant': false,
            //site
            'related_site_id': null,
            'related_site_name': '',
            //tour
            'related_tour_id': null,
            'related_tour_name': '',
            //map
            'related_map_id': null,
            'related_map_name': '',
            //event
            'related_event_id': null,
            'related_event_name': '',
            [`${props.type}_id`]: props.id,
            'source': props.type,
            'sort': entity.stories ? entity.stories?.length + 1 : 1,
            'description': '',
            'entity_type': 'na',

        }

        entity.stories.push(story);
        setDragula(uniqid())
        dispatch({ type: 'entity', payload: { ...entity, stories: entity.stories } });
    }

    const removeStory = async (index) => {

        if (entity.stories[index]?.source) {
            let type = 'site'
            if (entity.stories[index].source?.includes('site')) type = 'site'
            else if (entity.stories[index].source?.includes('person')) type = 'person'
            else if (entity.stories[index].source?.includes('map')) type = 'map'
            else if (entity.stories[index].source?.includes('tour')) type = 'tour'
            else if (entity.stories[index].source?.includes('event')) type = 'event'
            //delete Api call
            if (!entity.stories[index]?.id?.startsWith('temp.')) await _api[type].deleteStory(entity.stories[index]?.id);
        }
        entity.stories.splice(index, 1)
        dispatch({ type: 'entity', payload: { ...entity, stories: entity.stories } });
    }

    const removeStoryLink = async (id) => {
        let index = entity.stories.findIndex(function (item) { return item.id === id; });
        if (index >= 0) {
            entity.stories[index].related_site_id = null
            entity.stories[index].related_site_name = ''
            entity.stories[index].related_person_id = null
            entity.stories[index].related_person_last_name = ''
            entity.stories[index].related_person_first_name = ''
            entity.stories[index].related_person_name = ''
            entity.stories[index].is_participant = false
            entity.stories[index].related_tour_id = null
            entity.stories[index].related_tour_name = ''
            entity.stories[index].related_event_id = null
            entity.stories[index].related_event_name = ''
            entity.stories[index].related_map_id = null
            entity.stories[index].related_map_name = ''
            // entity.stories[index].source = null
            entity.stories[index].entity_type = 'na'
        }
        dispatch({ type: 'entity', payload: { ...entity, stories: entity.stories } });
        setKey(uniqid())
        entity.stories.forEach(factoid => {
            if (factoid.id.startsWith('temp.')) factoid.id = null
        })
        await _api[props.type].updateStory(props?.id, entity.stories)
    }

    const handleInputChange = ({ target: { name, value, checked, dataset } }) => {
        let index = entity.stories.findIndex(function (item) { return item.id === dataset.id; });
        if (name === 'year') entity.stories[index].date.year = value;
        if (name === 'description') entity.stories[index].description = value;
        if (name === 'link_' + dataset.id && checked) {
            entity.stories[index].related_site_id = null
            entity.stories[index].related_site_name = ''
            entity.stories[index].related_person_id = null
            entity.stories[index].related_person_last_name = ''
            entity.stories[index].related_person_first_name = ''
            entity.stories[index].is_participant = false
            entity.stories[index].related_tour_name = ''
            entity.stories[index].related_tour_id = null
            entity.stories[index].related_event_id = null
            entity.stories[index].related_event_name = ''
            entity.stories[index].related_map_id = null
            entity.stories[index].related_map_name = ''
            entity.stories[index].entity_type = value
            // entity.stories[index].source = value === 'na' ? null : value
        }
        dispatch({ type: 'entity', payload: { ...entity, stories: entity.stories } });
    }

    const handleLinkChange = (type, id, value) => {
        let index = entity.stories.findIndex(function (item) { return item.id === id; });
        if (type === 'site') {
            entity.stories[index].related_site_id = value.id
            entity.stories[index].related_site_name = value.name
        }
        if (type === 'person') {
            entity.stories[index].related_person_name = value?.name
            entity.stories[index].related_person_id = value?.id
            entity.stories[index].is_participant = value?.is_participant
        }
        if (type === 'tour') {
            entity.stories[index].related_tour_name = value?.name
            entity.stories[index].related_tour_id = value?.id
        }
        if (type === 'event') {
            entity.stories[index].related_event_name = value?.name
            entity.stories[index].related_event_id = value?.id
        }
        if (type === 'map') {
            entity.stories[index].related_map_name = value?.name
            entity.stories[index].related_map_id = value?.id
        }
        dispatch({ type: 'entity', payload: { ...entity, stories: entity.stories } });
    };

    const handleEditorChange = (id, data) => {
        let index = entity.stories.findIndex(function (item) { return item.id === id; });
        if (index >= 0) entity.stories[index].description = data;
        dispatch({ type: 'entity', payload: { ...entity, stories: entity.stories } });
    }

    const handleDateChange = (id, data) => {
        let index = entity.stories.findIndex(function (item) { return item.id === id; });
        if (index >= 0) {
            entity.stories[index].day = data.day;
            entity.stories[index].month = data.month;
            entity.stories[index].year = data.year;
        }
        if (data.bce === true || data.ca === true) {
            entity.stories[index].day = null
            entity.stories[index].month = null
            dispatch({ type: 'entity', payload: { ...entity, dates: entity.dates } });
        } else if (data.bce === false || data.ca === false) {
            dispatch({ type: 'entity', payload: { ...entity, dates: entity.dates } });
        }
    };

    return (
        <div>
            <hr className='cnow-hr' />

            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Factoids {entity?.type === 'person' ? '' : '(Did anything interesting happen at this site?)'}</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a Factoid' onClick={() => addStory()} />
                </div>
            </div>
            <div id={dragulaContainer} key={dragula} >
                {
                    entity?.stories?.length > 0 && entity?.stories?.map((story, index) => {
                        return (
                            <div key={`story-editor-${index}-${key}`} id={story.id} className={`${index > 0 ? 'mt-4' : ''} dragula-item`} >
                                <div className='row g-1'>
                                    {
                                        entity?.stories?.length > 1 &&
                                        <div className='col-auto'>
                                            <label className='cnow-form-label fw-bold'>Order</label>
                                            <input key={story.id} type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete="off" />
                                        </div>
                                    }
                                    <div className='col'>
                                        <div className='row'>
                                            <div className='col'>
                                                <SelectDate key={story.id} label={true} type={true} value={story} onChange={(data) => handleDateChange(story.id, data)} />
                                            </div>
                                            <div className='col-auto'>
                                                <label className='cnow-form-label'></label>
                                                <div className='text-end'>
                                                    <Button icon='delete' onClick={() => removeStory(index)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-auto'>
                                                <label className='cnow-form-label fw-bold'>Related Link</label>
                                            </div>
                                            <div className='col-auto'>
                                                <div className='form-check form-check-inline'>
                                                    <input key={story.id} className='form-check-input' type='radio' value='na' data-id={story.id}
                                                        name={`link_${story.id}`} id={`link_${story.id}_na`}
                                                        defaultChecked={(story.entity_type === 'na' || (!story.related_person_id && !story.related_site_id && !story.related_tour_id))}
                                                        onChange={handleInputChange} />
                                                    <label className='form-check-label small'>Not Applicable</label>
                                                </div>
                                            </div>
                                            <div className='col-auto'>
                                                <div className='form-check form-check-inline'>
                                                    <input key={story.id} className='form-check-input' type='radio' value='site' data-id={story.id}
                                                        name={`link_${story.id}`} id={`link_${story.id}_site`}
                                                        defaultChecked={story.entity_type === 'site' || (!story.source?.includes("related_") && story.related_site_id) || (story.source === 'related_site' && story.related_site_id)}
                                                        onChange={handleInputChange} />
                                                    <label className='form-check-label small'>Site</label>
                                                </div>
                                            </div>
                                            <div className='col-auto'>
                                                <div className='form-check form-check-inline'>
                                                    <input key={story.id} className='form-check-input' type='radio' value='person' data-id={story.id}
                                                        name={`link_${story.id}`} id={`link_${story.id}_people`}
                                                        defaultChecked={story.entity_type === 'person' || (!story.source?.includes("related_") && story.related_person_id) || (story.source === 'related_person' && story.related_person_id)}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label className='form-check-label small'>Person/Org</label>
                                                </div>
                                            </div>
                                            <div className='col-auto'>
                                                <div className='form-check form-check-inline'>
                                                    <input key={story.id} className='form-check-input' type='radio' value='tour' data-id={story.id}
                                                        name={`link_${story.id}`} id={`link_${story.id}_tour`}
                                                        defaultChecked={(story.entity_type === 'tour' || (!story.source?.includes("related_") && story.related_tour_id) || (story.source === 'related_tour' && story.related_tour_id))}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label className='form-check-label small'>Tour</label>
                                                </div>
                                            </div>

                                            <div className='col-auto'>
                                                <div className='form-check form-check-inline'>
                                                    <input key={story.id} className='form-check-input' type='radio' value='map' data-id={story.id}
                                                        name={`link_${story.id}`} id={`link_${story.id}_map`}
                                                        defaultChecked={(story.entity_type === 'map' || (!story.source?.includes("related_") && story.related_map_id) || (story.source === 'related_map' && story.related_map_id))}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label className='form-check-label small'>Map</label>
                                                </div>
                                            </div>

                                            <div className='col-auto'>
                                                <div className='form-check form-check-inline'>
                                                    <input key={story.id} className='form-check-input' type='radio' value='event' data-id={story.id}
                                                        name={`link_${story.id}`} id={`link_${story.id}_event`}
                                                        defaultChecked={(story.entity_type === 'event' || (!story.source?.includes("related_") && story.related_event_id) || (story.source === 'related_event' && story.related_event_id))}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label className='form-check-label small'>Event</label>
                                                </div>
                                            </div>      
                                        </div>
                                        {/* site */}
                                        {
                                            (story.entity_type === 'site' || (!story.source?.includes("related_") && story.related_site_id) || (story.source === 'related_site' && story.related_site_id)) &&
                                            <div className='mt-2'>
                                                <div className='row g-1'>
                                                    <div className='col'>
                                                        <label className='cnow-form-label fw-bold'>Related Site</label>
                                                        {!story.id.startsWith('temp.') ?
                                                            <input value={story?.related_site_name} disabled className='w-100' style={{ height: '38px' }} />
                                                            :
                                                            <SelectSite key={story.id} value={{ id: story.related_site_id, name: story.related_site_name }}
                                                                onChange={(value) => handleLinkChange('site', story.id, value)} />
                                                        }
                                                    </div>
                                                    <div className='col-auto'>
                                                        <label className='cnow-form-label'></label>
                                                        <div className='btn-list'>
                                                            <PreviewButton link={`site/${story.related_site_id}`} />
                                                            <LinkButton icon='edit' to={`/site/${story.related_site_id}/editor`} />
                                                            <Button icon='delete' onClick={() => removeStoryLink(story.id)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* person */}
                                        {
                                            (story.entity_type === 'person' || (!story.source?.includes("related_") && story.related_person_id) || (story.source === 'related_person' && story.related_person_id)) &&
                                            <div className='mt-2'>
                                                <div className='row g-1'>
                                                    <div className='col'>
                                                        <label className='cnow-form-label fw-bold'>Related Person / Organization</label>
                                                        {!story.id.startsWith('temp.') ?
                                                            <input value={story?.related_person_name} disabled className='w-100' style={{ height: '38px' }} />
                                                            :
                                                            <SelectPerson key={story.id} value={{
                                                                id: story.related_person_id,
                                                                name: story.related_person_name,
                                                                first_name: story.related_person_first_name,
                                                                last_name: story.related_person_last_name,
                                                                is_participant: story.is_participant
                                                            }} onChange={(value) => handleLinkChange('person', story.id, value)} />
                                                        }
                                                    </div>
                                                    <div className='col-auto'>
                                                        <label className='cnow-form-label'></label>
                                                        <div className='btn-list'>
                                                            <PreviewButton link={`${!story.is_participant ? 'people' : 'participant'}/${story.related_person_id}`} />
                                                            <LinkButton icon='edit' to={`/${!story.is_participant ? 'people' : 'participant'}/${story.related_person_id}/editor`} />
                                                            <Button icon='delete' onClick={() => removeStoryLink(story.id)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* tour */}
                                        {
                                            (story.entity_type === 'tour' || (!story.source?.includes("related_") && story.related_tour_id) || (story.source === 'related_tour' && story.related_tour_id)) &&
                                            <div className='mt-2'>
                                                <div className='row g-1'>
                                                    <div className='col'>
                                                        <label className='cnow-form-label fw-bold'>Tour</label>
                                                        {!story.id.startsWith('temp.') ?
                                                            <input value={story?.related_tour_name} disabled className='w-100' style={{ height: '38px' }} />
                                                            :
                                                            <SelectTour key={story.id} value={{
                                                                id: story.related_tour_id,
                                                                name: story.related_tour_name,
                                                            }} onChange={(value) => handleLinkChange('tour', story.id, value)} />}
                                                    </div>
                                                    <div className='col-auto'>
                                                        <label className='cnow-form-label'></label>
                                                        <div className='btn-list'>
                                                            <PreviewButton link={`tour/${story.related_tour_id}`} />
                                                            <LinkButton icon='edit' to={`/tour/${story.related_tour_id}/editor`} />
                                                            <Button icon='delete' onClick={() => removeStoryLink(story.id)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/* map */}

                                        {
                                            (story.entity_type === 'map' || (!story.source?.includes("related_") && story.related_map_id) || (story.source === 'related_map' && story.related_map_id)) &&
                                            <div className='mt-2'>
                                                <div className='row g-1'>
                                                    <div className='col'>
                                                        <label className='cnow-form-label fw-bold'>Map</label>
                                                        {!story.id.startsWith('temp.') ?
                                                            <input value={story?.related_map_name} disabled className='w-100' style={{ height: '38px' }} />
                                                            :
                                                            <SelectMap key={story.id} value={{
                                                                id: story.related_map_id,
                                                                name: story.related_map_name,
                                                            }} onChange={(value) => handleLinkChange('map', story.id, value)} />}
                                                    </div>
                                                    <div className='col-auto'>
                                                        <label className='cnow-form-label'></label>
                                                        <div className='btn-list'>
                                                            <PreviewButton link={`map/${story.related_map_id}`} />
                                                            <LinkButton icon='edit' to={`/map/${story.related_map_id}/editor`} />
                                                            <Button icon='delete' onClick={() => removeStoryLink(story.id)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        
                                        {/* Event */}

                                        {
                                            (story.entity_type === 'event' || (!story.source?.includes("related_") && story.related_event_id) || (story.source === 'related_event' && story.related_event_id)) &&
                                            <div className='mt-2'>
                                                <div className='row g-1'>
                                                    <div className='col'>
                                                        <label className='cnow-form-label fw-bold'>Event</label>
                                                        {!story.id.startsWith('temp.') ?
                                                            <input value={story?.related_event_name} disabled className='w-100' style={{ height: '38px' }} />
                                                            :
                                                            <SelectEvent key={story.id} value={{
                                                                id: story.related_event_id,
                                                                name: story.related_event_name,
                                                            }} onChange={(value) => handleLinkChange('event', story.id, value)} />}
                                                    </div>
                                                    <div className='col-auto'>
                                                        <label className='cnow-form-label'></label>
                                                        <div className='btn-list'>
                                                            <PreviewButton link={`event/${story.related_event_id}`} />
                                                            <LinkButton icon='edit' to={`/event/${story.related_event_id}/editor`} />
                                                            <Button icon='delete' onClick={() => removeStoryLink(story.id)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <TextEditor key={story.id} data={story.description} toolbar={true} onChange={(data) => handleEditorChange(story.id, data)}>
                                            <label className='cnow-form-label mt-2 fw-bold'>Factoid</label>
                                        </TextEditor>
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default StoryEditor;