import React from 'react'
import ImageViewer from '../viewers/image'

const CollectionImage = (props) => {
    return (
        <div className='mt-3 d-flex flex-wrap'>
            <div className='w-100 cnow-text-primary cnow-bg-grey fw-bold'>{props.title}</div>
            {props.data?.map(item => {
                return (
                    <div key={item?.title} className='w-50 pe-2'>
                        <div className='fw-bold small'>{item?.title}</div>
                        <ImageViewer url={item?.url} type="thumb" cssClass={'w-100'} />
                    </div>
                )
            })}
        </div>
    )
}

export default CollectionImage