/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';
import PodcastForm from './form';
import PodcastFormTwo from './form-two';
import PodcastUpload from './upload';
import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';
import ImageViewer from '../../components/viewers/image';
import { toast } from 'react-toastify';
import LinkButton from '../../components/buttons/link';
import Spinner from '../../components/spinner';
import { uniqBy } from 'lodash';
const _api = require('../../api');

const PodcastEditor = (props) => {
  const history = useHistory();
  const id = props.match.params.id;
  const podcast = useSelector((state) => state[id]);
  const [uploader, showUploader] = useState(false);
  //setPreviousUrl removed
  const [previousUrl] = useState('')
  const podcasts = useSelector((state) => state.podcasts);
  const dispatch = useDispatch();
  const [spinner, showSpinner] = useState(false)

  useEffect(() => {
    getPodcastData()
  }, [id]);

  const getPodcastData = async () => {
    showSpinner(true)
    let myPodcast = await _api.podcast.getById(id)

    //credits
    myPodcast.credits = await _api.podcast.getCredit(id)
    //tags
    myPodcast.tags = await _api.podcast.getTag(id) || []
    //related items
    myPodcast.related_items = await _api.podcast.getRelatedItem(id) || []
    myPodcast.related_items = uniqBy(myPodcast.related_items, 'id')

    dispatch({ type: 'entity', payload: myPodcast });
    showSpinner(false)
  }
  // useEffect(() => {
  //   if (props?.history?.location?.url) {
  //     (window.sessionStorage.setItem('podcastUrl', JSON.stringify({ url: props?.history?.location?.url })));
  //   }
  //   setPreviousUrl(JSON.parse(window.sessionStorage.getItem('podcastUrl')))
  // }, [props?.history?.location?.url])

  const save = async () => {
    showSpinner(true)
    if (podcast.name || podcast.description || podcast?.credits?.length > 0 ||
      podcast?.related_items?.length > 0 || podcast?.tags?.length > 0) {
      if (podcast.type === 'audio') {
        let params = {
          url: podcast.url,
          title: podcast.name,
          content: podcast.description,
          episode_id: podcast.id_ext || null
        }
        var podRes = await _api.podcast.process(podcast.id, params)

        podcast.url = podRes?.episode?.media_url
        podcast.id_ext = podRes?.episode?.id
      }
      await _api.podcast.update(podcast)

      if (podcast.credits?.length > 0) {
        await _api.podcast.updateCredit(id, podcast.credits)
      }

      if (podcast.tags?.length > 0) {
        await _api.podcast.updateTag(id, podcast.tags)
      }
      showSpinner(false)
      toast.success('Podcast saved successfully')
    } else {
      showSpinner(false)
      toast.error('Please select all the required fields');
    }
  };
  const handleOpenUploader = () => {
    dispatch({ type: 'podcasts', payload: [] })
    showUploader(true)
  }
  const downloadImage = () => {
    saveAs(podcast.url, podcast.caption);
  };

  const showPodcast = async (item) => {
    showSpinner(true)
    await save();
    history.push({
      pathname: `/podcast/${item?.podcast_id ? item?.podcast_id : item?.id}/editor`,
    })
  };

  return (
    <>
      <div id='top' className='fixed-top border-bottom p-3 bg-white'>
        <div className='row'>
          <div className='col'><strong>Podcast Editor</strong></div>
          <div className='col-auto'>
            <div className='btn-list'>
            <Spinner display={spinner}>
              <Button size='sm' icon='replace' onClick={() => handleOpenUploader()} />
              <Button size='sm' icon='download' onClick={() => downloadImage()} />
              <PreviewButton size='sm' link={`podcast/${props.match.params.id}`} />
              <Button size='sm' label='Save' color='danger' onClick={() =>  save()} />
              {previousUrl?.url ?
                <LinkButton size='sm' icon='close' to={previousUrl?.url} target='_self' onClick={() => {
                  dispatch({ type: 'podcasts', payload: [] })
                }} />
                :
                <Button size='sm' icon='close' target='_self' onClick={() => { podcasts?.source ? history.push(podcasts?.source) : history.goBack() }} />}
                 </Spinner>
            </div>
          </div>
        </div>
      </div>

      <div className='cnow-image-edit-container cnow-bg-light p-4'>
        <Spinner display={spinner}>
          <div className='row g-0'>
            {podcast && <>
              <div className='col-5 cnow-border-right'>
                <PodcastForm id={id} />
              </div>
              <div className='col-7 h-100vh'>
                <PodcastFormTwo id={id} />
              </div>
            </>}
          </div>
        </Spinner>
      </div>
      <div className='cnow-podcast-editor-list-container'>
        {podcasts?.entityPodcasts?.length > 0 && (
          <div className='d-flex flex-row ms-2'>
            {podcasts.entityPodcasts?.length > 0 && podcasts.entityPodcasts?.map((item, index) => {
              return (
                <div key={index} className='mt-3 me-2 d-flex flex-column align-items-left' onClick={() => showPodcast(item)} >
                  <ImageViewer entityType={'podcast'} entityId={item?.podcast_id} type='thumb@2x' cssClass='thumb' />
                  <span style={{ maxWidth: 'max-content' }} className='fz-12'>
                    {item?.podcast_name?.length > 10 ? 
                  item?.podcast_name?.substring(0, 11) + '...' : item?.podcast_name || ''}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {uploader && <PodcastUpload
        type={podcast?.type}
        show={uploader}
        updateID={podcast?.id}
        onHide={() => {
          showUploader(false)
        }} />}
    </>
  );
};

export default PodcastEditor;
