/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import uniqid from 'uniqid'
import MapAddressList from './mapAddressList';
import Icon from '../viewers/icon';
import GoogleMapReact from 'google-map-react';
import { useMemo } from 'react';

const LocationList = (props) => {

    const dispatch = useDispatch();
    const site = useSelector(state => state[props.id]);
    const history = useHistory();
    const [markers, setMarkers] = useState([]);
    //setCenter is removed
    const [center] = useState({ lat: 40.730610, lng: -98.6273925 }); //United States
    const [locations, setLocations] = useState(site?.locations || []);
    const [google, setGoogle] = useState();
    const [key, setKey] = useState(uniqid())
    const mapRef = useRef();
    const column = props.column || 'flex-column';
    let baseLocation = site?.locations?.length > 0 && site.locations.filter(x => x.id === props.locationId)[0]
    const height = props.height || ''

    useEffect(() => {
        if (google)
            drawMarkers(google, site?.locations);
    }, [((props.type === 'person' || props.type === 'map_overlay') ? props : baseLocation?.google_location?.geometry), site?.locations])

    useEffect(() => { setLocations(site?.locations); setKey(uniqid()) }, [site?.locations, props.overlays])

    const handleMapLoad = (google) => {
        mapRef.current = google.map;
        mapRef.current.setOptions({
            'zoomControlOptions': { 'position': google.maps.ControlPosition.RIGHT_BOTTOM },
            'controlSize': 24,
            'minZoom': 0
        })
        setGoogle(google);
        drawMarkers(google, locations);
    }

    const renderOverlays = (google, bounds) => {
        let overlays = props.overlays;
        overlays?.length > 0 && overlays.forEach(layer => {
            layer.items?.length > 0 && layer.items.forEach(link => {
                let OverLay = new google.maps.ImageMapType({
                    getTileUrl: function (coord, zoom) { return link.url + zoom + '/' + coord.x + '/' + coord.y + '.png' },
                    tileSize: new google.maps.Size(256, 256),
                    name: link.id
                });
                if (bounds && link.meta?.bounds?.length > 0) {
                    let tilerBounds = [
                        { lat: link.meta?.bounds[1], lng: link.meta?.bounds[0] },
                        { lat: link.meta?.bounds[1], lng: link.meta?.bounds[2] },
                        { lat: link.meta?.bounds[3], lng: link.meta?.bounds[2] },
                        { lat: link.meta?.bounds[3], lng: link.meta?.bounds[0] }
                    ]
                    tilerBounds.forEach(point => bounds.extend(point))
                    mapRef.current.fitBounds(bounds);
                    mapRef.current.setZoom(parseInt(link.meta.minzoom))
                }
                mapRef.current.overlayMapTypes.push(OverLay);
            })
        })
        let OverlayView = new google.maps.OverlayView();
        OverlayView.setMap(mapRef.current);
        OverlayView.getPanes().mapPane.style.zIndex = 105
        OverlayView.getPanes().markerLayer.style.zIndex = 106
    }

    const handleMarkerMove = (locationId, marker) => {
        const index = site?.locations.findIndex(x => x.id === locationId)
        site.locations[index].location = {
            lat: marker.latLng.lat(),
            lng: marker.latLng.lng()
        }
        locations[index].location = {
            lat: marker.latLng.lat(),
            lng: marker.latLng.lng()
        }
        setLocations(locations)
        if (site.locations[index].google_location.geometry.location_type === 'CHANGED MANUAL') {
            site.locations[index].google_location.geometry.location_type = 'APPROXIMATE';
        }
        dispatch({ type: 'entity', payload: { ...site, locations: site?.locations } });
    }

    const handleMarkerClick = (locationId, marker) => {
        if (!props.locationId) {
            history.push({ pathname: `/${props.type}/${site.id}/editor/location/${locationId}` })
        }
    }

    const drawMarkers = (google, locations) => {
        var fullBounds = new google.maps.LatLngBounds();
        markers && markers.forEach(marker => {
            marker.setMap(null);
        })

        var myMarkers = [];
        let featuresCount = 0;
        for (let i = 0; i < locations?.length; i++) {
            const location = locations[i];
            const features = location.geojson?.features;
            if (location.location && location.location.lat && location.location.lng) {
                const position = {
                    lat: parseFloat(location.location.lat),
                    lng: parseFloat(location.location.lng)
                }

                const marker = new google.maps.Marker({
                    id: location.id,
                    position: position,
                    draggable: props.locationId === location.id,
                    title: location.name,
                    icon: props.type === 'event' ? '/img/icons/Symbols_Event_Marker.svg' : '/img/icons/red-marker.svg'
                });
                marker.addListener('dragend', (marker) => handleMarkerMove(location.id, marker));
                marker.addListener('click', (marker) => handleMarkerClick(location.id, marker));
                marker.setMap(mapRef.current);
                myMarkers.push(marker);
            }
            if (location.geojson) {
                mapRef.current.data.addGeoJson(location.geojson);  //to add the geojson directly
                mapRef.current.data.setStyle(function (feature) {
                    const lineStyle = {
                        path: "M 0,-1 0,1",
                        strokeOpacity: 1,
                        scale: 4,
                    };
                    let type = feature.getGeometry().getType();
                    if (type === 'Polygon') {
                        return { strokeColor: '#A82829', fillColor: '#A82829' };
                    } else if (type === 'LineString') {
                        return {
                            strokeColor: '#A82829', fillColor: '#A82829', icons: [
                                {
                                    icon: lineStyle,
                                    offset: "0",
                                    repeat: "20px",
                                },], strokeWeight: 5, strokeOpacity: 0
                        };
                    } else if (type === 'MultiPolygon') {
                        return { strokeColor: '#A82829', fillColor: '#A82829' };
                    }
                });
            }

            if (!props.locationId || props.type === 'person') {
                if (location.location?.lat) {
                    let focus = {
                        lat: parseFloat(location.location?.lat),
                        lng: parseFloat(location.location?.lng)
                    }
                    fullBounds.extend(focus)
                }
                // eslint-disable-next-line
                features && features.forEach((feature) => {
                    featuresCount++;
                    let coordinates = convertLatLng(feature.geometry);
                    coordinates.forEach(line => fullBounds.extend(line))
                })

                if (location.location?.lat || features?.length) mapRef.current.fitBounds(fullBounds)


            } else if (props.locationId === location.id) {
                let bounds = new google.maps.LatLngBounds();
                if (location.location?.lat) {
                    let focus = {
                        lat: parseFloat(location.location?.lat),
                        lng: parseFloat(location.location?.lng)
                    }
                    bounds.extend(focus)
                }
                features && features.forEach((feature) => {
                    let coordinates = convertLatLng(feature.geometry);
                    coordinates.forEach(line => bounds.extend(line))
                });
                if (location.location?.lat || features?.length) mapRef.current.fitBounds(bounds)
                if (!features?.length && location.location?.lat) mapRef.current.setZoom(16);

            }
        }
        setMarkers(myMarkers);
        if (myMarkers.length === 1 && !props.locationId && !featuresCount) mapRef.current.setZoom(16);

        if (props.locationId && props.overlays?.length > 0) {
            renderOverlays(google, null);
        } else if (props.overlays?.length > 0) {
            renderOverlays(google, fullBounds);
        }

    }

    const convertLatLng = (geometry) => {
        let coords = [];
        let points = geometry.coordinates;
        if (geometry.type === 'Polygon') {
            points[0].forEach(p => { coords.push({ lat: p[1], lng: p[0] }); })
        } else if (geometry.type === 'LineString') {
            points.forEach(p => { coords.push({ lat: p[1], lng: p[0] }); })
        } else if (geometry.type === 'MultiPolygon') {
            points.forEach(polygon => polygon[0].forEach(p => { coords.push({ lat: p[1], lng: p[0] }) }))
        }
        return coords;
    }

    useMemo(() => {
        if (baseLocation && (((baseLocation?.location?.lat === baseLocation?.google_location?.geometry?.location?.lat) && (baseLocation?.location?.lng === baseLocation?.google_location?.geometry?.location?.lng)) || baseLocation?.google_location?.geometry?.location_type === 'CHANGED MANUAL') && google)
            drawMarkers(google, site?.locations);
    }, [baseLocation?.location])

    const handleMapRedirect = () => {
        if (props.type) window.open(`${process.env.REACT_APP_WEB_URL}/historic-map/${props.type}/${props.id}`, "_blank");
    }

    return (
        <>
            {
                (locations?.length > 0 || props.showMap) && <>
                    <div className={`d-flex  ${column}`}>

                        <div>
                            <hr />
                            <div className='d-flex cursor-pointer' onClick={() => { handleMapRedirect() }}>
                                <Icon name='map-view' size={31} />
                                <div className='fw-bold fst-italic ms-1 mt-1 text-dark'>Map View</div>
                            </div>
                            <div className='cnow-map-preview mt-2' style={{ height: height }}>
                                <GoogleMapReact
                                    key={key}
                                    center={center}
                                    defaultZoom={3}
                                    options={{ mapId: "1f81af8398de79dd" }}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={handleMapLoad}
                                />
                            </div>
                        </div>
                        <div>
                            {
                                locations?.length > 0 && <>

                                    <div className='cursor-pointer' onClick={() => { handleMapRedirect() }}>
                                        <hr className={(locations?.filter(x => x.previous === false).length === 0 || props.column === 'flex-column-reverse') ? 'd-none' : ''} />
                                        <div className={locations?.filter(x => x.previous === false).length === 0 ? 'd-none' : 'fw-bold fst-italic'}>{locations?.filter(x => x.previous === false).length === 1 ? 'Current Location' : 'Current Locations'}</div>
                                        {
                                            locations.filter(x => x.previous === false).map((location, index) => {
                                                return (
                                                    <div key={index} className='mt-2'>
                                                        <div className='fw-bold'>{location.name}</div>
                                                        <div className='text-muted'>{location.address}</div>
                                                        <div className='text-muted'>{location.description}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            }

                            {locations?.length > 0 && locations.filter(x => x.previous === true).length > 0 &&
                                <>

                                    <div className='cursor-pointer' onClick={() => { handleMapRedirect() }}>
                                        <hr className={(locations.filter(x => x.previous === false).length === 0 && props.column === 'flex-column-reverse') ? 'd-none' : ''} />
                                        <div className={locations.filter(x => x.previous === true).length === 0 ? 'd-none' : 'fw-bold fst-italic'}>{locations.filter(x => x.previous === true).length === 1 ? 'Previous Location' : 'Previous Locations'}</div>
                                        {
                                            locations.filter(x => x.previous === true).map((location, index) => {
                                                return (
                                                    <div key={index} className='mt-2' >
                                                        <div className='fw-bold'>{location.name}</div>
                                                        <div className='text-muted'>{location.address}</div>
                                                        <div className='text-muted'>{location.description}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            }

                            {locations?.length > 0 && props.type === 'event' && <MapAddressList id={props.id} type={props.type} locations={locations} />}

                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default LocationList;