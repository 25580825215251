
/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Spinner from '../../components/spinner';
import { auth } from '../../services/auth';

toast.configure();

const Login = (props) => {

  const dispatch = useDispatch()

  const loginData = {
    email: 'testuser@culturenow.com',
    password: 'Neetable@123',
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid.').required('Email is Required.'),
    password: Yup.string().required('Password is Required'),
  });

  const [loading, showSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    document.title = `Login - CultureNow (${process.env.REACT_APP_ENV})`;
    clearLocalStorage()
  }, []);

  const clearLocalStorage = () => {
    dispatch({ type: 'clearStorage' }); 
  };

  const doEmailLogin = async (form) => {
    if (form.email === loginData.email && form.password === loginData.password)
      dispatch({ type: 'loggedin', payload: true });
    props.history.push('/site');
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Spinner display={loading}>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 align-self-center px-5'>
            <div className='text-center px-5'>
              <img src='/img/logo/logo-light.svg' alt='CultureNow' className='img-fluid' />
            </div>
            <h3 className='text-center text-muted my-4'>CMS ({process.env.REACT_APP_ENV})</h3>
            <hr />
            <Formik initialValues={loginData} validationSchema={loginSchema} onSubmit={doEmailLogin}>
              {({ handleChange, handleSubmit, values, errors }) => (

                <form noValidate onSubmit={handleSubmit} autoComplete="off">
                  <div className='form-group'>
                    <label className='cnow-form-label'>Email</label>
                    <input type='email' name='email' placeholder='name@address.com' className='form-control' value={values.email} onChange={handleChange} />
                    {errors.email && <div className='cnow-form-subtitle'>{errors.email}</div>}
                  </div>

                  <div className='form-group mt-2'>
                    <div className='row'>
                      <div className='col'>
                        <label className='cnow-form-label'>Password</label>
                      </div>
                      <div className='col-auto d-none'>
                        <Link to='/forgot-password' className='form-text small'>
                          Forgot password?
                        </Link>
                      </div>

                      <div className='input-group input-group-merge'>
                        <input type={showPassword ? 'text' : 'password'} name='password' placeholder='Enter Password' className='form-control' value={values.password} onChange={handleChange} />
                        <span className='input-group-text' onClick={togglePassword}>
                          <i className={showPassword ? 'cnow-icon cnow-icon-preview' : 'cnow-icon cnow-icon-preview'}></i>
                        </span>
                      </div>
                      {errors.password && <div className='cnow-form-subtitle'>{errors.password}</div>}
                    </div>
                  </div>

                  <button type='submit' className='btn w-100 btn-danger mt-3'>
                    Sign In
                  </button>
                </form>
              )}
            </Formik>
          </div>
          <div className='col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block'>
            <div className='bg-cover h-100 min-vh-100 mt-n1 me-n3 p-5' style={{ backgroundImage: "url('/img/login.png')" }}></div>
          </div>
        </div>
      </div>
    </Spinner>
  );
};

export default Login;