/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';
import Spinner from '../../components/spinner';
import EventTitle from './title';
import EventMenu from './menu';
import EventPreview from './preview';
import EventLocation from './location';
import EventPeople from './people';
import EventFeaturedProject from './featuredProject';
import EventAbout from './about';
import EventDateTime from './dateTime';
import EventTickets from './tickets';
import EventTour from './eventTour';
import EventMeta from './meta';
import EventImages from './image';
import EventPodcast from './podcast';
import EventCuratorManagement from './curator';
import EventOnThisDayInThisPhoto from './onThisDayInThisPhoto';
import RelatedMapEditor from '../../components/editors/relatedMapEditor';
import EventSpecialFeature from './eventRecipe';
import Legal from '../../components/legal/editor';
const _api = require('../../api');

const EventEditor = (props) => {
    const id = props.match.params.id || 'NEW';
    const section = props.match.params.section || 'title';
    const sectionId = props.match.params.sectionId || '';
    const history = useHistory();
    const dispatch = useDispatch();
    const event = useSelector(state => state[id]);
    const menuSize = useSelector((state) => state.menu);
    const previewSize = useSelector((state) => state.preview);
    const [spinner, showSpinner] = useState(false);

    useEffect(() => {
        if (id === 'NEW') {
            dispatch({
                type: 'entity', payload: {
                    'id': 'NEW',
                    'name': '',
                    'description': '',
                    'meta': {},
                    'image_id': null,
                    'deleted': false,
                    'live': false,
                    'types': [],
                    'categories': [],
                    'themes': []
                }
            })
        } else {
            getEventData()
        }
    }, [id, sectionId, section]);

    const getEventData = async () => {
        showSpinner(true);
        let myEvent = await _api.event.getById(id)

        if (section === 'title' || !section) {
            myEvent.themes = await _api.event.getTheme(id)
            myEvent.categories = await _api.event.getCategory(id)
            myEvent.types = await _api.event.getType(id)
            myEvent.related_event_series = await _api.event.getEventSeries(id) || []
        }

        if (section === 'location') {
            myEvent.locations = await _api.event.getLocation(id)
        }

        if (section === 'people') {
            myEvent.participants = await _api.event.getParticipant(id)
            myEvent.hosts = await _api.event.getHost(id)
            myEvent.sponsors = await _api.event.getSponsor(id)
            myEvent.related_people = await _api.event.getRelatedPerson(id)
        }

        if (section === 'about') {
            myEvent.tags = await _api.event.getTag(myEvent.id)
            myEvent.stories = await _api.event.getStory(myEvent.id)
            myEvent.related_sites = await _api.event.getRelatedSite(id) || []
        }

        if (section === 'events_tours') {
            myEvent.related_events = await _api.event.getEvent(myEvent.id)
            myEvent.related_tours = await _api.event.getRelatedTour(myEvent.id)
        }

        if (section === 'attach_map') {
            myEvent.maps = await _api.event.getRelatedMap(id)
        }

        if (section === 'featured_projects') {
            myEvent.featured_people = await _api.event.getFeaturedPeople(id)
            myEvent.featured_projects = await _api.event.getFeaturedProjects(id)
        }

        if (section === 'on_this_day') {
            myEvent.on_this_day = await _api.event.getOnThisDay(id) || []
        }

        if (section === 'tickets') {
            myEvent.reservations = await _api.event.getReservation(id) || []
            myEvent.tickets = await _api.event.getTicket(id) || []
            myEvent.continuing_education = await _api.event.getContinuingEducation(id) || []
        }

        if (section === 'images') {
            myEvent.participants = await _api.event.getParticipant(id)
            myEvent.images = await _api.event.getImage(id) || []
            myEvent.featured_projects = await _api.event.getFeaturedProjects(id)
        }

        if (section === 'podcast') {
            myEvent.podcasts = await _api.event.getPodcast(id)
            myEvent.playlists = await _api.event.getPlaylist(id)
        }

        if (section === 'date_time') {
            myEvent.dates = await _api.event.getEventDate(id) || []
        }

        if (section === 'special_features') {
            myEvent.special_features = await _api.event.getEventRecipe(id)
        }

        if (section === 'legal') {
            myEvent.images = await _api.event.getImage(id) || []
            myEvent.podcasts = await _api.event.getPodcast(id)
        }

        if (section === 'curator') {
            myEvent.images = await _api.event.getImage(id) || []
            myEvent.podcasts = await _api.event.getPodcast(id)
            myEvent.curator = await _api.event.getCurator(id)

        }

        dispatch({ type: 'entity', payload: myEvent })
        showSpinner(false);
    }

    const updateAndRedirect = async (path) => {
        if (!path) {
            path = section;
            if (sectionId) path = path.concat('/', sectionId);
        }

        showSpinner(true);
        if (event.id === 'NEW') event.id = null;
        let myEvent = await _api.event.update(event)

        if (section === 'title' || !section) {
            myEvent.themes = await _api.event.updateTheme(myEvent.id, event.themes)
            myEvent.categories = await _api.event.updateCategory(myEvent.id, event.categories)
            myEvent.types = await _api.event.updateType(myEvent.id, event.types)
            // myEvent.related_event_series = await _api.event.updateEventSeries(myEvent.id, event.related_event_series)

            if (event.related_event_series?.length > 0) {
                let related_event_series = []
                for (const item of event.related_event_series) {
                    if (item.event_series_id) {
                        let response = await _api.event_series.update_event(item.event_series_id, [item])
                        related_event_series.push(response[0])
                    }
                }
                myEvent.related_event_series = related_event_series
            }
        }

        if (section === 'location') {
            myEvent.locations = await _api.event.updateLocation(myEvent.id, event.locations)
        }

        if (section === 'people') {
            myEvent.participants = await _api.event.updateParticipant(myEvent.id, event.participants)
            myEvent.hosts = await _api.event.updateHost(myEvent.id, event.hosts)
            myEvent.sponsors = await _api.event.updateSponsor(myEvent.id, event.sponsors)
            if (event.related_people?.length > 0) {
                let related_people = []
                for (const item of event.related_people) {
                    if (item.person_id) {
                        let response = await _api.person.updateEvent(item.person_id, [item])
                        related_people.push(response[0])
                    }
                }
                myEvent.related_people = related_people
            }
        }

        if (section === 'about') {
            myEvent.tags = await _api.event.updateTag(myEvent.id, event.tags)
            myEvent.stories = await _api.event.updateStory(myEvent.id, event.stories)
            if (event.related_sites?.length > 0) {
                let related_site = []
                for (const item of event.related_sites) {
                    if (item.site_id) {
                        let response = await _api.site.updateEvent(item.site_id, [item])
                        related_site.push(response[0])
                    }
                }
                myEvent.related_sites = related_site
            }
        }

        if (section === 'events_tours') {
            myEvent.related_events = await _api.event.updateEvent(myEvent.id, myEvent.related_events);
            if (event.related_tours?.length > 0) {
                let related_tour = []
                for (const item of event.related_tours) {
                    if (item.tour_id) {
                        let response = await _api.tour.updateEvent(item.tour_id, [item])
                        related_tour.push(response[0])
                    }
                }
                myEvent.related_tours = related_tour
            }
        }

        if (section === 'attach_map') {
            if (event.maps?.length > 0) {
                let related_map = []
                for (const item of event.maps) {
                    if (item.map_id) {
                        let response = await _api.map.updateEvent(item.map_id, [item])
                        related_map.push(response[0])
                    }
                }
                myEvent.maps = related_map
            }
        }

        if (section === 'on_this_day') {
            myEvent.on_this_day = await _api.event.updateOnThisDay(myEvent.id, event.on_this_day)
        }

        if (section === 'tickets') {
            myEvent.reservations = await _api.event.updateReservation(myEvent.id, event.reservations)
            myEvent.tickets = await _api.event.updateTicket(myEvent.id, event.tickets)
            myEvent.continuing_education = await _api.event.updateContinuingEducation(myEvent.id, event.continuing_education)
        }

        if (section === 'images') {
            myEvent.images = await _api.event.updateImage(myEvent.id, event.images) || []
        }

        if (section === 'podcast') {
            myEvent.podcasts = await _api.event.updatePodcast(myEvent.id, event.podcasts)
            if (event.playlists?.length > 0) {
                let related_playlist = []
                for (const item of event.playlists) {
                    if (item.playlist_id) {
                        let response = await _api.playlist.updateEvent(item.playlist_id, [item])
                        related_playlist.push(response[0])
                    }
                }
                myEvent.playlists = related_playlist
            }

        }

        if (section === 'date_time') {
            myEvent.dates = await _api.event.updateEventDate(myEvent.id, event.dates)
        }

        if (section === 'special_features') {
            myEvent.special_features = await _api.event.updateEventRecipe(myEvent.id, event.special_features)
        }


        if (section === 'curator') {
            myEvent.curator = await _api.event.updateCurator(myEvent.id, event.curator)
        }

        dispatch({ type: 'entity', payload: myEvent })
        history.push(`/event/${myEvent.id}/editor/${path}`)
        showSpinner(false);
    }

    return (
        <div className='cnow-editor-container'>
            <div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
                <EventMenu id={id} section={section} sectionId={sectionId} onClick={(path) => updateAndRedirect(path)} display={spinner} />
            </div>
            <div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
                <Spinner display={spinner}>
                    {section === 'title' && <EventTitle id={id} />}
                    {section === 'location' && <EventLocation id={id} locationId={sectionId} onUpdate={(path) => updateAndRedirect(path)} />}
                    {section === 'people' && <EventPeople id={id} />}
                    {section === 'featured_projects' && <EventFeaturedProject id={id} />}
                    {section === 'about' && <EventAbout id={id} />}
                    {section === 'special_features' && <EventSpecialFeature id={id} />}
                    {section === 'date_time' && <EventDateTime id={id} type='event' />}
                    {section === 'tickets' && <EventTickets id={id} />}
                    {section === 'events_tours' && <EventTour id={id} />}
                    {section === 'attach_map' && <RelatedMapEditor id={id} type={'event'} />}
                    {section === 'meta' && <EventMeta id={id} />}
                    {section === 'on_this_day' && <EventOnThisDayInThisPhoto id={id} onUpdate={(path) => updateAndRedirect(path)} />}
                    {section === 'images' && <EventImages id={id} onUpdate={() => updateAndRedirect()} />}
                    {section === 'podcast' && <EventPodcast id={id} />}
                    {section === 'legal' && <Legal id={id} />}
                    {section === 'curator' && <EventCuratorManagement id={id} />}
                </Spinner>
            </div>
            <div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
                <EventPreview id={id} onUpdate={() => updateAndRedirect()} spinner={spinner} section={section} locationId={sectionId} display={spinner} />
            </div>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ['places', 'visualization', 'drawing', 'geometry'],
})(EventEditor);