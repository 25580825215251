import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateString } from '../../helpers';

import TextEditor from './textEditor';

const DescriptionEditor = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);
    const wordlimit = props.limit || 500;

    const handleIsApplicable = ({ target: { checked, dataset } }) => {
        let index = entity.dates.findIndex(x => x.id === dataset.id)
        entity.dates[index].description_applicable = checked
        dispatch({ type: 'entity', payload: { ...entity, dates: entity.dates } });
    }

    const handleEditorChange = (data) => {
        dispatch({ type: 'entity', payload: { ...entity, description: data } });
    }

    const handleDateEditorChange = (index, data) => {
        entity.dates[index].description = data
        dispatch({ type: 'entity', payload: { ...entity, date_descriptions: entity.date_descriptions } });
    }

    return (
        <div onMouseLeave={() => props.refresh && props.refresh()}>
            <hr className='cnow-hr' />

            <TextEditor key={entity?.id} limit={wordlimit} toolbar={true} data={entity?.description} onChange={handleEditorChange}>
                <label className='cnow-form-title'>{props?.label} <span className='text-cn-warning'>*</span> </label>
            </TextEditor>

            {
                props.type !== 'event' && entity?.dates?.length > 0 &&
                <div id='descriptionByDate'>
                    <hr className='cnow-hr' />
                    <div className='cnow-form-title'>{props?.label}, By Dates </div>

                    {
                        entity?.dates?.map((date, index) => {
                            if (!date.description_applicable) {
                                date.description_applicable = date?.description?.length > 0;
                            }
                            let isApplicable = date.description_applicable
                            return (
                                <div key={index} className='mb-2'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <label className='cnow-form-subtitle'>{date.event_type_name} - {dateString(date)}</label>
                                        </div>
                                        <div className='col-4'>
                                            <div className='form-check'>
                                                <input className='form-check-input' name='date_descriptions' type='checkbox' checked={isApplicable} onChange={handleIsApplicable} data-id={date.id} />
                                                <label className='small'>If applicable</label>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        isApplicable && <TextEditor key={index} limit={500} toolbar={true} data={date.description} onChange={(data) => handleDateEditorChange(index, data)} />
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

export default DescriptionEditor;