import React from 'react';
import AsyncSelect from 'react-select/async';
import { customSelectStyle } from '../../helpers';
import NoOptionsMessage from './NoOptionsMessage';

const _api = require('../../api')

const SelectEvent = (props) => {
	let selected = props.value?.id ? { 'value': props.value.id, 'label': props.value.name } : {};

	const items = async (keyword) => {
		let items = [];
		if (keyword) {
			const params = {
				keyword: keyword,
				hitsPerPage: 100,
				facetFilters: [
					[
						"deleted:false",
	
	
					],["is_parent:true"]
	
				],
				page: 0
			}

			let response = await _api.event.search(params);
			response.hits.forEach(data => items.push({
				'value': data?.id,
				'label': data?.name,
				'image': data?.image?.url,
				'images': data?.images,
				'image_id': data?.image_id,
				'type': 'event'
			}));
		}
		return items;
	}

	const handleChange = (e) => {
		let value = { 'id': e.value, 'name': e.label, 'image': e.image, 'type': 'event', 'images': e.images, 'image_id': e.image_id};
		props.onChange(value);
	}

	return (
		<AsyncSelect cacheOptions defaultOptions
			styles={customSelectStyle}
			loadOptions={items}
			value={selected}
			onChange={handleChange}
			components={{ NoOptionsMessage }}
		/>
	);
};

export default SelectEvent;