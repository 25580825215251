import React, { useState, useEffect, useRef } from "react";
import Dragula from 'react-dragula'
import uniqid from 'uniqid'
import { useDispatch, useSelector } from 'react-redux';
import SelectGlobalList from './selects/globalList';
import Button from './buttons/default';
import { handleReorder, newTempId } from '../helpers';

const _api = require('../api')
const Theme = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector((state) => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-theme'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.themes, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, themes: items } })
            props.refresh && props.refresh()
        })
        // eslint-disable-next-line 
    }, [dragula])
    // Dragula End

    //#region Theme
    const addTheme = (theme_id) => {
        if (!entity.themes) entity.themes = [];
        entity.themes.push({
            'id': newTempId(),
            [`${props.type}_id`]: entity.id,
            'theme_name': '',
            'theme_id': theme_id,
            'sort': entity.themes?.length + 1
        })
        entity.themes.forEach((item, index) => item.sort = index + 1)
        setDragula(uniqid())
        dispatch({ type: 'entity', payload: { ...entity, themes: entity.themes } });
    }

    const removeTheme = async (index) => {
        if (entity.themes[index]) {
            if (!entity.themes[index].id.startsWith('temp.'))
                await _api[props.type].deleteTheme(entity.themes[index].id)

            entity.themes.splice(index, 1)
            entity.themes.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...entity, themes: entity.themes } });
        }
    }

    const handleThemeChange = (id, data) => {
        let index = entity.themes.findIndex(function (item) { return item.id === id; });
        if (index >= 0) {
            entity.themes[index].theme_id = data.id;
            entity.themes[index].theme_name = data.name
        }
        dispatch({ type: 'entity', payload: { ...entity, themes: entity.themes } });
    };
    //#endregion
    return (
        <div id='theme'>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <div className='form-group'>
                        <div className='cnow-form-title'>Theme *</div>
                    </div>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a Theme' onClick={() => addTheme(null)} disabled={(props?.disableButton && entity?.themes?.length === 3) ? true : false} />
                </div>
            </div>
            <div key={dragula} id={`${dragulaContainer}`}>
                {
                    entity?.themes?.length > 0 && entity.themes?.map((theme, index) => {
                        return (

                            <div className='row g-1 mb-3 dragula-item' key={theme.id} id={theme.id}>
                                {
                                    entity.themes.length > 1 &&
                                    <div className='col-auto'>
                                        {index === 0 && <label className='cnow-form-label'>Order</label>}
                                        <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} data-id={theme.id} autoComplete="off" />
                                    </div>
                                }
                                <div className='col'>
                                    <div className='w-100'>
                                        {index === 0 && <label className='cnow-form-label'>Theme *</label>}
                                        <SelectGlobalList type={props?.globalListType} value={{ id: theme.theme_id, name: theme.theme_name }} onChange={(data) => handleThemeChange(theme.id, data)} />
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label'></label>}
                                    <div className='text-end'>
                                        <Button icon='delete' onClick={() => removeTheme(index)} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Theme