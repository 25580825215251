import React from 'react';
import ImageViewer from '../viewers/image';
import LongTextViewer from '../viewers/longText';
import moment from 'moment';
import { personName } from '../../helpers';
const FeaturedCard = (props) => {
  const data = props.data
  const convertDateTimeZone = (date) => {
    let data = moment(new Date(date * 1000)).format('YYYY-MM-DD HH:mm:ss')
    return moment(data).format('LLLL')
  }
  return (
    <div className={`${props.cssClass} cursor-pointer card-deck`} style={{ width: '200px', heigth: '100px' }}>
      <div className='card small bg-light border-0'>
        <div onClick={() => { }} className='cursor-pointer'>
          <ImageViewer entityId={data?.image_id} entityType={'image'} type='thumb' cssClass='thumb-2x' url={data?.url} key={data?.image_id} />
        </div>
        <div className='card-body fz-12 p-2 pt-0 nearbyList-name  h-100 height-115px' >
          {!props?.displayTitle && <div className={`fw-bold d-flex `} >
            {data?.dates?.[0]?.year || data?.taken_date?.year || data?.year}{((data?.dates?.[0]?.year || data?.taken_date?.year || data?.year) && (data?.name || personName(data) || data?.caption)) ? ' - ' : ''}{data?.name || personName(data) || data?.caption}
          </div>}
          {props?.displayTitle && <div className={`fw-bold d-flex `} >
            {props.title}
          </div>}
          {props?.section !== 'event' && <LongTextViewer lines={4}>{data?.description}</LongTextViewer>}
          {props?.section === 'event' && data?.dates?.length > 0 && <LongTextViewer lines={4}>{`${data?.dates?.[0]?.start_date && convertDateTimeZone(data?.dates?.[0]?.start_date)} to ${data?.dates?.[0]?.end_date && convertDateTimeZone(data?.dates?.[0]?.end_date)}`}</LongTextViewer>}
        </div>
      </div>
    </div>
  )
}

export default FeaturedCard