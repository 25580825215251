import React from 'react';
import ImageViewer from './viewers/image';

const InlineImageList = (props) => {
    return (
        <div className='d-flex inlineimagelist'>
            {
                props.items?.length > 0 && props?.items?.map((item, index) => {
                    const selected = props.image ? props.image.id === item : props.bannerImageId === item;
                    return (
                        <div key={index} onClick={() => props.onSelect(item)} >
                            {
                                (props?.entity && props?.entity?.length > 0) && props?.entity?.find(x => x.image_id === item)?.image_url.endsWith('.pdf') ?
                                <ImageViewer key={index} type='thumb' url={props.entity.find(x => x.image_id === item).image_url} cssClass={`thumb thumb-auto m-2 col-sm-6 col-lg-4 ${selected ? 'highlight-box' : ''}`}/>
                                :
                                <ImageViewer key={index} type='thumb' cssClass={`thumb thumb-auto m-2 col-sm-6 col-lg-4 ${selected ? 'highlight-box' : ''}`} entityId={item} entityType='image' />
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default InlineImageList