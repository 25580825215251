import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

const Pagination = (props) => {

    const page = parseInt(props.page);
    const take = parseInt(props.take);
    const count = parseInt(props.count);
    const { setPage } = props

    const [pageNo, setPageNo] = useState(props.page);
    const [last, setLast] = useState(0);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    useEffect(() => {
        let last = parseInt(count / take) + 1;
        let left = [];
        let right = [];

        if (last > 7) {
            if (page === 1) {
                left = [];
                right = [2, 3, 4, 0, last - 1, last]
            } else if (page === 2) {
                left = [1];
                right = [3, 4, 0, last - 1, last]
            } else if (page === 3) {
                left = [1, 2];
                right = [4, 0, last - 1, last]
            } else if (page === last - 2) {
                left = [1, 0, page - 1];
                right = [page + 1, last]
            } else if (page === last - 1) {
                left = [1, 2, 0, page - 1];
                right = [last]
            } else if (page === last) {
                left = [1, 2, 0, page - 2, page - 1];
                right = []
            } else {
                left = [1, 0, page - 1];
                right = [page + 1, 0, last]
            }
        }
        else if (last <= 7) {
            for (let i = 0; i < page - 1; i++) {
                left.push(i + 1);
            }

            for (let i = page; i < last; i++) {
                right.push(i + 1);
            }
        }

        setPageNo(page);
        setLeft(left)
        setRight(right);
        setLast(last);
    }, [page, count, take]);

    return (
        <div>
            <div className='input-group'>
                <button className={`btn btn-light btn-pagination-text ${page > 1 ? '' : 'disabled'}`} onClick={() => { setPage(page - 1) }}>
                    <i className='fe fe-chevron-left' /> Previous
                </button>

                {
                    left.map((item, index) => {
                        return (
                            <button key={index}
                                className='btn btn-light btn-pagination'
                                disabled={item === 0}
                                onClick={() => setPage(item)}
                            >
                                {item === 0 ? '...' : item}
                            </button>
                        )
                    })
                }

                <NumberFormat className='form-control form-control-page' format='####'
                    value={pageNo} onValueChange={({ value }) => setPageNo(value)}
                    onKeyDown={(e) => { if (e.key === 'Enter') { setPage(pageNo) } }}
                    isAllowed={({ floatValue }) => { return floatValue >= 0 && floatValue <= last }}
                />

                {
                    right.map((item, index) => {
                        return (
                            <button key={index}
                                className='btn btn-light btn-pagination'
                                disabled={item === 0}
                                onClick={() => setPage(item)}
                            >
                                {item === 0 ? '...' : item}
                            </button>
                        )
                    })
                }

                <button className={`btn btn-light btn-pagination-text ${page < last ? '' : 'disabled'}`} onClick={() => { setPage(page + 1) }}>
                    Next <i className='fe fe-chevron-right' />
                </button>
            </div>
        </div>
    )
}

export default Pagination;