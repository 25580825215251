import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';
import LinkButton from '../../components/buttons/link';

import Name from '../../components/preview/name';
import Alias from '../../components/preview/alias';
import Location from '../../components/preview/location';
import PersonDesignationList from '../../components/preview/personDesignationList';
import ProfessionList from '../../components/preview/professionList';
import AffiliationList from '../../components/preview/affiliationList';
import PodcastList from '../../components/preview/podcastList';
import AwardList from '../../components/preview/awardList';
import HighlightList from '../../components/preview/highlightList';
import Description from '../../components/preview/description';
import DateDescriptionList from '../../components/preview/dateDescriptionList';
import RecipeList from '../../components/preview/recipieList';
import StoryList from '../../components/preview/storyList';
import QuoteList from '../../components/preview/quoteList';
import EventList from '../../components/preview/eventList';
import TourList from '../../components/preview/tourList';
import RelatedPeopleList from '../../components/preview/relatedPeopleList';
import RelatedSiteList from '../../components/preview/relatedSiteList';
import LocationList from '../../components/preview/locationList';
import TagList from '../../components/preview/tagList';
import RelatedLocation from '../../components/preview/relatedLocation';
import Playlist from '../../components/preview/playlist';
import MetaDescription from '../../components/preview/metaDescription';
import OrganizationTypeList from '../../components/preview/organizationTypeList';
import PublicationTypeList from '../../components/preview/publicationTypeList';
import PeopleImagesList from '../../components/preview/peopleImagesList'
import MapsList from '../../components/preview/mapsList'
import Publication from '../../components/preview/publication';
import swal from 'sweetalert'
import { personName } from '../../helpers';

const _api = require('../../api')

const PeoplePreview = (props) => {
	const dispatch = useDispatch();
	const person = useSelector(state => state[props.id]);
	const previewSize = useSelector((state) => state.preview);
	const togglePreviewSize = () => dispatch({ type: 'preview', payload: previewSize === 'sm' ? 'lg' : 'sm' })

	const handleLive = (personId) => {
		let selectedDateDescription = person?.dates?.filter((e) => e.description !== '')
		if (person.type === 'person') {
			if (
				person?.first_name &&
				person?.professions &&
				person?.professions?.[0]?.profession_name &&
				(person?.description || selectedDateDescription?.length > 0) &&
				person?.image?.url
			) {
				_api.person.updateLive(personId, true).then((_) => {
					dispatch({ type: 'entity', payload: { ...person, live: true } })
					swal({
						title: `Live!`,
						icon: 'success',
						text: `${person.first_name} is now Live.`,
						buttons: 'Ok',
					})
				})
			} else {
				swal({
					title: `Cannot Go Live!`,
					icon: 'error',
					text: ` Please fill the mandatory fields:
          ${person.first_name ? '' : 'Person Firstname is missing.'} 
          ${(person?.description || selectedDateDescription?.length > 0) ? '' : 'About Person is missing.'} 
          ${person?.professions?.[0] && person?.professions?.[0]?.profession_name ? '' : 'Person Profession is missing.'} 
          ${person?.image?.url ? '' : 'Person Image is missing.'}`,
					buttons: 'Go back',
					dangerMode: true,
				})
			}
		} else if (person.type === 'organization') {
			if (
				person?.name &&
				person?.organization_types && person?.organization_types?.[0]?.organization_type_name &&
				(person?.description || selectedDateDescription?.length > 0) &&
				person?.image?.url
			) {
				_api.person.updateLive(personId, true).then((_) => {
					dispatch({ type: 'entity', payload: { ...person, live: true } })
					swal({
						title: `Live!`,
						icon: 'success',
						text: `${person?.name} Organization is Live.`,
						buttons: 'Ok',
					})
				})
			} else {
				swal({
					title: `Cannot Go Live!`,
					icon: 'error',
					text: ` Please fill the mandatory fields:
          ${person?.name ? '' : '\n Organization name is missing.'} 
          ${(person?.description || selectedDateDescription?.length > 0) ? '' : '\n About Organization is missing.'} 
          ${person?.organization_types && person?.organization_types?.[0]?.organization_type_name ? '' : 'Organization type is missing.'}  
          ${person?.image?.url ? '' : '\n Organization Image is missing.'}`,
					buttons: 'Go back',
					dangerMode: true,
				})
			}
		} else {
			if (
				person?.name &&
				person?.publication_types && person?.publication_types?.[0]?.publication_type_name &&
				(person?.description || selectedDateDescription?.length > 0) &&
				person?.image?.url
			) {
				_api.person.updateLive(personId, true).then((_) => {
					dispatch({ type: 'entity', payload: { ...person, live: true } })
					swal({
						title: `Live!`,
						icon: 'success',
						text: `${person?.name} is Live.`,
						buttons: 'Ok',
					})
				})
			} else {
				swal({
					title: `Cannot Go Live.`,
					icon: 'error',
					text: ` Please fill the mandatory fields:
          ${person?.name ? '' : 'Publication name is missing.'} 
          ${person?.publication_types && person?.publication_types?.[0]?.publication_type_name ? '' : 'Publication type is missing.'} 
          ${(person?.description || selectedDateDescription?.length > 0) ? '' : 'About Publication is missing.'}  
          ${person?.image ? '' : 'Publication Image is missing.'}`,
					buttons: 'Go back',
					dangerMode: true,
				})
			}
		}
	}

	const handleNotLive = (personId) => {
		swal({
			title: 'Not Live!',
			icon: 'info',
			text: `${person?.type === 'person'
				? `${person?.first_name} is Not Live.`
				: `${person?.name} is Not Live.`
				}`,
			buttons: 'Ok',
		})
		_api.person.updateLive(personId, false).then(() =>
			dispatch({ type: 'entity', payload: { ...person, live: false } })
		)
	}

	return (
		<>
			<>
				{
					previewSize === 'sm' && <div className='btn-grid'>
						<Button size='sm' color='danger' icon='save-white' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
						<PreviewButton size='sm' link={`people/${person?.id}`} />
						<LinkButton size='sm' icon='pan' to={`/participant/${person?.id}/editor`} />
						{previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
						{previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

						<Button size='sm' color='light' icon='help' />
					</div>
				}

				{
					previewSize === 'lg' && <div className='btn-list action-bar btn-preview-pan'>
						<Button size='sm' color='light' label='Instructions' icon='help' />
						{person?.live ? (<Button size='sm' color='light' label='Live' onClick={() => handleNotLive(person?.id)} />) :
							(<Button size='sm' color='danger' label='Not Live' onClick={() => handleLive(person.id)} />)}
						<Button size='sm' color='danger' label='Save' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
						<LinkButton size='sm' icon='pan' to={`/participant/${person?.id}/editor`} />
						<PreviewButton size='sm' link={`people/${person?.id}`} />
						{previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
						{previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}
					</div>
				}
			</>

			{
				person &&
				<div className='preview'>
					{
						previewSize === 'lg' && props.section !== 'meta' && props.section !== 'contact' && props.section !== 'images' && props.section !== 'associated_sites' && props.section !== 'attach_map' &&
						<>
							<div className='cnow-bg-red text-white mt-2 fs-5'>Preview</div>
							<Name data={personName(person)} />
							<PersonDesignationList data={person?.designations} />
							<Alias data={person.alias} />
							{person.hide_location === false && <Location data={person.locations} />}
							<OrganizationTypeList data={person.organization_types} />
							<PublicationTypeList data={person.publication_types} />
							<ProfessionList data={person.professions} />
							<AffiliationList data={person.affiliations?.length > 0 ? person.affiliations.filter((item) => item.related_person_type !== 'publication') : []} />
							<Description data={person?.description} title='About' />
							{(!person.description && person.dates?.length > 0) && <hr />}
							<DateDescriptionList dates={person.dates} />
							<PodcastList data={person.podcasts} />
							<AwardList awards={person.awards} dateAwards={person.date_awards} />
							<HighlightList data={person.highlights} type={person.type} />
							<RecipeList data={person.recipes} />
							{!person?.credits?.publications && <Publication data={person.affiliations?.length > 0 ? person.affiliations.filter((item) => item.related_person_type === 'publication') : []} />}
							<StoryList data={person.stories} />
							<QuoteList data={person.quotes} quoteType={person.type} />
							<EventList data={person.events} />
							<TourList data={person.tours} />
							<RelatedPeopleList data={person.related_people?.length > 0 ? person.related_people.filter((item) => item.related_person_type !== 'publication') : []} />
							<RelatedSiteList data={person.related_sites} />
							<RelatedPeopleList data={person.related_people?.length > 0 ? person.related_people.filter((item) => item.related_person_type === 'publication') : []} title={'Related Publication'} />
							<Playlist data={person?.playlists} />
							{
								person.hide_location === false &&
								<LocationList id={person.id} type='person' />
							}
							{
								person.hide_location === false &&
								<RelatedLocation data={person.locations} id={person.id} type='person' />
							}
							<MapsList id={person.id} />
							<TagList tags={person.tags} themes={person.themes} entity={'person'} />
						</>
					}

					{
						previewSize === 'lg' && props.section === 'contact' && person.hide_location === false &&
						<>
							<RelatedLocation data={person.locations} type='person' id={person.id} />
							<LocationList id={person.id} height='70vh' type='person' column='flex-column-reverse' />
						</>
					}

					{
						previewSize === 'lg' && props.section === 'meta' &&
						<MetaDescription data={person.meta} id={person.id} type='people' />
					}

					{
						previewSize === 'lg' && props.section === 'images' &&
						<PeopleImagesList id={person.id} type='people' />
					}

					{
						previewSize === 'lg' && props.section === 'associated_sites' &&
						<PeopleImagesList id={person.id} type='people' />
					}

					{
						previewSize === 'lg' && props.section === 'attach_map' &&
						<>
							<MapsList id={person.id} type='person' />
							{person.hide_location === false && <>
								<LocationList id={person.id} height='70vh' type='person' />
								<RelatedLocation data={person.locations} />
							</>
							}
						</>
					}
				</div>
			}
		</>
	);
};

export default PeoplePreview;