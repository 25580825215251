import React from 'react';
import AsyncSelect from 'react-select/async';
import { customSelectStyle } from '../../helpers';
import NoOptionsMessage from './NoOptionsMessage';

const _api = require('../../api')

const SelectTour = (props) => {
    let selected = props.value?.id ? { 'value': props.value.id, 'label': props.value.name, 'image': props.value.image, } : {};

    const items = async (keyword) => {
        let items = [];
        if (keyword) {
            const params = {
                keyword: keyword,
                hitsPerPage: 100,
                facetFilters: ["deleted: false"],
                page: 0
            }

            let response = await _api.tour.search(params);
            response.hits.forEach(data => items.push({
                'value': data.id,
                'label': data.name,
                'image': data.image_id,
                'images': data?.images,
                'type': 'tour'
            }));
        }
        return items;
    }

    const handleChange = (e) => {
        let value = { 'id': e.value, 'name': e.label, 'image': e.image, 'type': 'tour', 'images': e.images };
        props.onChange(value);
    }

    return (
        <AsyncSelect cacheOptions defaultOptions
            styles={customSelectStyle}
            loadOptions={items}
            value={selected}
            onChange={handleChange}
            components={{ NoOptionsMessage }}
        />
    );
};

export default SelectTour;