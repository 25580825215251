import React from 'react';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';

const DateEvent = (props) => {

  const years = Array.from(
    { length: 2101 - 1900 },
    (_, index) => 1900 + index
  );
  const months = moment.months();

  const convertDateTimeZone = (date) => {
    let data = moment.unix(date).tz(props.timeZone).format('YYYY-MM-DD HH:mm:ss')
    return new Date(data)
}


  return (
    <div>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={date.getFullYear()}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
        selected={props.value ? convertDateTimeZone(props.value) : ''}
        onChange={(date) => props?.onChange(date)}
        showIcon
        closeOnScroll={true}
        placeholderText={props.time ? '00:00 pm' : `Day, Month DD, YYYY`}
        showPopperArrow={false}
        calendarClassName='cnow-date'
        className='cnow-date-input'
        showTimeSelect={false || props.time}
        showTimeSelectOnly={false || props.time}
        dateFormat={props.time ? "h:mm aa" : "EE MMMM d, yyyy"}
        minDate={convertDateTimeZone(props.minDate)}
      />
    </div>
  );
};

export default DateEvent;
