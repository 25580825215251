import React from 'react';
import Nav from './nav';
import 'bootstrap-daterangepicker/daterangepicker.css';

const AdminLayout = (props) => {
  document.title = `${process.env.REACT_APP_ENV} CultureNOW - Museum Without Walls`

  return (
    <>
      <Nav />
      <div className='main-content'>
        {props.children}
      </div>
    </>
  );
};

export default AdminLayout;