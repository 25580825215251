/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { uniqBy } from 'lodash';
import Button from "../buttons/default";
import ImageViewer from "../viewers/image";
import PreviewButton from '../buttons/preview';
import { newTempId, personName, relatedPersonName } from '../../helpers';
import { useSelector } from 'react-redux';

const _api = require('../../api')

export const PodcastsCard = ({ title, subtitle, data, type, titleLabel }) => {
  const history = useHistory();
  const location = useLocation();
  const [minimize, setMinimize] = useState([])

  const handleEdit = (route) => {
    history.push({ pathname: route, url: location.pathname })
  }

  const handleMinimize = async (item) => {
    let newMin = [...minimize]

    if (newMin.includes(item.id)) newMin = newMin.filter(x => x !== item.id)
    else {
      newMin.push(item.id)

      if (type === 'site' && item.site_id) {
        item.podcasts = await _api.site.getPodcast(item.site_id) || []
      } else if (type === 'person' && item.person_id) {
        item.podcasts = await _api.person.getPodcast(item.person_id) || []
      }
    }

    setMinimize(newMin)
  }

  return (
    <div className='table table-borderless' key={title}>
      <div className='d-flex'>
        <div className='cnow-form-title'>{title}</div> <div className='sub-title ps-1'>{subtitle}</div>
      </div>
      <div className='podcast-card pb-2'>
        {data?.length > 0 && data?.map((item, index) => {
          return (
            <div className='d-flex' key={item.id}>
              <div>
                <label className='cnow-form-label fw-bold'>Order</label>
                <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete="off" />
              </div>
              <div className='ms-2'>
                <div>&nbsp;</div>
                <ImageViewer entityId={item.site_id || item.person_id} entityType={item.site_id ? 'site' : 'person'} type='thumb' cssClass='thumb' />
              </div>

              <div className='flex-grow-1 pe-0'>

                <span className='fw-bold'>{titleLabel ? titleLabel : (<div>&nbsp;</div>)}</span>
                <div className='w-100 border border-2 border-dark p-1  fw-bold bg-gray'>{item.site_name || personName(item)}</div>
                {minimize.includes(item.id) &&
                  <div className='pt-2'>
                    {item.podcasts?.length > 0 && item.podcasts?.map((subItem, idx) => {
                      return (
                        <div key={subItem.id} className='d-flex pb-3'>
                          <div>
                            <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={idx + 1} autoComplete="off" />
                          </div>
                          <div className='ms-2'>
                            <div className='position-relative'>
                              {subItem?.podcast_image_url &&  <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
                                {subItem.podcast_type === 'audio' ? <img src="/img/icons/podcast-outline.svg" style={{ width: '100%', height: '90%'}} alt="playlist" />
                                : <img src="/img/icons/podcast_video.svg" style={{ width: '100%', height: '70%'}} alt="playlist" />}
                              </div>}
                              <ImageViewer url={subItem?.podcast_image_url} type='thumb' cssClass='thumb' />
                            </div>
                          </div>
                          <div className='text-start small ms-2 flex-grow-1'>
                            <div className='bg-light fw-bold'>{subItem?.podcast_name} </div>
                            <div> by {subItem?.podcast_credits} </div>
                          </div>
                          <div className='btn-list d-flex align-items-baseline ms-1 pe-1 me-1'>
                            <PreviewButton size='md' link={`podcast/${subItem.podcast_id}`} />
                            <Button size='md' icon='edit' onClick={() => { handleEdit(`/podcast/${subItem?.podcast_id}/editor`) }} />
                          </div>
                        </div>
                      )
                    })}
                  </div>}
              </div>
              <div className='ms-1 ps-0'>
                <div>&nbsp;</div>
                <Button size='sm' color='light'
                  icon={`${minimize.includes(item.id) ? 'arrow-top' : 'arrow-bottom'}`}
                  onClick={() => handleMinimize(item)} />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function RelatedPodcast(props) {
  const entity = useSelector((state) => state[props.id]);
  const [publications, setPublications] = useState([])
  const [projectSites, setProjectSites] = useState([])
  const [projectPeople, setProjectPeople] = useState([])

  useEffect(() => {
    getPublications()
    getFeaturingProject()
  }, []);

  const getPublications = () => {
    if (entity?.affiliations?.length > 0) {
      let list = [...entity?.affiliations].filter(x => x.related_person_type?.toLowerCase() === 'publication')

      //structure change for podcast card
      let final = []
      list.forEach(item => {
        final.push({ id: newTempId(), person_id: item.related_person_id, person_name: relatedPersonName(item) })
      })

      setPublications(final)
    }
  }

  const getFeaturingProject = async () => {
    let sites = []
    let people = []
    let podcast_related_items = []
    if (entity.credited_podcasts) {
      for (const pod of entity.credited_podcasts) {
        let res = await _api.podcast.getRelatedItem(pod.podcast_id) || []
        podcast_related_items = podcast_related_items.concat(res)
      }
    }

    podcast_related_items = uniqBy(podcast_related_items, 'id')
    if (podcast_related_items) {
      for (const item of podcast_related_items) {
        if (item.source.toLowerCase() === 'site' && item.id !== props.id) {
          sites.push({ id: newTempId(), site_id: item.id, site_name: item.name, podcasts: [] })
        }
        if (item.source.toLowerCase() === 'person' && item.id !== props.id) {
          people.push({ id: newTempId(), person_id: item.id, person_name: item.name, podcasts: [] })
        }
      }
    }

    setProjectSites(sites)
    setProjectPeople(people)
  }

  return (
    <div>
      {(entity?.credits?.sites && entity?.sites?.length > 0) && <><hr className='cnow-hr' />
        <PodcastsCard title='SITES' subtitle='(Direct relationship to a site)' data={entity?.sites} type="site" titleLabel='Site Name' /></>}

      {(entity?.sites?.length > 0 || entity?.related_sites?.length > 0) && <hr className='cnow-hr' />}

      {(entity?.credits?.related_sites && entity?.related_sites?.length > 0) &&
        <PodcastsCard title='RELATED SITES' subtitle='(Indirect relationship to a site)' data={entity?.related_sites} type="site" titleLabel='Site Name' />}

      {(entity?.related_sites?.length > 0 || publications.length > 0) && <hr className='cnow-hr' />}

      {(entity?.credits?.publications && publications.length > 0) &&
        <PodcastsCard title='PUBLICATIONS' subtitle='(Author/Editor)' data={publications} type="person" titleLabel='Site Name' />}

      {(publications.length > 0 && (projectSites.length > 0 || projectPeople.length > 0)) && <hr className='cnow-hr' />}

      {(projectSites.length > 0 || projectPeople.length > 0) && <div className='cnow-form-title'>PODCASTS - Featuring Projects, Events, Walking Tours, and People</div>}

      {projectSites.length > 0 &&
        <PodcastsCard title='Projects' data={projectSites} type="site" titleLabel='Site Title' />}

      {(projectSites.length > 0 && projectPeople.length > 0) && <hr className='cnow-hr' />}

      {projectPeople.length > 0 &&
        <PodcastsCard title='People' data={projectPeople} type="person" titleLabel='Person/Organization/Publication Name' />}

    </div>
  )
}

export default RelatedPodcast