/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import moment from 'moment';
// import { saveAs } from 'file-saver'; 
import Button from '../../../components/buttons/default';
import LinkButton from '../../../components/buttons/link';
import PreviewButton from '../../../components/buttons/preview';
import Pagination from '../../../components/pagination';
import ResultCount from '../../../components/resultCount';
import ScrollToTop from '../../../components/scrollToTop';
import Spinner from '../../../components/spinner';
import TableHeader from '../../../components/tableHeader';
import Icon from '../../../components/viewers/icon';
import ImageViewer from '../../../components/viewers/image';
import LongTextView from '../../../components/viewers/longText';
import RelatedPeopleList from '../../../components/table/relatedPeopleList'
import PodcastList from '../../../components/table/podcastList'
import TagList from '../../../components/table/tagList';
import CategoryList from '../../../components/table/categoryList';
import ThemeList from '../../../components/table/themeList';
import RelatedSiteList from '../../../components/table/relatedSiteList';
import RelatedMapList from '../../../components/table/relatedMapList';
import PeopleList from '../../../components/table/peopleList';
import { differenceBy } from 'lodash';
import { TABLE_ROWS, getPodcastsDuration, newTempId } from '../../../helpers';

const columns = require('../../../data/columns_playlist.json')
const _api = require('../../../api')

const FeaturedPlaylist = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()
  // const [searchResult, setSearchResult] = useState([]);

  const [keywordValue, setKeywordValue] = useState('');
  const [keyword, setKeyword] = useState('');

  const take = 50;
  const [page, setPage] = useState(1);;
  const [sort, setSort] = useState(columns[0]);
  const [spinner, showSpinner] = useState(false);

  const [paginationHits, setPaginationHits] = useState(0)
  let section = props.section
  const searchPlaylist = useSelector((state) => state.search);
  let searchResult = searchPlaylist.searchResult || []
  let selectedResult = searchPlaylist.selectedResult?.filter((elem) => elem.entity_type === 'playlist') || []
  let sectionResult = searchPlaylist[section]?.filter((elem) => elem.entity_type === 'playlist') || []
  let selectedEntity = [ ...sectionResult, ...selectedResult]
  const featured = useSelector((state) => state.featured);

  const [resultFilters, setResultFilters] = useState({
    live: searchPlaylist.filterLive,
  })
  const [label, setLabel] = useState([])
  let start_date = featured.start_date && JSON.parse(featured.start_date)
  let end_date = featured.end_date && JSON.parse(featured.end_date)
  let start = start_date ? moment(start_date).format("MMMM Do YYYY") : ''
  let end = end_date ? moment(end_date).format("MMMM Do YYYY") : ''

  useEffect(() => {
    search()
  }, [page, sort, keyword]);

  const search = async () => {
    showSpinner(true);

    const params = {
      keyword: keyword,
      hitsPerPage: take,
      page: page - 1,
      facetFilters: [
        [`live:${resultFilters?.live}`],
        ["deleted:false"]
      ]
    }
    handleSetKeywords(keyword)
    _api.playlist.search(params).then(response => {
      // setSearchResult(response)
      setPaginationHits(response.nbHits);
      const filteredArray = differenceBy(response.hits, selectedEntity, 'id')
      dispatch({ type: 'search', payload: { ...searchPlaylist, searchResult: filteredArray } })
      showSpinner(false)
    })
  }

  const handleSetKeywords = (keyword) => {
    let data = [];
    if (resultFilters?.live) data.push(`Live - Playlists`)
    if (keyword) data.push(keyword)
    setLabel(data)
  }

  const handleDeleteLabel = (index) => {
    let data = label[index];
    if (data.includes('Live')) resultFilters.live = ''
    if (data === keyword) setKeywordValue(''); setKeyword('')
    setResultFilters(resultFilters)
    search()
  }

  const deletePlaylist = (playlist) => {
    swal({
      title: `Delete Playlist`,
      text: `Are you sure that you want to delete ${playlist.name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then((status) => { _api.playlist.deleteById(playlist?.id).then(() => { search() }) });
  };

  const handleLive = (playlistId) => {
    showSpinner(true)
    let selectedSearchResult = searchResult.hits.filter(
      (item) => item.id === playlistId
    )
    let selectedResult = selectedSearchResult[0]
    if (
      selectedResult?.name &&
      selectedResult?.categories &&
      selectedResult?.categories?.[0] &&
      selectedResult?.description &&
      selectedResult?.url &&
      selectedResult?.podcasts &&
      selectedResult?.podcasts?.length
    ) {
      _api.playlist.updateLive(playlistId, true).then((_) => {
        search()
        swal({
          title: 'Playlist is Live!',
          icon: 'success',
          text: `${selectedResult?.name} is now Live!`,
          buttons: 'Ok',
        })
      })
    } else {
      swal({
        title: `${selectedResult.name} cannot go Live!`,
        icon: 'error',
        text: `Please fill the mandatory fields:
          ${selectedResult?.name ? '' : 'Title is missing.'}
          ${selectedResult?.categories && selectedResult?.categories?.[0] ? '' : 'Category is missing.'} 
          ${selectedResult?.description ? '' : 'Description is missing.'} 
          ${selectedResult?.url ? '' : 'Image is missing.'},
          ${selectedResult?.podcasts && selectedResult?.podcasts?.length && selectedResult?.podcasts?.length > 0 ? '' : 'Podcast is missing.'} `,
        buttons: 'Go back',
        dangerMode: true,
      })
    }
    showSpinner(false)
  }

  const handleNotLive = (playlistId) => {
    showSpinner(true)
    let selectedSearchResult = searchResult.hits.filter(
      (item) => item.id === playlistId
    )
    let selectedResult = selectedSearchResult[0]
    _api.playlist.updateLive(playlistId, false).then((_) => {
      search()
      swal({
        title: 'Not Live!',
        icon: 'info',
        text: `${selectedResult?.name} is Not Live.`,
        buttons: 'Ok',
      })
    })
    showSpinner(true)
  }

  const downloadPlaylist = (item) => {
    // item.podcasts.map((item) => saveAs(item.url, item.fileName))   
  }

  const PlaylistImage = ({ url }) => {
    return (
      <div className='position-relative'>
        <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
          <img src="/img/icons/playlist.svg" style={{ width: '100%', height: '100%' }} alt="playlist" />
        </div>
        <ImageViewer url={url} type='thumb' cssClass='thumb' />
      </div>
    )
  }

  const selectedPlaylist = (index) => {
    showSpinner(true)
    searchResult[index].entity_type = 'playlist'
    selectedResult.push(searchResult[index])
    searchResult.splice(index, 1)
    dispatch({
      type: 'search', payload: {
        ...searchPlaylist,
        selectedResult: selectedResult,
        searchResult: searchResult
      }
    })
    showSpinner(false)
  }

  const removeSelectedPlaylist = (index) => {
    if (selectedEntity[index].section_index !== undefined && selectedEntity[index].entity_index !== undefined) {
      let section_index = selectedEntity[index].section_index
      let entity_index = selectedEntity[index].entity_index
      featured[section][section_index].edited = true
      featured[section][section_index].data[section].splice(entity_index, 1)
      featured[section][section_index].data[section].forEach((elem, index) => elem.entity_index = index)
      let sectionIndex = sectionResult.findIndex(elem => elem.section_index === section_index && elem.entity_index === entity_index)
      sectionResult.splice(sectionIndex, 1)
      dispatch({ type: 'featured', payload: { ...featured, [`${section}`]: featured[`${section}`] } })
      dispatch({ type: 'search', payload: { ...searchPlaylist, [`${section}`]: sectionResult } })
    }
    else {
      let searchResultIndex = selectedResult.findIndex(elem => elem.id === selectedEntity[index].id)
      searchResult.push(selectedResult[searchResultIndex])
      selectedResult.splice(searchResultIndex, 1)
      dispatch({
        type: 'search', payload: { ...searchPlaylist, selectedResult: selectedResult, searchResult: searchResult }
      })
    }

  }

  const selectedPlaylistDispatch = () => {
    if (selectedResult.length > 0) {
      let index = featured[`${section}`].length === 0 ? -1 : 0
      let data = {
        "start_date": start_date.slice(0, 10),
        "end_date": end_date.slice(0, 10),
        "section": `${section}`,
      }
      if (index === -1) {
        data.id = newTempId()
        data.data = {
          [`${section}`]: selectedResult
        }
        featured[`${section}`].push(data)
      }
      else {
        featured[`${section}`][index].edited = true
        featured[`${section}`][index].data[`${section}`] = [...featured[`${section}`][index].data[`${section}`], ...selectedResult]
      }
    }
    dispatch({ type: 'featured', payload: { ...featured, source: location.pathname } })
    dispatch({ type: 'search', payload: {} })
    history.goBack()
  }

  return (
    <>
      <div className='container-fluid mt-3'>
        <div className='row g-1'>
          <div className='col'>
            <div className='input-group'>
              <span className='input-group-text border-end-0'>
                <Icon name='search' />
              </span>
              <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a playlist name, date' value={keywordValue}
                onChange={(event) => { setKeywordValue(event.target.value) }}
                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue) } }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className='col-auto'>
            <div className='btn-list d-flex gap-1'>
              <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue) }} />
              <Button label='Add a Playlist' color='danger' icon='plus-white' onClick={() => history.push('/playlist/create')} />
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid mt-3 p-0'>
        <div className='card'>
          <div className='card-header bg-light p-2'>
            <div className='d-flex justify-content-between'>
              <div>
                <h4 className='mb-0 cursor-pointer text-capitalize' onClick={() => { selectedPlaylistDispatch() }}>{`${searchPlaylist.title} - ${start_date === end_date ? start : `${start} - ${end}`}`}</h4>
                <h6 className='mb-0'>PLAYLISTS</h6>
                <div className='small'>
                  <ResultCount page={page} take={take} count={paginationHits} />
                  {
                    label && label.map((elem, index) => {
                      return (
                        <span className='badge badge-search'>
                          {elem} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { handleDeleteLabel(index) }} />
                        </span>
                      )
                    })
                  }
                </div>
              </div>
              <div className='cols align-self-end'>
                <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
              </div>
              <div className='align-self-end'>
                <div className='dropdown'>
                  <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                    Sort by: {sort.name}
                  </button>
                  <ul className='dropdown-menu'>
                    {columns.filter(x => x.sort === true)
                      .map((column, index) =>
                        <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Spinner display={spinner}>
            <div className='table-responsive cnow-table'>
              <table className='table table-striped table-hover table-bordered'>
                <thead>
                  <tr className='bg-light'>
                    {columns.filter(x => x.display === true).map((column, index) =>
                      <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />)}
                  </tr>
                </thead>
                <tbody>
                  <tr className='bg-dark'>
                    <th className='text-white' scope="col " colspan="100%">
                      <div className='d-flex ' style={{ width: "94vw" }}>
                        <span className=''>
                        {`${searchPlaylist.title} PLAYLIST SELECTED`}
                        </span>
                        <span className='ms-auto text-lowercase'>{selectedEntity.length > 1 ? `${selectedEntity.length} results` : `${selectedEntity.length} result`}</span>
                      </div>
                    </th>
                  </tr>
                  {selectedEntity && selectedEntity.map((item, index) => {
                    return (
                      <tr key={`${item.id}-${index}`}>
                        <td>
                          <div className='row g-1'>
                            <div className='col-auto'>
                              <div className='form-check mt-1'>
                                <input className='form-check-input align-middle' type='checkbox' name='select_site' value={item.id} checked={true} onClick={() => { removeSelectedPlaylist(index) }} />
                              </div>
                            </div>
                            <div className='col'>
                              <div className='btn-list'>
                                <LinkButton size='sm' icon='edit' to={`/playlist/${item.id}/editor`} />
                                <Button size='sm' icon='delete' onClick={() => deletePlaylist(item)} />
                                <PreviewButton size='sm' link={`playlist/${item.id}`} />
                                <Button size='sm' icon='download' onClick={() => downloadPlaylist(item)} />
                                {item.live ? <Button size='live' icon='live' onClick={() => handleNotLive(item.id)} />
                                  : <Button size='live' icon='not-live' color='danger' onClick={() => handleLive(item.id)} />}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <PlaylistImage url={item?.url} />
                        </td>
                        <td><b>{item.name}</b></td>
                        <td>{item?.type}</td>
                        <td>{getPodcastsDuration(item?.podcasts) + ' Minutes'}</td>
                        <PeopleList data={item.curators} />
                        <PodcastList data={item.podcasts} />
                        <td>{item.id}</td>
                        <TagList data={item.tags} />
                        <td><LongTextView lines={TABLE_ROWS + 1}>{item.description}</LongTextView></td>
                        <CategoryList data={item.categories} />
                        <ThemeList data={item.themes} />
                        <RelatedSiteList data={item?.related_sites} />
                        <RelatedPeopleList data={item?.related_people} />
                        <td></td>
                        <td></td>
                        <td></td>
                        <RelatedMapList data={item?.maps} />
                        <td>{item?.edited_by}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tbody>
                  <tr className='bg-dark'>
                    <th className='text-white' scope="col " colspan="100%">
                      <div className='d-flex ' style={{ width: "94vw" }}>
                        <span className=''>
                        {`${searchPlaylist.title}  PLAYLIST AVAILABLE`} 
                        </span>
                        <span className='ms-auto text-lowercase'>{searchResult.length > 1 ? `${searchResult.length} results` : `${searchResult.length} result`}</span>
                      </div>
                    </th>
                  </tr>
                  {searchResult && searchResult.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <div className='row g-1'>
                            <div className='col-auto'>
                              <div className='form-check mt-1'>
                                <input className='form-check-input align-middle' type='checkbox' name='select_site' value={item.id} onClick={() => { selectedPlaylist(index) }} />
                              </div>
                            </div>
                            <div className='col'>
                              <div className='btn-list'>
                                <LinkButton size='sm' icon='edit' to={`/playlist/${item.id}/editor`} />
                                <Button size='sm' icon='delete' onClick={() => deletePlaylist(item)} />
                                <PreviewButton size='sm' link={`playlist/${item.id}`} />
                                <Button size='sm' icon='download' onClick={() => downloadPlaylist(item)} />
                                {item.live ? <Button size='live' icon='live' onClick={() => handleNotLive(item.id)} />
                                  : <Button size='live' icon='not-live' color='danger' onClick={() => handleLive(item.id)} />}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <PlaylistImage url={item?.url} />
                        </td>
                        <td><b>{item.name}</b></td>
                        <td>{item?.type}</td>
                        <td>{getPodcastsDuration(item?.podcasts) + ' Minutes'}</td>
                        <PeopleList data={item.curators} />
                        <PodcastList data={item.podcasts} />
                        <td>{item.id}</td>
                        <TagList data={item.tags} />
                        <td><LongTextView lines={TABLE_ROWS + 1}>{item.description}</LongTextView></td>
                        <CategoryList data={item.categories} />
                        <ThemeList data={item.themes} />
                        <RelatedSiteList data={item?.related_sites} />
                        <RelatedPeopleList data={item?.related_people} />
                        <td></td>
                        <td></td>
                        <td></td>
                        <RelatedMapList data={item?.maps} />
                        <td>{item?.edited_by}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className='d-flex justify-content-between m-3'>
              <div className='cols'>
                <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
              </div>
            </div>
          </Spinner>
        </div>
      </div>

      <ScrollToTop />
    </>
  );
};

export default FeaturedPlaylist;