/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';
import Pagination from '../../components/pagination';
import ResultCount from '../../components/resultCount';
import ScrollToTop from '../../components/scrollToTop';
import Spinner from '../../components/spinner';
import CreditList from '../../components/table/creditList';
import LocationList from '../../components/table/locationList';
import RelatedEntityList from '../../components/table/relatedEntityList';
import TagList from '../../components/table/tagList';
import TableHeader from '../../components/tableHeader';
import Icon from '../../components/viewers/icon';
import ImageViewer from '../../components/viewers/image';
import LongTextView from '../../components/viewers/longText';
import PodcastUpload from './upload';
import { useHistory, useLocation } from 'react-router-dom';

import { dateString, TABLE_ROWS } from '../../helpers';

const columns = require('../../data/columns_podcast.json')
const _api = require('../../api')

const Podcasts = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()
  const [searchResult, setSearchResult] = useState([]);
  const [uploader, showUploader] = useState(false);
  const [keywordValue, setKeywordValue] = useState('');
  const [keyword, setKeyword] = useState('');

  const take = 50;
  const [page, setPage] = useState(1);;
  const [sort, setSort] = useState(columns[0]);

  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    search()
  }, [page, sort, keyword]);

  const search = async () => {
    showSpinner(true);

    const params = {
      keyword: keyword,
      hitsPerPage: take,
      page: page - 1,
      facetFilters: [
        [
          "deleted:false"
        ]
      ]
    }

    _api.podcast.search(params).then(results => {
      setSearchResult(results)
      showSpinner(false)
      // dispatch({ type: 'podcasts', payload: [] })
    })
  }

  const deletePodcast = (podcast) => {
    swal({
      title: `Delete Podcast`,
      text: `Are you sure that you want to delete ${podcast.index_name}?`,
      buttons: ['Cancel', 'Yes'],
      icon: 'warning',
      dangerMode: true,
    }).then((status) => { _api.podcast.deleteById(podcast?.id).then(() => { search() }) });
  };

  const updateLive = (id, live) => {
    showSpinner(true)
    let selectedSearchResult = searchResult.hits.filter((item) => item.id === id)
    let selectedResult = selectedSearchResult[0]
    if (live === true) {
      _api.podcast.updateLive(id, live).then((_) => search())
      swal({
        title: 'Podcast is Live!',
        icon: 'success',
        text: `${selectedResult?.name} is now Live!`,
        buttons: 'Ok',
      })
    } else {
      _api.podcast.updateLive(id, live).then((_) => search())
      swal({
        title: 'Not Live!',
        icon: 'info',
        text: `${selectedResult?.name} is Not Live.`,
        buttons: 'Ok',
      })
    }
    showSpinner(false)
  }

  const handleEdit = (route) => {
    history.push({ pathname: route })
    dispatch({ type: 'podcasts', payload: { entityPodcasts: [], source: location.pathname } })
  }

  const handleOpenUploader = () => {
    dispatch({ type: 'podcasts', payload: { entityPodcasts: [], source: location.pathname } })
    showUploader(true)
  }
  return (
    <>
      <div className='container-fluid mt-3'>
        <div className='row g-1'>
          <div className='col'>
            <div className='input-group'>
              <span className='input-group-text border-end-0'>
                <Icon name='search' />
              </span>
              <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                onChange={(event) => { setKeywordValue(event.target.value) }}
                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue) } }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className='col-auto'>
            <div className='d-flex gap-1 btn-list'>
              <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue) }} />
              <Button label='Add a Podcast' color='danger' icon='plus-white' onClick={() => handleOpenUploader()} />
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid mt-3 p-0'>
        <div className='card'>
          <div className='card-header bg-light p-2'>
            <div className='d-flex justify-content-between'>
              <div>
                <h4 className='mb-0'>Podcasts</h4>
                <div className='small'>
                  <ResultCount page={page} take={take} count={searchResult?.nbHits} />

                  {keyword && <span className='badge badge-search'>
                    {keyword} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { setKeyword(''); setKeywordValue(''); }} />
                  </span>}
                </div>
              </div>
              <div className='cols align-self-end'>
                <Pagination page={page} take={take} count={searchResult?.nbHits} setPage={setPage} />
              </div>
              <div className='align-self-end'>
                <div className='dropdown'>
                  <button className='btn btn-light dropdown-toggle' type='button' id='sort' data-bs-toggle='dropdown' aria-expanded='false'>
                    Sort by: {sort.name}
                  </button>
                  <ul className='dropdown-menu'>
                    {columns.filter(x => x.sort === true)
                      .map((column, index) =>
                        <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Spinner display={spinner}>
            <div className='table-responsive cnow-table'>
              <table className='table table-striped table-hover table-bordered'>
                <thead>
                  <tr className='bg-light'>
                    {columns.filter(x => x.display === true).map((column, index) =>
                      <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                    )}
                  </tr>
                </thead>
                <tbody>
                  {searchResult?.hits?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='row g-1'>
                            <div className='col-auto'>
                              <div className='form-check mt-1'>
                                <input className='form-check-input align-middle' type='checkbox' name='select_site' value={item.id} />
                              </div>
                            </div>
                            <div className='col-auto'>
                              <div className='btn-list'>
                                <Button size='sm' icon='edit' onClick={() => { handleEdit(`/podcast/${item.id}/editor`) }} />
                                <Button size='sm' icon='delete' onClick={() => deletePodcast(item)} />
                                <PreviewButton size='sm' link={`podcast/${item.id}`} />
                                <Button size='live'
                                  icon={item.live === true ? 'live' : 'not-live'}
                                  color={item.live === true ? '' : 'danger'}
                                  onClick={() => updateLive(item.id, item.live === true ? false : true)} />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <ImageViewer entityType={'podcast'} entityId={item?.id} type='thumb@2x' cssClass='thumb' />
                        </td>
                        <td className='fw-bold'><LongTextView lines={TABLE_ROWS + 1}>{item.name}</LongTextView></td>
                        <td style={{ textAlign: 'center' }}>
                          <Icon name={`podcast_${item.type}`} size={16} />
                        </td>
                        <td>{item.duration}</td>
                        <CreditList data={item.credits} />
                        <LocationList data={item?.related_item} />
                        <td>{item.id}</td>
                        <td>{dateString(item.date_uploaded)}</td>
                        <TagList data={item.tags} />
                        <td>
                          <LongTextView lines={TABLE_ROWS + 1}>{item.description}</LongTextView>
                        </td>
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'playlist')} type='playlist' />
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'site')} type='site' />
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'person')} type='people' />
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'person')} type='tour' />
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'person')} type='events' />
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'person')} type='event_series' />

                        <td></td>
                        <td></td>
                        <td></td>
                        <RelatedEntityList data={item?.related_item?.filter((elem, index) => elem.source === 'map')} type='map' />
                        <td>{item?.edited_by}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className='d-flex justify-content-between m-3'>
              <div className='cols'>
                <Pagination page={page} take={take} count={searchResult?.nbHits} setPage={setPage} />
              </div>
            </div>
          </Spinner>
        </div>
      </div>
      {uploader && <PodcastUpload
        show={uploader}
        onHide={() => {
          // dispatch({ type: 'podcasts', payload: [] })
          showUploader(false)
        }
        } />}
      <ScrollToTop />
    </>
  );
};

export default Podcasts;