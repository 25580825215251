/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dragula from "react-dragula";
import uniqid from 'uniqid';
import TextEditor from '../../components/editors/textEditor';
import { handleReorder } from '../../helpers';
import Button from '../../components/buttons/default';
const _api = require('../../api')

const Reservations = (props) => {
    const dispatch = useDispatch();
    const event = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-reservation'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(event.reservations, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...event, reservations: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addReservations = () => {
        if (!event.reservations) event.reservations = [];
        let reservations_item = {
            'id': `temp.${uniqid()}`,
            'name': '',
            'url': '',
            'description': '',
            'sort': event.reservations?.length + 1,
        }
        event.reservations.push(reservations_item)
        setDragula(uniqid())
        event.reservations.forEach((item, index) => item.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...event, reservations: event.reservations } });
    }

    const removeReservations = async (index) => {
        if (event.reservations[index]) {
            if (!event.reservations[index].id.startsWith('temp.')) await _api.event.deleteReservation(event.reservations[index].id)
            event.reservations.splice(index, 1)
            event.reservations.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...event, reservations: event.reservations } });
        }
    }

    const handleEditorChange = (data, index) => {
        event.reservations[index].name = data
        dispatch({ type: 'entity', payload: { ...event, reservations: event.reservations } });
    }

    const handleInputChange = (name, data, index) => {
        if (name === 'link') event.reservations[index].url = data
        if (name === 'information') event.reservations[index].description = data
        dispatch({ type: 'entity', payload: { ...event, reservations: event.reservations } });
    }

    return (
        <>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Reservations</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add Reservation' onClick={() => addReservations()} />
                </div>
            </div>
            <div id={dragulaContainer} key={dragula} className='dragula-container'>
                {
                    event?.reservations?.length > 0 && event.reservations.map((item, index) => {
                        return (
                            <div className='row g-1 mt-1 dragula-item' key={index} id={item.id}>
                                {
                                    event?.reservations?.length > 1 &&
                                    <div className='col-auto' >
                                        {<label className='fw-bold'><small>Order</small></label>}
                                        <input type='text' id={`${item.name}, ${item.id}`} className='nwprime form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete="off" />
                                    </div>
                                }
                                <div className='col'>
                                    <div className='row'>
                                        <div className='col'>
                                            <TextEditor key={`reservation-${item.id}`} limit={25} toolbar={true} data={item?.name} onChange={(data) => handleEditorChange(data, index)} placeholder='Reservations Required. Book here' >
                                                <label className='fw-bold'><small>{'Reservation Name Text'}</small></label>
                                            </TextEditor>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <label className='fw-bold'><small>{'Link'}</small></label>
                                            <input name='link' type='text' className='form-control' placeholder='Select' value={item?.url || ''} onChange={({ target: { value } }) => handleInputChange('link', value, index)} autoComplete='off' />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <TextEditor limit={25} toolbar={true} data={item?.description} onChange={(data) => handleInputChange('information', data, index)} placeholder='Select' key={`information-${item.id}`} >
                                                <label className='fw-bold'><small>{'For More Information'}</small></label>
                                            </TextEditor>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    {<label className='cnow-form-label'></label>}
                                    <div className='btn-list'>
                                        <Button icon='delete' onClick={() => removeReservations(index)} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Reservations;