/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../components/buttons/default';
import ImageViewer from '../../components/viewers/image';
import EntityImages from '../../components/editors/entityImages';
import PageTitle from '../../components/pageTitle';
import FeaturedProjectImage from './featuredProjectImage';
import { useHistory } from 'react-router-dom';

const EventImages = (props) => {

    useEffect(() => {
        getAllRecipeImages()
    }, []);

    const dispatch = useDispatch()
    const event = useSelector(state => state[props.id])
    const [recipeImages, setrecipeImages] = useState([]);

    const location = useLocation()
    const history = useHistory()

    let currentImages = event ? event.images?.filter(x => x.type === 'image' && x.date_id === null) : []
    let currentDocuments = event ? event.images?.filter(x => x.type === 'document' && x.date_id === null) : []

    const editItems = (id) => {
        let imagesList = event.images.filter(image => image.type !== 'then_now')
        dispatch({ type: 'images', payload: { entityImages: imagesList, source: location.pathname, id: event.id, entityType: 'event' } })
        history.push(`/image/${id}/banner`)
    }

    const getAllRecipeImages = () => {
        let allRecipeImages = []
        if (event?.recipes) {
            for (let result of event?.recipes) {
                if (result?.images) {
                    for (let images of result?.images) {
                        allRecipeImages.push(images)
                    }
                }
            }
            setrecipeImages(allRecipeImages)
        }
    }
    return (
        <>
            {
                event && <>
                    <PageTitle title={event?.name} />

                    <div id='banner'>
                        <hr className='cnow-hr' />
                        <div className='row'>
                            <div className='col-auto'>
                                <div className='form-group'>
                                    <div className='cnow-form-title'>Set Banner Image *</div>
                                    <div onClick={() => editItems(event.image_id)} className='cursor-pointer'>
                                        {event?.images?.find(item => item.image_id === event?.image_id)?.image_url?.endsWith('.pdf') ?
                                            <ImageViewer
                                                url={event?.images?.find(item => item.image_id === event?.image_id)?.image_url}
                                                entityType={'image'}
                                                type='banner'
                                                cssClass='cnow-image-editor-banner'
                                            />
                                            :
                                            <ImageViewer
                                                entityId={event?.image_id}
                                                entityType={'image'}
                                                type='banner'
                                                cssClass='cnow-image-editor-banner'
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='d-flex gap-1 justify-content-end'>
                                    <Button icon='edit' label='Set Banner Image' onClick={() => editItems(event.image_id)} disabled={event.images?.length > 0 ? false : true} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className='cnow-hr' />
                    <div id='entity-image-sort'>

                        {
                            <EntityImages title={`Images *`} items={currentImages}
                                imageType='image'
                                entityType='event'
                                entityId={event.id}
                                buttonLabel={'Add a Image'}
                                offsetIndex={0}
                            />
                        }

                        {
                            <EntityImages title={`Drawing and Documents`} items={currentDocuments}
                                imageType='document'
                                entityType='event'
                                entityId={event.id}
                                buttonLabel={'Add a Document'}
                                offsetIndex={0}
                            />
                        }
                        <label className='cnow-form-title'>{`Recipe Photographs`}</label>
                        <FeaturedProjectImage id={props?.id} type='person' items={event.recipes ? recipeImages : []} title={'Person/Org Name'} />

                        <label className='cnow-form-title pt-3'>{`Featured Project Images`}</label>
                        <FeaturedProjectImage id={props?.id} type='site' items={event.featured_projects ? event.featured_projects.filter((elem) => elem.featured) : []} title={'Site Title'} />

                        <hr className='cnow-hr' />
                        <label className='cnow-form-title'>{`Participant Images`}</label>
                        <FeaturedProjectImage id={props?.id} type='person' items={event.participants ? event.participants : []} title={'Person/Org Name'} />
                    </div>

                </>
            }
        </>
    )
}


export default EventImages 
