import { ApiCall } from './utils'

//recipe
const getById = (id) => ApiCall('get', `/recipe/${id}`)

const search = (params) => ApiCall('post', `/recipe/search`, params)

const update = (params) => ApiCall('post', `/recipe`, params)

const deleteById = (id) => ApiCall('delete', `/recipe/${id}`)

const updateLive = (id, live) => ApiCall('put', `/recipe/${id}/live/${live}`)

//tag
const getTag = (id) => ApiCall('get', `/recipe/${id}/tag`)

const updateTag = (id, params) => ApiCall('post', `/recipe/${id}/tag`, params)

const deleteTag = (id) => ApiCall('delete', `/recipe/tag/${id}`)

//category
const getCategory = (id) => ApiCall('get', `/recipe/${id}/category`)

const updateCategory = (id, params) => ApiCall('post', `/recipe/${id}/category`, params)

const deleteCategory = (id) => ApiCall('delete', `/recipe/category/${id}`)

//theme
const getTheme = (id) => ApiCall('get', `/recipe/${id}/theme`)

const updateTheme = (id, params) => ApiCall('post', `/recipe/${id}/theme`, params)

const deleteTheme = (id) => ApiCall('delete', `/recipe/theme/${id}`)

//recipe_section

const getSection = (id) => ApiCall('get', `/recipe/${id}/section`)

const updateSection = (id, params) => ApiCall('post', `/recipe/${id}/section`, params)

const deleteSection = (id) => ApiCall('delete', `/recipe/section/${id}`)


//recipe_participants

const getPerson = (id) => ApiCall('get', `/recipe/${id}/person`)

//Related Sites Get and Related Site People
const getRelatedPerson = (id) => ApiCall('get', `/recipe/${id}/related_person`)

const getRelatedSite = (id) => ApiCall('get', `/recipe/${id}/related_site`)


//stories
const getStory = (id) => ApiCall('get', `/recipe/${id}/story`)

const updateStory = (id, params) => ApiCall('post', `/recipe/${id}/story`, params)

const deleteStory = (id) => ApiCall('delete', `/recipe/story/${id}`)


//get Related Tour

const getRelatedTour = (id) => ApiCall('get', `/recipe/${id}/related_tour`)

const deleteTour = (id) => ApiCall('delete', `/tour/recipe/${id}`)

//get Related map

const getRelatedMap = (id) => ApiCall('get', `/recipe/${id}/related_map`)


//image
const getImage = (id) => ApiCall('get', `/recipe/${id}/image`)

const updateImage = (id, params) => ApiCall('post', `/recipe/${id}/image`, params)

const deleteImage = (id) => ApiCall('delete', `/recipe/image/${id}`)

//podcast
const getPodcast = (id) => ApiCall('get', `/recipe/${id}/podcast`)

const updatePodcast = (id, params) => ApiCall('post', `/recipe/${id}/podcast`, params)

const deletePodcast = (id) => ApiCall('delete', `/recipe/podcast/${id}`)

//playlist
const getPlaylist = (id) => ApiCall('get', `/recipe/${id}/related_playlist`)

const deletePlaylist = (id) => ApiCall('delete', `/playlist/recipe/${id}`)

//eventseries
const getEventSeries = (id) => ApiCall('get', `/recipe/${id}/related_event_series`)

//event
const getEvent = (id) => ApiCall('get', `/recipe/${id}/related_event`)

//site
const getSite = (id) => ApiCall('get', `/recipe/${id}/related_site`)

//curator

const getCurator = (id) => ApiCall('get', `/recipe/${id}/curator`)

const updateCurator = (id, params) => ApiCall('post', `/recipe/${id}/curator`, params)


export default {
    getById,
    search,
    update,
    deleteById,
    updateLive,
    getTag,
    updateTag,
    deleteTag,
    getCategory,
    updateCategory,
    deleteCategory,
    getTheme,
    updateTheme,
    deleteTheme,


    getPerson,
    getRelatedPerson,
    getRelatedSite,

    getRelatedTour,
    deleteTour,
    getStory,
    updateStory,
    deleteStory,
    getRelatedMap,
    getImage,
    updateImage,
    deleteImage,
    getPodcast,
    updatePodcast,
    deletePodcast,
    getPlaylist,
    deletePlaylist,

    getSection,
    updateSection,
    deleteSection,

    getEventSeries,
    getEvent,
    getSite,

    getCurator,
    updateCurator
}
