import React from 'react'
import { Modal } from "react-bootstrap";
import Button from '../../components/buttons/default'
import { useCallback, useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import LongTextView from "../../components/viewers/longText";
import Spinner from "../../components/spinner";
import { default as ImageViewer } from "../../components/viewers/image";
import TagList from '../../components/table/tagList'
import LocationList from '../../components/table/locationList';

const _api = require('../../api');
const TourSearch = (props) => {
  const [spinner, showSpinner] = useState(false);
  const [keyword, setKeyword] = useState("");

  const [searchResult, setSearchResult] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [type, setType] = useState(props.type);
  const entity = useSelector(state => state[props.entityId])



  const search = useCallback(() => {
    showSpinner(true);

    const params = {
      keyword: keyword,
      hitsPerPage: 100,
      page: 0,
      facetFilters: ["deleted: false"],
    }
    _api.tour.search(params).then(response => {

      setSearchResult(response)
      showSpinner(false)
    })
  }, [keyword])

  useEffect(() => {
    search()
  }, [search]);
  const handleSave = (values) => {
    if (props.onSave) {
      props.onSave(values);
      props.onHide();
    }
  }

  const handleSelect = (item) => {
    let items = selectedItems
    if (items?.findIndex(x => x.id === item.id) >= 0) items = items.filter(x => x.id !== item.id)
    else items.push(item)

    setSelectedItems(items)
  };

  return (
    <Modal show={props.show} size='xl'  onHide={() => props.onHide(false)}>
      <Modal.Body>
        {!type && (
          <div>
            <div className='row'>
              <div className='col'>
                <div className='row'>
                  <div className='col p-1'><label className='cnow-form-title mb-0'>{`ADD A EVENT - TITLE ENTERED ON PAGE ONE`}</label></div>
                </div>
              </div>
              <div className='col-auto btn-list'>
                {<Button size='sm' icon='close' onClick={() => props.onHide()} />}
              </div>
            </div>
            <div className='d-flex  mt-5'>
              <Button size='md' className="ms-5 me-5" label='Add Tour from cultureNow database' onClick={() => setType("dataBase")} />
              <Button size='md' className="ms-5" label='Add new Tour' onClick={() => window.open('/tour/create')} />
            </div>
          </div>)}

        {type === "dataBase" && (
          <>
            <div className='d-flex justify-content-between'>
              <div className='cnow-form-title'>SEARCH FOR TOURS IN THE CULTURENOW MEDIA DATABASE</div>
              <div>
                <Button
                  size='sm'
                  label='Save'
                  color='light'
                  onClick={() => handleSave(selectedItems)}
                  className='me-2'
                />
                <Button
                  size='sm'
                  icon='close'
                  onClick={() => props.onHide(false)}
                />
              </div>
            </div>
            <hr />
            <div className='row g-1 mt-5'>
              <div className='col'>
                <input className='form-control bg-light border-start-0' type='text' placeholder='Search for tours from database...' value={keyword}
                  onChange={(event) => { setKeyword(event.target.value) }}
                  onKeyDown={(e) => { if (e.key === 'Enter') { search(); } }}
                  autoComplete="off"
                />
              </div>
              <div className='col-auto'>
                <div className='btn-list'>
                  <Button label='Submit' onClick={() => { search(); }} />
                </div>
              </div>
            </div>

            <Spinner display={spinner}>
              <div className='mt-2'>
                <table className='table small'>
                  <thead>
                    <tr className='bg-light small'>
                      <th style={{ width: "100px" }}>Thumbnail</th>
                      <th style={{ width: "150px" }}>Tour ID</th>
                      <th style={{ width: "150px" }}>Tour Name</th>

                      <th style={{ width: "100px" }}> Tour Type</th>
                      <th style={{ width: "100px" }}> Location</th>
                      {/* <th style={{ width: "100px" }}> Date and time </th> */}
                      {/* <th style={{ width: "100px" }}> People</th> */}
                      <th style={{ width: "100px" }}> Public Tag</th>
                      <th className='text-center'>Select Tour</th>
                    </tr>
                  </thead>

                  <tbody>
                    {searchResult && searchResult.hits && searchResult.hits.map((item, index) => {
                      const isChecked = selectedItems?.findIndex(x => x.podcast_id === item.id) >= 0;
                      return (
                        <tr key={index}>
                          <td>
                            <ImageViewer entityType={'podcast'} entityId={item?.id} type='thumb@2x' cssClass='thumb' />
                          </td>
                          <td>{item?.id}</td>
                          <td>
                            <LongTextView>{item.name}</LongTextView>
                          </td>
                          <td>{item?.type}</td>
                          <LocationList data={item.locations} />
                          <TagList data={item.tags} />
                          {/* <EventDateTime/> */}
                          <td>
                            <div className='form-check align d-flex justify-content-center'>
                              {
                                <input
                                  disabled={entity?.podcasts && entity?.podcasts.findIndex(x => x?.podcast_id === item?.id) >= 0 ? true : false}
                                  id={item?.id}
                                  className='form-check-input'
                                  type='checkbox' name='select_podcast' defaultChecked={isChecked}
                                  onChange={(e) => { handleSelect(item) }} />
                              }
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Spinner>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default TourSearch