/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';
import TourMenu from './menu';
import TourPreview from './preview';
import TourTitle from './title';
import TourAbout from './about'
import TourPeople from './people'
import TourPodcast from './podcast'
import Spinner from '../../components/spinner';
import TourMetaDescription from './meta';
import TourStops from './tour_stops';
import PathDuration from './path';
import TourImages from './image';
import OnThisDayInThisPhoto from './onThisDayInThisPhoto';
import RelatedMapEditor from '../../components/editors/relatedMapEditor';
import EventsTours from './eventsTours';
import TourCuratorManagement from './curator';
import Legal from '../../components/legal/editor';
const _api = require('../../api');

const TourEditor = (props) => {
    const id = props.match.params.id || 'NEW';
    const section = props.match.params.section || 'title';
    const sectionId = props.match.params.sectionId || '';
    const history = useHistory();
    const dispatch = useDispatch();
    const tour = useSelector(state => state[id]);
    const menuSize = useSelector((state) => state.menu);
    const previewSize = useSelector((state) => state.preview);
    const [spinner, showSpinner] = useState(false);

    useEffect(() => {
        if (id === 'NEW') {
            dispatch({
                type: 'entity', payload: {
                    'id': 'NEW',
                    'name': '',
                    'description': '',
                    'type': '',
                    'duration': '',
                    'meta': {},
                    'image_id': null,
                    'deleted': false,
                    'format': 2,
                    'live': false,
                }
            })
        } else {
            getTourData()
        }
    }, [id, sectionId, section]);

    const getTourData = async () => {
        showSpinner(true);
        let myTour = await _api.tour.getById(id)

        if (section === 'title' || !section) {
            myTour.themes = await _api.tour.getTheme(id)
            myTour.categories = await _api.tour.getCategory(id)
        }

        if (section === 'about') {
            myTour.tags = await _api.tour.getTag(id)
            myTour.related_sites = await _api.tour.getRelatedSite(id)
            myTour.stories = await _api.tour.getStory(id)
        }

        if (section === 'people') {
            myTour.created_by = await _api.tour.getCreatedBy(id)
            myTour.partner_org = await _api.tour.getPartnerOrg(id)
            myTour.tour_leader = await _api.tour.getTourLeader(id)
            myTour.related_people = await _api.tour.getRelatedPerson(id)
        }

        if (section === 'attach_map') {
            myTour.maps = await _api.tour.getRelatedMap(id)
        }

        if (section === 'tour_stops' || section === 'tour_map_editor' || section === 'path_duration') {
            myTour.stops = await _api.tour.getTourStops(id)
        }

        if (section === 'podcast') {
            myTour.stops = await _api.tour.getTourStops(id)
            myTour.podcasts = await _api.tour.getPodcast(id)
            myTour.playlists = await _api.tour.getRelatedPlaylist(id) || []
        }

        if (section === 'events_tours') {
            myTour.related_tours = await _api.tour.getTour(id) || []
            myTour.related_events = await _api.tour.getEvent(id) || []
        }


        if (section === 'on_this_day') {
            myTour.images = await _api.tour.getImage(id)
            myTour.on_this_day = await _api.tour.getOnThisDay(id) || []
        }

        if (section === 'images') {
            myTour.stops = await _api.tour.getTourStops(id)
            myTour.images = await _api.tour.getImage(id)
        }

        if (section === 'legal') {
            myTour.images = await _api.tour.getImage(id)
            myTour.podcasts = await _api.tour.getPodcast(id)
        }

        if (section === 'curator') {
            myTour.images = await _api.tour.getImage(id)
            myTour.podcasts = await _api.tour.getPodcast(id)
            myTour.curator = await _api.tour.getCurator(id)
        }

        dispatch({ type: 'entity', payload: myTour })
        showSpinner(false);
    }

    const updateAndRedirect = async (path) => {
        if (!path) {
            path = section;
            if (sectionId) path = path.concat('/', sectionId);
        }

        showSpinner(true);
        if (tour.id === 'NEW') tour.id = null;
        let myTour = await _api.tour.update(tour)

        if (section === 'title' || !section) {
            myTour.themes = await _api.tour.updateTheme(myTour.id, tour.themes)
            myTour.categories = await _api.tour.updateCategory(myTour.id, tour.categories)
        }

        if (section === 'about') {
            myTour.tags = await _api.tour.updateTag(id, tour.tags)

            if (myTour.related_sites?.length > 0) {
                let related_site = []
                for (const item of myTour.related_sites) {
                    let response = await _api.site.updateTour(item.site_id, [item])
                    related_site.push(response[0])
                }
                myTour.related_sites = related_site
            }

            myTour.stories = await _api.tour.updateStory(myTour.id, tour.stories)
        }

        if (section === 'people') {
            myTour.created_by = await _api.tour.updateCreatedBy(myTour.id, tour.created_by)
            myTour.partner_org = await _api.tour.updatePartnerOrg(myTour.id, tour.partner_org)
            myTour.tour_leader = await _api.tour.updateTourLeader(myTour.id, tour.tour_leader)

            if (tour.related_people?.length > 0) {
                let peopleList = []
                for (const item of tour.related_people) {
                    let response = await _api.person.updateTour(item.person_id, [item])
                    peopleList.push(response[0])
                }
                myTour.related_people = peopleList
            }
        }

        if (section === 'attach_map') {
            if (tour.maps?.length > 0) {
                let maps = [];
                for (const item of tour.maps) {
                    let response = await _api.map.updateTour(item.map_id, [item])
                    maps.push(response[0])
                }
                myTour.maps = maps
            }
        }

        if (section === 'tour_stops' || section === 'tour_map_editor') {
            if (tour.stops && tour.stops.length) {
                tour.stops.forEach((stop, stopIndex) => {
                    stop.sort = stopIndex + 1
                    if (stop.sites && stop.sites.length) {
                        stop.sites.forEach((site, siteIndex) => {
                            site.sort = siteIndex + 1
                        })
                    }
                })
            }
            myTour.stops = await _api.tour.updateTourStops(myTour.id, tour.stops)
        }

        if (section === 'podcast') {
            myTour.podcasts = await _api.tour.updatePodcast(myTour.id, tour.podcasts)

            if (myTour.playlists?.length > 0) {
                let playlists = []
                for (const item of myTour.playlists) {
                    let response = await _api.playlist.updateTour(item.playlist_id, [item])
                    playlists.push(response[0])
                }
                myTour.playlists = playlists
            }
        }

        if (section === 'events_tours') {
            myTour.related_tours = await _api.tour.updateTour(id, tour.related_tours)
            myTour.related_events = await _api.tour.updateEvent(id, tour.related_events)
        }

        if (section === 'on_this_day') {
            myTour.on_this_day = await _api.tour.updateOnThisDay(tour.on_this_day)
        }

        if (section === 'images') {
            myTour.images = await _api.tour.updateImage(myTour.id, tour.images)
        }

        if (section === 'curator') {
            myTour.curator = await _api.tour.updateCurator(myTour.id, tour.curator)
        }

        dispatch({ type: 'entity', payload: myTour })
        history.push(`/tour/${myTour.id}/editor/${path}`)
        showSpinner(false);
    }

    return (
        <div className='cnow-editor-container'>
            <div className={`cnow-editor-menu cnow-editor-menu-${menuSize}`}>
                <TourMenu id={id} section={section} sectionId={sectionId} onClick={(path) => updateAndRedirect(path)} display={spinner} />
            </div>
            <div className={`cnow-editor-content cnow-editor-content-${menuSize}-${previewSize}`}>
                <Spinner display={spinner}>
                    {section === 'title' && <TourTitle id={id} />}
                    {section === 'about' && <TourAbout id={id} />}
                    {section === 'people' && <TourPeople id={id} />}
                    {section === 'tour_stops' && <TourStops id={id} />}
                    {section === 'path_duration' && <PathDuration id={id} />}
                    {section === 'images' && <TourImages id={id} />}
                    {section === 'podcast' && <TourPodcast id={id} />}
                    {section === 'attach_map' && <RelatedMapEditor id={id} type={'tour'} />}
                    {section === 'events_tours' && <EventsTours id={id} />}
                    {section === 'meta' && <TourMetaDescription id={id} />}
                    {section === 'on_this_day' && <OnThisDayInThisPhoto id={id} onUpdate={(path) => updateAndRedirect(path)} />}
                    {section === 'legal' && <Legal id={id} />}
                    {section === 'curator' && <TourCuratorManagement id={id} />}
                </Spinner>
            </div>
            <div className={`cnow-editor-preview cnow-editor-preview-${previewSize}`}>
                <TourPreview id={id} onUpdate={() => updateAndRedirect()} spinner={spinner} section={section} locationId={sectionId} display={spinner} />
            </div>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAP_KEY,
    libraries: ['places', 'visualization', 'drawing', 'geometry'],
})(TourEditor);