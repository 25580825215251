import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import LegalHistory from "../../components/curator/legalHistory";
import PageTitle from "../../components/pageTitle";
import EntityOfDayCheckBox from "../../components/curator/entityOfDayCheckbox";
import CuratorTextField from "../../components/curator/textField";
import { newTempId } from "../../helpers";

const CuratorManagement = ({ id }) => {
    const event_series = useSelector(state => state[id]);
    return (
        <>
            <PageTitle title={event_series.name} />
            <TextFied id={id} key={id} />
            <LegalHistory id={id} type={'event_series'} />
            <EntityOfDayCheckBox type={'event_series'} id={id} entity_day={'Event Series'} show_entity_of_day={'not_show_event_series_of_the_day'} key={event_series?.curator?.not_show_event_series_of_the_day} />
        </>
    )
}

export default CuratorManagement

const TextFied = ({ id }) => {
    const event_series = useSelector(state => state[id]);
    const dispatch = useDispatch()
    const event_series_curator = event_series.curator
    const handleOnChange = (data, type) => {
        if (!event_series?.curator) event_series.curator = {}
        if (!event_series?.curator?.id) event_series.curator.id = newTempId()
        if (!event_series?.curator?.[type]) event_series.curator[type] = ''
        event_series.curator[type] = data
        dispatch({ type: 'entity', payload: { ...event_series, curator: { ...event_series.curator, [type]: event_series.curator[type] } } })
    }

    return (
        <>
            <CuratorTextField label={'DESCRIPTION'} title={'Remarks Attribution:'} subTitle={'Internal Notes (available only to users with admin access):'} handleMainInputChange={(e) => { handleOnChange(e, 'remarks_attribution') }} handleSubInputChange={(e) => { handleOnChange(e, 'internal_notes') }} mainInput={event_series_curator?.remarks_attribution || ''} subInput={event_series_curator?.internal_notes || ''} hrTrue />
        </>
    )
}