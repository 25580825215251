import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ImageView from '../../components/viewers/image';
import { dateString, getImageUrl } from '../../helpers';
import ImageCreditList from '../../components/preview/imageCreditList';

const _api = require('../../api')

const ImagePreview = (props) => {
    const history = useHistory();

    const image = useSelector(state => state[props.id]);
    // const images = useSelector((state) => state.images);
    const preview = () => {
        _api.image.update(image);
        let url = `/image/${image.id}/preview`;
        if (image.type === 'dzi') url = `/image/${image.id}/dzi`;
        if (image.type === 'then_now') url = `/image/${image.id}/then_now`;
        history.push({
            pathname: url
        })
    }

    const redirect = (path) => {
        _api.image.update(image).then(
            history.push({
                pathname: path
            })
        )
    }

    const getThenNowCredits = () => {
        let credits = []
        if (image.related_images?.length > 0) {
            image.related_images.forEach(item => {
                if (item.related_image_credits) credits.push(item.related_image_credits)
            })
        }
        return credits?.length > 0 ? credits.join(', ') : ''
    }

    return (
        <>
            {
                image &&
                <div className='cnow-image-editor-container'>
                    <div className='cnow-image-editor-thumb'>
                        <div className='row g-2'>
                            <div className='col-auto'>
                            {
                                image.url?.toLowerCase().endsWith('.pdf') ? (
                                    <>
                                    <div className='cnow-form-label'>Edit Thumbnail</div>
                                    <div onClick={() => { redirect(`/image/${image.id}/resize/thumb`); }}>
                                    <ImageView url={getImageUrl(image.url, 'thumb')} type='thumb' cssClass='cnow-image-editor-thumbnail' />
                                    </div>
                                    </>
                                ) : image.type === 'then_now' ? (
                                    <>
                                    <div className='cnow-form-label' onClick={() => props.onSave()}>Thumbnail</div>
                                    <ImageView url={getImageUrl(image.url, 'thumb')} type='thumb' cssClass='cnow-dzi-editor-thumbnail' />
                                    </>
                                ) : image.type === 'dzi' ? (
                                    <>
                                    <div className='cnow-form-label'>Edit Thumbnail</div>
                                    <div onClick={() => { redirect(`/image/${image.id}/resize/thumb`); props.onSave() }}>
                                        <ImageView url={getImageUrl(image.url, 'thumb')} type='thumb' cssClass='cnow-dzi-editor-thumbnail' />
                                    </div>
                                    </>
                                ) : (
                                    <>
                                    <div className='cnow-form-label'>Edit Thumbnail</div>
                                    <div onClick={() => { redirect(`/image/${image.id}/resize/thumb`); props.onSave() }}>
                                        <ImageView url={getImageUrl(image.url, 'thumb')} type='thumb' cssClass='cnow-image-editor-thumbnail' />
                                    </div>
                                    </>
                                )
                            }

                            </div>
                            <div className='col-auto'>
                                {
                                    image.url?.toLowerCase().endsWith('.pdf') ? <>
                                        <div className='cnow-form-label'>Edit Banner</div>
                                        <div onClick={() => { redirect(`/image/${image.id}/resize/banner`) }}>
                                            <ImageView url={image.url} type='banner' cssClass='cnow-image-editor-banner' />
                                        </div>
                                    </> :
                                        (image.type === 'then_now') ? (
                                            <>
                                                <div className='cnow-form-label'>Banner</div>
                                                <ImageView url={image.url} type='banner' cssClass='cnow-dzi-editor-banner ' />
                                            </>
                                        ) :
                                         image.type === 'dzi' ?
                                            <>
                                                <div className='cnow-form-label'>Edit Banner</div>
                                                <div onClick={() => { redirect(`/image/${image.id}/resize/banner`); props.onSave() }}>
                                                    <ImageView url={image.url} type='banner' cssClass='cnow-dzi-editor-banner ' />
                                                </div>
                                            </>
                                            : (
                                                <div className='d-flex'>
                                                    <div>
                                                        <div className='cnow-form-label'>Edit Banner</div>
                                                        <div onClick={() => { redirect(`/image/${image.id}/resize/banner`); props.onSave() }}>
                                                            <ImageView url={image.url} type='banner' cssClass='cnow-image-editor-banner' />
                                                        </div>
                                                    </div>
                                                    {/* <div>
                                                        {images?.type &&
                                                            <div className='px-2'>
                                                                <div className='cnow-form-label'>Headshot</div>
                                                                <div onClick={() => { redirect(`/image/${image.id}/resize/headshot`); props.onSave() }}>
                                                                    <ImageView url={image.url} type='headshot' cssClass='cnow-image-editor-banner' />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div> */}
                                                </div>
                                            )
                                }
                            </div>

                            <div className='col-auto'>
                            {
                                image.url?.toLowerCase().endsWith('.pdf') ? (
                                    <>
                                    <div className='cnow-form-label'>Edit Headshot</div>
                                    <div onClick={() => { redirect(`/image/${image.id}/resize/headshot`); }}>
                                    <ImageView url={getImageUrl(image.url, 'headshot')} type='headshot' cssClass='cnow-image-editor-banner' />
                                    </div>
                                    </>
                                ) : image.type === 'then_now' ? (
                                    <>
                                    </>
                                ) : image.type === 'dzi' ? (
                                    <>
                                    <div className='cnow-form-label'>Edit Headshot</div>
                                    <div onClick={() => { redirect(`/image/${image.id}/resize/headshot`); props.onSave() }}>
                                        <ImageView url={getImageUrl(image.url, 'headshot')} type='headshot' cssClass='cnow-image-editor-banner' />
                                    </div>
                                    </>
                                ) : (
                                    <>
                                    <div className='cnow-form-label'>Edit Headshot</div>
                                    <div onClick={() => { redirect(`/image/${image.id}/resize/headshot`); props.onSave() }}>
                                        <ImageView url={getImageUrl(image.url, 'headshot')} type='headshot' cssClass='cnow-image-editor-banner' />
                                    </div>
                                    </>
                                )
                            }

                            </div>
                        </div>
                    </div>

                    <div className='cnow-form-label mt-2'>Full Image Preview and Image Editor</div>
                    <div className='border'>
                        <div className='cnow-image-editor-image-container' 
                            onClick={() => {if(image.type !== "document") {
                                                preview(); props.onSave()
                                            } else { 
                                                window.open(image.url, '_blank')
                                    } }}>
                            {
                                // image.url?.toLowerCase().endsWith('.pdf') ? 
                                // <div>
                                //     <ImageView url={getImageUrl(image.url, 'thumb@2x')} type='thumb@2x' cssClass='cnow-image-editor-image' />
                                // </div> 
                                // : 
                                <ImageView url={getImageUrl(image.url, image?.type ==='then_now' ? 'web':'original')} type='original' cssClass='cnow-image-editor-image' />
                            }
                        </div>
                        <div className='bg-light p-2 small'>
                            <div className='d-flex justify-content-between'>
                                <div className='cnow-form-label'>{(image.show_taken_date && dateString(image?.taken_date))}{image.show_taken_date && dateString(image?.taken_date) && image?.caption ? '-' : ''}{image?.caption}</div>

                                {image.type === 'then_now'
                                    ? <div>© {getThenNowCredits()}</div>
                                    : <ImageCreditList data={image.credits} />}

                            </div>
                            <div className='cnow-ellipsis-2' dangerouslySetInnerHTML={{ __html: image?.description }} />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ImagePreview;