import React from 'react';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import { personName } from '../../helpers';

import MetaDescriptionEditor from '../../components/editors/metaDescriptionEditor';
import MetaKeywordsEditor from '../../components/editors/metaKeywordsEditor';
import MetaTitleEditor from '../../components/editors/metaTitleEditor';

const PeopleMetaDescription = (props) => {
  const person = useSelector(state => state[props.id]);

  return (
    <>
      {
        person && <>
          <PageTitle title={personName(person)} />
          <MetaDescriptionEditor id={props.id} type='people' />
          <MetaKeywordsEditor id={props.id} type='people' />
          <MetaTitleEditor id={props.id} type='people' />
        </>
      }
    </>
  );
};

export default PeopleMetaDescription;