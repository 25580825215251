import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import uniqid from 'uniqid';
import Dragula from "react-dragula";
import { ReorderSpecialFeature } from "../helpers";
import SelectRecipe from "./selects/recipe";
import SelectGlobalList from "./selects/globalList";
import Button from "./buttons/default";
import LinkButton from "./buttons/link";
import PreviewButton from "./buttons/preview";
const _api = require('../api')


const SpecialFeature = (props) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const entity = useSelector((state) => state[props.id]);
    const getRecipeType = async () => {
        let res = await _api.global_list.getAll('recipe_type')
        setData(res)
        setDragula(uniqid())
    }
    useEffect(() => {
        getRecipeType()
        // eslint-disable-next-line
    }, [])
    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-special-feature'

    useEffect(() => {
        let containers = []
        if (data && data?.length > 0) data.forEach((type) => containers.push(document.getElementById(`${dragulaContainer}-${type.id}`)))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = ReorderSpecialFeature(entity.special_features, data, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, special_features: items } })

        })
        // eslint-disable-next-line
    }, [dragula])
    // Dragula End
    return (
        <>
            {
                data.length > 0 && data.map((data, index) => {
                    return (
                        <EntitySpecialFeature id={props.id} refresh={() => setDragula(uniqid())} title={data.name} type_id={data.id} dragulaContainer={`${dragulaContainer}-${data.id}`} dragula={dragula} recipe_role={props.recipe_role} type={props.type} />
                    )
                })
            }
        </>
    )
}

export default SpecialFeature


const EntitySpecialFeature = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);
    const addSpecialFeature = () => {
        if (!entity.special_features) entity.special_features = [];
        let special_feature_item = {
            'id': `temp.${uniqid()}`,
            'recipe_id': '',
            'recipe_name': '',
            'profession_name': '',
            'profession_id': null,
            'role_name': '',
            'role_id': '',
            'type_id': props.type_id,
            'type_name': props.title,
            'sort': entity.special_features?.length + 1,
        }
        entity.special_features.push(special_feature_item)
        entity.special_features.forEach((item, index) => item.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...entity, special_features: entity.special_features } });
        props.refresh()
    }

    const removeSpecialFeature = async (id) => {
        let index = entity.special_features.findIndex((elem) => elem.id === id)
        if (entity.special_features[index]) {
            if (!entity.special_features[index].id.startsWith('temp.')) await _api[props.type].deleteRecipe(entity.special_features[index].id)
            entity.special_features.splice(index, 1)
            entity.special_features.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...entity, special_features: entity.special_features } });
        }
    }

    const handleRecipeRole = (id, value) => {
        let index = entity.special_features.findIndex((elem) => elem.id === id)
        entity.special_features[index].role_id = value.id;
        entity.special_features[index].role_name = value.name;
        dispatch({ type: 'entity', payload: { ...entity, special_features: entity.special_features } })
        props.refresh()
    };

    const handleRecipe = (id, value) => {
        let index = entity.special_features.findIndex((elem) => elem.id === id)
        entity.special_features[index].recipe_id = value.id;
        entity.special_features[index].recipe_name = value.name;
        dispatch({ type: 'entity', payload: { ...entity, special_features: entity.special_features } })
        props.refresh()
    };

    return (

        <>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>{props.title}</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a Recipe' onClick={() => addSpecialFeature()} />
                </div>
            </div>
            <div id={props.dragulaContainer} key={props.dragula} className='dragula-container'>
                {
                    entity?.special_features?.length > 0 && entity?.special_features.filter((elem) => elem?.type_id === props?.type_id).map((item, index) => {
                        return (
                            <div className='row g-1 mt-1 dragula-item' key={index} id={item.id}>
                                {
                                    entity?.special_features?.length > 1 &&
                                    <div className='col-auto' >
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Order </label>}
                                        <input type='text' id={`${item.recipe_name}, ${item.recipe_id}`} className='nwprime form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete="off" />
                                    </div>
                                }
                                <div className='col-auto d-flex flex-grow-1'>
                                    <div className='w-100 ms-2'>
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Recipe Name</label>}
                                        <SelectRecipe key={item.id} value={{ id: item.recipe_id, name: item.recipe_name }} className="prime" onChange={(value) => handleRecipe(item.id, value)} type_id={item?.type_id} />
                                    </div>
                                    {props.recipe_role && <div className='w-100 ms-2'>
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Role</label>}
                                        <SelectGlobalList key={item.id} value={{ id: item.role_id, name: item.role_name }} className="prime" onChange={(value) => handleRecipeRole(item.id, value)} type={`recipe_role`} />
                                    </div>}
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label'></label>}
                                    <div className='btn-list'>
                                        <PreviewButton size='md' link={`recipe/${item.recipe_id}`} />
                                        <LinkButton icon='edit' to={`/recipe/${item.recipe_id}/editor`} />
                                        <Button icon='delete' onClick={() => removeSpecialFeature(item.id)} />
                                    </div>
                                </div>
                            </div>
                        )

                    })
                }
            </div>
        </>
    )


}