import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid'
import RelatedTourEditor from '../../components/editors/relatedTourEditor';
import RelatedEventEditor from '../../components/editors/eventEditor';
import PageTitle from '../../components/pageTitle';
import RelatedEventSeriesEditor from '../../components/editors/relatedEventSeriesEditor';

const EventsTours = (props) => {
    const site = useSelector(state => state[props.id]);
    const [key, setKey] = useState(uniqid())
    return (
        <>
            <PageTitle title={site.name} />
            <RelatedTourEditor id={site.id} type={'site'} key={`related-tour-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
            <RelatedEventEditor id={site.id} type={'site'} key={`related-event-editor-${key}`} refresh={() => { setKey(uniqid()) }} remarksEditor={true} />
            <RelatedEventSeriesEditor   id={site.id} type={'site'} key={`related-event_series -editor-${key}`} refresh={() => { setKey(uniqid()) }}/>
            
        </>
    );
};

export default EventsTours;