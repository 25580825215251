import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy } from 'lodash';
import Spinner from '../../components/spinner';
import EntityOfTheDay from '../../components/featured/preview/entityOfTheDay';
import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';
import FeaturedDescription from '../../components/featured/preview/description';
import FeaturedOnthisDay from '../../components/featured/preview/onThisDay';
import FeaturedInThisPhoto from '../../components/featured/preview/inThisPhoto';
import FeaturedWhatHappenedHere from '../../components/featured/preview/whatHappenedHere';
import FeaturedCollectionStaicImage from '../../components/featured/preview/collectionStaticImages';
import EntityTimeLine from '../../components/featured/entityTimeLine';
const _api = require('../../api');

const FeaturePreview = (props) => {
	const dispatch = useDispatch();
	const previewSize = useSelector((state) => state.preview);
	const togglePreviewSize = () => dispatch({ type: 'preview', payload: previewSize === 'sm' ? 'lg' : 'sm' })
	const [spinner, showSpinner] = useState(false);
	const featured = useSelector((state) => state.featured);
	const start_date = featured && featured.start_date && JSON.parse(featured.start_date)
	const end_date = featured && featured.end_date && JSON.parse(featured.end_date)
	const start = featured && featured.start_date;
	const end = featured && featured.end_date;

	useEffect(() => {
		if (featured?.start_date && featured?.end_date && start_date && end_date && end_date !== "1970-01-01" && !featured.source && (start === end)) { getData(); }
		if (start_date && end_date && end_date !== "1970-01-01" && !featured.source && featured?.end_date && (start !== end)) { dispatch({ type: 'featured', payload: { start_date: start, end_date: end, source: '', [props.section]: [] } }); }
		// eslint-disable-next-line 
	}, [featured?.start_date, featured?.end_date, start_date, end_date, props.section])

	useEffect(() => {
		showSpinner(props.display)
	}, [props.display])

	const handleMultipleIds = (response) => {
		let data = []
		response.forEach((elem) => {
			data.push(elem.data.site_of_the_day)
			elem.data.site_of_the_day = []
		})
		response[0].edited = true;
		response[0].data.site_of_the_day = data.flat()
		return response
	}

	const getData = async () => {
		showSpinner(true);
		let params = {
			"section": '',
			"start_date": start_date.slice(0, 10),
			"end_date": end_date.slice(0, 10)
		}
		let response = await _api.featured.getFeatured(params)
		let data = response?.length > 0 ? groupBy(response, response => response.section) : {}
		if (!data[props.section]) data[props.section] = []
		if (data?.site_of_the_day && data?.site_of_the_day?.length > 0) {
			data.site_of_the_day = handleMultipleIds(data.site_of_the_day)
		}
		data.start_date = start
		data.end_date = end
		dispatch({ type: 'featured', payload: { ...data } })
		showSpinner(false);
	}
	return (
		<>
			<>
				{
					previewSize === 'sm' && <div className='btn-grid'>
						<Button size='sm' color='danger' icon='save-white' onClick={() => { props.onUpdate(); props.onSave() }} disabled={(!props.display && props.section) ? false : true} />
						<PreviewButton size='sm' link={`index`} />
						{previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
						{previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

						<Button size='sm' color='light' icon='help' />
					</div>
				}

				{
					previewSize === 'lg' && <div className='btn-list action-bar btn-preview-pan'>
						<Button size='sm' color='light' label='Instructions' icon='help' />
						{true ? (<Button size='sm' color='light' label='Live' onClick={() => { }} />) :
							(<Button size='sm' color='danger' label='Not Live' onClick={() => { }} />)}
						<Button size='sm' color='danger' label='Save' onClick={() => { props.onUpdate(); props.onSave() }} disabled={(!props.display && props.section) ? false : true} />
						<PreviewButton size='sm' link={`index`} />
						{previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
						{previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}
					</div>
				}
			</>

			<div className='preview'>
				{
					previewSize === 'lg' &&
					<>
						<div className='cnow-bg-red text-white mt-2 fs-5 fw-bold'>PREVIEW</div>
						<Spinner display={spinner}>
							<EntityOfTheDay featured={featured} />
							<FeaturedDescription featured={featured} />
							<div className='mt-3 cnow-text-primary fw-bold'>
								<div className='fs-6'>culture<span className='fs-5'>NOW</span></div>
								<div className='fs-6'>Welcome to the Museum Without Walls</div>
							</div>
							<FeaturedOnthisDay featured={featured} />
							<FeaturedInThisPhoto featured={featured} />
							<FeaturedWhatHappenedHere featured={featured} />
							<FeaturedCollectionStaicImage featured={featured} id={'whats_in_the_collection'} />
							<FeaturedCollectionStaicImage featured={featured} id={'explore_the_collection'} />
							{featured?.places_over_time && featured?.places_over_time?.length > 0 && <div className='pt-4'>
								<div className='w-100 cnow-text-primary cnow-bg-grey fw-bold'>{`PLACES OVER TIME`}</div>
								<EntityTimeLine />
							</div>}
							<FeaturedCollectionStaicImage featured={featured} id={'participate'} />

						</Spinner>
					</>
				}
			</div>
		</>
	);
}

export default FeaturePreview