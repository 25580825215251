import React from 'react';

const Spinner = ({ display, children, fullScreen }) => {
  return (
    <>
      {
        display ? (
          <div className={`text-center ${fullScreen && 'vh-100'}`}>
            <div className='spinner-border text-danger m-3' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <>{children}</>
        )
      }
    </>
  );
};

export default Spinner;
