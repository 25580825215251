import React, { useState } from "react";
import CollectionImage from "../../preview/collectionImage";

const CollectionStaicImage = ({ featured, id }) => {
    const [data] = useState(require('../../../data/featured_preview_collection.json'))
    const info = data.filter((elem) => elem.id === id)[0]
    const imagesInfo = info.data.map((elem) => {
        return {
            title: `${featured[elem.path]?.[0]?.data?.title || elem.title}`,
            url: `${featured[elem.path]?.[0]?.data?.[elem.path]?.[0]?.url || featured[elem.path]?.[0]?.data?.[elem.path]?.[0]?.image?.url}`
        }
    })
    return (
        <>
            {
                <CollectionImage
                    title={info.Label}
                    data={imagesInfo}
                />
            }
        </>
    )
}

export default CollectionStaicImage