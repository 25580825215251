/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dragula from "react-dragula";
import uniqid from 'uniqid';
import { handleReorder } from '../../helpers';
import Button from '../../components/buttons/default';
import LinkButton from '../../components/buttons/link';
import PreviewButton from '../../components/buttons/preview';
import SelectPerson from '../../components/selects/person';
import SelectGlobalList from '../../components/selects/globalList';

const _api = require('../../api')


const EventParticipants = (props) => {
    const dispatch = useDispatch();
    const event = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-participants'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(event.participants, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...event, participants: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addParticipants = () => {
        if (!event.participants) event.participants = [];
        let participants_item = {
            'id': `temp.${uniqid()}`,
            'person_first_name': '',
            'person_last_name': '',
            'person_id': null,
            'person_name': '',
            'profession_id': null,
            'profession_name': '',
            'role_name': '',
            'role_id': null,
            'is_primary': false,
            'sort': event.participants?.length + 1,
        }
        participants_item[`${props.type}_id`] = event.id
        event.participants.push(participants_item)
        setDragula(uniqid())
        event.participants.forEach((item, index) => item.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...event, participants: event.participants } });
    }

    const removeParticipants = async (index) => {
        if (event.participants[index]) {
            if (!event.participants[index].id.startsWith('temp.')) await _api[props.type].deleteParticipant(event.participants[index].id)
            event.participants.splice(index, 1)
            event.participants.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...event, participants: event.participants } });
        }
    }

    const handleParticipantsChange = (index, value) => {
        event.participants[index].person_id = value.id;
        event.participants[index].person_name = value.name;
        event.participants[index].profession_name = value.role_name;
        event.participants[index].profession_id = value.role_id;
        dispatch({ type: 'entity', payload: { ...event, participants: event.participants } })
    };

    const handleEventRoleChange = (index, value) => {
        event.participants[index].role_id = value.id;
        event.participants[index].role_name = value.name;
        dispatch({ type: 'entity', payload: { ...event, participants: event.participants } });
    }

    const handlePrimaryChange = (index) => {
        event.participants[index].is_primary = !event.participants[index].is_primary
        dispatch({ type: 'entity', payload: { ...event, participants: event.participants } });
    }


    return (
        <>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Participants *</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add a Person' onClick={() => addParticipants()} />
                </div>
            </div>
            <div id={dragulaContainer} key={dragula} className='dragula-container'>
                {
                    event?.participants?.length > 0 && event.participants.map((item, index) => {
                        return (
                            <div className='row g-1 mt-1 dragula-item' key={index} id={item.id}>
                                {
                                    event?.participants?.length > 1 &&
                                    <div className='col-auto' >
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Order</label>}
                                        <input type='text' id={`${item.person_name}, ${item.person_id}`} className='nwprime form-control form-control-sort' disabled name='sort' placeholder={item.sort} autoComplete="off" />
                                    </div>
                                }
                                <div className='col-auto d-flex flex-grow-1'>
                                    <div className='w-100'>
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Event Role *</label>}
                                        <SelectGlobalList key={item.id} value={{ id: item.role_id, name: item.role_name }} className="prime" onChange={(value) => handleEventRoleChange(index, value)} type={'event_person_role'} />

                                    </div>
                                    <div className='w-100 ms-2'>
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Person *</label>}
                                        <SelectPerson key={item.id} value={item} className="prime" onChange={(value) => handleParticipantsChange(index, value)} entityType='person' type='event_participant' />
                                    </div>
                                    <div className='w-100 ms-2'>
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Profession *</label>}
                                        <input value={item?.profession_name} disabled className='w-100 cnow-input-disabled-height' />
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label'></label>}
                                    <div className='btn-list'>
                                        <Button icon={`${item.is_primary ? 'star-red' : 'star-black'}`} onClick={() => handlePrimaryChange(index)} />
                                        <PreviewButton link={`people/${item.person_id}`} />
                                        <LinkButton icon='edit' to={`/people/${item.person_id}/editor`} />
                                        <Button icon='delete' onClick={() => removeParticipants(index)} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default EventParticipants;