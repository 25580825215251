/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dragula from 'react-dragula';
import uniqid from 'uniqid';
import Select from 'react-select';
import { handleReorder, customSelectStyle } from '../../helpers';
import Button from '../../components/buttons/default';

const currency = require('../../data/event_currency.json')
const _api = require('../../api')

const Tickets = (props) => {
    const dispatch = useDispatch();
    const event = useSelector(state => state[props.id]);

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-tickets'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(event.tickets, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...event, tickets: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addTickets = () => {
        if (!event.tickets) event.tickets = [];
        let tickets_item = {
            'id': `temp.${uniqid()}`,
            'category': '',
            'currency': '$ USD',
            'price': null,
            'sort': event.tickets?.length + 1,
        }
        event.tickets.push(tickets_item)
        setDragula(uniqid())
        event.tickets.forEach((item, index) => item.sort = index + 1)
        dispatch({ type: 'entity', payload: { ...event, tickets: event.tickets } });
    }

    const removeTickets = async (index) => {
        if (event.tickets[index]) {
            if (!event.tickets[index].id.startsWith('temp.')) await _api.event.deleteTicket(event.tickets[index].id)
            event.tickets.splice(index, 1)
            event.tickets.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...event, tickets: event.tickets } });
        }
    }

    const handleInputChange = (name, data, index) => {
        if (name === 'category') event.tickets[index].category = data
        if (name === 'price') event.tickets[index].price = data
        dispatch({ type: 'entity', payload: { ...event, tickets: event.tickets } });
    }

    const handleChange = (e, index) => {
        event.tickets[index].currency = e.value
        dispatch({ type: 'entity', payload: { ...event, tickets: event.tickets } });
    }

    return (
        <>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Tickets</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add Category' onClick={() => addTickets()} />
                </div>
            </div>
            <div id={dragulaContainer} key={dragula} className='dragula-container'>
                {
                    event?.tickets?.length > 0 && event.tickets.map((item, index) => {
                        return (
                            <div className='row g-1 mt-1 dragula-item' key={index} id={item.id}>
                                {
                                    event?.tickets?.length > 1 &&
                                    <div className='col-auto' >
                                        {index === 0 && <label className='fw-bold'><small>Order</small></label>}
                                        <input type='text' id={`${item.id}`} className='nwprime form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete='off' />
                                    </div>
                                }
                                <div className='col'>
                                    {index === 0 && <label className='fw-bold'><small>{'Audience Category'}</small></label>}
                                    <input name='category' type='text' className='form-control' placeholder='xxx' value={item?.category || ''} onChange={({ target: { value } }) => handleInputChange('category', value, index)} autoComplete='off' />
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='fw-bold'><small>{'Currency'}</small></label>}
                                    <Select cacheOptions defaultOptions
                                        styles={customSelectStyle}
                                        options={currency}
                                        value={{
                                            value: item?.currency,
                                            label: item?.currency
                                        }}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='fw-bold'><small>{'Price'}</small></label>}
                                    <input name='price' type='number' className='form-control' placeholder='xxx' value={item?.price || ''} onChange={({ target: { value } }) => handleInputChange('price', value, index)} autoComplete='off' />
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label'></label>}
                                    <div className='btn-list'>
                                        <Button icon='delete' onClick={() => removeTickets(index)} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Tickets;