import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import uniqid from 'uniqid'
import TagEditor from '../../components/editors/tagEditor';
import TextEditor from '../../components/editors/textEditor';

import SelectSite from '../../components/selects/site';
import SelectPerson from '../../components/selects/person';
import SelectPlaylist from '../../components/selects/playlist';
import SelectMap from '../../components/selects/map';
import SelectTour from '../../components/selects/tour';
import SelectEvent from '../../components/selects/event';
import SelectRecipe from '../../components/selects/recipe';
import SelectEventSeries from '../../components/selects/eventSeries';
import SelectGlobalList from '../../components/selects/globalList';
import TextAreaEditor from '../../components/editors/textAreaEditor';

import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';
import LinkButton from '../../components/buttons/link';
import { customSelectStyle, newTempId, personName } from '../../helpers';

const _api = require('../../api')
const relatedItemTypes = require('../../data/related_item_types.json');

const PodcastForm = (props) => {
	const dispatch = useDispatch();

	const podcast = useSelector(state => state[props.id]);

	const handleInputChange = ({ target: { name, value } }) => {
		podcast[name] = value;
		dispatch({ type: 'entity', payload: { ...podcast, [name]: value } });
	};

	//#region Podcast Credits
	const addPodcastCredit = () => {
		if (!podcast.credits) podcast.credits = [];
		podcast.credits.push({
			'id': `temp.${uniqid()}`,
			'podcast_id': podcast.id,
			'person_id': null,
			'person_name': '',
			'person_first_name': '',
			'person_last_name': '',
			'role_id': null,
			'role_name': '',
			'sort': podcast.credits ? podcast.credits.length + 1 : 1
		});

		podcast.credits.forEach((item, idx) => item.sort = idx + 1)
		dispatch({ type: 'entity', payload: { ...podcast, credits: podcast.credits } });
	}

	const removePodcastCredit = async (id) => {
		let index = podcast.credits.findIndex(x => x.id === id)

		if (index >= 0) {
			if (!podcast.credits[index].id.startsWith('temp.'))
				await _api.podcast.deleteCredit(podcast.credits[index].id)
			podcast.credits.splice(index, 1)
			podcast.credits.forEach((item, idx) => item.sort = idx + 1)
			dispatch({ type: 'entity', payload: { ...podcast, credits: podcast.credits } });
		}
	}

	const handleCreditChange = (type, id, value) => {
		let index = podcast.credits.findIndex(x => x.id === id)
		if (type === 'person') {
			podcast.credits[index].person_name = value.name
			podcast.credits[index].person_id = value.id
			podcast.credits[index].is_participant = value.is_participant || false
		}
		if (type === 'role') {
			podcast.credits[index].role_name = value.name
			podcast.credits[index].role_id = value.id
		}
		dispatch({ type: 'entity', payload: { ...podcast, credits: podcast.credits } });
	};
	//#endregion

	//#region Related Items
	const addRelatedItem = () => {
		if (!podcast.related_items) podcast.related_items = [];
		podcast.related_items.push({
			'row_id': newTempId(),
			'id': null,
			'name': '',
			'source': '',
		});

		dispatch({ type: 'entity', payload: { ...podcast, related_items: podcast.related_items } });
	}

	const removeRelatedItem = async (index) => {
		if (podcast.related_items[index]) {
			if (!podcast.related_items[index].row_id?.startsWith('temp.'))
				await _api[podcast.related_items[index].source].deletePodcast(podcast.related_items[index].row_id)

			podcast.related_items.splice(index, 1)
			dispatch({ type: 'entity', payload: { ...podcast, related_items: podcast.related_items } });
		}
	}

	const handleRelatedItemTypeChange = (index, value) => {
		podcast.related_items[index].source = value.value;
		dispatch({ type: 'entity', payload: { ...podcast, related_items: podcast.related_items } });
	}

	const handleRelatedItemChange = async (index, value, entity) => {
		podcast.related_items[index].id = value.id
		podcast.related_items[index].name = value.name
		podcast.related_items[index].images = value.images
		podcast.related_items[index].is_participant = value.is_participant || false
		let params = {
			id: podcast.related_items[index].row_id,
			podcast_id: podcast.id,
			date_id: null,
		}
		if (entity === 'event') params.type = 'event_trailer_recording'
		let response = await _api[podcast.related_items[index].source].updatePodcast(value.id, [params])
		podcast.related_items[index].row_id = response ? response[0].id : podcast.related_items[index].row_id;
		dispatch({ type: 'entity', payload: { ...podcast, related_items: podcast.related_items } });
	};
	//#endregion

	const handleEditorChange = (data) => {
		podcast.description = data;
		dispatch({ type: 'entity', payload: { ...podcast, description: podcast.description } });
	}

	const getRouteName = (item) => {
		if (item.source === 'person') {
			return !item.is_participant ? 'people' : 'participant'
		}
		else return item.source
	}
	return (
		<div className='cnow-left-menu'>
			<div className='cnow-form-title'>Edit Podcast Information *</div>
			<div id='caption_description'>
				<span className='cnow-form-label'>Title *</span>
				<input name='name' type='text' className='form-control' value={podcast?.name || ''} onChange={handleInputChange} />
				<TextEditor data={podcast?.description || ''} toolbar={true} onChange={(data) => handleEditorChange(data)} autoComplete="off">
					<label className='cnow-form-label mt-2'>Description *</label>
				</TextEditor>
			</div>

			<div id='podcast_credits'>
				<hr className='cnow-hr' />
				<div className='d-flex justify-content-between'>
					<div className=''>
						<label className='cnow-form-title'>Podcast Credits *</label>
					</div>
					<div className=''>
						<Button size='sm' icon='plus' label='Add one more' onClick={() => addPodcastCredit()} />
					</div>
				</div>
				{podcast?.credits?.map((item, index) => {
					return (
						<div className='row g-1 mt-1' key={index}>
							<div className={podcast?.credits?.length > 1 ? 'col-auto' : 'd-none'}>
								{index === 0 && <label className='cnow-form-label'>Order</label>}
								<input type='text' className='form-control form-control-sort' disabled name='sort' value={index + 1} autoComplete="off" />
							</div>
							<div className='col-auto d-flex flex-grow-1'>
								<div className='w-75'>
									{index === 0 && <label className='cnow-form-label'>Role</label>}
									<SelectGlobalList type='podcast_credit' value={{ id: item.role_id, name: item.role_name }} onChange={(value) => handleCreditChange('role', item.id, value)} />
								</div>

								<div className='w-100 ms-2'>
									{index === 0 && <label className='cnow-form-label'>Name</label>}
									<SelectPerson value={{ id: item.person_id, name: personName(item), 'is_participant': item.is_participant }} onChange={(value) => handleCreditChange('person', item.id, value)} />
								</div>
							</div>
							<div className='col-auto'>
								{index === 0 && <label className='cnow-form-label'></label>}
								<div className='btn-list'>
									<PreviewButton link={`${!item.is_participant ? 'people' : 'participant'}/${item.person_id}`} />
									<LinkButton icon='edit' to={`/${!item.is_participant ? 'people' : 'participant'}/${item.person_id}/editor`} />
									<Button icon='delete' onClick={() => removePodcastCredit(item.id)} />
								</div>
							</div>
						</div>
					)
				})}
			</div>

			<div id='move_podcast'>
				<hr className='cnow-hr' />
				<div className='cnow-form-title'>Move podcast to a different section</div>
			</div>

			<div id='related_items'>
				<hr className='cnow-hr' />
				<div className='d-flex justify-content-between'>
					<div className=''>
						<label className='cnow-form-title'>Related Item</label>
					</div>
					<div className=''>
						<Button size='sm' icon='plus' label='Add one more' onClick={() => addRelatedItem()} />
					</div>
				</div>

				<div className='d-flex mt-1'>
					<div className={podcast?.related_items?.length > 1 ? '' : 'd-none'} style={{ width: '50px' }}>
						<label className='cnow-form-label'>Order</label>
					</div>
					<div className='flex-grow-1 d-flex'>
						<div className='w-75 ms-1'>
							<label className='cnow-form-label'>Type</label>
						</div>
						<div className='w-100 ms-1'>
							<label className='cnow-form-label'>Name</label>
						</div>
					</div>
					<div className='text-end' style={{ width: '130px' }}>
						<label className='cnow-form-label'></label>
					</div>
				</div>
				{podcast?.related_items?.map((item, index) => {
					return (
						<div className='d-flex mt-1' key={index}>
							<div className={podcast?.related_items?.length > 1 ? '' : 'd-none'}>
								{/* {index === 0 && <label className='cnow-form-label'>Order</label>} */}
								<input type='text' className='form-control form-control-sort' disabled name='sort' value={index + 1} autoComplete="off" />
							</div>
							<div className='flex-grow-1 d-flex'>
								<div className='w-75 ms-1'>
									{/* {index === 0 && <label className='cnow-form-label'>Type</label>} */}
									{!item?.row_id?.startsWith('temp.') ?
										<input value={item.source} disabled className='cnow-input-height w-100' /> :
										<Select name='type'
											options={relatedItemTypes}
											styles={customSelectStyle}
											value={{ 'value': item.source, 'label': item.source }}
											onChange={(value) => { handleRelatedItemTypeChange(index, value) }} placeholder={<div className='text-muted'>MM</div>} />}
								</div>

								<div className='w-100 ms-1'>
									{/* {index === 0 && <label className='cnow-form-label'>Name</label>} */}
									{!item?.row_id?.startsWith('temp.') ?
										<input value={item.name} disabled className='cnow-input-height w-100' />
										:
										<>
											{item.source === '' &&
												<Select styles={customSelectStyle} />}
											{item.source === 'site' &&
												<SelectSite value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
											{item.source === 'person' &&
												<SelectPerson value={{ 'id': item.id, 'name': item.name, 'is_participant': item.is_participant }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
											{item.source === 'playlist' &&
												<SelectPlaylist value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
											{item.source === 'map' &&
												<SelectMap value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
											{item.source === 'tour' &&
												<SelectTour value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
											{item.source === 'event' &&
												<SelectEvent value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value, 'event') }} />}
											{item.source === 'event_series' &&
												<SelectEventSeries value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} />}
											{item.source === 'recipe' &&
												<SelectRecipe value={{ 'id': item.id, 'name': item.name }} onChange={(value) => { handleRelatedItemChange(index, value) }} type_id='' />}
										</>}

								</div>
							</div>
							<div className='text-end ms-1'>
								{/* {index === 0 && <label className='cnow-form-label'></label>} */}
								<div className='d-flex gap-1 justify-content-end'>
									<PreviewButton link={`${getRouteName(item)}/${item.id}`} />
									<LinkButton icon='edit' to={`/${getRouteName(item)}/${item.id}/editor`} />
									<Button icon='delete' onClick={() => removeRelatedItem(index)} />
								</div>
							</div>
						</div>
					)
				})}
			</div>

			<TagEditor id={props.id} type='podcast' />

			<div id='alt_text'>
				<hr className='cnow-hr' />
				<TextAreaEditor id={podcast?.id} title='Alt Text' subTitle='(Additional Description for Image recognition)' data={podcast?.alt_text || ''} />
			</div>
		</div>
	)
}

export default PodcastForm;