import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageViewer from "./viewers/image";
import PreviewButton from './buttons/preview';
import LinkButton from './buttons/link'
import { useHistory, useLocation } from 'react-router-dom';
import Button from "./buttons/default";
import { personName } from '../helpers';
const _api = require('../api');

const TaggedImageViewer = ({ data, index, id }) => {
    const dispatch = useDispatch();

    const entity = useSelector(state => state[id]);
    const [tags, setTags] = useState({});
    const location = useLocation()
    const history = useHistory()
    useEffect(() => {

        getImage();
        // eslint-disable-next-line
    }, [])

    const getImage = async () => {
        setTags(await _api.image.getEntityTag(data.image_id));
    }

    const editItems = (id) => {
        dispatch({ type: 'images', payload: { source: location.pathname } })
        history.push(`/image/${id}/editor`)
    }

    const HandleLive = (data, status) => {
        const Index = entity.images.findIndex((el) => data.id === el.id)
        entity.images[Index].in_this_photo_live = status;
        dispatch({ type: 'entity', payload: { ...entity, images: entity.images } })

    }

    return (
        <>
            {
                tags?.length > 0 && <>
                    <div className='row g-2'>
                        <div className='col-auto'>
                            <label className='cnow-form-label fw-bold'>Order</label>
                            <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete="off" />
                        </div>
                        <div className='col-auto'>
                            <div className='fw-bold'>&nbsp;</div>
                            <ImageViewer key={data.id} entityType='image' entityId={data.image_id} type='thumb' cssClass='thumb-1x' />
                        </div>
                        <div className='col'>
                            <label className='cnow-form-label fw-bold'>Caption - Description</label>
                            <input value={data.image_caption} disabled className='w-100 cnow_onThisDay' />
                        </div>
                        <div className='col-auto btn-list align-self-end'>
                            <div className="then_now_buttons">
                                {data?.in_this_photo_live === true ? <Button size='live' icon='live' onClick={() => { HandleLive(data, false) }} />
                                    : <Button size='live' icon='not-live' color='danger' onClick={() => { HandleLive(data, true) }} />}
                                <PreviewButton size='sm' link={`image/${data.image_id}`} />
                                <Button size='sm' icon='edit' onClick={() => editItems(data.image_id)} />
                            </div>
                        </div>
                    </div>
                    {
                        tags.map((tag, subIndex) => {
                            return (
                                <div key={tag.id} className='row g-2 mt-2 then_now_margin'>
                                    <div className='col-auto'>
                                        <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={subIndex + 1} autoComplete="off" />
                                    </div>
                                    <div className='col-auto'>
                                        {tag.site_id && <ImageViewer key={tag.id} entityType='site' entityId={tag.site_id} type='thumb' cssClass='thumb' />}
                                        {tag.person_id && <ImageViewer key={tag.id} entityType='person' entityId={tag.person_id} type='thumb' cssClass='thumb' />}
                                    </div>
                                    <div className='col'>
                                        <input value={tag.site_name || personName(tag)} disabled className='w-100' />
                                    </div>
                                    <div className='col-auto btn-list'>
                                        <PreviewButton size='sm' link={`${tag.site_id ? 'site' : `${!tag.is_participant ? 'people' : 'participant'}`}/${tag.site_id || tag.person_id}`} />
                                        <LinkButton size='sm' icon='edit' to={`/${tag.site_id ? 'site' : `${!tag.is_participant ? 'people' : 'participant'}`}/${tag.site_id || tag.person_id}/editor`} />
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
            }
        </>
    );
}
export default TaggedImageViewer;