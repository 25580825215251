
import { ApiCall } from './utils'

//site
const getById = (id) => ApiCall('get', `/site/${id}`)

const search = (params) => ApiCall('post', `/site/search`, params)

const update = (params) => ApiCall('post', `/site`, params)

const deleteById = (id) => ApiCall('delete', `/site/${id}`)

const updateLive = (id, live) => ApiCall('put', `/site/${id}/live/${live}`)

//Award
const getAward = (id) => ApiCall('get', `/site/${id}/award`)

const updateAward = (id, params) => ApiCall('post', `/site/${id}/award`, params)

const deleteAward = (id) => ApiCall('delete', `/site/award/${id}`)

//category
const getCategory = (id) => ApiCall('get', `/site/${id}/category`)

const updateCategory = (id, params) => ApiCall('post', `/site/${id}/category`, params)

const deleteCategory = (id) => ApiCall('delete', `/site/category/${id}`)

//date
const getDate = (id) => ApiCall('get', `/site/${id}/date`)

const updateDate = (id, params) => ApiCall('post', `/site/${id}/date`, params)

const deleteDate = (id) => ApiCall('delete', `/site/date/${id}`)

//designation
const getDesignation = (id) => ApiCall('get', `/site/${id}/designation`)

const updateDesignation = (id, params) => ApiCall('post', `/site/${id}/designation`, params)

const deleteDesignation = (id) => ApiCall('delete', `/site/designation/${id}`)

//image
const getImage = (id) => ApiCall('get', `/site/${id}/image`)

const updateImage = (id, params) => ApiCall('post', `/site/${id}/image`, params)

const deleteImage = (id) => ApiCall('delete', `/site/image/${id}`)

//location
const getLocation = (id) => ApiCall('get', `/site/${id}/location`)

const updateLocation = (id, params) => ApiCall('post', `/site/${id}/location`, params)

const deleteLocation = (id) => ApiCall('delete', `/site/location/${id}`)

//map
const getMap = (id) => ApiCall('get', `/site/${id}/map`)

const updateMap = (id, params) => ApiCall('post', `/site/${id}/map`, params)

const deleteMap = (id) => ApiCall('delete', `/site/map/${id}`)

//person
const getPerson = (id) => ApiCall('get', `/site/${id}/person`)

const updatePerson = (id, params) => ApiCall('post', `/site/${id}/person`, params)

const deletePerson = (id) => ApiCall('delete', `/site/person/${id}`)

//podcast
const getPodcast = (id) => ApiCall('get', `/site/${id}/podcast`)

const updatePodcast = (id, params) => ApiCall('post', `/site/${id}/podcast`, params)

const deletePodcast = (id) => ApiCall('delete', `/site/podcast/${id}`)

//RelatedPerson
const getRelatedPerson = (id) => ApiCall('get', `/site/${id}/related_person`)

const updateRelatedPerson = (id, params) => ApiCall('post', `/site/${id}/related_person`, params)

const deleteRelatedPerson = (id) => ApiCall('delete', `/site/related_person/${id}`)

//RelatedSite
const getRelatedSite = (id) => ApiCall('get', `/site/${id}/related_site`)

const updateRelatedSite = (id, params) => ApiCall('post', `/site/${id}/related_site`, params)

const deleteRelatedSite = (id) => ApiCall('delete', `/site/related_site/${id}`)

//stories
const getStory = (id) => ApiCall('get', `/site/${id}/story`)

const updateStory = (id, params) => ApiCall('post', `/site/${id}/story`, params)

const deleteStory = (id) => ApiCall('delete', `/site/story/${id}`)

//tag
const getTag = (id) => ApiCall('get', `/site/${id}/tag`)

const updateTag = (id, params) => ApiCall('post', `/site/${id}/tag`, params)

const deleteTag = (id) => ApiCall('delete', `/site/tag/${id}`)

//theme
const getTheme = (id) => ApiCall('get', `/site/${id}/theme`)

const updateTheme = (id, params) => ApiCall('post', `/site/${id}/theme`, params)

const deleteTheme = (id) => ApiCall('delete', `/site/theme/${id}`)

//playlist
const getPlaylist = (id) => ApiCall('get', `/site/${id}/playlist`)

const updatePlaylist = (id, params) => ApiCall('post', `/site/${id}/playlist`, params)

const deletePlaylist = (id) => ApiCall('delete', `/site/playlist/${id}`)

//tours
const getTour = (id) => ApiCall('get', `/site/${id}/tour`)

const updateTour = (id, params) => ApiCall('post', `/site/${id}/tour`, params)

const deleteTour = (id) => ApiCall('delete', `/site/tour/${id}`)

// Associated Sites
const getAssociatedSites = (id) => ApiCall('get', `/site/${id}/associated_site`)
const getAssociatedSiteDate = (id) => ApiCall('get', `/site/${id}/associated_site/date`)
const getAssociatedSitePerson = (id) => ApiCall('get', `/site/${id}/associated_site/person`)
const updateAssociatedSite = (id, params) => ApiCall('post', `/site/${id}/associated_site`, params)
const processAssociatedSite = (id) => ApiCall('post', `/site/${id}/associated_site/process`)

//Get By PersonId
const getByPersonId = (id) => ApiCall('get', `/site?people=${id}&show_all=true`)

// Get By Related PersonId
const getByRelatedPersonId = (id) => ApiCall('get', `/site?related_people=${id}`)

//Get By Date PersonId
const getByDatePersonId = (id) => ApiCall('get', `/site?date_people=${id}&show_all=true`)

const getOnThisDay = (id) => ApiCall('get', `/site/on_this_day/${id}`)

const updateOnThisDay = (params) => ApiCall('post', `/site/on_this_day`, params)

const updateListOnThisDay = (site_id, id, value) => ApiCall('put', `/site/${site_id}/on_this_day/${id}/live/${value}`)

const deleteOnThisDay = (id) => ApiCall('delete', `/site/on_this_day/${id}`)

//Automated Playlist 

const automatedPlaylist = (id) => ApiCall('post', `/site/${id}/playlist/sync`, '')

//site Event
const getEvent = (id) => ApiCall('get', `/site/${id}/event `)

const updateEvent = (id, params) => ApiCall('post', `/site/${id}/event `, params)

const deleteEvent = (id) => ApiCall('delete', `/site/event/${id}`)

//site Event
const getEventSeries = (id) => ApiCall('get', `/site/${id}/event_series `)

const updateEventSeries = (id, params) => ApiCall('post', `/site/${id}/event_series `, params)

const deleteEventSeries = (id) => ApiCall('delete', `/site/event_series/${id}`)

//recipe

const getSiteRecipe = (id) => ApiCall('get', `/site/${id}/recipe`)

const updateSiteRecipe = (id, params) => ApiCall('post', `/site/${id}/recipe`, params)

const deleteRecipe = (id) => ApiCall('delete', `/site/recipe/${id}`)

const validateVanity = (params) => ApiCall('post', `/site/validateVanity`, params)

//curator

const getCurator = (id) => ApiCall('get', `/site/${id}/curator`)

const updateCurator = (id, params) => ApiCall('post', `/site/${id}/curator`, params)

export default {
    getById,
    search,
    update,
    deleteById,
    updateLive,

    getByPersonId,
    getByDatePersonId,
    getByRelatedPersonId,

    getDate,
    updateDate,
    deleteDate,

    getLocation,
    updateLocation,
    deleteLocation,

    getStory,
    updateStory,
    deleteStory,

    getCategory,
    updateCategory,
    deleteCategory,

    getAward,
    updateAward,
    deleteAward,

    getDesignation,
    updateDesignation,
    deleteDesignation,

    getPerson,
    updatePerson,
    deletePerson,

    getTag,
    updateTag,
    deleteTag,

    getRelatedSite,
    updateRelatedSite,
    deleteRelatedSite,

    getRelatedPerson,
    updateRelatedPerson,
    deleteRelatedPerson,

    getImage,
    updateImage,
    deleteImage,

    getTheme,
    updateTheme,
    deleteTheme,

    getPodcast,
    updatePodcast,
    deletePodcast,

    getPlaylist,
    updatePlaylist,
    deletePlaylist,

    getMap,
    updateMap,
    deleteMap,

    getTour,
    updateTour,
    deleteTour,

    getAssociatedSites,
    getAssociatedSiteDate,
    getAssociatedSitePerson,
    updateAssociatedSite,
    processAssociatedSite,

    getOnThisDay,
    updateOnThisDay,
    updateListOnThisDay,
    deleteOnThisDay,
    automatedPlaylist,

    getEvent,
    updateEvent,
    deleteEvent,

    getEventSeries,
    updateEventSeries,
    deleteEventSeries,


    getSiteRecipe,
    updateSiteRecipe,
    deleteRecipe,
    validateVanity,

    getCurator,
    updateCurator

}