import React from "react";
import Button from "../buttons/default";
import { newTempId } from "../../helpers";
import LinkButton from "../buttons/link";
const _api = require('../../api')

const Program = (props) => {
    const item = props.item
    const category_id = props.category_id
    const list_id = props.list_id
    const parent_id = props.id
    const parent_name = props.name
    const programs = props.programs ? props?.programs.filter((elem) => elem.type === list_id) : []
    const handleOnClick = () => {
        item.has_children = true
        _api.global_list.create(item.type, item)
        _api.global_list.create(list_id, {
            name: 'New Item',
            parent: parent_id,
            parent_name: parent_name,
            alias: `${parent_name} New Item`,
            id: newTempId(),
            has_children: false,
            type: list_id
        }).then(response => {
            window.location = `/global-list/${list_id}/${category_id}/${parent_id}/${response.id}/editor`;
        })
    }

    return (
        <>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>{props.title}</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add Program' onClick={() => handleOnClick()} />
                </div>
            </div>

            {
                programs.map((item, index) => {
                    return (
                        <div key={index} className={`${index > 0 ? 'mt-4' : ''}`} id={item.id}>
                            <div className='row g-1'>
                                {
                                    programs?.length > 1 &&
                                    <div className='col-auto'>
                                        <label className='cnow-form-label fw-bold'>Order</label>
                                        <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete='off' />
                                    </div>
                                }
                                <div className='col'>
                                    <div className='row g-1'>
                                        <div className='col-auto d-flex flex-grow-1'>
                                            <div className='w-100'>
                                                <label className='cnow-form-label fw-bold'>Award*</label>
                                                <input value={item?.name} disabled className='w-100 cnow-input-disabled-height' />
                                            </div>
                                        </div>
                                        <div className='col-auto'>
                                            <label className='cnow-form-label'></label>
                                            <div className='btn-list'>
                                                <LinkButton icon='edit' to={`/global-list/${list_id}/${category_id}/${parent_id}/${item.id}/editor`} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }


        </>
    )
}
export default Program