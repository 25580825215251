/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from 'uniqid'
import Select from 'react-select';
import ImageViewer from "../../components/viewers/image";
import PageTitle from '../../components/pageTitle';
import SelectSite from '../../components/selects/site';
import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';
import LinkButton from '../../components/buttons/link';
import Spinner from '../../components/spinner';
import { customSelectStyle, personName } from '../../helpers';
import axios from 'axios';

const _api = require('../../api');

const AssociatedSites = (props) => {
    const dispatch = useDispatch();

    const site = useSelector(state => state[props.id]);
    const [spinner, showSpinner] = useState(false);

    const [associatedSiteDate, setAssociatedSiteDate] = useState([])
    const [associatedSitePerson, setAssociatedSitePerson] = useState([])

    const getData = async () => {
        showSpinner(true)
        const associatedSiteDate = await _api.site.getAssociatedSiteDate(props.id)
        setAssociatedSiteDate(associatedSiteDate)
        const associatedSitePerson = await _api.site.getAssociatedSitePerson(props.id)
        setAssociatedSitePerson(associatedSitePerson)
        showSpinner(false)
    }

    useEffect(() => {
        if (site.has_associated_site) getData()
    }, [props.id]);

    const handleIsApplicable = async ({ target: { checked, dataset } }) => {
        showSpinner(true)
        // Update has_associated_site
        site.has_associated_site = checked
        await _api.site.update(site)
        await _api.site.processAssociatedSite(props.id)
        let sites = await _api.site.getAssociatedSites(props.id)
        dispatch({ type: 'entity', payload: { ...site, associated_sites: sites, has_associated_site: checked } });
        showSpinner(false)
    }

    const addSite = () => {
        let sites = site.associated_sites
        sites.push({
            'id': `temp.${uniqid()}`,
            'site_id': site.id,

            'associated_site_id': null,
            'associated_site_name': '',

            'date_id': null,
            'date_year': '',

            'person_id': null,
            'person_name': '',

            'deleted': false,
            'manual': true
        });

        dispatch({ type: 'entity', payload: { ...site, associated_sites: sites } })
    }

    const removeSite = async (index) => {
        let sites = site.associated_sites
        if (sites[index]) {
            if (!sites[index].id.startsWith('temp.'))
                await axios.delete(`/associated_site/${sites[index].id}`)

            sites.splice(index, 1)
            sites.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...site, associated_sites: sites } })
        }
    }

    const handleSiteChange = async (index, value) => {
        let sites = site.associated_sites
        sites[index].associated_site_id = value.id
        sites[index].associated_site_name = value.name

        sites[index].date_id = null
        sites[index].date_year = ''

        sites[index].person_id = null
        sites[index].person_name = ''

        let selectedSite = await _api.site.getById(value.id)
        if (selectedSite.people?.length > 0) {
            sites[index].person_id = selectedSite.people[0].person_id
            sites[index].person_name = personName(selectedSite.people[0])
            let associatedPersonArr = associatedSitePerson
            selectedSite.people.forEach(person => {
                associatedPersonArr.push({
                    associated_site_id: selectedSite.id,
                    person_id: person.person_id,
                    person_name: personName(person)
                })
            })
            setAssociatedSitePerson(associatedPersonArr)
        }
        if (selectedSite.dates?.length > 0) {
            sites[index].date_id = selectedSite.dates[0].id
            sites[index].date_year = selectedSite.dates[0].year
            let associatedDateArr = associatedSiteDate
            selectedSite.dates.forEach(date => {
                associatedDateArr.push({
                    associated_site_id: selectedSite.id,
                    date_id: date.id,
                    date_year: date.year
                })
            })
            setAssociatedSiteDate(associatedDateArr)
        }

        dispatch({ type: 'entity', payload: { ...site, associated_sites: sites } })
    };

    const peopleOptions = (associated_site_id) => {
        let items = [];

        associatedSitePerson.filter(x => x.associated_site_id === associated_site_id).forEach(x => {
            if (x.person_id && personName(x)) items.push({ value: x.person_id, label: personName(x) })
        })

        return items;
    }

    const yearOptions = (associated_site_id) => {
        let items = [];

        associatedSiteDate.filter(x => x.associated_site_id === associated_site_id).forEach(x => {
            if (x.date_id && x.date_year) items.push({ value: x.date_id, label: x.date_year })
        })

        return items;
    }

    const handleYearChange = (id, data) => {
        let sites = site.associated_sites
        const index = sites.findIndex(x => x.associated_site_id === id)
        if (index >= 0) {
            sites[index].date_id = data.value
            sites[index].date_year = data.label
            dispatch({ type: 'entity', payload: { ...site, associated_sites: sites } })
        }
    }

    const handlePersonChange = (id, data) => {
        let sites = site.associated_sites
        const index = sites.findIndex(x => x.associated_site_id === id)
        if (index >= 0) {
            sites[index].person_id = data.value
            sites[index].person_name = data.label
            dispatch({ type: 'entity', payload: { ...site, associated_sites: sites } })
        }
    }

    return (
        <Spinner display={spinner}>
            <PageTitle title={site?.name} />

            <hr className='cnow-hr' />

            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Associated sites at that location</label>
                </div>
                <div className='col-auto'>
                    <div className='form-check'>
                        <input className='form-check-input' name='date_descriptions' type='checkbox' defaultChecked={site?.has_associated_site} onChange={handleIsApplicable} />
                        <label className='small'>If applicable</label>
                    </div>
                    {
                        site?.has_associated_site &&
                        <Button size='sm' icon='plus' label='Add a Site' onClick={() => addSite()} />
                    }
                </div>
            </div>
            {
                site.has_associated_site && site.associated_sites?.filter(x => x.deleted === false).map((item, index) => {
                    const selectedYear = { value: item.date_id, label: item.date_year }
                    const selectedPerson = { value: item.person_id, label: item.person_name }
                    const siteIndex = site.associated_sites?.findIndex(x => x.id === item.id)
                    return (
                        <div key={index} className='mt-2'>
                            <div className='row g-1'>
                                {
                                    site.associated_sites?.length > 0 &&
                                    <div className='col-auto'>
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Order</label>}
                                        <input type='text' className='form-control form-control-sort' disabled name='sort' value={index + 1} autoComplete="off" />
                                    </div>
                                }
                                <div className='col-auto'>
                                    {index === 0 && <div className=''>&nbsp;</div>}
                                    <ImageViewer entityType='site' entityId={item.associated_site_id} type='thumb' cssClass='thumb-small' />
                                </div>
                                <div className='col'>
                                    <div className='w-100'>
                                        {index === 0 && <label className='cnow-form-label fw-bold'>Site Title *</label>}
                                        {!item.id.startsWith('temp.') ? <input value={item?.associated_site_name} className='form-control' disabled /> : <SelectSite value={{ id: item.associated_site_id, name: item.associated_site_name }} onChange={(value) => handleSiteChange(siteIndex, value)} />}
                                    </div>
                                </div>
                                <div className='col'>
                                    {index === 0 && <label className='cnow-form-label fw-bold'>Year *</label>}
                                    <Select key={item.associated_site_id}
                                        styles={customSelectStyle}
                                        options={yearOptions(item.associated_site_id)}
                                        defaultValue={selectedYear}
                                        onChange={(data) => handleYearChange(item.associated_site_id, data)}
                                    />
                                </div>
                                <div className='col'>
                                    <div className='w-100'>
                                        {index === 0 && <label className='cnow-form-label fw-bold'>People *</label>}
                                        <Select key={item.associated_site_id}
                                            styles={customSelectStyle}
                                            options={peopleOptions(item.associated_site_id)}
                                            defaultValue={selectedPerson}
                                            onChange={(data) => handlePersonChange(item.associated_site_id, data)}
                                        />
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    {index === 0 && <label className='cnow-form-label'>&nbsp;</label>}
                                    <div className='btn-list'>
                                        <PreviewButton link={`site/${item?.associated_site_id}`} />
                                        <LinkButton icon='edit' to={`/site/${item?.associated_site_id}/editor`} />
                                        <Button icon='delete' onClick={() => removeSite(siteIndex)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </Spinner>
    );
}

export default AssociatedSites;