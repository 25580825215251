import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';

import DescriptionEditor from '../../components/editors/descriptionEditor';
import TagEditor from '../../components/editors/tagEditor';
import StoryEditor from '../../components/editors/storyEditor';
import QuoteEditor from '../../components/editors/quoteEditor';
import { personName } from '../../helpers';
import uniqid from 'uniqid'

const PeopleDescription = (props) => {
  const person = useSelector(state => state[props.id]);
  const [key, setKey] = useState(uniqid())
  return (
    <>
      <PageTitle title={personName(person)} />
      <DescriptionEditor id={props.id} type='person' refresh={() => setKey(uniqid())} label={'About'} />
      <TagEditor id={props.id} type='person' />
      <StoryEditor id={props.id} type='person' key={`story-editor-${key}`} refresh={() => { setKey(uniqid()) }} />
      {person?.type === 'person' && <QuoteEditor id={props.id} type='person' name='Quote' key={`quote-editor-${key}`} refresh={() => { setKey(uniqid()) }} />}
      {person?.type === 'publication' && <QuoteEditor id={props.id} type='person' name='Excerpt' key={`quote-editor-${key}`} refresh={() => { setKey(uniqid()) }} />}
    </>
  );
};

export default PeopleDescription;