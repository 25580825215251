/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import Button from "./buttons/default";
import ImageViewer from "./viewers/image";
import { useSelector, useDispatch } from 'react-redux';
import { dateString, personName } from '../helpers'
import InlineImageList from '../components/inlineImageList';
import PreviewButton from "./buttons/preview";

const _api = require('../api');

const OnThisDay = (props) => {

    const dispatch = useDispatch()
    const [imageModal, setImageModal] = useState(false);
    const [editItem, setEditItem] = useState({})

    const entity = useSelector(state => state[props.id]);

    const [selectedImage, setSelectedImage] = useState(null);
    const sortOrder = ['Date', 'Story', 'Award', 'Designation', 'Highlight']
    const entityType = props.type

    const handleImageSelect = () => {
        setImageModal(false);
        const Index = entity.on_this_day.findIndex((el) => el.id === editItem.id)
        entity.on_this_day[Index].image_id = selectedImage.id;
        dispatch({ type: 'entity', payload: { ...entity, on_this_day: entity.on_this_day } });
    }

    const handleLive = (item, status) => {
        const Index = entity.on_this_day.findIndex((el) => item.id === el.id)
        entity.on_this_day[Index].live = status;
        dispatch({ type: 'entity', payload: { ...entity, on_this_day: entity.on_this_day } });
    }

    const getType = (value) => {
        let type = ''
        if (entityType === 'site') {
            if (value.award_id) type = 'Award'
            else if (value.designation_id) type = 'Designation'
            else if (value.story_id) type = 'Story'
            else type = 'Date'
        } else if (entityType === 'person') {
            if (value.award_id) type = 'Award'
            else if (value.highlight_id) type = 'Highlight'
            else if (value.story_id) type = 'Story'
            else type = 'Date'
        } else {
            if (value.story_id) type = 'Story'
            else type = 'Date'
        }
        return type;
    }

    const handleClick = (value) => {
        let url
        if (props.type === 'site') {
            if (value === 'date') url = value;
            if (value === 'award' || value === 'designation') url = 'awards_designations'
            if (value === 'story') url = 'description'
        }
        else if (props.type === 'person') {
            if (value === 'date') url = value;
            if (value === 'award') url = 'awards_highlights'
            if (value === 'story') url = 'about'
        }
        else if (props.type === 'map') {
            if (value === 'date') url = value;
            if (value === 'story') url = 'about'
        }
        props.onUpdate(url)
    }

    const getEntityName = () => {
        if (props.type === 'person') return personName(entity)
        return entity.name;
    }

    const getTitle = (type) => {
        let title = type;
        if (type === 'Story')
            title = 'Factoid';
        if (type === 'Designation' && props.type === 'person')
            title = 'Highlights'
        return title;
    }

    const getDescription = (item) => {
        return item[`${getType(item)?.toLowerCase()}_description`];
    }

    const getName = (item) => {
        let type = getType(item);
        if (type === 'Date') {
            return item.event_type_name;
        }
        return item[`${type?.toLowerCase()}_name`];
    }

    (() => { entity?.on_this_day?.length > 0 && entity.on_this_day.sort((a, b) => sortOrder.indexOf(getType(a)) - sortOrder.indexOf(getType(b))) })();

    return (
        <>
            <hr className='cnow-hr' />
            <label className='cnow-form-title'>On This Day/Month/Year</label>
            {
                entity?.on_this_day?.length > 0 && entity.on_this_day.map((item, index) => {
                    return (
                        <div className='py-2'>
                            <div className="d-flex">
                                <div style={{ width: '160px' }}>
                                    <label className='cnow-form-subtitle'>{getTitle(getType(item))}</label>
                                </div>
                                <div >
                                    <label className='cnow-form-label fw-bold'>Caption - Description</label>
                                </div>
                            </div>
                            <div key={index} className='row g-2'>
                                <div className='col-auto'>
                                    <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete="off" />
                                </div>
                                <div className='col-auto'>
                                    <ImageViewer key={item.id} entityType='image' entityId={item.image_id || entity.image_id} type='thumb' cssClass='thumb-1x' />
                                </div>
                                <div className='col'>

                                    <div className='px-1 cnow_onThisDay w-100' onClick={() => handleClick(getType(item).toLowerCase())}>
                                        <span className='fw-bold' >
                                            {dateString(item) && <><span>{dateString(item)}</span> - </>}<span>{getEntityName()}</span>{getName(item) && <> - <span>{getName(item)}</span></>}
                                        </span>
                                        <span dangerouslySetInnerHTML={{ __html: getDescription(item) }} />
                                    </div>
                                </div>
                                <div className='col-auto btn-list align-self-end'>
                                    <div className="then_now_buttons">
                                        {item.live ? <Button size='live' icon='live' onClick={() => { handleLive(item, false) }} /> :
                                            <Button size='live' icon='not-live' color='danger' onClick={() => { handleLive(item, true) }} />}
                                        <Button size='sm' icon='edit' onClick={() => { setImageModal(true); setEditItem(item) }} />
                                        <PreviewButton size='sm' link={`image/${item.image_id}`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            <div id='banner' className='row'>
                {imageModal &&
                    <Modal show={imageModal} fullscreen={props.fullscreen} size='xl' onHide={() => setImageModal(false)} backdrop='static' >
                        <Modal.Body>
                            <div className='banner-head'>
                                <div className='d-flex justify-content-between align-items-end mb-2' >
                                    <div className='cnow-form-title'>SELECT IMAGE</div>
                                    <div className='d-flex gap-2'>
                                        <Button size='sm' label='Save' color='danger' onClick={() => handleImageSelect()} />
                                        <Button size='sm' icon='close' target='_self' onClick={() => setImageModal(false)} />
                                    </div>
                                </div>
                                <hr className='cnow-hr mb-2' />
                            </div>
                            <InlineImageList onSelect={async (id) => setSelectedImage(await _api.image.getById(id))}
                                items={entity.images.map(x => x.image_id)}
                                image={selectedImage}
                                type='banner'
                            />
                        </Modal.Body>
                    </Modal>
                }
            </div>
        </>);
}
export default OnThisDay