/* eslint-disable react-hooks/exhaustive-deps */
import React , {useState , useRef , useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dragula from 'react-dragula'
import uniqid from 'uniqid'
import ImageViewer from '../viewers/image';
import Button from '../buttons/default';
import LinkButton from '../buttons/link';
import { newTempId, handleReorder } from '../../helpers';
import SelectMapOverlay from '../selects/map_overlay';

const _api = require('../../api')

const MapOverlayEditor = (props) => {
    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);


    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container-overlay'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.overlays, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, overlays: items } })
            props.refresh && props.refresh()
        })
    }, [dragula])
    // Dragula End

    const addOverlay = () => {
        if (!entity.overlays) entity.overlays = [];
        entity.overlays.push({
            'id': newTempId(),
            'map_id': entity.id,
            'map_overlay_id': null,
            'map_overlay_name': '',
            'sort': entity.overlays.length + 1
        });
        entity.overlays.forEach((item, index) => item.sort = index + 1)
        setDragula(uniqid())
        dispatch({ type: 'entity', payload: { ...entity, overlays: entity.overlays } });
    }

    const removeOverlay = async (index) => {
        if (entity.overlays[index]) {
            if (!entity.overlays[index].id.startsWith('temp.')) {
                await _api.map.deleteOverlay(entity.overlays[index].id)
            }
            entity.overlays.splice(index, 1)
            entity.overlays.forEach((item, index) => item.sort = index + 1)
            dispatch({ type: 'entity', payload: { ...entity, overlays: entity.overlays } });
        }
    }

    const handleOverlayChange = (data, index) => {
        entity.overlays[index].map_overlay_id = data.id
        entity.overlays[index].map_overlay_name = data.title
        entity.overlays[index].image_id = data.image
        dispatch({ type: 'entity', payload: { ...entity, overlays: entity.overlays } });
    }

    return (
        <div>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Map Overlays</label>
                </div>
                <div className='col-auto'>
                    <Button size='sm' icon='plus' label='Add an Overlay' onClick={() => addOverlay()} />
                </div>
            </div>

            <div id={dragulaContainer} key={dragula}>
                {
                    entity?.overlays?.length > 0 && entity?.overlays?.map((item, index) => {
                        return (
                            <div className='row g-1 dragula-item' id={item.id} key={item.id} >
                                {
                                    entity?.overlays?.length > 1 &&
                                    <div className='col-auto'>
                                        {<label className='cnow-form-label'>{!index ? 'Order' : ''}</label>}
                                        <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={index + 1} autoComplete='off' />
                                    </div>
                                }
                                <div className='col-auto'>
                                    <div className=''>&nbsp;</div>
                                    <ImageViewer key={item.id} entityType='image' entityId={item.image_id} type='thumb' cssClass='thumb-small' />
                                </div>
                                <div className='col'>
                                    <label className='cnow-form-label'>{!index ? 'Overlay Title' : ''}</label>
                                    <div className='row g-1'>
                                        <div className='col'>
                                            <SelectMapOverlay value={{ id: item.map_overlay_id, name: item.map_overlay_name }} onChange={(data) => handleOverlayChange(data, index)} />
                                        </div>
                                        <div className='col-auto'>
                                            <div className='btn-list'>
                                                <LinkButton icon='edit' to={`/map_overlay/${item.map_overlay_id}/editor`} />
                                                <Button icon='delete' onClick={() => removeOverlay(index)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default MapOverlayEditor;