import React from 'react';
import { useSelector } from 'react-redux';

import PageTitle from '../../components/pageTitle';
import MetaDescriptionEditor from '../../components/editors/metaDescriptionEditor';
import MetaKeywordsEditor from '../../components/editors/metaKeywordsEditor';
import MetaTitleEditor from '../../components/editors/metaTitleEditor';

const TourMetaDescription = (props) => {
    const tour = useSelector(state => state[props.id]);
    return (
        <>
            <PageTitle title={tour.name} />
            <MetaDescriptionEditor id={props.id} type='tour' />
            <MetaKeywordsEditor id={props.id} type='tour' />
            <MetaTitleEditor id={props.id} type='tour' />
        </>
    );
};

export default TourMetaDescription;