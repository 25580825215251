import React, { useState, useEffect } from 'react'
import FeaturedTitle from './title';
import CollapsableSection from '../../components/collapsableSection';
import TextEditor from '../../components/editors/textEditor';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StaticImageCard from '../../components/featured/staticImagesCard';
import StaticContentCard from '../../components/featured/staticContentCard';
import Spinner from '../../components/spinner';
import CollectionImageCard from '../../components/featured/collectionsImageCard';
const Podcast = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [editedIndex, setEditedIndex] = useState(-1);
    const [isContentEdit, setIsContentEdit] = useState(false);
    const [podcastData, setPodcastData] = useState([])
    const [display, setDisplay] = useState(false)

    const searchPodcast = useSelector((state) => state.search);
    const featured = useSelector((state) => state.featured);
    const podcast = featured.podcast || []

    // eslint-disable-next-line
    useEffect(() => { formatPodcast(); if (!podcast[0]?.data?.description && !podcast[0]?.data?.title) setTitleDescription() }, [podcast?.length, podcast])

    const formatPodcast = () => {
        let data = []
        if (podcast)
            for (let i in podcast) {
                if (podcast[i]?.data?.podcast) {
                    for (let j in podcast[i].data.podcast) {
                        podcast[i].data.podcast[j].section_index = i
                        podcast[i].data.podcast[j].entity_index = j
                        data.push(podcast[i].data.podcast[j])
                    }
                }
            }
        setPodcastData(data)
    }

    const setTitleDescription = () => {
        if (podcast[0]?.data?.podcast.length > 0) {
            podcast[0].data.title = 'Podcast'
            podcast[0].data.description = 'Number of Podcasts 2137'
            dispatch({ type: 'featured', payload: { ...featured, podcast: podcast } })
        }
    }

    const handleRedirect = () => {
        dispatch({ type: 'search', payload: { ...searchPodcast, podcast: podcastData, selectedResult: [], title: `HOMEPAGE - What's in the Collection - Podcast`, filterLive: true, selectedLimit: 2 } })
        history.push(`/featured/searchList/podcast/podcast`)
    }

    const handleInputChange = (value, type, id) => {
        if (type === 'name') {
            podcast[0].data.title = value
        }
        if (type === 'description' && value) {
            podcast[0].data.description = value
        }
        podcast[0].edited = true
        dispatch({ type: 'featured', payload: { ...featured, podcast: podcast } })
    }

    const handleDeletePodcast = () => {
        setDisplay(true)
        let id_index = podcastData[editedIndex].section_index
        let id_podcast_index = podcastData[editedIndex].entity_index
        podcast[id_index].edited = true
        podcast[id_index].data.podcast.splice(id_podcast_index, 1)
        dispatch({ type: 'featured', payload: { ...featured, podcast: podcast } })
        setDisplay(false)
        //to refresh Call function Again
        formatPodcast()
        setEditedIndex(editedIndex - 1)
    }

    const handleClick = (index) => {
        setEditedIndex(index);
        setIsContentEdit(false)
    }

    return (
        <>
            <FeaturedTitle title={'Featured Sites on the Landing page'} page={props?.page} />
            <hr className={`cnow-hr ${props?.page ? 'd-none' : ''}`} />

            <div>
                <CollapsableSection collapsable={true} title="WHATS IN THE COLLECTION" count='1 item'
                    disable={props?.page === 'home' ? true : false}
                    button={{
                        close: { show: true, onHandle: () => { console.log('closedd') } }
                    }}>
                    <CollapsableSection textColor="cnow-text-primary" collapsable={true} title="PODCAST"
                        count={podcastData.length > 1 ? `${podcastData?.length} items` : `${podcastData?.length} item`}
                        page={props?.page} zzzz
                        button={{
                            // set: { show: true, onHandle: () => { } },
                            search: { show: true, onHandle: () => { handleRedirect() } },
                            // setIsEdited(true);
                            edit: { show: true, onHandle: () => { handleClick(0) } },
                            // add: { show: true, onHandle: () => { } },
                        }}
                    >
                        <Spinner display={display}>
                            <div className='row'>
                                {podcastData.map((item, index) => (
                                    <div className={`mt-2 col-auto`} style={{ border: `${index === editedIndex ? '3px solid #a82829' : 'none'}` }} onClick={() => { if (props?.page !== 'home') handleClick(index) }}>
                                        <StaticImageCard data={item} />
                                    </div>))
                                }
                            </div>
                        </Spinner>
                        {podcastData?.length > 0 && <div className='mt-2' onClick={(e) => { if (props?.page !== 'home') { setIsContentEdit(true); setEditedIndex(-1); } }} style={{ border: `${isContentEdit ? '3px solid #a82829' : 'none'}` }}>
                            <StaticContentCard data={podcast[0]?.data} />
                        </div>}
                    </CollapsableSection>
                    {(editedIndex !== -1) && podcastData?.length > 0 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            edit: { show: true, onHandle: () => { history.push(`/podcast/${podcastData[editedIndex].id}/editor`) } },
                            reset: { show: true, onHandle: () => { setEditedIndex(-1) } },
                            save: { show: true, onHandle: () => { setEditedIndex(-1) } },
                            delete: { show: (true), onHandle: () => { handleDeletePodcast() } }
                        }}>
                        <CollectionImageCard key={`image-${editedIndex}`} data={podcastData[editedIndex]} name={podcastData[editedIndex]?.name} author={podcastData[editedIndex]?.credits?.[0]?.person_name} />
                    </CollapsableSection>}

                    {isContentEdit && podcast[0]?.data.description && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            save: { show: true, onHandle: () => { setIsContentEdit(false); } },
                        }}>

                        <div className='mt-2 mx-5'>
                            <div>
                                <label className='cnow-form-label fw-bold'>Section Title</label>
                                <input name='name' type='text' className='form-control' value={podcast[0]?.data.title} onChange={(e) => { handleInputChange(e.target.value, 'name') }} autoComplete="off" />
                            </div>
                            <div>
                                <TextEditor data={podcast[0]?.data.description} onChange={(data) => { handleInputChange(data, 'description') }} limit="50"
                                    button={{
                                        reset: { show: true, onHandle: () => { } },
                                    }} key={`TextEditor${editedIndex}`}>
                                    <label className='cnow-form-label mt-2 fw-bold'>Section Description</label>
                                </TextEditor>
                            </div>
                        </div>
                    </CollapsableSection>}
                </CollapsableSection>
            </div>
        </>
    )
}

export default Podcast