import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import LegalHistory from "../../components/curator/legalHistory";
import PageTitle from "../../components/pageTitle";
import EntityOfDayCheckBox from "../../components/curator/entityOfDayCheckbox";
import CuratorTextField from "../../components/curator/textField";
import { newTempId } from "../../helpers";

const CuratorManagement = ({ id }) => {
    const event = useSelector(state => state[id]);
    return (
        <>
            <PageTitle title={event.name} />
            <TextFied id={id} key={id} />
            <LegalHistory id={id} type={'event'} />
            <EntityOfDayCheckBox type={'event'} id={id} entity_day={'Event'} show_entity_of_day={'not_show_event_of_the_day'} key={event?.curator?.not_show_event_of_the_day} />
        </>
    )
}

export default CuratorManagement

const TextFied = ({ id }) => {
    const event = useSelector(state => state[id]);
    const dispatch = useDispatch()
    const event_curator = event.curator
    const handleOnChange = (data, type) => {
        if (!event?.curator) event.curator = {}
        if (!event?.curator?.id) event.curator.id = newTempId()
        if (!event?.curator?.[type]) event.curator[type] = ''
        event.curator[type] = data
        dispatch({ type: 'entity', payload: { ...event, curator: { ...event.curator, [type]: event.curator[type] } } })
    }

    return (
        <>
            <div className="py-2"><CuratorTextField label={'WRITTEN DATE'} title={'Date'} disableSubTextBox={true} handleMainInputChange={(e) => { handleOnChange(e, 'written_date') }} mainInput={event_curator?.written_date || ''} placeholder={'xxx'} /></div>
            <CuratorTextField label={'DESCRIPTION'} title={'Remarks Attribution:'} subTitle={'Internal Notes (available only to users with admin access):'} handleMainInputChange={(e) => { handleOnChange(e, 'remarks_attribution') }} handleSubInputChange={(e) => { handleOnChange(e, 'internal_notes') }} mainInput={event_curator?.remarks_attribution || ''} subInput={event_curator?.internal_notes || ''} hrTrue />
        </>
    )
}