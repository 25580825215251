import React, { useState, useEffect } from 'react'
import FeaturedTitle from './title';
import CollapsableSection from '../../components/collapsableSection';
import TextEditor from '../../components/editors/textEditor';
import { useHistory } from 'react-router-dom';
import Spinner from '../../components/spinner';
import { useDispatch, useSelector } from 'react-redux';
import StaticImageCard from '../../components/featured/staticImagesCard';
import StaticContentCard from '../../components/featured/staticContentCard';
import CollectionImageCard from '../../components/featured/collectionsImageCard';

const CNowMaps = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [editedIndex, setEditedIndex] = useState(-1);
    const [cnowMapData, setCnowMapData] = useState([])
    const [display, setDisplay] = useState(false)
    const [isContentEdit, setIsContentEdit] = useState(false);

    const searchCnowMap = useSelector((state) => state.search);
    const featured = useSelector((state) => state.featured);
    const cnow_maps = featured.cnow_maps || []

    // eslint-disable-next-line
    useEffect(() => { formatCnowMaps(); if (!cnow_maps[0]?.data?.description && !cnow_maps[0]?.data?.title) setTitleDescription() }, [cnow_maps?.length, cnow_maps])

    const formatCnowMaps = () => {
        let data = []
        if (cnow_maps)
            for (let i in cnow_maps) {
                if (cnow_maps[i]?.data?.cnow_maps) {
                    for (let j in cnow_maps[i].data.cnow_maps) {
                        cnow_maps[i].data.cnow_maps[j].section_index = i
                        cnow_maps[i].data.cnow_maps[j].entity_index = j
                        data.push(cnow_maps[i].data.cnow_maps[j])
                    }
                }
            }
        setCnowMapData(data)
    }

    const setTitleDescription = () => {
        if (cnow_maps[0]?.data?.cnow_maps.length > 0) {
            cnow_maps[0].data.title = 'The Arm Chair traveller'
            cnow_maps[0].data.description = 'Overlay the historic maps with Google to see what our cities used to look like.'
            dispatch({ type: 'featured', payload: { ...featured, cnow_maps: cnow_maps } })
        }
    }

    const handleClick = (index) => {
        setEditedIndex(index);
        setIsContentEdit(false)
    }

    const handleRedirect = () => {
        dispatch({ type: 'search', payload: { ...searchCnowMap, cnow_maps: cnowMapData, selectedResult: [], title: `HOMEPAGE - Maps`, filterLive: true, selectedLimit: 1 } })
        history.push(`/featured/searchList/cnow_maps/map`)
    }

    const handleInputChange = (value, type, id) => {
        if (type === 'name') {
            cnow_maps[0].data.title = value
        }
        if (type === 'description' && value) {
            cnow_maps[0].data.description = value
        }
        cnow_maps[0].edited = true
        dispatch({ type: 'featured', payload: { ...featured, cnow_maps: cnow_maps } })
    }

    const handleDeleteCnowMaps = () => {
        setDisplay(true)
        let id_index = cnowMapData[editedIndex].section_index
        let id_map_index = cnowMapData[editedIndex].entity_index
        cnow_maps[id_index].edited = true
        cnow_maps[id_index].data.cnow_maps.splice(id_map_index, 1)
        dispatch({ type: 'featured', payload: { ...featured, cnow_maps: cnow_maps } })
        setDisplay(false)
        //to refresh Call function Again
        formatCnowMaps()
        setEditedIndex(editedIndex - 1)
    }

    return (
        <>
            <FeaturedTitle title={'Featured Sites on the Landing page'} page={props?.page} />
            <hr className={`cnow-hr ${props?.page ? 'd-none' : ''}`} />

            <div>
                <CollapsableSection collapsable={true} title="WHATS IN THE COLLECTION" count='1 item'
                    disable={props?.page === 'home' ? true : false}
                    button={{
                        close: { show: true, onHandle: () => { console.log('closedd') } }
                    }}>
                    <CollapsableSection textColor="cnow-text-primary" collapsable={true} title="CULTURENOW MAPS"
                        count={cnowMapData.length > 1 ? `${cnowMapData?.length} items` : `${cnowMapData?.length} item`}
                        page={props?.page}
                        button={{
                            // set: { show: true, onHandle: () => { } },
                            search: { show: true, onHandle: () => { handleRedirect() } },
                            // setIsEdited(true);
                            edit: { show: true, onHandle: () => { handleClick(0) } },
                            // add: { show: true, onHandle: () => { } },
                        }}
                    >
                        <Spinner display={display}>
                            <div className='row'>
                                {cnowMapData.map((item, index) => (
                                    <div className={`mt-2 col-auto`} style={{ border: `${index === editedIndex ? '3px solid #a82829' : 'none'}` }} onClick={() => { if (props?.page !== 'home') handleClick(index) }}>
                                        <StaticImageCard data={item} />
                                    </div>))
                                }
                            </div>
                        </Spinner>
                        {cnowMapData?.length > 0 && <div className='mt-2' onClick={(e) => { if (props?.page !== 'home') { setIsContentEdit(true); setEditedIndex(-1); } }} style={{ border: `${isContentEdit ? '3px solid #a82829' : 'none'}` }}>
                            <StaticContentCard data={cnow_maps[0]?.data} />
                        </div>}
                    </CollapsableSection>
                    {(editedIndex !== -1) && cnowMapData?.length > 0 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            edit: { show: true, onHandle: () => { history.push(`/map/${cnowMapData[editedIndex].id}/editor`) } },
                            reset: { show: true, onHandle: () => { } },
                            save: { show: true, onHandle: () => { setEditedIndex(-1) } },
                            delete: { show: (true), onHandle: () => { handleDeleteCnowMaps() } }
                        }}>
                        <CollectionImageCard key={`image-${editedIndex}`} data={cnowMapData[editedIndex]} name={cnowMapData[editedIndex]?.name} />
                    </CollapsableSection>}

                    {isContentEdit && cnow_maps[0]?.data?.description && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            save: { show: (cnowMapData.length > 0), onHandle: () => { setIsContentEdit(false); } },
                        }}>

                        <div className='mt-2 mx-5'>
                            <div>
                                <label className='cnow-form-label fw-bold'>Section Title</label>
                                <input name='name' type='text' className='form-control' value={cnow_maps[0]?.data.title} onChange={(e) => { handleInputChange(e.target.value, 'name') }} autoComplete="off" />
                            </div>
                            <div>
                                <TextEditor data={cnow_maps[0]?.data.description} onChange={(data) => { handleInputChange(data, 'description') }} limit="50"
                                    button={{
                                        reset: { show: true, onHandle: () => { } },
                                    }}
                                    key={`TextEditor${editedIndex}`}
                                >
                                    <label className='cnow-form-label mt-2 fw-bold'>Section Description</label>
                                </TextEditor>
                            </div>
                        </div>
                    </CollapsableSection>}
                </CollapsableSection>
            </div>
        </>
    )
}

export default CNowMaps