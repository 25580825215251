/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid'
import MapAddressList from './mapAddressList';
import Icon from '../viewers/icon';
import GoogleMapReact from 'google-map-react';

const EventMap = (props) => {
    const event = useSelector(state => state[props.id]);
    const [center] = useState({ lat: 40.730610, lng: -98.6273925 }); //United States
    const [google, setGoogle] = useState();
    const [mapKey, refreshMap] = useState(uniqid())
    const mapRef = useRef();
    const column = props.column || 'flex-column';
    const height = props.height || ''
    const featured_project_info = event?.featured_projects?.length > 0 ? event.featured_projects.filter((elem) => elem.featured) : []

    const setBounds = (google) => {
        var bounds = new google.maps.LatLngBounds();
        let markerCount = 0;
        if (event.locations?.length > 0) {
            event.locations.forEach((event, index) => {
                if (event.location?.lat && event.location?.lng)
                    bounds.extend(event.location)
                markerCount = markerCount + 1;
            })
            mapRef.current.fitBounds(bounds);
        }
        if (markerCount === 1) mapRef.current.setZoom(16);
    }

    const handleMapLoad = (google) => {
        mapRef.current = google.map;
        mapRef.current.setOptions({
            'zoomControlOptions': { 'position': google.maps.ControlPosition.RIGHT_BOTTOM },
            'controlSize': 24,
            'minZoom': 0
        })
        setGoogle(google);
        setBounds(google);
    }

    const handleMapRedirect = () => {
        if (props.type) window.open(`${process.env.REACT_APP_WEB_URL}historic-map/${props.type}/${props.id}`, "_blank");
    }

    const MarkerIcon = ({ number, colour, fill, type }) => {
        return (
            <>
                {type === 'site' &&
                    <svg id="Component_131_14" data-name="Component 131 – 14" xmlns="http://www.w3.org/2000/svg" width="28" height="37" viewBox="0 0 28 37">
                        <g id="Path_583" data-name="Path 583" fill="#fff">
                            <path d="M 14 26 C 10.79467964172363 26 7.781219959259033 24.75177955627441 5.51471996307373 22.48527908325195 C 3.248219966888428 20.21878051757812 2 17.20532035827637 2 14 C 2 10.79467964172363 3.248219966888428 7.781219959259033 5.51471996307373 5.51471996307373 C 7.781219959259033 3.248219966888428 10.79467964172363 2 14 2 C 17.20532035827637 2 20.21878051757812 3.248219966888428 22.48527908325195 5.51471996307373 C 24.75177955627441 7.781219959259033 26 10.79467964172363 26 14 C 26 17.20532035827637 24.75177955627441 20.21878051757812 22.48527908325195 22.48527908325195 C 20.21878051757812 24.75177955627441 17.20532035827637 26 14 26 Z" stroke="none" fill={fill ? colour : null} />
                            <path d="M 14 4 C 8.485979080200195 4 4 8.485979080200195 4 14 C 4 19.51401901245117 8.485979080200195 24 14 24 C 19.51401901245117 24 24 19.51401901245117 24 14 C 24 8.485979080200195 19.51401901245117 4 14 4 M 14 0 C 21.73197937011719 0 28 6.268009185791016 28 14 C 28 21.73197937011719 21.73197937011719 28 14 28 C 6.268009185791016 28 0 21.73197937011719 0 14 C 0 6.268009185791016 6.268009185791016 0 14 0 Z" stroke="none" fill={colour} />
                        </g>
                        <text y="18" x="10" fill="#000"></text>
                        <path id="Polygon_164" data-name="Polygon 164" d="M10,0,20,14H0Z" transform="translate(24 37) rotate(180)" fill={colour} />
                    </svg>
                }
                {type === 'event' &&
                    <svg id="Component_131_17" data-name="Component 131 – 17" xmlns="http://www.w3.org/2000/svg" width="28" height="37" viewBox="0 0 28 37">
                        <path id="Path_583" data-name="Path 583" d="M14,0A14,14,0,1,1,0,14,14,14,0,0,1,14,0Z" fill="#a82829" />
                        <path id="Polygon_164" data-name="Polygon 164" d="M10,0,20,14H0Z" transform="translate(24 37) rotate(180)" fill="#a82829" />
                        <g id="Layer_1" transform="translate(7 6)">
                            <g id="Layer_1-2" data-name="Layer_1" transform="translate(-0.02 -0.52)">
                                <g id="Group_349" data-name="Group 349">
                                    <g id="Group_344" data-name="Group 344">
                                        <path id="Path_425" data-name="Path 425" d="M14.17,2.97v10.7H.77V2.97h13.4m.75-.75H.02v12.2h14.9V2.22Z" fill="#fff" />
                                    </g>
                                    <g id="Group_345" data-name="Group 345">
                                        <path id="Path_426" data-name="Path 426" d="M14.17,2.97v2.5H.77V2.97h13.4m.75-.75H.02v4h14.9v-4Z" fill="#fff" />
                                    </g>
                                    <g id="Group_348" data-name="Group 348">
                                        <path id="Path_427" data-name="Path 427" d="M7.49,5.36c-1.12,0-5.45-.01-6.58-.01L.9,4.75V3.07s1.94.02,2.4.02h.33l-.02.42v.5a.252.252,0,0,0,.06.19l.03.04.13.02c.09,0,.16-.01.22-.01s.25.01.25.01l.12-.06a.256.256,0,0,0,.06-.19V3.06l3,.02H10.5l-.02.49v.5l.06.12a.213.213,0,0,0,.16.06h.5l.11-.06a.23.23,0,0,0,.06-.16V3.06l1.37.01h1.39c-.02.78-.02,1.48-.01,2.27Z" fill="#a82829" />
                                        <path id="Path_428" data-name="Path 428" d="M1.03,3.2l.91.01c.45,0,.9.01,1.37.01h.17v.8a.393.393,0,0,0,.1.28.379.379,0,0,0,.28.1h.01c.06,0,.12-.01.19-.01a1.942,1.942,0,0,1,.22.01.365.365,0,0,0,.25-.09.381.381,0,0,0,.1-.28v-.8h5.75v.82a.365.365,0,0,0,.09.26.342.342,0,0,0,.26.09h.44a.324.324,0,0,0,.24-.09.349.349,0,0,0,.09-.25V3.23h2.49V5.25H7.49c-1.12,0-2.21,0-3.29-.01-1.03,0-2.06-.01-3.17-.01V4.81c0-.54,0-1.05,0-1.61M.89,2.95c-.09,0-.11.03-.11.12V5.33c0,.14,0,.14.13.14,2.25,0,4.33.01,6.58.01h6.62c.08,0,.12-.02.12-.12V3.07c0-.09-.03-.11-.11-.11H11.35c-.09,0-.11.03-.11.11v.96c0,.07-.02.09-.08.09H10.7c-.07,0-.09-.03-.09-.1V3.07c0-.09-.02-.11-.11-.11H4.47c-.08,0-.11.02-.11.11.01.36,0,.58,0,.94,0,.08-.02.12-.09.12H4.25c-.07,0-.13-.01-.2-.01a1.34,1.34,0,0,0-.2.01H3.83c-.08,0-.1-.03-.1-.12.01-.36,0-.58,0-.93,0-.09-.02-.12-.11-.12H3.31c-.83.01-1.56,0-2.42-.01Z" fill="#a82829" />
                                    </g>
                                    <g id="Group_346" data-name="Group 346">
                                        <line id="Line_463" data-name="Line 463" y2="3.6" transform="translate(4.03 0.52)" fill="none" stroke="#fff" stroke-width="0.75" />
                                    </g>
                                    <g id="Group_347" data-name="Group 347">
                                        <line id="Line_464" data-name="Line 464" y2="3.6" transform="translate(10.92 0.52)" fill="none" stroke="#fff" stroke-width="0.75" />
                                    </g>
                                </g>
                            </g>
                        </g>
                        {<text y="18" x="11" fill="#ffff" width="5" height="5" style={{ fontSize: 'xx-small' }}>{number}</text>}<path id="Polygon_164" data-name="Polygon 164" d="M10,0,20,14H0Z" transform="translate(24 37) rotate(180)" fill={colour} />
                    </svg>
                }
            </>
        )

    }


    const Marker = ({ lat, lng, type, number }) => {
        let colour = type === 'site' ? '#86E8FF' : '#a82829';
        if (type === 'event') {
            number = (event.locations?.length > 1) ? number : ''
        }
        return (
            <div className='cnow-marker'>
                <MarkerIcon colour={colour} number={number} fill={false} type={type} />
            </div>
        )
    }

    useEffect(() => {
        if (google) setBounds(google);
    }, [event])

    useMemo(() => {
        refreshMap(uniqid());
    }, [featured_project_info?.length])

    return (
        <>
            {event?.locations?.length > 0  &&
                <>
                    <div className={`d-flex  ${column}`}>
                        <div>
                            <hr />
                            <div className='d-flex cursor-pointer' onClick={() => { handleMapRedirect() }}>
                                <Icon name='map-view' size={31} />
                                <div className='fw-bold fst-italic ms-1 mt-1 text-dark'>Map View</div>
                            </div>
                            <div className='cnow-map-preview mt-2' style={{ height: height }}>
                                <GoogleMapReact
                                    key={mapKey}
                                    center={center}
                                    defaultZoom={3}
                                    options={{ mapId: "1f81af8398de79dd" }}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={handleMapLoad}
                                >

                                    {
                                        featured_project_info?.length > 0 && featured_project_info.map((site, index) => {
                                            if (site?.locations && site?.locations?.[0]?.location?.lat && site?.locations?.[0]?.location?.lng)
                                                return (
                                                    <Marker
                                                        key={site.id}
                                                        lat={site.locations[0]?.location?.lat}
                                                        lng={site.locations[0]?.location?.lng}
                                                        type={'site'}
                                                        number={index + 1}
                                                    />)
                                            else return null;
                                        }
                                        )
                                    }

                                    {
                                        event?.locations?.length > 0 && event.locations.map((event, index) => {
                                            if (event.location?.lat && event.location?.lng)
                                                return (
                                                    <Marker
                                                        key={event.id}
                                                        lat={event.location.lat}
                                                        lng={event.location.lng}
                                                        type={'event'}
                                                        number={index + 1}
                                                    />)
                                            else return null;
                                        }
                                        )
                                    }
                                </GoogleMapReact>
                            </div>
                            {event?.locations?.length > 0 && <MapAddressList id={props.id} type={props.type} locations={event?.locations} />}
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default EventMap;