/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';

const MetaVanityUrlEditor = (props) => {

    const dispatch = useDispatch();
    const entity = useSelector(state => state[props.id]);
    useEffect(() =>{
        if(!entity.meta.vanity_url){

            entity.meta.vanity_url = entity?.name?.toLowerCase().split(' ').join('-').replace(/[^a-z0-9-]/g, '');
            dispatch({ type: 'entity', payload: { ...entity, meta: entity.meta } });
        }
    },[])

    const handleInputChange = async ({ target: { name, value } }) => {
        const regex = /^[a-z0-9-]*$/; 
        if (regex.test(value)) {
            entity.meta[name] = value;
            // entity.meta.prevVantity = true;
            dispatch({ type: 'entity', payload: { ...entity, meta: entity.meta } });
        }
    }

    return (
        <div>
            <div className='row mt-2'>
                <div className='col'>
                    <label className='cnow-form-title'>Vanity URL</label><span style={{fontSize: "12px"}}>  (Caution: Vanity URL once changed will affect the indexing of the site)</span>

                </div>

            </div>
            <textarea type='textarea' rows='2' className='form-control' pattern='/^[a-z0-9-]*$/' name='vanity_url' value={entity?.meta?.vanity_url} onChange={handleInputChange}/>
            <p style={{fontSize: "12px"}}>Guidelines: Only alphanumeric characters and the "-" symbol are accepted.</p>
        </div>
    )

}

export default MetaVanityUrlEditor