/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import Button from '../../../components/buttons/default';
import LinkButton from '../../../components/buttons/link';
import PreviewButton from '../../../components/buttons/preview';
import Pagination from "../../../components/pagination";
import ResultCount from '../../../components/resultCount';
import ScrollToTop from '../../../components/scrollToTop';
import Spinner from '../../../components/spinner';
import Icon from '../../../components/viewers/icon';
import ImageViewer from '../../../components/viewers/image';
import LongTextView from '../../../components/viewers/longText';
import moment from 'moment';
import AwardList from '../../../components/table/awardList';
import DateList from '../../../components/table/dateList';
import DesignationList from '../../../components/table/designationList';
import LocationList from '../../../components/table/locationList';
import RelatedSiteList from '../../../components/table/relatedSiteList';
import ImageAlbumsList from '../../../components/table/imageAlbumsList';
import TagList from '../../../components/table/tagList';
import TableHeader from '../../../components/tableHeader';
import { differenceBy } from 'lodash';
import { personName, TABLE_ROWS, newTempId } from '../../../helpers';
import PodcastList from '../../../components/table/podcastList';
import PlayList from '../../../components/table/playlist';
import ProfessionList from '../../../components/table/professionList';
import OrganizationTypeList from '../../../components/table/organizationTypeList';
import PublicationTypeList from '../../../components/table/publicationTypeList';
import AffiliationList from '../../../components/table/affiliationList';
import RelatedPeopleList from '../../../components/table/relatedPeopleList'
import RelatedMapList from '../../../components/table/relatedMapList';

const _api = require('../../../api');
const columns = require('../../../data/columns_people.json');

const FeaturedPeople = (props) => {
    const history = useHistory();

    //   const [searchResult, setSearchResult] = useState([]);
    const location = useLocation();
    const [keywordValue, setKeywordValue] = useState('');
    const [keyword, setKeyword] = useState('');
    const dispatch = useDispatch()
    const take = 50;
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState(columns[0]);
    const [spinner, showSpinner] = useState(false);
    const [paginationHits, setPaginationHits] = useState(0)
    let section = props.section

    const searchPerson = useSelector((state) => state.search);
    let searchResult = searchPerson.searchResult || []
    let selectedResult = searchPerson.selectedResult?.filter((elem) => elem.entity_type === 'person') || []
    let sectionResult = searchPerson[section]?.filter((elem) => elem.entity_type === 'person') || []
    let selectedEntity = [...sectionResult, ...selectedResult]
    const featured = useSelector((state) => state.featured);
    const [resultFilters, setResultFilters] = useState({
        live: searchPerson.filterLive,
        limit: searchPerson.selectedLimit,
    })
    const [label, setLabel] = useState([])
    let start_date = featured.start_date && JSON.parse(featured.start_date)
    let end_date = featured.end_date && JSON.parse(featured.end_date)
    let start = start_date ? moment(start_date).format("MMMM Do YYYY") : ''
    let end = end_date ? moment(end_date).format("MMMM Do YYYY") : ''

    useEffect(() => {
        search();
    }, [page, sort, keyword]);

    const search = () => {
        showSpinner(true);

        const params = {
            keyword: keyword,
            hitsPerPage: take,
            page: page - 1,
            facetFilters: [
                [`live:${resultFilters?.live}`],
                ["deleted:false"]
            ]
        }
        handleSetKeywords(keyword)
        _api.person.search(params).then(response => {
            setPaginationHits(response.nbHits);
            const filteredArray = differenceBy(response.hits, selectedEntity, 'id')
            dispatch({ type: 'search', payload: { ...searchPerson, searchResult: filteredArray } })
            // setSearchResult(response)
            showSpinner(false)
        })
    };

    const handleSetKeywords = (keyword) => {
        let data = [];
        if (resultFilters?.live) data.push(`Live - People`)
        if (keyword) data.push(keyword)
        setLabel(data)
    }

    const handleDeleteLabel = (index) => {
        let data = label[index];
        if (data.includes('Live')) resultFilters.live = ''
        if (data === keyword) setKeywordValue(''); setKeyword('')
        setResultFilters(resultFilters)
        search()
    }

    const deletePerson = (person) => {
        swal({
            title: `Delete People/Organization/Publication`,
            text: `Are you sure that you want to delete ${personName(person)}?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then(async (status) => { _api.person.deleteById(person?.id).then(() => { search() }) });
    };

    const handleLive = (personId) => {
        showSpinner(true)
        let selectedSearchResult = searchResult?.hits?.filter(item => item.id === personId)
        let selectedResult = selectedSearchResult[0]
        let selectedDateDescription = selectedResult?.dates?.filter((e) => e.description !== '')
        if (selectedResult.type === 'person') {
            if (
                selectedResult?.first_name &&
                selectedResult?.professions?.[0]?.profession_name &&
                (selectedResult?.description || selectedDateDescription?.length > 0) &&
                selectedResult?.image?.url
            ) {
                _api.person.updateLive(personId, true).then((_) => {
                    search();
                    swal({
                        title: `Live!`,
                        icon: 'success',
                        text: `${selectedResult.first_name} is now Live.`,
                        buttons: 'Ok',
                    })
                })
            } else {
                swal({
                    title: `Cannot Go Live!`,
                    icon: 'error',
                    text: ` Please fill the mandatory fields:
          ${selectedResult.first_name ? '' : 'Person Firstname is missing.'} 
          ${(selectedResult?.description || selectedDateDescription?.length > 0) ? '' : 'About Person is missing.'} 
          ${selectedResult?.professions && selectedResult?.professions?.[0]?.profession_name ? '' : 'Person Profession is missing.'} 
          ${selectedResult?.image?.url ? '' : 'Person Image is missing.'}`,
                    buttons: 'Go back',
                    dangerMode: true,
                })
            }
        } else if (selectedResult.type === 'organization') {
            if (
                selectedResult?.name &&
                selectedResult?.organization_types && selectedResult?.organization_types?.[0]?.organization_type_name &&
                (selectedResult?.description || selectedDateDescription?.length > 0) &&
                selectedResult?.image?.url
            ) {
                _api.person.updateLive(personId, true).then((_) => {
                    search()
                    swal({
                        title: `Live!`,
                        icon: 'success',
                        text: `${selectedResult?.name} Organization is Live.`,
                        buttons: 'Ok',
                    })
                })
            } else {
                swal({
                    title: `Cannot Go Live!`,
                    icon: 'error',
                    text: ` Please fill the mandatory fields:
          ${selectedResult?.name ? '' : '\n Organization name is missing.'} 
          ${(selectedResult?.description || selectedDateDescription?.length > 0) ? '' : '\n About Organization is missing.'} 
          ${selectedResult?.organization_types && selectedResult?.organization_types?.[0]?.organization_type_name ? '' : 'Organization type is missing.'}  
          ${selectedResult?.image?.url ? '' : '\n Organization Image is missing.'}`,
                    buttons: 'Go back',
                    dangerMode: true,
                })
            }
        } else {
            if (
                selectedResult?.name &&
                selectedResult?.publication_types && selectedResult?.publication_types?.[0]?.publication_type_name &&
                (selectedResult?.description || selectedDateDescription?.length > 0) &&
                selectedResult?.image?.url
            ) {
                _api.person.updateLive(personId, true).then((_) => {
                    search()
                    swal({
                        title: `Live!`,
                        icon: 'success',
                        text: `${selectedResult?.name} is Live.`,
                        buttons: 'Ok',
                    })
                })
            } else {
                swal({
                    title: `Cannot Go Live.`,
                    icon: 'error',
                    text: ` Please fill the mandatory fields:
          ${selectedResult?.name ? '' : 'Publication name is missing.'} 
          ${selectedResult?.publication_types && selectedResult?.publication_types?.[0]?.publication_type_name ? '' : 'Publication type is missing.'} 
          ${(selectedResult?.description || selectedDateDescription?.length > 0) ? '' : 'About Publication is missing.'}  
          ${selectedResult?.image ? '' : 'Publication Image is missing.'}`,
                    buttons: 'Go back',
                    dangerMode: true,
                })
            }
        }
        showSpinner(false)
    }

    const handleNotLive = (personId) => {
        showSpinner(true)
        _api.person.updateLive(personId, false).then((_) => search())
        let selectedSearchResult = searchResult?.hits?.filter(item => item.id === personId)
        let selectedResult = selectedSearchResult[0]
        swal({
            title: 'Not Live!',
            icon: 'info',
            text: `${selectedResult?.type === 'person'
                ? `${selectedResult?.first_name} is Not Live.`
                : `${selectedResult?.name} is Not Live.`
                }`,
            buttons: 'Ok',
        })
        showSpinner(false)
    }

    const selectedPerson = (index) => {
        showSpinner(true)
        searchResult[index].entity_type = 'person'
        selectedResult.push(searchResult[index])
        searchResult.splice(index, 1)
        dispatch({
            type: 'search', payload: {
                ...searchPerson,
                selectedResult: selectedResult,
                searchResult: searchResult
            }
        })
        showSpinner(false)
    }

    const removeSelectedPerson = (index) => {
        if (selectedEntity[index].section_index !== undefined && selectedEntity[index].entity_index !== undefined) {
            let section_index = selectedEntity[index].section_index
            let entity_index = selectedEntity[index].entity_index
            featured[section][section_index].edited = true
            featured[section][section_index].data[section].splice(entity_index, 1)
            featured[section][section_index].data[section].forEach((elem, index) => elem.entity_index = index)
            let sectionIndex = sectionResult.findIndex(elem => elem.section_index === section_index && elem.entity_index === entity_index)
            sectionResult.splice(sectionIndex, 1)
            dispatch({ type: 'featured', payload: { ...featured, [`${section}`]: featured[`${section}`] } })
            dispatch({ type: 'search', payload: { ...searchPerson, [`${section}`]: sectionResult } })
        }
        else {
            let searchResultIndex = selectedResult.findIndex(elem => elem.id === selectedEntity[index].id)
            searchResult.push(selectedResult[searchResultIndex])
            selectedResult.splice(searchResultIndex, 1)
            dispatch({
                type: 'search', payload: { ...searchPerson, selectedResult: selectedResult, searchResult: searchResult }
            })
        }

    }

    const selectedPersonDispatch = () => {
        if (selectedResult.length > 0) {
            let index = featured[`${section}`].length === 0 ? -1 : 0
            let data = {
                "start_date": start_date.slice(0, 10),
                "end_date": end_date.slice(0, 10),
                "section": `${section}`,
            }
            if (index === -1) {
                data.id = newTempId()
                data.data = {
                    [`${section}`]: selectedResult
                }
                featured[`${section}`].push(data)
            }
            else {
                featured[`${section}`][index].edited = true
                featured[`${section}`][index].data[`${section}`] = [...featured[`${section}`][index].data[`${section}`], ...selectedResult]
            }
        }
        dispatch({ type: 'featured', payload: { ...featured, source: location.pathname } })
        dispatch({ type: 'search', payload: {} })
        history.goBack()
    }

    return (
        <>
            <div className='container-fluid mt-3'>
                <div className='row g-1'>
                    <div className='col'>
                        <div className='input-group'>
                            <span className='input-group-text border-end-0'>
                                <Icon name='search' />
                            </span>
                            <input className='form-control bg-light border-start-0' type='text' placeholder='Search by a person, a category, a site name, date' value={keywordValue}
                                onChange={(event) => { setKeywordValue(event.target.value) }}
                                onKeyDown={(e) => { if (e.key === 'Enter') { setPage(1); setKeyword(keywordValue) } }}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <div className='btn-list'>
                            <Button label='Search' onClick={() => { setPage(1); setKeyword(keywordValue) }} />
                        </div>
                    </div>
                    <div className='col-auto'>
                        <div className='btn-list'>
                            <Button label='Add a Person' color='danger' icon='plus-white' onClick={() => history.push('/people/create')} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid mt-3 p-0'>
                <div className='card'>
                    <div className='card-header bg-light p-2'>
                        <div className='d-flex justify-content-between align-items-end'>
                            <div>
                                <h4 className='mb-0 cursor-pointer text-capitalize' onClick={() => selectedPersonDispatch()}>{`${searchPerson.title} - ${start_date === end_date ? start : `${start} - ${end}`}`}</h4>
                                <h6 className='mb-0'>PEOPLE/ORGANIZATION/PUBLICATIONS</h6>
                                <div className='small'>
                                    <ResultCount page={page} take={take} count={paginationHits} />
                                    {
                                        label && label.map((elem, index) => {
                                            return (
                                                <span className='badge badge-search'>
                                                    {elem} <Icon name='close' size={10} left={4} cursor='pointer' onClick={() => { handleDeleteLabel(index) }} />
                                                </span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div>
                                <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
                            </div>
                            <div>
                                <div className='dropdown'>
                                    <button className='btn btn-light dropdown-toggle' type='button' id="sort" data-bs-toggle='dropdown' aria-expanded='false'>
                                        Sort by: {sort.name}
                                    </button>
                                    <ul className='dropdown-menu'>
                                        {
                                            columns.filter(x => x.sort === true)
                                                .map((column, index) =>
                                                    <button key={index} className='dropdown-item' onClick={() => setSort(column)}>{column.name}</button>
                                                )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Spinner display={spinner}>
                        <div className='table-responsive cnow-table'>
                            <table className='table table-striped table-hover table-bordered'>
                                <thead>
                                    <tr className='bg-light'>
                                        {columns.filter(x => x.display === true).map((column, index) =>
                                            <TableHeader key={index} column={column} onSort={(column) => setSort(column)} />
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='bg-dark'>
                                        <th className='text-white' scope="col " colspan="100%">
                                            <div className='d-flex ' style={{ width: "94vw" }}>
                                                <span className=''>
                                                    {`${searchPerson.title} PERSON SELECTED`}
                                                </span>
                                                <span className='ms-auto text-lowercase'>{selectedEntity.length > 1 ? `${selectedEntity.length} results` : `${selectedEntity.length} result`}</span>
                                            </div>
                                        </th>
                                    </tr>
                                    {selectedEntity.map((item, index) => {
                                        return (
                                            <tr key={`${item.id}-${index}`}>
                                                <td>
                                                    <div className='row g-1'>
                                                        <div className='col-auto'>
                                                            <div className='form-check mt-1'>
                                                                <input className='form-check-input align-middle' type='checkbox' name='select_person' checked={true} value={item.id} onClick={() => { removeSelectedPerson(index) }} />
                                                            </div>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <div className='btn-list'>
                                                                <LinkButton size='sm' icon='edit' to={!item.is_participant ? `/people/${item.id}/editor` : `/participant/${item.id}/editor`} />
                                                                <LinkButton size='sm' icon='pan' to={!item.is_participant ? `/participant/${item.id}/editor` : `/people/${item.id}/editor`} />
                                                                <Button size='sm' icon='delete' onClick={() => deletePerson(item)} />
                                                                <PreviewButton size='sm' link={!item.is_participant ? `people/${item.id}` : `participant/${item.id}`} />
                                                                <Button size='sm' icon='download' onClick={() => { }} />
                                                                {
                                                                    item.live ? <Button size='live' icon='live' onClick={() => handleNotLive(item.id)} />
                                                                        : <Button size='live' icon='not-live' color='danger' onClick={() => handleLive(item.id)} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ImageViewer url={item.image?.url} type='thumb' cssClass='thumb' />
                                                </td>
                                                <td className='fw-bold'>
                                                    {personName(item)}
                                                </td>
                                                <DesignationList data={item.designations} />
                                                <td className='text-capitalize fw-bold'>{item.type}</td>
                                                <td className='fw-bold fst-italic'>
                                                    <ProfessionList data={item.professions} />
                                                    <OrganizationTypeList data={item.organization_types} />
                                                    <PublicationTypeList data={item.publication_types} />
                                                </td>
                                                <AffiliationList data={item.affiliations} />
                                                {/* <CreditList data={item?.credits} /> */}
                                                <RelatedSiteList data={item?.credited_sites} />
                                                <RelatedSiteList data={item?.related_sites} />
                                                <ImageAlbumsList data={item?.image_albums} />
                                                <td>{item.id}</td>
                                                <TagList data={item.tags} />
                                                <td>
                                                    <LongTextView lines={TABLE_ROWS + 1}>{item.description}</LongTextView>
                                                </td>
                                                <PodcastList data={item.podcasts} />

                                                <AwardList data={item.awards} />
                                                <LocationList data={item.locations} />
                                                <DateList data={item.dates} />
                                                <td></td>
                                                <PlayList data={item.playlists} />
                                                <td></td>
                                                <td></td>
                                                <RelatedPeopleList data={item.related_people} />
                                                <td></td>
                                                <td></td>
                                                <RelatedMapList data={item?.maps} />
                                                <td></td>

                                                <td></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <tbody>
                                    <tr className='bg-dark'>
                                        <th className='text-white' scope="col " colspan="100%">
                                            <div className='d-flex ' style={{ width: "94vw" }}>
                                                <span className=''>
                                                    {`${searchPerson.title} PERSON AVAILABLE`}
                                                </span>
                                                <span className='ms-auto text-lowercase'>{searchResult.length > 1 ? `${searchResult.length} results` : `${searchResult.length} result`}</span>
                                            </div>
                                        </th>
                                    </tr>
                                    {searchResult.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>
                                                    <div className='row g-1'>
                                                        <div className='col-auto'>
                                                            <div className='form-check mt-1'>
                                                                <input className='form-check-input align-middle' type='checkbox' name='select_person' value={item.id} onClick={() => { selectedPerson(index) }} disabled={resultFilters.limit && (selectedEntity.length >= resultFilters.limit) ? true : false} />
                                                            </div>
                                                        </div>
                                                        <div className='col-auto'>
                                                            <div className='btn-list'>
                                                                <LinkButton size='sm' icon='edit' to={!item.is_participant ? `/people/${item.id}/editor` : `/participant/${item.id}/editor`} />
                                                                <LinkButton size='sm' icon='pan' to={!item.is_participant ? `/participant/${item.id}/editor` : `/people/${item.id}/editor`} />
                                                                <Button size='sm' icon='delete' onClick={() => deletePerson(item)} />
                                                                <PreviewButton size='sm' link={!item.is_participant ? `people/${item.id}` : `participant/${item.id}`} />
                                                                <Button size='sm' icon='download' onClick={() => { }} />
                                                                {
                                                                    item.live ? <Button size='live' icon='live' onClick={() => handleNotLive(item.id)} />
                                                                        : <Button size='live' icon='not-live' color='danger' onClick={() => handleLive(item.id)} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ImageViewer url={item.image?.url} type='thumb' cssClass='thumb' />
                                                </td>
                                                <td className='fw-bold'>
                                                    {personName(item)}
                                                </td>
                                                <DesignationList data={item.designations} />
                                                <td className='text-capitalize fw-bold'>{item.type}</td>
                                                <td className='fw-bold fst-italic'>
                                                    <ProfessionList data={item.professions} />
                                                    <OrganizationTypeList data={item.organization_types} />
                                                    <PublicationTypeList data={item.publication_types} />
                                                </td>
                                                <AffiliationList data={item.affiliations} />
                                                {/* <CreditList data={item?.credits} /> */}
                                                <RelatedSiteList data={item?.credited_sites} />
                                                <RelatedSiteList data={item?.related_sites} />
                                                <ImageAlbumsList data={item?.image_albums} />
                                                <td>{item.id}</td>
                                                <TagList data={item.tags} />
                                                <td>
                                                    <LongTextView lines={TABLE_ROWS + 1}>{item.description}</LongTextView>
                                                </td>
                                                <PodcastList data={item.podcasts} />

                                                <AwardList data={item.awards} />
                                                <LocationList data={item.locations} />
                                                <DateList data={item.dates} />
                                                <td></td>
                                                <PlayList data={item.playlists} />
                                                <td></td>
                                                <td></td>
                                                <RelatedPeopleList data={item.related_people} />
                                                <td></td>
                                                <td></td>
                                                <RelatedMapList data={item?.maps} />
                                                <td></td>

                                                <td></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className='d-flex justify-content-between m-3'>
                            <div className='cols'>
                                <Pagination page={page} take={take} count={paginationHits} setPage={setPage} />
                            </div>
                        </div>
                    </Spinner>
                </div>
            </div>

            <ScrollToTop />
        </>
    );
};

export default FeaturedPeople;