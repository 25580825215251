import React, { useState, useEffect, useRef } from "react";
import { uniqBy } from "lodash";
import uniqid from 'uniqid';
import { useSelector, useDispatch } from 'react-redux'
import ImageViewer from "../../components/viewers/image";
import Button from "../../components/buttons/default";
import PodcastSiteName from "./podcastSiteName";
import PodcastUpload from "../podcast/upload";
import { useHistory, useLocation } from 'react-router-dom';
import PreviewButton from "../../components/buttons/preview";
import { getNewItemIndex, personName, newTempId, handleReorder } from "../../helpers";
import swal from "sweetalert";
import Dragula from "react-dragula";
const _api = require('../../api')
const PodcastStopName = ({ stop, id, index }) => {
    const [minimize, setMinimize] = useState(false)
    const [showUpload, setShowUpload] = useState(false);
    const entity = useSelector((state) => state[id])
    const dispatch = useDispatch()
    const onUpdateStopPodcast = async (data, stop_id) => {
        let podcasts = entity.podcasts || []
        let index = getNewItemIndex(podcasts, entity.dates, null)
        let items = (data?.length && Array.isArray(data)) ? data : data?.entityPodcasts
        for (let i in items) {
            let podcast = {
                'id': newTempId(),
                'podcast_id': items[i].id,
                'podcast_name': items[i].name,
                'podcast_type': items[i].type,
                'date_id': null,
                'podcast_image_url': items[i]?.image?.url ? items[i]?.image?.url : entity?.image?.url ? entity?.image?.url : null,
                'podcast_credits': items[i].credits?.length ? personName(items[i].credits[0]) : '',
                'sort': podcasts.length + 1,
                'tour_id': entity.id,
                'stop_id': stop_id
            }
            podcasts.splice(index, 0, podcast)
        }
        podcasts = uniqBy(podcasts, 'podcast_id')
        podcasts.forEach((item, index) => item.sort = index + 1)
        podcasts = await _api['tour'].updatePodcast(entity.id, podcasts)
        dispatch({ type: 'entity', payload: { ...entity, podcasts: podcasts } })
        //refresh dragula
        // setDragula(uniqid())
    }
    return (
        <>
            <div className='tour-stop-bg p-2 mb-4 prevent-select'>
                {entity?.format === 2 && <div className='row g-2 mb-2'>
                    <div className='col-auto'>
                        <div className=''>&nbsp;</div>
                        <div className='tour-stop-number d-flex'>{stop?.sort}</div>
                    </div>
                    <div className='col-auto'>
                        <div className=''>&nbsp;</div>
                        <ImageViewer entityType='image' entityId={stop?.image_id} type='thumb' cssClass='thumb-small' />
                    </div>
                    <div className='col'>
                        <label className='cnow-form-label fw-bold'>Stop Name*</label>
                        <input value={stop?.stop_name ? stop?.stop_name : `Tour Stop ${stop?.sort}`} className={`${stop?.stop_name ? '' : 'cnow-form-title'} form-control cnow-preview-title fw-bold`} disabled />
                    </div>

                    <div className='col-auto'>
                        <div className=''>&nbsp;</div>
                        <div className='btn-list'>
                            <Button size='md' label='Add a Podcast' icon='plus' onClick={() => { dispatch({ type: 'podcasts', payload: [] }); setShowUpload(true);  }} />
                        </div>
                    </div>

                    <div className='col-auto'>
                        <div className=''>&nbsp;</div>
                        <div className='btn-list'><Button icon={`${minimize ? 'arrow-top' : 'arrow-bottom'}`} onClick={() => setMinimize(!minimize)} label={entity?.podcasts?.length > 0 ? entity?.podcasts.filter((elem) => elem.stop_id === stop.id).length : ''} /></div>
                    </div>
                </div>}
                <div className={`${!minimize ? 'd-none' : ''}`}>
                    <PodcastList items={entity?.podcasts?.length > 0 ? entity?.podcasts.filter((elem) => elem.stop_id === stop.id) : []} id={id} />
                </div>

                {stop?.sites?.length > 0 && stop?.sites.map((site, site_index) => < PodcastSiteName site={site} stop_index={index} site_index={site_index} format={entity.format} />)}

                {showUpload && <PodcastUpload show={true} entityId={id} onlyFromDB={false}
                    onHide={() => { setShowUpload(false) }} onSave={(items) => onUpdateStopPodcast(items, stop.id)} key={stop.id}
                />}

            </div>
        </>
    )

}

const PodcastList = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const entity = useSelector((state) => state[props.id])

    // Dragula Start
    const [dragula, setDragula] = useState(uniqid())
    const dragulaRef = useRef()
    const dragulaContainer = 'dragula-container'

    useEffect(() => {
        let containers = []
        containers.push(document.getElementById(dragulaContainer))
        dragulaRef.current = Dragula(containers, {})
        dragulaRef.current.on('drop', () => {
            const items = handleReorder(entity.podcasts, null, dragulaContainer)
            setDragula(uniqid())
            dispatch({ type: 'entity', payload: { ...entity, podcasts: items } })
        })
        // eslint-disable-next-line
    }, [dragula])
    // Dragula End


    const handleEdit = (route) => {
        history.push({ pathname: route })
        dispatch({ type: 'podcasts', payload: { entityPodcasts: props.items, source: location.pathname } })
    }

    const removeItem = (id) => {
        swal({
            title: `Remove Podcast?`,
            text: `Are you sure that you want to remove this podcast?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then(async (status) => {
            if (status) {
                const index = entity.podcasts.findIndex(x => x.id === id)
                if (entity.podcasts[index]) {
                    if (!entity.podcasts[index].id.startsWith('temp.')) await _api['tour'].deletePodcast(entity.podcasts[index].id)
                    entity.podcasts.splice(index, 1)
                    entity.podcasts.forEach((item, index) => item.sort = index + 1)
                    dispatch({ type: 'entity', payload: { ...entity, podcasts: entity.podcasts } })
                }
            }
        })
    }
    return (
        <div id={dragulaContainer} key={dragula} className='dragula-container'>
            {
                props.items?.map((item, index) => {
                    return (
                        <div key={index} id={item?.id} className={`${item?.id} text-left dragula-item d-flex py-2`}>
                            <div style={{ width: '70px' }}>
                                <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={props?.offsetIndex !== undefined ? props.offsetIndex + index + 1 : index + 1} autoComplete='off' />
                            </div>
                            <div style={{ width: '90px' }}>
                                {/* <ImageViewer entityType={'podcast'} entityId={item?.podcast_id} type='thumb@2x' cssClass='thumb' /> */}
                                <div className='position-relative'>
                                    {item?.podcast_image_url &&  <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
                                    {item.podcast_type === 'audio' ? <img src="/img/icons/podcast-outline.svg" style={{ width: '100%', height: '90%'}} alt="playlist" />
                                    : <img src="/img/icons/podcast_video.svg" style={{ width: '100%', height: '70%'}} alt="playlist" />}
                                    </div>}
                                    <ImageViewer url={item?.podcast_image_url} entityType={'podcast'} entityId={item?.podcast_id} type='thumb' cssClass='thumb' />
                                </div>
                            </div>
                            <div className='text-start small flex-grow-1'>
                                <div className='bg-light fw-bold'>{item?.podcast_name} </div>
                                <div>by {item?.podcast_credits}</div>
                            </div>
                            <div className='text-end' style={{ width: '150px' }}>
                                <div className='d-flex gap-1 justify-content-end'>
                                    <PreviewButton size='md' icon='preview' link={`podcast/${item?.podcast_id}`} />
                                    <Button size='md' icon='edit' onClick={() => { handleEdit(`/podcast/${item?.podcast_id}/editor`) }} />
                                    <Button size='md' icon='delete' onClick={() => removeItem(item?.id)} />
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}

export default PodcastStopName