import React, { useState, useEffect } from 'react'
import CollapsableSection from '../../components/collapsableSection';
import StaticImageCard from '../../components/featured/staticImagesCard';
import TextEditor from '../../components/editors/textEditor';
import Spinner from '../../components/spinner';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeaturedTitle from './title';
import StaticContentCard from '../../components/featured/staticContentCard';
import ImagesCardStatic from '../../components/featured/imagesCardStatic';
const imagesLabel = require('../../data/static_images.json')
const StaticImages = ({ section, page }) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [editedIndex, setEditedIndex] = useState(-1);
    const [isContentEdit, setIsContentEdit] = useState(false);
    const [staticImages, setStaticImages] = useState([])
    const [display, setDisplay] = useState(false)
    const searchImages = useSelector((state) => state.search);
    const featured = useSelector((state) => state.featured);
    const static_images = featured[section] || []
    const label = imagesLabel.filter((elem) => elem.section === section)[0]
    // eslint-disable-next-line
    useEffect(() => {
        formatStaticImages();
        if (!static_images[0]?.data?.description && !static_images[0]?.data?.title && section !== 'image_search_for_app') setTitleDescription();
        if (!static_images[0]?.data?.description_web && !static_images[0]?.data?.title_web && !static_images[0]?.data?.description_app && !static_images[0]?.data?.title_app && section === 'image_search_for_app') setTitleDescriptionWebApp()
        // eslint-disable-next-line 
    }, [static_images?.length, static_images])

    const formatStaticImages = () => {
        let data = []
        if (static_images)
            for (let i in static_images) {
                if (static_images[i]?.data[section]) {
                    for (let j in static_images[i].data[section]) {
                        static_images[i].data[section][j].section_index = i
                        static_images[i].data[section][j].entity_index = j
                        data.push(static_images[i].data[section][j])
                    }
                }
            }
        setStaticImages(data)
    }

    const setTitleDescription = () => {
        if (static_images[0]?.data[section]?.length > 0) {
            static_images[0].data.title = label?.title
            static_images[0].data.description = label?.description
            dispatch({ type: 'featured', payload: { ...featured, [section]: static_images } })
        }
    }

    const setTitleDescriptionWebApp = () => {
        if (static_images[0]?.data[section]?.length > 0) {
            static_images[0].data.title_web = label?.title_web
            static_images[0].data.title_app = label?.title_app
            static_images[0].data.description_web = label?.description_web
            static_images[0].data.description_app = label?.description_app
            dispatch({ type: 'featured', payload: { ...featured, [section]: static_images } })
        }
    }

    const handleInputChange = (value, type, id) => {
        static_images[0].edited = true
        if (type === 'description' && value) {
            static_images[0].data.description = value
        }
        if (type === 'name') {
            static_images[0].data.title = value
        }
        if (type === 'title_web') {
            static_images[0].data.title_web = value
        }
        if (type === 'description_web' && value) {
            static_images[0].data.description_web = value
        }
        if (type === 'title_app') {
            static_images[0].data.title_app = value
        }
        if (type === 'description_app' && value) {
            static_images[0].data.description_app = value
        }
        dispatch({ type: 'featured', payload: { ...featured, [section]: static_images } })
    }

    const handleDeleteStaticImages = () => {
        setDisplay(true)
        let id_index = staticImages[editedIndex].section_index
        let id_image_index = staticImages[editedIndex].entity_index
        static_images[id_index].edited = true
        static_images[id_index].data[section].splice(id_image_index, 1)
        dispatch({ type: 'featured', payload: { ...featured, [section]: static_images } })
        setDisplay(false)
        //to refresh Call function Again
        formatStaticImages()
        setEditedIndex(editedIndex - 1)
    }

    const handleRedirect = () => {
        dispatch({ type: 'search', payload: { ...searchImages, [section]: staticImages, selectedResult: [], title: `Static Images - ${label?.title || `Website - App - What's Here`}`, selectedLimit: 1 } })
        history.push(`/featured/searchList/${section}/image`)
    }


    const handleClick = (index) => {
        setEditedIndex(index);
        setIsContentEdit(false)
    }

    return (
        <div>

            <FeaturedTitle title='Featured Sites on the Landing page' page={page} />
            <hr className={`cnow-hr ${page ? 'd-none' : ''}`} />

            <div>
                <CollapsableSection collapsable={true} title="Static Images" count='1 item'
                    disable={page === 'home' ? true : false}
                    button={{
                        close: { show: true, onHandle: () => { console.log('closedd') } }
                    }}>
                    <CollapsableSection textColor="cnow-text-primary" collapsable={true} title={section !== 'image_search_for_app' ? label?.name : 'WEBSITE - VIA THE APP'}
                        page={page}
                        count={staticImages.length > 1 ? `${staticImages?.length} items` : `${staticImages?.length} item`}
                        button={{
                            search: { show: true, onHandle: () => { handleRedirect() } },
                            edit: { show: true, onHandle: () => { setEditedIndex(0) } },
                            add: { show: true, onHandle: () => { } },
                        }}
                        title2={section === 'image_search_for_app' && `APP - WHAT'S HERE`}
                    >
                        <Spinner display={display}>
                            <div className='row'>
                                {staticImages.map((item, index) => (
                                    <div className={`mt-2 col-auto`} style={{ border: `${index === editedIndex ? '3px solid #a82829' : 'none'}` }} onClick={() => { if (page !== 'home') handleClick(index) }}>
                                        <StaticImageCard data={item} />
                                    </div>))
                                }
                            </div>

                            {section !== 'image_search_for_app' && staticImages?.length > 0 && <div className='mt-2' onClick={(e) => { if (page !== 'home') { setIsContentEdit(true); setEditedIndex(-1); } }} style={{ border: `${isContentEdit ? '3px solid #a82829' : 'none'}` }}>
                                <StaticContentCard data={static_images[0]?.data} />
                            </div>}

                            {section === 'image_search_for_app' && staticImages?.length > 0 && <div className='mt-2' onClick={(e) => { if (page !== 'home') { setIsContentEdit(true); setEditedIndex(-1); } }} style={{ border: `${isContentEdit ? '3px solid #a82829' : 'none'}` }}>
                                <div className='row cursor-pointer'>
                                    <div className='col-6'>
                                        <div className='fw-bold bg-light'>{static_images[0]?.data?.title_web}</div>
                                        <div dangerouslySetInnerHTML={{ __html: static_images[0]?.data?.description_web }} className='bg-light small' />
                                    </div>
                                    <div className='col-6'>
                                        <div className='fw-bold bg-light'>{static_images[0]?.data?.title_app}</div>
                                        <div dangerouslySetInnerHTML={{ __html: static_images[0]?.data?.description_app }} className='bg-light small' />
                                    </div>
                                </div>
                            </div>}
                        </Spinner>
                    </CollapsableSection>
                    {(editedIndex !== -1) && staticImages?.length > 0 && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            edit: { show: true, onHandle: () => { history.push(`/image/${staticImages[editedIndex].id}/editor`) } },
                            reset: { show: true, onHandle: () => { setEditedIndex(-1) } },
                            save: { show: true, onHandle: () => { setEditedIndex(-1) } },
                            delete: { show: (true), onHandle: () => { handleDeleteStaticImages() } }
                        }}>
                        <ImagesCardStatic data={staticImages[editedIndex]} key={`ImagePreview-${editedIndex}`} section={'static-images'} imageEditorTitle={`Static Images - ${label?.name || `Website - App - What's Here`} - ${staticImages[editedIndex]?.caption || ''}`} />
                    </CollapsableSection>}

                    {isContentEdit && section !== 'image_search_for_app' && static_images?.[0]?.data?.description && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            save: { show: true, onHandle: () => { setIsContentEdit(false); } },
                        }}>

                        <div className='mt-2 mx-5'>
                            <div>
                                <label className='cnow-form-label fw-bold'>Section Title</label>
                                <input name='name' type='text' className='form-control' value={static_images[0]?.data.title} onChange={(e) => { handleInputChange(e.target.value, 'name') }} autoComplete="off" />
                            </div>
                            <div>
                                <TextEditor data={static_images[0]?.data.description} onChange={(data) => { handleInputChange(data, 'description') }} limit="50"
                                    button={{
                                        reset: { show: true, onHandle: () => { } },
                                    }}
                                    key={`Editor-${editedIndex}`}
                                >
                                    <label className='cnow-form-label mt-2 fw-bold'>Section Description</label>
                                </TextEditor>
                            </div>
                        </div>
                    </CollapsableSection>}

                    {isContentEdit && section === 'image_search_for_app' && static_images?.[0]?.data?.description_web && static_images?.[0]?.data?.description_app && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            save: { show: true, onHandle: () => { setIsContentEdit(false); } },
                        }}>
                        <div className='py-2 bg-light fw-bold px-2'>Website Text</div>
                        <div className='my-2 mx-5'>
                            <div>
                                <label className='cnow-form-label fw-bold'>Section Title</label>
                                <input name='name' type='text' className='form-control' value={static_images[0]?.data.title_web} onChange={(e) => { handleInputChange(e.target.value, 'title_web') }} autoComplete="off" />
                            </div>
                            <div>
                                <TextEditor data={static_images[0]?.data.description_web} onChange={(data) => { handleInputChange(data, 'description_web') }} limit="50"
                                    button={{
                                        reset: { show: true, onHandle: () => { } },
                                    }}
                                    key={`Editor-${editedIndex}-web`}
                                >
                                    <label className='cnow-form-label mt-2 fw-bold'>Section Description</label>
                                </TextEditor>
                            </div>
                        </div>

                        <div className='py-2 bg-light fw-bold px-2'>Phone App Text</div>
                        <div className='my-2 mx-5'>
                            <div>
                                <label className='cnow-form-label fw-bold'>Section Title</label>
                                <input name='name' type='text' className='form-control' value={static_images[0]?.data.title_app} onChange={(e) => { handleInputChange(e.target.value, 'title_app') }} autoComplete="off" />
                            </div>
                            <div>
                                <TextEditor data={static_images[0]?.data.description_app} onChange={(data) => { handleInputChange(data, 'description_app') }} limit="50"
                                    button={{
                                        reset: { show: true, onHandle: () => { } },
                                    }}
                                    key={`Editor-${editedIndex}-app`}
                                >
                                    <label className='cnow-form-label mt-2 fw-bold'>Section Description</label>
                                </TextEditor>
                            </div>
                        </div>
                    </CollapsableSection>}

                </CollapsableSection>
            </div>
        </div>
    )
}

export default StaticImages