import React, { useState, useEffect } from 'react';

import swal from 'sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import ImageUploader from '../../pages/image/upload';
import Button from '../buttons/default';
import ImageViewer from '../viewers/image';
import Checkbox from '../checkbox';
import uniqid from 'uniqid';

import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../spinner';
import { getCredits } from '../../helpers';

const _api = require('../../api')

const EntityImages = (props) => {
    const entity = useSelector(state => state[props.entityId]);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const [expand, setExpand] = useState(true)
    const [uploader, showUploader] = useState(false)
    const [spinner, showSpinner] = useState(false);

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);



    let entityImages
    if (props.imageType === 'image') {
        entityImages = entity?.images?.length > 0 ? entity.images?.filter(x => (x.type === 'image' || x.type === 'dzi') && x.date_id === props.dateId) : []
    }
    else {
        if (props?.entityType === 'tour') {
            entityImages = entity?.images?.length > 0 ? entity.images : []
        } else if (props.imageType === 'image-recognition') {
            entityImages = entity?.images?.length > 0 ? entity.images?.filter(x => x.type === props.imageType) : []
        } else {
            entityImages = entity?.images?.length > 0 ? entity.images?.filter(x => x.type === props.imageType && x.date_id === props.dateId) : []
        }
    }

    useEffect(() => {
        setList(entityImages)
        setIsCheck([])
        // eslint-disable-next-line
    }, [entity.images])

    const addItems = async (items) => {
        if (!entity.images) entity.images = []
        for (let i in items) {
            // let item = items[i]
            entity.images.push({
                'id': `temp.${uniqid()}`,
                'type': props?.entityType === 'tour' ? items[i]?.type : props.imageType,
                'image_id': items[i].id,
                'image_credits': getCredits(items[i].credits),
                'image_caption': items[i].caption,
                'image_url': items[i].url,
                'date_id': props.dateId,
                'location_id': null,
                'sort': items.length + 1,
                [`${props.entityType}_id`]: entity.id
            })

        }

        showSpinner(true)
        entity.images.forEach((item, idx) => item.sort = idx + 1)
        entity.images = await _api[props.entityType].updateImage(entity.id, entity.images)
        dispatch({ type: 'entity', payload: { ...entity, images: entity.images } });
        if (props.onUpdate) props.onUpdate()
        showSpinner(false)
        if (props.handleSort) props.handleSort()
    }

    const editItems = (id) => {
        let imagesList = entity.images.filter(image => image.type !== 'then_now')
        dispatch({ type: 'images', payload: { entityImages: imagesList, source: location.pathname } })
        history.push(`/image/${id}/editor`)
    }

    useEffect(() => {
        if (props.entityType === 'site' || props.entityType === 'map' || props.entityType === 'event' || props.entityType === 'event_series' || props.entityType === 'recipe') {
            if (entity.image_id === null) {
                entity.image_id = entity.images?.length > 0 ? entity.images[0].image_id : null
                entity.image = entity.images?.length > 0 ? { id: entity.images[0]?.image_id, caption: entity.images[0]?.image_caption, url: entity.images[0]?.image_url } : null
                _api[props.entityType].update(entity);
                dispatch({ type: 'entity', payload: { ...entity, image_id: entity.image_id, image: entity.image } })
            }
        }
        // eslint-disable-next-line
    }, [entity.images])

    const removeItem = (id) => {
        swal({
            title: `Remove Image?`,
            text: `Are you sure that you want to delete this image?`,
            buttons: ['Cancel', 'Yes'],
            icon: 'warning',
            dangerMode: true,
        }).then(async (status) => {
            if (status) {
                let copyOfImages = [...entity.images]
                const index = copyOfImages.findIndex(x => x.image_id === id)
                if (index >= 0) {
                    if (!copyOfImages[index].id.startsWith('temp.')) await _api[props.entityType].deleteImage(copyOfImages[index].id)
                    copyOfImages.splice(index, 1)
                    if (props.entityType === 'site' || props.entityType === 'map') {
                        if (entity.images[index].image_id === entity.image?.id) {
                            entity.image = copyOfImages.length > 0 ? { id: copyOfImages[0]?.image_id, caption: copyOfImages[0]?.image_caption, url: copyOfImages[0]?.image_url } : null
                            entity.image_id = copyOfImages.length === 0 ? copyOfImages[0]?.image_id : null
                        }
                        if (entity.images[index]?.image_id !== entity.image?.id) {
                            entity.image_id = copyOfImages[0]?.image_id
                        }
                        if (entity.images?.length === 0) {
                            entity.image_id = null
                            entity.image = {}
                        }
                    }
                    copyOfImages.forEach((item, index) => item.sort = index + 1)
                    dispatch({ type: 'entity', payload: { ...entity, images: copyOfImages, image: entity.image, image_id: entity.image_id } })
                    await _api[props.entityType].update(entity)
                }
            }
        })
    }

    const handleSelectAll = (e) => {
        let newArr = []
        setIsCheckAll(!isCheckAll);
        newArr = list.map(li => li.image_id)
        setIsCheck(newArr);
        if (isCheckAll) {
            newArr = []
            setIsCheck(newArr);
        }
        props.onSelect(newArr)
    };

    const handleClick = (e) => {
        const { id, checked } = e.target;
        let newArr = [...isCheck, id]
        setIsCheck(newArr);
        if (!checked) {
            newArr = newArr.filter(item => item !== id)
            setIsCheck(newArr);
        }
        props.onSelect(newArr)
    };


    return (
        <div className='mt-3'>
            <Spinner display={spinner}>
                <div className='cnow-form-title'></div>
                <div className='row mb-2'>
                    <div className='col'>
                        <label className='cnow-form-title'>{props.title}</label>
                    </div>
                    <div className='col-auto'>
                        <Button size='sm' icon='plus' label={props.buttonLabel || props.imageType} onClick={() => showUploader(true)} />
                    </div>
                </div>

                <table className='table'>
                    <thead className='bg-light'>
                        <tr className='small'>
                            <td style={{ width: props.widthOfOrder ? props.widthOfOrder : '70px' }}>Order</td>
                            {props.showCheckbox && <td style={{ width: '40px' }}>
                                <div className='form-check m-0 align'>
                                    <Checkbox type='checkbox' id='selectAll' handleClick={handleSelectAll} isChecked={isCheckAll} />
                                </div>
                            </td>}
                            <td style={{ width: props.widthOfMedia ? props.widthOfMedia : '90px', paddingLeft: props.paddingLeftMedia ? props.paddingLeftMedia : "0" }}>Media</td>
                            <td style={{ paddingLeft: props.paddingLeftPhoto ? props.paddingLeftPhoto : "0" }}>Photo Title, Credits</td>
                            {
                                props.entityType === 'map' &&
                                <td style={{ width: '40px' }}>Type</td>
                            }
                            <td className='text-end text-capitalize' style={{ width: '150px' }}>
                                {entityImages && entityImages.length > 0 ? entityImages.length : 0} {props.imageType}
                                {expand === true && <i className='fe fe-chevron-down ms-1' style={{ 'cursor': 'pointer' }} onClick={() => setExpand(false)} />}
                                {expand === false && <i className='fe fe-chevron-up ms-1' style={{ 'cursor': 'pointer' }} onClick={() => setExpand(true)} />}
                            </td>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            expand === true && entityImages.map((item, index) => {
                                return (
                                    <tr key={index} className='text-left sort-images' id={item.id}>
                                        <td className='ps-0'>
                                            <input type='text' className='form-control form-control-sort' disabled name='sort' value={props?.sort === 'index' ? index + 1 : (props?.offsetIndex !== undefined ? props?.offsetIndex + index + 1 : item.sort)} autoComplete='off' />
                                        </td>
                                        {props.showCheckbox && <td>
                                            <div className='form-check m-0 align'>
                                                <Checkbox type='checkbox' id={item?.image_id} handleClick={handleClick} isChecked={isCheck.includes(item.image_id)} />
                                            </div>
                                        </td>}
                                        <td>
                                            <ImageViewer url={item?.image_url} type='thumb' cssClass='thumb' />
                                        </td>
                                        <td>
                                            <div>{item?.image_caption}</div>
                                            <div className='small'>© {item?.image_credits}</div>
                                        </td>
                                        {
                                            props.entityType === 'map' &&
                                            <td>{item.type}</td>
                                        }
                                        <td className='pe-0'>
                                            <div className='d-flex gap-1 justify-content-end'>
                                                <Button size='sm' icon='edit' onClick={() => editItems(item.image_id)} />
                                                <Button size='sm' icon='delete' onClick={() => removeItem(item.image_id)} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                {
                    uploader && <ImageUploader globalSearch={true} show={uploader}
                        onHide={() => showUploader(false)}
                        onSave={async (items) => { await addItems(items); showUploader(false) }}
                        items={entityImages}
                    />
                }
            </Spinner>
        </div>
    )
}

export default EntityImages