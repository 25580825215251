/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { uniqBy } from "lodash";
import { useSelector } from 'react-redux';
import LinkButton from '../../components/buttons/link';
import PreviewButton from '../../components/buttons/preview';
import Button from '../../components/buttons/default';
import ImageViewer from '../../components/viewers/image';
const _api = require('../../api');
const PeopleFeaturedProject = (props) => {
    const event = useSelector(state => state[props.id]);
    const [featuredProjects, setFeaturedProjects] = useState([])
    const featuredEvent = event?.featured_people?.filter((elem) => elem.featured)
    useEffect(() => { handleFeaturedProjects() }, [featuredEvent?.length])

    const handleFeaturedProjects = () => {
        let personData = uniqBy(featuredEvent, 'person_id')
        let data = []
        for (let index in personData) {
            let elem = event?.featured_projects?.filter((elem) => elem?.person_id === personData[index]?.person_id)
            data.push({
                person_name: elem[0]?.person_name,
                person_sites: elem
            })
        }
        if (data?.length > 0) setFeaturedProjects(data)
    }

    const handleHighlightChange = async (index, siteIndex) => {
        await _api.event.updateFeaturedProjects(featuredProjects[index]?.person_sites[siteIndex]?.id, !featuredProjects[index]?.person_sites[siteIndex]?.featured)
        let data = [...featuredProjects]
        data[index].person_sites[siteIndex].featured = !data[index].person_sites[siteIndex].featured
        setFeaturedProjects(data)
    }


    return (
        <>
            <hr className='cnow-hr' />
            <div className='row'>
                <div className='col'>
                    <label className='cnow-form-title'>Featured Projects</label>
                </div>
            </div>

            <div>
                {
                 featuredProjects?.length > 0 && featuredProjects.map((item, index) => {
                        return (
                            <div className={item?.person_sites?.length > 0 ?`podcast-card py-2 my-2` : ''}>
                                <div className='cnow-form-title'> 
                                    {item?.person_name}
                                </div>
                                {item?.person_sites?.length > 0 && item?.person_sites.map((site, siteIndex) => {
                                    return (
                                        <div className='row g-1 mt-1 dragula-item' key={siteIndex} id={site.id}>
                                            {
                                                item?.person_sites?.length > 1 &&
                                                <div className='col-auto' >
                                                    {siteIndex === 0 && <label className='cnow-form-label fw-bold'>Order</label>}
                                                    <input type='text' id={`${site.site_name}, ${site.site_id}`} className='nwprime form-control form-control-sort' disabled name='sort' placeholder={siteIndex + 1} autoComplete="off" />
                                                </div>
                                            }
                                            <div className='col-auto' >
                                                {siteIndex === 0 && <div>&nbsp;</div>}
                                                <ImageViewer entityType={'site'} entityId={site.site_id} type='thumb@2x' cssClass='thumb' />
                                            </div>
                                            <div className='col-auto d-flex flex-grow-1'>
                                                <div className='w-100'>
                                                    {siteIndex === 0 && <label className='cnow-form-label fw-bold'>Associated Site Name *</label>}
                                                    <input value={site?.site_name} disabled className='w-100 cnow-input-disabled-height' />
                                                </div>
                                            </div>
                                            <div className='col-auto'>
                                                {siteIndex === 0 && <label className='cnow-form-label'><div>&nbsp;</div></label>}
                                                <div className='btn-list me-3'>
                                                    <PreviewButton link={`/site/${site.site_id}`} />
                                                    <LinkButton icon='edit' to={`/site/${site.site_id}/editor`} />
                                                    <Button icon={`${site.featured ? 'star-red' : 'star-black'}`} onClick={() => handleHighlightChange(index, siteIndex)} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })
                }
            </div>

        </>
    )
}

export default PeopleFeaturedProject;