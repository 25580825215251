/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from "react-redux";
import PreviewButton from "../buttons/preview";
import LinkButton from "../buttons/link";
import ImageViewer from "../viewers/image";

const CuratorPlaylist = (props) => {
  const entity = useSelector((state) => state[props.id]);

  return (
    <>
      <hr className='cnow-hr' />
      <div className='d-flex justify-content-between'>
        <div className='cnow-form-title'>More Playlist by Curator</div>
      </div>
      <div className="table table-borderless">
        {entity.curator_playlist?.length > 0 && entity.curator_playlist?.map((item, index) => {
          return (
            <div key={'curator_ playlist' + index} className='mb-3'>
              <div className='row g-1'>
                <div className={entity.curator_playlist?.length > 0 ? "col-auto" : "d-none"}>
                  <label className='cnow-form-label fw-bold'>Order</label>
                  <input type='text' className='form-control form-control-sort' disabled name='sort' value={index + 1} autoComplete='off' />
                </div>
                <div className={entity.curator_playlist?.length > 0 ? "col-auto" : ""}>
                  <div className=''>&nbsp;</div>
                  {/* <ImageViewer url={item?.url} type='thumb' cssClass='thumb' key={item?.id} /> */}
                  <div className='position-relative'>
                    <div className='d-flex justify-content-center align-items-center w-100 h-100 position-absolute'>
                      {item?.url && <img src="/img/icons/playlist.svg" style={{ width: '100%', height: '100%'}} alt="playlist" />}
                    </div>
                    <ImageViewer url={item?.url} type='thumb' cssClass='thumb' key={item.id} />
                  </div>
                </div>
                <div className='col'>
                  <div className='row g-1'>
                    <div className='col'>
                      <div className='w-100'>
                        <label className='cnow-form-label fw-bold'>Playlist</label>
                        <input value={item?.playlist_name} disabled className='w-100' style={{ height: '38px' }} />
                      </div>
                    </div>
                    <div className='col-auto'>
                      <label className='cnow-form-label'></label>
                      <div className='btn-list'>
                        <PreviewButton size='md' link={`playlist/${item?.playlist_id}`} />
                        <LinkButton icon='edit' size='md' to={`/playlist/${item?.playlist_id}/editor`} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
        }
      </div>
    </>
  );
};

export default CuratorPlaylist;