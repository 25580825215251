import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Button from '../../components/buttons/default';
import Icon from '../../components/viewers/icon';

const menu = require('../../data/menu_event.json');

const EventMenu = (props) => {
    const dispatch = useDispatch();

    const event = useSelector(state => state[props.id]);
    const menuSize = useSelector((state) => state.menu);

    const toggleMenuSize = () => dispatch({ type: 'menu', payload: menuSize === 'sm' ? 'lg' : 'sm' })

    const hasData = (path) => {
        let hasData = false;

        if (path === 'title') hasData = event?.name?.length > 0 || event?.categories?.length || event?.themes?.length;
        if (path === 'location') hasData = event?.locations?.length > 0;
        if (path === 'date_time') hasData = event?.dates?.length > 0;
        if (path === 'people') hasData = event?.participants?.length > 0 || event?.hosts?.length > 0 || event?.sponsors?.length > 0 || event?.related_people > 0;
        if (path === 'special_features') hasData = event?.recipes?.length > 0;
        if (path === 'about') hasData = event?.description?.length > 0 || event?.tags?.length > 0 || event?.stories?.length > 0 || event?.related_sites?.length > 0;
        if (path === 'events_tours') hasData = event?.related_events?.length > 0 || event?.related_tours?.length
        if (path === 'attach_map') hasData = event?.related_maps?.length > 0;
        if (path === 'featured_projects') hasData = event?.featured?.length > 0;
        if (path === 'images') hasData = event?.images?.length > 0;
        if (path === 'podcast') hasData = event?.podcasts?.length > 0 || event?.playlists?.length > 0;
        if (path === 'on_this_day') hasData = (event?.images?.length > 0 && event?.images?.filter((item) => item?.type === 'then_now')?.length > 0) || event?.on_this_day?.length > 0
        if (path === 'tickets') hasData = event?.reservations?.length > 0 || event?.tickets?.length > 0 || event?.continuing_education > 0;
        if (path === 'meta') hasData = event?.meta?.description?.length > 0 || event?.meta?.keywords?.length > 0 || event?.meta?.og_title?.length > 0
        if (path === 'legal') hasData = event?.images?.filter((elem) => !elem?.is_legal && elem?.type !== 'then_now')?.length > 0 || event?.podcasts?.filter((elem) => !elem?.is_legal)?.length > 0
        else if (path === 'curator') hasData = event?.curator !== null || event?.images?.filter((elem) => elem?.is_legal && elem?.type !== 'then_now')?.length > 0 || event?.podcasts?.filter((elem) => elem?.is_legal)?.length > 0

        return hasData;
    }

    return (
        <>
            {
                menuSize === 'sm' && <>
                    <Button size='sm' color='light' icon='chevrons-right' onClick={() => toggleMenuSize()} />
                    <div className='btn-list mt-4 text-center'>
                        {
                            menu.map((item, index) => {
                                let icon = item?.icon;
                                if (hasData(item.path)) icon = `${item.icon}-done`;
                                if (props.section === item.path) icon = `${item.icon}-active`

                                return (
                                    <div key={index} className='mt-2'>
                                        <OverlayTrigger placement='right' overlay={<Tooltip>{item?.name}</Tooltip>}>
                                            <button className='btn' onClick={() => { if (!props.display) props.onClick(item.path) }} data-bs-toggle='tooltip' data-bs-placement='right' title='Tooltip on right'>
                                                <Icon name={icon} size={24} />
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }

            {
                menuSize === 'lg' && <>
                    <div className='d-flex justify-content-between'>
                        <h5>Add a Event</h5>
                        <Button size='sm' color='light' icon='chevrons-left' onClick={() => toggleMenuSize()} />
                    </div>

                    <ul className='list-group'>
                        {
                            menu.map((item, index) => {
                                let itemClass = 'list-group-item cnow-list-group-item text-start';
                                if (props.section === item.path) itemClass = `${itemClass} active`;
                                if (!item.enabled) itemClass = `${itemClass} disabled`
                                if (!event?.id) itemClass = `${itemClass} disabled`;
                                return (
                                    <li key={index} className={itemClass}>
                                        <div className='row g-1'>
                                            <div className='col-auto pt-1'>
                                                <i className={`fe fe-check ${hasData(item?.path) ? '' : 'cnow-text-light'}`} />
                                            </div>
                                            <div className='col'>
                                                <button className={itemClass} onClick={() => { if (!props.display) props.onClick(item.path) }}>

                                                    {item.name}
                                                    {item.required && <span className='ms-1'>*</span>}
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            item.path === 'location' && event?.locations && event?.locations.map((location, index) => {
                                                let subItemClass = 'list-group-item cnow-list-group-item text-start';
                                                if (props.sectionId === location.id) subItemClass = `${subItemClass} active`;

                                                return (
                                                    <div className='small ms-5' key={index}>
                                                        <button className={subItemClass} onClick={() => { if (!props.display) props.onClick(`${item.path}/${location.id}`) }} >
                                                            {location.name || `Location ${index + 1}`}
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </li>
                                );
                            })
                        }
                    </ul>

                </>
            }
        </>
    )
}

export default EventMenu;