import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/buttons/default';
import PreviewButton from '../../components/buttons/preview';
import Name from '../../components/preview/name';
import { chain } from "lodash";
import MapsList from '../../components/preview/mapsList';
import MetaDescription from '../../components/preview/metaDescription';
import LocationList from '../../components/preview/locationList';
import swal from 'sweetalert'
import EventHost from '../../components/preview/eventHost';
import RecipeImages from '../../components/preview/recipeImages';
import PodcastList from '../../components/preview/podcastList';
import RelatedSiteList from '../../components/preview/relatedSiteList';
import EventList from '../../components/preview/eventList';
import Playlist from '../../components/preview/playlist';
import EventSeriesList from '../../components/preview/relatedEventSeries';
import ImageViewer from '../../components/viewers/image';
import About from '../../components/preview/about';
const _api = require('../../api');

const RecipePreview = (props) => {
    const dispatch = useDispatch();
    const recipe = useSelector(state => state[props.id]); 
    const previewSize = useSelector((state) => state.preview);
    const [eventPodcast, seteventPodcast] = useState([]);
    useEffect(() => {
        getAllEventPodcast()/* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    const togglePreviewSize = () => dispatch({ type: 'preview', payload: previewSize === 'sm' ? 'lg' : 'sm' })


    const handleLive = (recipeId) => {
        if (recipe.id === 'NEW') {
            swal({
                title: 'Recipe cannot go Live.',
                icon: 'error',
                text: `${recipe?.id === 'NEW' && 'Fill the mandatory fields * to make the recipe go live.'}`,
                buttons: 'Go back',
                dangerMode: true,
            })
        }
        else if (recipe?.name) {
            swal({
                title: 'Recipe is Live!',
                icon: 'success',
                text: `${recipe?.name} is now Live.`,
                buttons: 'Ok',
            })
            _api.recipe.updateLive(recipeId, true).then(() =>
                dispatch({ type: 'entity', payload: { ...recipe, live: true } })
            )
        } else {
            swal({
                title: 'Recipe cannot go Live.',
                icon: 'error',
                text: `${'Please fill the mandatory fields:'}
					${recipe?.name ? '' : 'Title is missing.'}`,
                buttons: 'Go back',
                dangerMode: true,
            })
            _api.recipe.updateLive(recipeId, false).then(() =>
                dispatch({ type: 'entity', payload: { ...recipe, live: false } })
            )
        }

    }

    const handleNotLive = (recipeId) => {
        _api.recipe.updateLive(recipeId, false).then(() =>
            dispatch({ type: 'entity', payload: { ...recipe, live: false } })
        )
        swal({
            title: 'Not Live!',
            icon: 'info',
            text: `${recipe?.name} is now Not Live.`,
            buttons: 'Ok',
        })

    }
    let recipePeople = chain([...(recipe?.people || [])]).groupBy("role_name").map((value, key) => ({ role_name: key, people: value })).value()

    const getAllEventPodcast = () => {
        let allEventPodcast = []
        if (recipe?.related_events) {
            for (let result of recipe?.related_events) {
                if (result?.podcasts) { for (let images of result?.podcasts) { allEventPodcast.push(images) } }
            }
            seteventPodcast(allEventPodcast)
        }
    }
    return (
        <>
            <>
                {
                    previewSize === 'sm' && <div className='btn-grid'>
                        <Button size='sm' color='danger' icon='save-white' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
                        <PreviewButton size='sm' color='light' link={`recipe/${recipe?.id}`} />

                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}

                        <Button size='sm' color='light' icon='help' />
                    </div>
                }

                {
                    previewSize === 'lg' && <div className='btn-list action-bar'>
                        <Button size='sm' color='light' label='Instructions' icon='help' />
                        {recipe?.live ? (<Button size='sm' color='light' label='Live' onClick={() => { handleNotLive(recipe.id) }} />)
                            : (<Button size='sm' color='danger' label='Not Live' onClick={() => { handleLive(recipe.id) }} />)}
                        <Button size='sm' color='danger' label='Save' onClick={() => props.onUpdate()} disabled={(!props.display) ? false : true} />
                        <PreviewButton size='sm' color='light' link={`recipe/${recipe?.id}`} />
                        {previewSize === 'sm' && <Button size='sm' color='light' icon='chevrons-left' onClick={() => togglePreviewSize()} />}
                        {previewSize === 'lg' && <Button size='sm' color='light' icon='chevrons-right' onClick={() => togglePreviewSize()} />}
                    </div>
                }
            </>

            {previewSize === 'lg' && <div className='preview'>
                {
                    props.section !== 'location' && props.section !== 'attach_map' && props.section !== 'meta' && props.section !== 'images' && props.section !== 'about' && <>
                        <div className='cnow-bg-red text-white mt-2 fs-5'>Preview</div>
                        <Name data={recipe?.name} />
                        <div> {recipe?.location}</div>
                        {recipePeople?.length > 0 &&
                            recipePeople?.map((item, index) => { return (<> <EventHost data={item?.people} title={item.role_name} />  </>) })}
                        {
                            recipe?.podcasts?.length > 0 ? (<PodcastList data={recipe?.podcasts} />) : (eventPodcast?.length > 0 ? (<PodcastList data={eventPodcast} />) : (''))
                        }
                        <RelatedSiteList data={recipe?.site} />
                        <EventList data={recipe?.event} type={"recipe"} />
                        <EventSeriesList data={recipe?.event_series} type={"recipe"} />
                        <Playlist data={recipe?.playlist} />

                        <div className='cnow-bg-red text-white mt-2 fs-5 mb-2'>Preview</div>
                        <ImageViewer entityId={recipe?.image_id} entityType={"image"} type="banner@3x" cssClass="banner" />
                        {
                            recipe?.description?.length > 0 &&
                            <>
                                <About data={recipe?.description} />
                            </>
                        }
                        {
                            recipe?.sections?.length > 0 &&
                            <>
                                <div className='cnow-form-title cnow-preview-title'>{recipe?.type_name}</div>
                                {
                                    recipe?.sections?.map((item, index) => {
                                        return (
                                            <>
                                                <div className='fw-bold'>{item?.title}</div>
                                                <div><span dangerouslySetInnerHTML={{ __html: item?.description }} /></div>
                                            </>
                                        )
                                    })}
                            </>}
                        {<RecipeImages id={recipe?.id} />}
                    </>

                }

                {recipe && props.section === 'location' && !props.locationId && <recipeMap id={props?.id} type={'recipe'} height='70vh' column='flex-column-reverse' />}
                {recipe && props.section === 'location' && props.locationId && <LocationList id={recipe.id} height='70vh' locationId={props.locationId} type={'recipe'} column='flex-column-reverse' />}
                {recipe && props.section === 'attach_map' &&
                    <>
                        <MapsList id={recipe.id} type='recipe' />
                        <recipeMap id={props?.id} height='70vh' type={'recipe'} />
                    </>
                }

                {recipe && props.section === 'about' && <>{recipe?.description?.length > 0 && <> <About data={recipe?.description} /> </>}
                    {recipe?.sections?.length > 0 && <>
                        <div className='cnow-form-title cnow-preview-title'>{recipe?.type_name}</div>
                        {recipe?.sections?.map((item, index) => {
                            return (
                                <>
                                    <div className='fw-bold'>{item?.title}</div>
                                    <div><span dangerouslySetInnerHTML={{ __html: item?.description }} /></div>
                                </>
                            )
                        })}
                    </>
                    }</>}
                {recipe && props.section === 'images' && <RecipeImages id={recipe?.id} />}
                {recipe && props.section === 'meta' && <MetaDescription data={recipe?.meta} id={recipe?.id} type='recipe' />}
            </div>}
        </>
    );
};

export default RecipePreview;