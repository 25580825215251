import React, { useState } from "react";
import { useSelector, useDispatch, } from 'react-redux'
import PageTitle from '../../components/pageTitle';
import ImageViewer from "../../components/viewers/image";
import Button from "../../components/buttons/default";
import PreviewButton from '../../components/buttons/preview';
import EventSearch from "./eventSearch";
import { changeUTC, newTempId } from '../../helpers';
const _api = require('../../api')
const FeaturedEvent = (props) => {
  const event = useSelector(state => state[props.id])
  const dispatch = useDispatch()
  const [showUpload, setShowUpload] = useState(false);
  const entity = useSelector((state) => state[props.id]);


  const onUpdate = async (data) => {
    let items = data

    if (entity.featured?.length === 0) entity.featured = []
    if (items?.length > 0) {
      for (let i in items) {
        entity.featured.push({
          'id': newTempId(),
          'event_id': data[i].id,
          'participants': data[i].participants,
          'description': '',
          "event_name": data[i].name,
          "type": data[i].type,
          'sort': entity.featured ? entity.featured.length + 1 : 1,
        })
      }
    }
    entity.featured.forEach((item, index) => item.sort = index + 1)
    dispatch({ type: 'entity', payload: { ...entity, featured: entity.featured } });
  }

  const removeEvent = async (id) => {


    const index = entity.featured.findIndex(x => x.id === id)
    if (entity.featured[index]) {
      if (!entity.featured[index].id.startsWith('temp.'))
        await _api.event_series.delete_event(entity.featured[index].id)

      entity.featured.splice(index, 1)
      entity.featured.forEach((item, index) => item.sort = index + 1)
      dispatch({ type: 'entity', payload: { ...entity, featured: entity.featured } })
    }
  }

  // const handleEdit = (route) => {
  //   window.open({ pathname: route })
  //   // dispatch({ type: 'podcasts', payload: { entityPodcasts: entity.podcasts, source: location.pathname } })
  // }

  const FeaturedEventItem = ({ item, index }) => {
    const [minimize, setMinimize] = useState(false);

    return (<div className='tour-stop-bg p-2 mb-4 prevent-select'>
      {<div className='row g-2 mb-2'>
        <div className='col-auto'>
          <div className=''>&nbsp;</div>
          <div style={{ width: '70px' }}>
            <input type='text' className='form-control form-control-sort' disabled name='sort' placeholder={props?.offsetIndex !== undefined ? props.offsetIndex + index + 1 : index + 1} autoComplete='off' />
          </div>
        </div>
        <div className='col-auto'>
          <div className=''>&nbsp;</div>
          <ImageViewer entityType='event' entityId={item.event_id} type='thumb' cssClass='thumb-small' />
        </div>
        <div className='col'>
          <label className='cnow-form-label fw-bold'>Event Title</label>
          <input value={item.event_name} className={'cnow-form-title form-control text-black cnow-preview-title fw-bold'} disabled />
        </div>


        <div className='col-auto'>
          <div className=''>&nbsp;</div>
          <div className='btn-list'><Button icon={`${minimize ? 'arrow-top' : 'arrow-bottom'}`} onClick={() => setMinimize(!minimize)} />
            <PreviewButton size='md' icon='preview' link={`event/${item?.event_id}`} />
            <Button size='md' icon='edit' onClick={() => {
              window.open(`/event/${item?.event_id}/editor`)
            }} />
            <Button size='md' icon='delete' onClick={() => removeEvent(item?.id)}

            />
          </div>
        </div>
      </div>}
      <div className={`${!minimize ? 'd-none' : ''}`}>
        <div>
          <div className="row">
            <div className="col">
              <div className="col fw-bold"> Participtants</div>
              {item?.participants?.length > 0 &&
                item?.participants?.map((items, index) => {
                  return (
                    <div className="col"> {items.person_name}</div>
                  )
                })
              }
            </div>
            <div className="col">
              <div className="col fw-bold"> Type of Event</div>
              {item?.types?.length > 0 &&
                item?.types?.map((items, index) => {
                  return (
                    <div className="col"> {items.type_name}</div>
                  )
                })
              }
            </div>
            <div className="col ">
              <div className="col  fw-bold">date & time</div>
              {item?.dates?.length > 0 &&
                item?.dates?.map((items, index) => {
                  return (
                    <div className="col">{changeUTC(items.start_date)}</div>
                  )
                })
              } </div>
          </div>
        </div>
      </div>

    </div>

    )
  }

  return (
    <div>
      <PageTitle title={event?.name} />
      <hr className='cnow-hr' />
      <div className='d-flex justify-content-end align-items-end mb-2'>

        <div>
          <Button size='md' icon='plus' label='Add a Event' onClick={() => setShowUpload(true)} />
        </div>
      </div>

      {entity?.featured?.length > 0 && entity?.featured.map((item, index) => {
        return (
          <FeaturedEventItem key={item.id} item={item} index={index} />

        )
      })

      }

      {
        showUpload && (
          <EventSearch show={true} entityId={props?.id} onSave={async (items) => await onUpdate(items)}
            onHide={() => { setShowUpload(false) }}


          />
        )
      }

    </div>
  )
}

export default FeaturedEvent