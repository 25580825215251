import React, {useState} from 'react'
import PageTitle from '../../components/pageTitle';
import CollapsableSection from '../../components/collapsableSection';
// import { useHistory } from 'react-router-dom';
// import AsyncCreatableSelect from 'react-select/dist/declarations/src/AsyncCreatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { customSelectStyle } from '../../helpers';




const SiteOfTheDay = () => {
    // const history = useHistory();
    const [selected, setSelected] = useState("");
    // const data = {
    //     tag: {
    //         title: "Hash Tag",
    //         values: []
    //     },
    //     personOrg: {
    //         title: "Person/Org",
    //         values: []
    //     },
    //     event: {
    //         title: "Events",
    //         values: []
    //     },
    //     tour: {
    //         title: "Tour",
    //         values: []
    //     },
    // }
    const options = [
        {label: "HASH TAG", value: "tag"}, 
        {label: "PERSON/ORG", value: "personOrg"}, 
        {label: "EVENTS", value: "event"}, 
        {label: "TOUR", value: "tour"}
    ]

    const [data, setData] = useState([{
        tag: {
            title: "Hash Tag",
            values: []
        },
        personOrg: {
            title: "Person/Org",
            values: []
        },
        event: {
            title: "Events",
            values: []
        },
        tour: {
            title: "Tour",
            values: []
        },
    }]);
    
    
    const handleSelect = (event) => {
        // console.log("event.value", event.value);
        setSelected(event.value);
        // console.log("data[selected].values", data[event.value].values);
        // setTagArray(data[event.value].values);
        // console.log("selected", selected);
    }

    const searchTags = async (keyword) => {
        // let response = await _api.global_list.getAll('tags', keyword);
        // let items = [];
        // response.forEach(data => items.push({ 'value': data.id, 'label': data.name, id: newTempId() }));
        // if (items.length === 0) items.push({ 'value': 'NEW', 'label': keyword, 'data': keyword, id: newTempId() })
        // return items;
        let items = [
            {
                "value": "284816d5-0784-4048-9ad7-de9559a2509d",
                "label": "Academic",
                "id": "temp.lgbwn540"
            },
            {
                "value": "187d46ae-cd52-48bb-960f-c5e1447ce2ad",
                "label": "Academy Theatre",
                "id": "temp.lgbwn541"
            },
            {
                "value": "6f575e18-ee1c-4995-a633-9a51dd4a9a43",
                "label": "Activities",
                "id": "temp.lgbwn542"
            },
            {
                "value": "fdc2fc1f-a31c-4145-a683-da6cb38b35e8",
                "label": "ADA",
                "id": "temp.lgbwn543"
            },
            {
                "value": "eae605af-96d0-4881-8d48-47ac1e36eff6",
                "label": "adaptive re-use",
                "id": "temp.lgbwn544"
            },
            {
                "value": "374e99ba-d799-4ca1-a323-03de119167f5",
                "label": "A+D Museum",
                "id": "temp.lgbwn545"
            },
            {
                "value": "913c51e3-bf45-422b-96ad-6df4f5b17fb4",
                "label": "African American",
                "id": "temp.lgbwn546"
            },
            {
                "value": "1da9dfb2-e186-4b7c-a84f-bbd066f52f30",
                "label": "agriculture",
                "id": "temp.lgbwn547"
            },
            {
                "value": "7fb28837-0450-4e13-b151-67233a04a89d",
                "label": "ahmedabad",
                "id": "temp.lgbwn548"
            },
            {
                "value": "ed5e986e-8ac9-410e-bb7c-5cbe680848b5",
                "label": "AIA",
                "id": "temp.lgbwn549"
            },
            {
                "value": "cfb020a5-07c8-47ed-ad05-abfd6d6a30c2",
                "label": "AIA LA",
                "id": "temp.lgbwn54a"
            },
            {
                "value": "441679d2-54cc-49f7-8955-4f3f29d8399c",
                "label": "AIA NC",
                "id": "temp.lgbwn54b"
            },
            {
                "value": "1956dada-5a59-452e-9fec-0d9ffbdeb9c6",
                "label": "Alan Voo House",
                "id": "temp.lgbwn54c"
            },
            {
                "value": "69972d0a-f314-4863-8542-40592d608136",
                "label": "album",
                "id": "temp.lgbwn54d"
            },
            {
                "value": "eed6861f-fd07-4609-a94b-f94a08055a82",
                "label": "america",
                "id": "temp.lgbwn54e"
            },
            {
                "value": "2f98ac7b-121a-4a34-a622-7ae3e4cfe4d5",
                "label": "americanrevolution",
                "id": "temp.lgbwn54f"
            },
            {
                "value": "c8d917ca-b0d9-42ca-9df4-f0c20e44f2c1",
                "label": "ancient",
                "id": "temp.lgbwn54g"
            },
            {
                "value": "f092948c-f442-458f-bf60-c52568cfc893",
                "label": "Animals",
                "id": "temp.lgbwn54h"
            },
            {
                "value": "b9253f7d-7c98-43ae-a103-4277abaf75f6",
                "label": "Animation",
                "id": "temp.lgbwn54i"
            },
            {
                "value": "ad3e96d0-1446-4c3b-b6cc-032ba11fef6e",
                "label": "Animo High School",
                "id": "temp.lgbwn54j"
            },
            {
                "value": "dba1b5ae-7bb5-4112-9eb1-a58867b2605a",
                "label": "Annenberg",
                "id": "temp.lgbwn54k"
            },
        ];
        keyword && await items.filter((item) => {
            return item.startsWith(keyword);
        })
        return items;
    }

    const handleChange = async (items, e) => {
        console.log("handleChange items", items);
        // console.log("handleChange e", e);
        console.log(items.label);
        // if(data[selected].values.indexOf(items.label) === -1){
            // data[selected].values = [...data[selected].values,items.label]
           
            // setTagArray( [...tagArray, items.label])
            setData((prev)=> [{...prev, tag:{title:'Hash Tag', values:[items.label]}}])
            console.log("data[selected].values", data);
        // }
        // if (e.action === 'select-option') {
        //     entity.tags = [];

        //     for (let i = 0; i < items.length; i++) {
        //         let item = items[i];
        //         if (item.value === 'NEW') {
        //             const response = await _api.global_list.create('tags', { name: item.label })
        //             item = { 'value': response.id, 'label': response.name, 'id': newTempId() }
        //         }
        //         entity.tags.push({ 'tag_id': item.value, 'tag_name': item.label, 'id': item?.id })
        //     }
        //     dispatch({ type: 'entity', payload: { ...entity, tags: entity.tags } });
        // }
        // if (e.action === 'remove-value') {
        //     entity.tags = [];
        //     for (let i = 0; i < items.length; i++) {
        //         let item = items[i];
        //         item = { 'tag_id': item.value, 'tag_name': item.label, 'id': item?.id }
        //         entity.tags.push(item)
        //     }
        //     dispatch({ type: 'entity', payload: { ...entity, tags: entity.tags } });
        //     if (!e.removedValue?.id?.startsWith('temp.')) _api[type].deleteTag(e.removedValue.id);
        // }
    }

    // const onRemoveKeyword = (val) =>{
    //     // const index = data[selected].values.indexOf(val);
    //     // if(index !== -1){
    //     //     data[selected].values.splice(index, 1);
    //     //     setTagArray(data[selected].values)
    //     // }
    // }

    return (
        <div>
            <PageTitle title={'Featured Sites on the Landing page'} />
            <hr className='cnow-hr' />

            <div>
                <CollapsableSection collapsable={true} title="FEATURED" count='1 item'
                    button={{
                        close: { show: true, onHandle: () => { } }
                    }}>
                    <CollapsableSection textColor="cnow-text-primary" collapsable={true} title="FEATURED FOR COLLECTION PAGES"
                        button={{
                            edit: { show: true, onHandle: () => { handleSelect("tag")} },
                        }}
                        select={{
                            entity: { show: true, options: options, onHandle: (e) => { handleSelect(e) } },
                        }}>

                    </CollapsableSection>
                    {(selected.length !== 0) && <CollapsableSection textColor="text-white" bgColor="cnow-bg-red" collapsable={false} title="EDIT INFORMATION"
                        button={{
                            edit: { show: true, onHandle: () => { } },
                            reset: { show: true, onHandle: () => { } },
                            save: { show: true, onHandle: () => { } },
                        }}>
                        <div className='mt-2 mx-5'>
                            <label className='cnow-form-title'>{data[selected]?.title}</label>
                            <AsyncCreatableSelect cacheOptions defaultOptions styles={customSelectStyle}
                                loadOptions={searchTags} value={data[selected]?.values}
                                onChange={(items, e) => handleChange(items, e)} isClearable={false} 
                            />
                            
                            {/* <span className='badge badge-search'>
                                {tagArray.map((val) => (
                                    <>
                                        {val}
                                        <span style={{ cursor: 'pointer' }} onClick={() => onRemoveKeyword(val)}>
                                            <Icon name='close' size={10} left={4} />
                                        </span>
                                    </>
                                ))}
                            </span> */}
                        </div>
                       
                    </CollapsableSection>}
                </CollapsableSection>
            </div>
        </div>
    )
}

export default SiteOfTheDay