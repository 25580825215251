/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "./buttons/default";
import ImageUpload from "../pages/image/upload";
import uniqid from 'uniqid';
import Spinner from "./spinner";
import TaggedImageViewer from "./taggedImageViewer";
import ThenNowListView from "./thenNowListView";
import { getNewItemIndex } from "../helpers";

const _api = require('../api')

const InThisPhoto = (props) => {

    const dispatch = useDispatch();

    const entity = useSelector(state => state[props.id]);

    const [uploader, showUploader] = useState(false);
    const [spinner, showSpinner] = useState(false);

    const onSave = async (items) => {
        if (!entity.images) entity.images = []
        for (let i in items) {
            let item = items[i]
            if (entity.images.findIndex(x => x.image_id === item.id) < 0 && item.type === 'then_now') {
                item = {
                    'id': `temp.${uniqid()}`,
                    'type': item.type,
                    'image_id': item.id,
                    'image_credits': '',
                    'image_caption': items.caption,
                    'image_url': items.url,
                    'date_id': null,
                    'location_id': null,
                    'sort': items.length + 1,
                    'taken_date': null
                }
                item[`${props.type}_id`] = entity.id
                entity.images.push(item)
                let related_images = await _api.image.getRelatedImage(item.image_id)
                related_images.forEach(img => {
                    if (entity.images.findIndex(x => x.image_id === img.related_image_id) < 0) {
                        let related_img_item = {
                            'id': `temp.${uniqid()}`,
                            'type': 'image',
                            'image_id': img.related_image_id,
                            'image_credits': '',
                            'image_caption': img.related_image_caption,
                            'image_url': '',
                            'date_id': null,
                            'location_id': null,
                            'sort': entity.images.length + 1,
                            'taken_date': null
                        }
                        let index = getNewItemIndex(entity.images.filter((img) => img.type !== 'then_now'), entity.dates, null)
                        entity.images.splice(index, 0, related_img_item)
                    }
                });
            }
        }
        entity.images.forEach((image, index) => image.sort = index + 1)
        showSpinner(true)
        entity.images = await _api[props.type].updateImage(entity.id, entity.images)
        dispatch({ type: 'entity', payload: { ...entity, images: entity.images } });
        // if (props.onUpdate) props.onUpdate()
        showSpinner(false)
    }

    return (
        <>
            <hr className='cnow-hr' />
            <label className='cnow-form-title'>In this Photo</label>
            {
                entity.images?.length > 0 && entity.images.map((image, index) => <TaggedImageViewer id={props?.id} data={image} index={index} />)
            }
            <div>
                <hr className='cnow-hr' />
                <div className="row">
                    <div className="col">  <label className='cnow-form-title'>Then & Now Images</label></div>
                    <div className="col-auto"><Button icon='plus' label='Add One More' onClick={() => showUploader(true)} /></div>
                </div>
                {
                    entity.images?.length > 0 && entity.images.filter(x => x.type === 'then_now').map((image, index) => {
                        return (
                            <Spinner display={spinner}>
                                <div className="mt-4">
                                    <ThenNowListView image={image} index={index} id={props.id} />
                                </div>
                            </Spinner>
                        )
                    })}
                {uploader &&
                    <ImageUpload
                        show={uploader}
                        onHide={() => showUploader(false)}
                        onSave={items => onSave(items)}
                        imageType={'then_now'}
                        thenAndNow={true}
                        browse={false}
                        globalSearch={true}
                        entitySearch={true}
                        entityId={entity.id}
                        type={props.type} />
                }
            </div>
        </>);
}
export default InThisPhoto;