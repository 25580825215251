
import { ApiCall } from './utils'

//map
const getById = (id) => ApiCall('get', `/map/${id}`)

const search = (params) => ApiCall('post', `/map/search`, params)

const update = (params) => ApiCall('post', `/map`, params)

const deleteById = (id) => ApiCall('delete', `/map/${id}`)

const updateLive = (id, live) => ApiCall('put', `/map/${id}/live/${live}`)

//Award
const getAward = (id) => ApiCall('get', `/map/${id}/award`)

const updateAward = (id, params) => ApiCall('post', `/map/${id}/award`, params)

const deleteAward = (id) => ApiCall('delete', `/map/award/${id}`)

//category
const getCategory = (id) => ApiCall('get', `/map/${id}/category`)

const updateCategory = (id, params) => ApiCall('post', `/map/${id}/category`, params)

const deleteCategory = (id) => ApiCall('delete', `/map/category/${id}`)

//date
const getDate = (id) => ApiCall('get', `/map/${id}/date`)

const updateDate = (id, params) => ApiCall('post', `/map/${id}/date`, params)

const deleteDate = (id) => ApiCall('delete', `/map/date/${id}`)

//designation
const getDesignation = (id) => ApiCall('get', `/map/${id}/designation`)

const updateDesignation = (id, params) => ApiCall('post', `/map/${id}/designation`, params)

const deleteDesignation = (id) => ApiCall('delete', `/map/designation/${id}`)

//image
const getImage = (id) => ApiCall('get', `/map/${id}/image`)

const updateImage = (id, params) => ApiCall('post', `/map/${id}/image`, params)

const deleteImage = (id) => ApiCall('delete', `/map/image/${id}`)

//location
const getLocation = (id) => ApiCall('get', `/map/${id}/location`)

const updateLocation = (id, params) => ApiCall('post', `/map/${id}/location`, params)

const deleteLocation = (id) => ApiCall('delete', `/map/location/${id}`)

//map
const getRelatedMap = (id) => ApiCall('get', `/map/${id}/related_map`)

const updateRelatedMap = (id, params) => ApiCall('post', `/map/${id}/related_map`, params)

const deleteRelatedMap = (id) => ApiCall('delete', `/map/related_map/${id}`)

//person
const getPerson = (id) => ApiCall('get', `/map/${id}/person`)

const updatePerson = (id, params) => ApiCall('post', `/map/${id}/person`, params)

const deletePerson = (id) => ApiCall('delete', `/map/person/${id}`)

//podcast
const getPodcast = (id) => ApiCall('get', `/map/${id}/podcast`)

const updatePodcast = (id, params) => ApiCall('post', `/map/${id}/podcast`, params)

const deletePodcast = (id) => ApiCall('delete', `/map/podcast/${id}`)

//stories
const getStory = (id) => ApiCall('get', `/map/${id}/story`)

const updateStory = (id, params) => ApiCall('post', `/map/${id}/story`, params)

const deleteStory = (id) => ApiCall('delete', `/map/story/${id}`)

//tag
const getTag = (id) => ApiCall('get', `/map/${id}/tag`)

const updateTag = (id, params) => ApiCall('post', `/map/${id}/tag`, params)

const deleteTag = (id) => ApiCall('delete', `/map/tag/${id}`)

//theme
const getTheme = (id) => ApiCall('get', `/map/${id}/theme`)

const updateTheme = (id, params) => ApiCall('post', `/map/${id}/theme`, params)

const deleteTheme = (id) => ApiCall('delete', `/map/theme/${id}`)

//playlist
const getPlaylist = (id) => ApiCall('get', `/map/${id}/playlist`)

const updatePlaylist = (id, params) => ApiCall('post', `/map/${id}/playlist`, params)

const deletePlaylist = (id) => ApiCall('delete', `/map/playlist/${id}`)

//tours
const getTour = (id) => ApiCall('get', `/map/${id}/tour`)

const updateTour = (id, params) => ApiCall('post', `/map/${id}/tour`, params)

const deleteTour = (id) => ApiCall('delete', `/map/tour/${id}`)

//Get By PersonId
const getByPersonId = (id) => ApiCall('get', `/site?people=${id}&show_all=true`)

// Get By Related PersonId
const getByRelatedPersonId = (id) => ApiCall('get', `/site?related_people=${id}`)

//Get By Date PersonId
const getByDatePersonId = (id) => ApiCall('get', `/site?date_people=${id}&show_all=true`)

// related entity
const getRelatedPerson = (id) => ApiCall('get', `/map/${id}/related_person`)

const getRelatedSite = (id) => ApiCall('get', `/map/${id}/related_site`)

// then_now
const getOnThisDay = (id) => ApiCall('get', `/map/on_this_day/${id}`)

const updateOnThisDay = (params) => ApiCall('post', `/map/on_this_day`, params)

const deleteOnThisDay = (id) => ApiCall('delete', `/map/on_this_day/${id}`)

//overlays
const getOverlay = (id) => ApiCall('get', `/map/${id}/map_overlay`)

const updateOverlay = (id, params) => ApiCall('post', `/map/${id}/map_overlay`, params)

const deleteOverlay = (id) => ApiCall('delete', `/map/map_overlay/${id}`)

//Automated Playlist 

const automatedPlaylist = (id) => ApiCall('post', `/map/${id}/playlist/sync`, '')

//Tour Event
const getEvent = (id) => ApiCall('get', `/map/${id}/event `)

const updateEvent = (id, params) => ApiCall('post', `/map/${id}/event `, params)

const deleteEvent = (id) => ApiCall('delete', `/map/event/${id}`)

//Event series

const getEventSeries = (id) => ApiCall('get', `/map/${id}/event_series `)

const updateEventSeries = (id, params) => ApiCall('post', `/map/${id}/event_series`, params)

const deleteEventSeries = (id) => ApiCall('delete', `/map/event_series/${id}`)

//on this day
const updateListOnThisDay = (map_id, id, value) => ApiCall('put', `/map/${map_id}/on_this_day/${id}/live/${value}`)

//curator
const getCurator = (id) => ApiCall('get', `/map/${id}/curator`)
const updateCurator = (id, params) => ApiCall('post', `/map/${id}/curator`, params)

export default {
    getById,
    search,
    deleteById,
    update,
    updateLive,

    getByPersonId,
    getByDatePersonId,
    getByRelatedPersonId,

    getDate,
    updateDate,
    deleteDate,

    getLocation,
    updateLocation,
    deleteLocation,

    getStory,
    updateStory,
    deleteStory,

    getCategory,
    updateCategory,
    deleteCategory,

    getAward,
    updateAward,
    deleteAward,

    getDesignation,
    updateDesignation,
    deleteDesignation,

    getPerson,
    updatePerson,
    deletePerson,

    getTag,
    updateTag,
    deleteTag,

    getTour,
    updateTour,
    deleteTour,

    getRelatedSite,

    getRelatedPerson,

    getImage,
    updateImage,
    deleteImage,

    getTheme,
    updateTheme,
    deleteTheme,

    getPlaylist,
    updatePlaylist,
    deletePlaylist,

    getPodcast,
    updatePodcast,
    deletePodcast,

    getRelatedMap,
    updateRelatedMap,
    deleteRelatedMap,

    getOnThisDay,
    updateOnThisDay,
    deleteOnThisDay,

    getOverlay,
    updateOverlay,
    deleteOverlay,

    automatedPlaylist,

    getEvent,
    updateEvent,
    deleteEvent,

    getEventSeries,
    updateEventSeries,
    deleteEventSeries,

    updateListOnThisDay,

    getCurator,
    updateCurator
}